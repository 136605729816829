import { ReactElement, ReactNode } from 'react';
import { Typography } from 'antd';
import './style.scss';
const { Text } = Typography;
interface Props {
  children: ReactNode;
}
function ErrorMessage({ children }: Props): ReactElement {
  return (
    <Text strong className="error-messsage" type="danger">
      {' '}
      {children}
    </Text>
  );
}

export default ErrorMessage;
