import { createReducer } from '@reduxjs/toolkit';
import { asyncFetchInterface } from '@Types/ReduxTypes';
import { AllReportFetchResponse } from '@Types/Report';
import { getAllReports } from './action';

interface IInitialState {
  reports: asyncFetchInterface<AllReportFetchResponse>;
}

const initialState: IInitialState = {
  reports: {
    data: [],
    status: 'Empty',
  },
};

const ReportsReducer = createReducer(initialState, builder => {
  builder
    .addCase(getAllReports.pending, state => {
      state.reports = {
        ...state.reports,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllReports.fulfilled, (state, action) => {
      state.reports.data = action.payload;
      state.reports.status = 'fulfilled';
    })
    .addCase(getAllReports.rejected, (state, action) => {
      state.reports = {
        ...state.reports,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default ReportsReducer;
