import { MultipleEntityActionsButton } from '@Components/MultipleEntityActionsButton';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

type SelectAllButtonGroupProps = {
  onSelectAll?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  showActionButtons?: boolean;
  editGroup?: boolean;
};

export function SelectAllButtonGroup({
  onSelectAll,
  onDelete,
  showActionButtons,
  editGroup,
  onEdit,
}: SelectAllButtonGroupProps) {
  const { t } = useTranslation();

  return (
    <Button.Group>
      <Button onClick={onSelectAll}>{t('selectAll')}</Button>
      <MultipleEntityActionsButton
        hidden={!showActionButtons}
        onDelete={onDelete}
        onEdit={onEdit}
        editGroup={editGroup}
      />
    </Button.Group>
  );
}
