import { Form, Button, Row } from 'antd';
import { useMemo, useState } from 'react';
import { SaveOutlined } from '@ant-design/icons';
import { HardwareListTable } from './HardwareListTable/HardwareListTable';
import { BoardBrandCamera } from '@Types/Hardware';
import { apiCall } from '@Utils/index';
import BoardBrandModelService from '@Services/Api/BoardBrandModel';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@Store/hooks';
import { getBoardBrandCameras } from '@Store/Hardware/action';

type Props = {
  initialHardwares: Omit<BoardBrandCamera, 'Id'>[];
  getHardwares: () => void;
};

interface IForm {
  hardwares: BoardBrandCamera[];
}

export const HardwareListForm = (props: Props) => {
  const { getHardwares, initialHardwares } = props;

  const [initialData] = useState(initialHardwares);
  const [data, setData] = useState({ hardwares: initialHardwares });
  const [form] = Form.useForm<IForm>();

  const dispatch = useAppDispatch();

  function handleSubmit(values: IForm) {
    apiCall(
      () =>
        new BoardBrandModelService().CreateBoardBrandModelCamera(
          values.hardwares
        ),
      'default',
      () => {
        dispatch(getBoardBrandCameras());
        setData(values);
      }
    );
  }

  function handleDelete(id: number) {
    apiCall(
      () => new BoardBrandModelService().DeleteHardware(id),
      'default',
      () => {
        dispatch(getBoardBrandCameras());
        getHardwares();

        setData(data => ({
          ...data,
          hardwares: Array.isArray(data.hardwares)
            ? data.hardwares.filter(
                hardware => hardware.BoardBrandModelId !== id
              )
            : [],
        }));
      }
    );
  }

  const hardwares = Form.useWatch('hardwares', form);

  const showSubmitButton = useMemo(() => {
    return JSON.stringify(initialData) !== JSON.stringify(hardwares);
  }, [hardwares]);

  const { t } = useTranslation();

  return (
    <Form
      form={form}
      name="hardware-list-form"
      onFinish={handleSubmit}
      initialValues={data}
    >
      <Form.List name="hardwares">
        {(hardwares, { remove }) => (
          <HardwareListTable
            form={form}
            hardwares={hardwares}
            remove={remove}
            onDelete={handleDelete}
          />
        )}
      </Form.List>

      {showSubmitButton && (
        <>
          <br />
          <Row>
            <Form.Item style={{ marginLeft: 'auto' }}>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                {t('saveChanges')}
              </Button>
            </Form.Item>
          </Row>
        </>
      )}
    </Form>
  );
};
