import {
  AimOutlined,
  BarChartOutlined,
  CameraOutlined,
  FolderOpenOutlined,
  InboxOutlined,
  ShareAltOutlined,
  WifiOutlined,
} from '@ant-design/icons';
import EditButton from '@Components/Card/EditButton';
import CardButton from '@Components/CardButton';
import { Popconfirm } from '@Components/Popconfirm';
import StoresService from '@Services/Api/Stores';
import { FetchedStore } from '@Types/Store';
import { Space } from 'antd';

export enum StoreActionButtonEnums {
  ALL,
  CAMERAS,
  BOARDS,
  CONNECT_CAMERAS,
  DATA,
  ARCHIVE,
  WIFI_CONFIG,
  EDIT,
}

interface Props {
  store: FetchedStore;
  isArchived: boolean;
  getStores: () => void;
  hiddenButtons?: StoreActionButtonEnums[];
}

export default function StoreActionButtons({
  store,
  isArchived,
  getStores,
  hiddenButtons,
}: Props) {
  const toggleArchiveType = async () => {
    try {
      if (isArchived) {
        await new StoresService().UnarchiveStore(store.StoreId);
      } else {
        await new StoresService().ArchiveStore(store.StoreId);
      }

      getStores();
    } catch (e) {
      console.warn(e);
    }
  };

  if (hiddenButtons?.includes(StoreActionButtonEnums.ALL)) {
    return <></>;
  }

  return (
      <Space direction="horizontal" size="small" wrap>
        {!hiddenButtons?.includes(StoreActionButtonEnums.CAMERAS) && (
          <CardButton
            icon={<CameraOutlined />}
            tooltipKey="cameras"
            to={`/all-stores/${store.StoreId}/cameras`}
            locationState={{ showArchived: isArchived }}
          />
        )}

        {!hiddenButtons?.includes(StoreActionButtonEnums.BOARDS) && (
          <CardButton
            icon={<AimOutlined />}
            tooltipKey="boards"
            to={`/brands/${store.BrandId}/stores/${store.StoreId}/boards`}
            locationState={{ showArchived: isArchived }}
          />
        )}

        {!hiddenButtons?.includes(StoreActionButtonEnums.CONNECT_CAMERAS) && (
          <CardButton
            icon={<ShareAltOutlined />}
            tooltipKey={'connectCameras'}
            to={`/all-stores/${store.StoreId}/connect-cameras`}
          />
        )}

        {!hiddenButtons?.includes(StoreActionButtonEnums.DATA) && (
          <CardButton
            icon={<BarChartOutlined />}
            tooltipKey={'navigation.data'}
            to={`/all-stores/${store.StoreId}/data`}
          />
        )}

        {!hiddenButtons?.includes(StoreActionButtonEnums.ARCHIVE) &&
          (!isArchived ? (
            <Popconfirm onConfirm={() => toggleArchiveType()}>
              <CardButton
                icon={<FolderOpenOutlined />}
                tooltipKey={'archive'}
              />
            </Popconfirm>
          ) : (
            <CardButton
              icon={<InboxOutlined />}
              tooltipKey={'unarchive'}
              to={`/all-stores/${store.StoreId}/unarchive`}
            />
          ))}

        {!hiddenButtons?.includes(StoreActionButtonEnums.WIFI_CONFIG) && (
          <CardButton
            icon={<WifiOutlined />}
            tooltipKey="Wifi Konfigürasyonu"
            to={`/all-stores/${store.StoreId}/wifi-configiration`}
          />
        )}

        {!hiddenButtons?.includes(StoreActionButtonEnums.EDIT) && (
          <EditButton to={`/brands/${store.BrandId}/stores/${store.StoreId}`} />
        )}
      </Space>
  );
}
