import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Popover, Space } from 'antd';
import moment from 'moment';
import DateRangeCard from './components/DateRangeCard/DateRangeCard';
import NewDateRangeForm from './components/NewDateRangeForm/NewDateRangeForm';
import { IDateRange, IDateRangeForm } from './types';
import './style.scss';

export interface IMultipleDateRangeSelectorProps {
  onChange: (dateRanges: IDateRange[]) => void;
  dateRanges: IDateRange[];
}

export default function MultipleDateRangeSelector({
  dateRanges,
  onChange,
}: IMultipleDateRangeSelectorProps) {
  const handleDateRangeChange = ({ startDate, duration }: IDateRangeForm) => {
    const endDate = moment(startDate).add(duration, 'minutes');
    const newDateRange: IDateRange = { startDate, endDate, duration };
    onChange([...dateRanges, newDateRange]);
  };

  const handleDateRangeDelete = (dateRangeToDelete: IDateRange) => {
    const newDateRanges = [...dateRanges].filter(
      dateRange =>
        !(
          dateRange.startDate.isSame(dateRangeToDelete.startDate) &&
          dateRange.endDate.isSame(dateRangeToDelete.endDate) &&
          dateRange.duration === dateRangeToDelete.duration
        )
    );

    onChange(newDateRanges);
  };

  return (
    <div className="multiple-start-date-selector">
      {dateRanges.map((dateRange, i) => (
        <DateRangeCard
          key={i}
          dateRange={dateRange}
          onDelete={handleDateRangeDelete}
        />
      ))}
      <Popover
        trigger="click"
        content={<NewDateRangeForm onSubmit={handleDateRangeChange} />}
      >
        <Button
          icon={<PlusCircleOutlined />}
          className="add-date-range-button"
        />
      </Popover>
    </div>
  );
}
