import { Card, Form, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import './style.scss';
import { useAppSelector } from '@Store/hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/lib/form/Form';
import Meta from 'antd/lib/card/Meta';
import ModalHeader from '@Pages/Users/Components/ModalHeader';
import ModalFooter from '@Components/ModalFooter';
import { NewRole } from '@Types/Role';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import RoleService from '@Services/Api/Role';
import usePermissions from '@Hooks/usePermissions';
import Permissions from '../../Components/Permissions';
import { apiCall, checkboxPageUpdater } from '@Utils/index';
import { useNavigate } from 'react-router';
import { Permissions as PermissionsEnum } from '@Enums/Permission';

const RoleEdit = () => {
  const roles = useAppSelector(state => state.Role.allRoles);
  const perms = usePermissions();

  useEffect(() => form.resetFields(), [perms.status, roles.status]);

  let navigate = useNavigate();
  const [form] = useForm();
  const { t } = useTranslation();

  const handleSubmit = async (newRole: NewRole) => {
    const role: NewRole = {
      RoleName: newRole.RoleName,
      PermissionIds: newRole.Permissions?.map(
        p => perms.data.find(d => d.Name === p)?.Id!
      )!,
    };
    try {
      await apiCall(() => addRole(role), 'newRole');
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const addRole = async (newRole: NewRole) => {
    return await new RoleService().New(newRole);
  };

  const inputRule = [{ required: true, message: t('cannotBeEmpty') }];

  const onCheckboxChange = (e: CheckboxChangeEvent): void => {
    let Permissions = form.getFieldValue('Permissions') as string[];
    const isChecked = e.target.checked;
    const permission = e.target.value as string;

    const permIndex = Permissions.findIndex(p => p === permission);

    if (isChecked && permIndex === -1) {
      Permissions.push(permission);
    } else {
      Permissions.splice(permIndex, 1);
    }

    // Make sure main personnel page is selected if any sub-pages are selected
    Permissions = checkboxPageUpdater({
      mainPage: PermissionsEnum.PANEL_PAGE_PERSONNEL,
      subPages: [
        PermissionsEnum.PANEL_PAGE_PERSONNEL_PERSONNEL,
        PermissionsEnum.PANEL_PAGE_RECEIVER,
      ],
      values: Permissions,
    });

    // Make sure main settings page is selected if any sub-pages are selected
    Permissions = checkboxPageUpdater({
      mainPage: PermissionsEnum.PANEL_PAGE_SETTINGS,
      subPages: [
        PermissionsEnum.PANEL_PAGE_SETTINGS_CAMERA,
        PermissionsEnum.PANEL_PAGE_SETTINGS_NOTIFICATIONS,
        PermissionsEnum.PANEL_PAGE_SETTINGS_DEPARTMENT,
        PermissionsEnum.PANEL_PAGE_SETTINGS_WORKING_HOURS,
        PermissionsEnum.PANEL_PAGE_SETTINGS_SALES_UPLOADING,
        PermissionsEnum.PANEL_PAGE_SETTINGS_CAMERA_SNAPSHOT,
        PermissionsEnum.PANEL_PAGE_SETTINGS_FORECAST,
        PermissionsEnum.PANEL_PAGE_SETTINGS_CAMPAIGN,
        PermissionsEnum.PANEL_PAGE_SETTINGS_IFRAME,
        PermissionsEnum.PANEL_PAGE_SETTINGS_UPLOAD_REPORTS,
        PermissionsEnum.PANEL_PAGE_SETTINGS_SKETCH,
        PermissionsEnum.PANEL_PAGE_SETTINGS_REPORTS,
      ],
      values: Permissions,
    });

    form.setFieldsValue({ Permissions });
  };

  const initialValues = {
    RoleName: '',
    Permissions: [],
  };

  const values = (Form.useWatch('Permissions', form) as string[]) || [];

  return (
    <div className="wrapper">
      <Card
        title={
          <ModalHeader titleKey="newRole">
            <EditOutlined />
          </ModalHeader>
        }
        className="role-edit-wrapper"
      >
        <Form
          form={form}
          name="role-info-form"
          id="role-info-form"
          onFinish={handleSubmit}
          initialValues={initialValues}
          className="role-edit-container"
        >
          <Form.Item label={t('name')} name="RoleName" rules={inputRule}>
            <Input />
          </Form.Item>

          <Permissions
            values={values}
            intlValues={initialValues}
            onCheckboxChange={onCheckboxChange}
          />

          <ModalFooter formKey="role-info-form" />
        </Form>
      </Card>
    </div>
  );
};

export default RoleEdit;
