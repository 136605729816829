import FormCard from '@Components/FormCard';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import GatewayTypes from '@Types/Gateway';
import PageSkeletons from '@Components/PageSkeletons';
import GatewayForm from '@Forms/GatewayForm';
import { getGatewayById } from '@Store/Gateway/action';
import GatewaysService from '@Services/Api/Gateway';
import { apiCall } from '@Utils';

type Props = {};

export default function GatewayEdit({}: Props) {
  const { gatewayId } = useParams();
  const dispatch = useAppDispatch();
  const gatewayData = useAppSelector(s => s.Gateaways.gatewayData);
  let navigate = useNavigate();

  useEffect(() => {
    if (gatewayId) {
      dispatch(getGatewayById(parseInt(gatewayId)));
    }
  }, [gatewayId]);

  const handleSubmit = async (newGateway: GatewayTypes.NewGateway) => {
    let editedGatewayData: GatewayTypes.NewGateway = {
      StoreId: gatewayData.data?.StoreId,
      ExternalId: newGateway.ExternalId,
      Setup: newGateway.Setup,
    };
    try {
      await apiCall(() => editGateway(editedGatewayData), 'editGateway');
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const editGateway = async (newGateway: GatewayTypes.EditedGateway) => {
    return await new GatewaysService().EditGateway(
      parseInt(gatewayId!),
      newGateway
    );
  };

  if (Object.keys(gatewayData).length === 0 || gatewayData.status === 'Pending')
    return <PageSkeletons />;
  return (
    <FormCard titleIcon={<EditOutlined />} titleKey="editGateway">
      <GatewayForm onFinish={handleSubmit} gatewayData={gatewayData.data} />
    </FormCard>
  );
}
