import { Form, Modal, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectCameraContext } from '../../Contexts/ConnectCameraContext';

type Props = {
  show: boolean;
  onCancel: () => void;
  onOk: (values: { groupName: string }) => void;
  initialValues?: { groupName: string };
};
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

function PolygonGroupModal({ show, onCancel, onOk, initialValues }: Props) {
  const { ExistGroupNames } = useContext(ConnectCameraContext);
  const { t } = useTranslation();
  const [form] = useForm<{ groupName: string }>();
  const modalOnOk = () => {
    form.submit();
  };
  const preSubmit = (values: { groupName: string }) => {
    const groupName = form.getFieldValue('groupName');
    if (isAvailableInExistNames(groupName)) {
      form.setFields([
        {
          name: 'groupName',
          errors: ['Grup zaten var!'],
        },
      ]);
    } else {
      onOk(values);
    }
  };
  const isAvailableInExistNames = (groupName: string) => {
    return ExistGroupNames.includes(groupName);
  };
  return (
    <Modal
      title={t('modal.title.polygonGroup')}
      open={show}
      onOk={modalOnOk}
      onCancel={onCancel}
      destroyOnClose
    >
      <Form
        {...layout}
        initialValues={initialValues}
        onFinish={preSubmit}
        form={form}
        preserve={false}
      >
        <Form.Item
          name="groupName"
          label={t('groupName')}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default PolygonGroupModal;
