import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { CLEAR_TICKET, GET_TICKET_DETAILS, GET_TICKETS } from './type';
import { PaginationParams } from '@Types/Filter';
import { TicketService } from '@Services/Api/TicketService';

export const getTickets = createAsyncThunk(
  GET_TICKETS,
  async (params: PaginationParams) => {
    try {
      const tickets = await new TicketService().GetTickets(params);
      return tickets;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getTicketDetails = createAsyncThunk(
  GET_TICKET_DETAILS,
  async (id: number) => {
    try {
      const ticket = await new TicketService().GetTicket(id);
      return ticket;
    } catch (err) {
      console.error(err);
    }
  }
);

export const clearTicket = createAction(CLEAR_TICKET);
