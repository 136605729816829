import {
  CreateBeaconRule,
  IBeaconRule,
  IStoreBeacon,
  IStoreReceiver,
} from '@Types/BeaconRule';
import { PaginationParams } from '@Types/Filter';
import { PaginationData } from '@Types/index';
import model from './index';

class BeaconRulesService extends model {
  constructor() {
    super();
    this.Prefix('BeaconRules');
  }

  /**
   * @description Create a new beacon rule
   */
  async CreateBeaconRule(form: CreateBeaconRule) {
    return this.Post().Data(form).Save();
  }

  /**
   * @description Get all beacon rules
   */
  async GetAllBeaconRules(params: PaginationParams) {
    return this.Post('GetAll').Data(params).Save<PaginationData<IBeaconRule>>();
  }

  /**
   * @description Get an beacon rules details by its id
   */
  async GetStoreBeacons(brandId: number) {
    return this.Get(`StoreBeacons/${brandId}`).Save<IStoreBeacon[]>();
  }

  /**
   * @description Get an beacon rules details by its id
   */
  async GetStoreReceivers(brandId: number) {
    return this.Get(`StoreReceivers/${brandId}`).Save<IStoreReceiver[]>();
  }

  /**
   * @description Delete an beacon rule
   */
  async DeleteBeaconRule(beaconRuleId: number) {
    return this.PrefixId(beaconRuleId).Delete().Save();
  }
}

export default BeaconRulesService;
