import Status from '@Enums/Status';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { FetchedBrand } from '@Types/Brand';
import { GetAllBrandGroupsData } from '@Types/BrandGroup';
import { asyncFetchInterface } from '@Types/ReduxTypes';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import { BrandGroupActionButtons } from '@Features/brand-groups/components';

interface Params {
  brandGroups: asyncFetchInterface<GetAllBrandGroupsData>;
  onRefetch: () => void;
}

/**
 *
 * @description This "hook" is not a generic hook. It's made to abstract the code and make it much more readable. It will only be used for <AllBrands/>.
 * @returns
 */
export default function useBrandGroupsColumns({
  brandGroups,
  onRefetch,
}: Params): ColumnsType<FetchedBrand> {
  const { t } = useTranslation();

  const actionPermission = useCheckPermission(Permissions.ADMIN_BRAND_ACTION);

  const brandsTableColumns: ColumnsType<FetchedBrand> = useMemo(() => {
    if (brandGroups.status !== Status.success) return [];

    const columns: ColumnsType<FetchedBrand> = [
      {
        title: t('name'),
        dataIndex: 'Name',
      },
    ];

    if (actionPermission) {
      columns.push({
        title: t('actionButtons'),
        dataIndex: 'actionButtons',
        render: (_: any, brandGroup) => (
          <BrandGroupActionButtons id={brandGroup.Id} onRefetch={onRefetch} />
        ),
      });
    }

    return columns;
  }, [brandGroups.status, t]);

  return brandsTableColumns;
}
