import Status from '@Enums/Status';
import AdminService from '@Services/Api/Admin';
import { getConfigSchemas } from '@Store/Config/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import {
  ConfigBase,
  ConfigPageParams,
  ConfigSchemaWithoutId,
} from '@Types/Config';
import { apiCall } from '@Utils/index';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ConfigDetail from '../ConfigDetail';

export default function ConfigEdit() {
  const { configId } = useParams() as ConfigPageParams;
  const dispatch = useAppDispatch();
  const configSchemas = useAppSelector(s => s.Config.configSchemas);
  let navigate = useNavigate();

  const configSchema: ConfigBase = useMemo(() => {
    if (configSchemas.status !== Status.success) return {} as ConfigBase;

    return configSchemas.data.find(c => c.Id === parseInt(configId!))!;
  }, [configSchemas.status]);

  const editConfigSchema = async (schema: ConfigSchemaWithoutId) => {
    try {
      const req = () =>
        new AdminService().UpdateConfigSchema(parseInt(configId!), schema);
      await apiCall(req, 'editConfigSchema');
      dispatch(getConfigSchemas());
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  return <ConfigDetail schema={configSchema} onSubmit={editConfigSchema} />;
}
