import { PlusCircleOutlined } from '@ant-design/icons';
import * as VersionTypes from '@Types/Version';
import VersionService from '@Services/Api/Version';
import FormCard from '@Components/FormCard';
import VersionForm from '@Forms/VersionForm';
import { apiCall } from '@Utils';
import { useNavigate } from 'react-router-dom';

const NewVersion = () => {
  let navigate = useNavigate();

  const handleSubmit = async (newVersion: VersionTypes.VersionForm) => {
    let newVersionData: VersionTypes.NewVersion = {
      Name: newVersion.Name,
      Version: newVersion.Version,
      CompMain: { Id: newVersion.CompMainId },
      CompModel: { Id: newVersion.CompModelId },
      CompBeacon: { Id: newVersion.CompBeaconId },
      CompControl: { Id: newVersion.CompControlId },
      HardwareId: newVersion.HardwareId,
      ImageVersion: newVersion.ImageVersion,
    };
    try {
      await apiCall(() => addVersion(newVersionData), 'newVersion');
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const addVersion = async (newVersion: VersionTypes.NewVersion) => {
    return await new VersionService().New(newVersion);
  };

  return (
    <FormCard
      className="version-form"
      titleIcon={<PlusCircleOutlined />}
      titleKey="addVersion"
    >
      <VersionForm onFinish={handleSubmit} />
    </FormCard>
  );
};

export default NewVersion;
