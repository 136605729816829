import ModalFooter from '@Components/ModalFooter';
import { BeaconRuleEnum } from '@Features/beaconRules/enums';
import { CreateBeaconRule, IBeaconRulesForm } from '@Types/BeaconRule';
import { Alert, Divider, Form } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import {
  BeaconRuleBeaconSelector,
  BeaconRuleBrandSelector,
  BeaconRuleReceiverSelector,
  BeaconRuleTypeSelector,
  BeaconRuleUserSelector,
  BeaconRuleValueSelector,
} from './components';
import { BeaconRuleFormDescription } from './components/BeaconRuleFormDescription';
import {
  useBeaconRuleSelectedStoreIds,
  useDisableBeaconRuleSendButton,
  useMergeBeaconsAndReceivers,
} from './hooks';

type BeaconRuleFormProps = {
  onSubmit: (value: CreateBeaconRule) => void;
};

export function BeaconRuleForm({ onSubmit }: BeaconRuleFormProps) {
  const [form] = useForm<IBeaconRulesForm>();

  const type = useWatch('Type', form);
  const value = useWatch('Value', form);
  const brandId = useWatch('BrandId', form);

  const sendButtonDisableState = useDisableBeaconRuleSendButton(form);

  const storeIds = useBeaconRuleSelectedStoreIds(form);

  const mergeBeaconsAndReceivers = useMergeBeaconsAndReceivers();

  function handleSubmit(values: IBeaconRulesForm) {
    onSubmit({
      Type: values.Type,
      UserIds: values.UserIds,
      Value: values.Value,
      BeaconReceivers: mergeBeaconsAndReceivers(values, storeIds),
    });
  }

  return (
    <Form
      form={form}
      name="beacon-rule-form"
      onFinish={handleSubmit}
      initialValues={{
        Type: BeaconRuleEnum.Closed,
        BeaconIds: [],
        ReceiverIds: [],
        Value: 1,
      }}
    >
      <BeaconRuleTypeSelector />

      <Divider />
      <BeaconRuleBrandSelector form={form} />
      <BeaconRuleBeaconSelector disabled={!brandId} form={form} />
      <BeaconRuleReceiverSelector disabled={!brandId} form={form} />
      <BeaconRuleUserSelector storeIds={storeIds} />
      <Divider />

      <BeaconRuleValueSelector type={type} />

      <Divider />
      <BeaconRuleFormDescription type={type} amount={value} />
      <Divider />

      {sendButtonDisableState.reason && (
        <>
          <Alert
            type="warning"
            message={sendButtonDisableState.reason}
            showIcon
          />
          <Divider />
        </>
      )}

      <ModalFooter
        formKey="beacon-rule-form"
        sendButtonDisabled={sendButtonDisableState.disabled}
      />
    </Form>
  );
}
