import CardGridWrapper from '@Components/CardGridWrapper';
import GoBackButton from '@Components/GoBackButton';
import useBrands from '@Hooks/useBrands';
import { getAllApiKeys, getAllAvailableBrands } from '@Store/Brand/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { Button, Card } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import ApiKeyCard from './ApiKeyCard';

function ApiKeyList() {
  const { t } = useTranslation();

  const { brandId } = useParams();
  const dispatch = useAppDispatch();
  const apiKeys = useAppSelector(s => s.Brand.allApiKeys);
  const allBrands = useAppSelector(s => s.Brand.allBrands.data.Data);

  useEffect(() => {
    dispatch(getAllApiKeys(parseInt(brandId!)));
  }, []);

  const brandName =
    allBrands?.find(b => b.Id === parseInt(brandId!))?.Name || t('brand');

  return (
    <Card title={brandName + ' - ' + t('apiKeyList')}>
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <GoBackButton />
          <Link to="new-api-key">
            <Button type="primary">{t('addApiKey')}</Button>
          </Link>
        </div>
        <div className="card-grid-container">
          <CardGridWrapper
            status={apiKeys.status}
            noData={apiKeys.data?.length === 0}
            cards={
              <React.Fragment>
                {apiKeys.data?.map(apiKey => (
                  <ApiKeyCard apiKey={apiKey} key={apiKey.ApiKey} />
                ))}
              </React.Fragment>
            }
          />
        </div>
      </div>
    </Card>
  );
}

export default ApiKeyList;
