import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GET_ALL_ARCHIVED_CAMERAS,
  GET_ALL_ARCHIVED_CAMERAS_OF_STORE,
  GET_ALL_CAMERAS,
  GET_ALL_CAMERAS_FOR_BRANDS,
  GET_ALL_CAMERAS_FOR_STORES,
  GET_ALL_UNARCHIVED_CAMERAS,
  GET_ALL_UNARCHIVED_CAMERAS_OF_STORE,
  GET_ARCHIVED_CAMERAS,
  GET_CAMERAS_SORTED_BY_STORE_IDS,
  GET_CAMERA_DETAIL,
  GET_UNARCHIVED_CAMERAS,
  RESET_ALL_CAMERAS,
  RESET_CURRENT_CAMERA,
  SET_SELECTED_CAMERA,
  SET_SELECTED_CAMERAS,
} from './type';
import {
  Camera,
  CameraId,
  CamerasByStoreId,
  GetCamerasParams,
} from '@Types/Camera';
import BoardsService from '@Services/Api/Boards';
import CamerasService from '@Services/Api/Cameras';
import StoreService from '@Services/Api/Store';
import StoresService from '@Services/Api/Stores';
import { RootState } from '..';
import BrandService from '@Services/Api/Brand';

export const getAllCamerasOfBrands = createAsyncThunk(
  GET_ALL_CAMERAS_FOR_BRANDS,
  async (brandIds: number[]) => {
    try {
      const allCamerasPromise = await Promise.all(
        brandIds.map(brandId => new BrandService().GetCameras(brandId))
      );

      const allCameras: Camera[] = [];

      for (const stores of allCamerasPromise) {
        allCameras.push(
          ...stores.filter(store => !allCameras.some(s => s.Id === store.Id))
        );
      }

      return allCameras;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getUnarchivedCameras = createAsyncThunk(
  GET_UNARCHIVED_CAMERAS,
  async (params: GetCamerasParams) => {
    try {
      const allCameras = await new BoardsService().GetUnArchivedCameras(
        params.filters,
        params.boardId!
      );
      return allCameras;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getArchivedCameras = createAsyncThunk(
  GET_ARCHIVED_CAMERAS,
  async (params: GetCamerasParams) => {
    try {
      const allCameras = await new BoardsService().GetArchivedCameras(
        params.filters,
        params.boardId!
      );
      return allCameras;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getAllUnarchivedCameras = createAsyncThunk(
  GET_ALL_UNARCHIVED_CAMERAS,
  async (params: GetCamerasParams) => {
    try {
      const allCameras = await new CamerasService().GetAllUnarchived(params);
      return allCameras;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getAllArchivedCameras = createAsyncThunk(
  GET_ALL_ARCHIVED_CAMERAS,
  async (params: GetCamerasParams) => {
    try {
      const allCameras = await new CamerasService().GetAllArchived(params);
      return allCameras;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getAllUnarchivedCamerasOfStore = createAsyncThunk(
  GET_ALL_UNARCHIVED_CAMERAS_OF_STORE,
  async (params: GetCamerasParams) => {
    const cameras = await new StoresService().GetAllUnarchivedCameras(params);
    return cameras;
  }
);

export const getAllArchivedCamerasOfStore = createAsyncThunk(
  GET_ALL_ARCHIVED_CAMERAS_OF_STORE,
  async (params: GetCamerasParams) => {
    const ameras = await new StoresService().GetAllArchivedCameras(params);
    return ameras;
  }
);

export const getCameraDetail = createAsyncThunk(
  GET_CAMERA_DETAIL,
  async (cameraId: CameraId) => {
    try {
      const camera = await new CamerasService().GetCameraDetail(cameraId);
      return camera;
    } catch (err) {
      console.error(err);
      return {};
    }
  }
);

export const resetAllCameras = createAction(RESET_ALL_CAMERAS);
export const resetCurrentCamera = createAction(RESET_CURRENT_CAMERA);

export const getAllCamerasOfStores = createAsyncThunk(
  GET_ALL_CAMERAS_FOR_STORES,
  async (storeIds: number[], { getState }) => {
    try {
      const oldAllCameras =
        (getState() as RootState).Camera.allCameras.data ?? [];

      const allCamerasPromise = await Promise.all(
        storeIds.map(storeId => new StoreService().GetAll(storeId))
      );

      const allCameras: Camera[] = JSON.parse(JSON.stringify(oldAllCameras));

      for (const cameras of allCamerasPromise) {
        allCameras.push(
          ...cameras.filter(camera => !allCameras.some(c => c.Id === camera.Id))
        );
      }

      return allCameras;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getAllCamerasSortedByStoreIds = createAsyncThunk(
  GET_CAMERAS_SORTED_BY_STORE_IDS,
  async (storeIds: number[]) => {
    try {
      const allCamerasPromise = await Promise.all(
        storeIds.map(storeId => new StoreService().GetAll(storeId))
      );

      let allCameras: CamerasByStoreId = {};
      for (let i = 0; i < storeIds.length; i++) {
        allCameras[storeIds[i]] = allCamerasPromise[i];
      }

      return allCameras;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getAllCameras = createAsyncThunk(
  GET_ALL_CAMERAS,
  async (storeId: number) => {
    try {
      const allCameras = await new StoreService().GetAll(storeId);
      return allCameras;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const setSelectedCamera = createAction(
  SET_SELECTED_CAMERA,
  (selectedCamera: Camera) => {
    return {
      payload: selectedCamera,
    };
  }
);

export const setSelectedCameras = createAction(
  SET_SELECTED_CAMERAS,
  (selectedCameras: Camera[]) => {
    return {
      payload: selectedCameras,
    };
  }
);
