import { ArrowRightOutlined, DeleteOutlined } from '@ant-design/icons';
import { IDateRange } from '@Components/MultipleDateRangeSelector/types';
import useHover from '@Hooks/useHover';
import { Button, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.scss';

const { Text } = Typography;

export interface IDateRangeCardProps {
  dateRange: IDateRange;
  onDelete: (dateRange: IDateRange) => void;
}

export default function DateRangeCard({
  dateRange,
  onDelete,
}: IDateRangeCardProps) {
  const { startDate, endDate, duration } = dateRange;
  const { t } = useTranslation();
  const [cardRef, isHovered] = useHover<HTMLDivElement>();

  const handleDeleteClick = () => onDelete(dateRange);

  const formattedStartDate = startDate.format('DD/MM/YYYY HH:mm');
  const formattedEndDate = endDate.format('DD/MM/YYYY HH:mm');

  return (
    <Tooltip title={`${t('duration')}:  ${duration} ${t('minute')}`}>
      <div className="date-range-card" ref={cardRef}>
        <Button
          onClick={handleDeleteClick}
          className="delete-button"
          shape="circle"
          hidden={!isHovered}
        >
          <DeleteOutlined />
        </Button>
        <Text className="date-range-text">{formattedStartDate}</Text>
        <ArrowRightOutlined size={24} />
        <Text className="date-range-text">{formattedEndDate}</Text>
      </div>
    </Tooltip>
  );
}
