import SearchableSelect from '@Components/SearchableSelect';
import Status from '@Enums/Status';
import { getBrandBeacons } from '@Store/Beacons/action';
import { getAllAvailableBrands } from '@Store/Brand/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getBrandReceivers } from '@Store/Receiver/action';
import { getUserStores } from '@Store/User/action';
import { IBeaconRulesForm } from '@Types/BeaconRule';
import { Form, FormInstance } from 'antd';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type BeaconRuleBrandSelectorProps = {
  form: FormInstance<IBeaconRulesForm>;
};

export function BeaconRuleBrandSelector({
  form,
}: BeaconRuleBrandSelectorProps) {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const brands = useAppSelector(s => s.Brand.allAvailableBrands);

  useEffect(() => {
    if (brands.status !== Status.success) {
      dispatch(getAllAvailableBrands());
    }
  }, []);

  const brandOptions = useMemo(() => {
    if (brands.status !== Status.success) return [];
    return [...brands.data]
      .sort((a, b) => a.Name.localeCompare(b.Name))
      .map(brand => ({ value: brand.Id, label: brand.Name }));
  }, [brands.status]);

  async function handleBrandChange(brandId: number) {
    form.resetFields(['ReceiverIds', 'UserIds', 'BeaconIds']);
    dispatch(getBrandReceivers(brandId));
    dispatch(getBrandBeacons(brandId));
    dispatch(getUserStores(brandId));
  }

  const inputRule = [{ required: true, message: t('cannotBeEmpty') }];

  return (
    <Form.Item label={t('brand')} name="BrandId" rules={inputRule}>
      <SearchableSelect
        onChange={handleBrandChange}
        options={brandOptions}
        loading={brands.status === Status.pending}
      />
    </Form.Item>
  );
}
