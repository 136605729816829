import ModalFooter from '@Components/ModalFooter';
import { Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';

type Props = {
  onFinish?: (values: any) => void;
  lens?: {};
};

function LensForm({ onFinish, lens }: Props) {
  const { t } = useTranslation();
  const [form] = useForm();

  return (
    <Form
      form={form}
      name="lens-add-form"
      id="lens-add-form"
      onFinish={onFinish}
      className="lens-add-container"
      initialValues={lens}
    >
      <Form.Item label={t('name')} name="Name" required>
        <Input required />
      </Form.Item>
      <ModalFooter formKey="lens-add-form" />
    </Form>
  );
}

export default LensForm;
