import { Card } from 'antd';
import ModalHeader from '@Pages/Users/Components/ModalHeader';
import './style.scss';
import GoBackButton from '@Components/GoBackButton';

type Props = {
  children: React.ReactNode;
  titleKey: string;
  extraTitle?: string;
  titleIcon: JSX.Element;
  className?: string;
  showGoBack?: boolean;
  extraButtons?: React.ReactElement;
};

const FormCard = ({
  children,
  titleKey,
  titleIcon,
  className,
  extraTitle,
  showGoBack,
  extraButtons,
}: Props) => {
  return (
    <Card
      title={
        <ModalHeader titleKey={titleKey} extraTitle={extraTitle}>
          {titleIcon}
        </ModalHeader>
      }
      extra={extraButtons}
      className={`form-card-container ${className || ''}`}
    >
      {children}

      {showGoBack && <GoBackButton />}
    </Card>
  );
};

export default FormCard;
