import { DrawElements } from '@Components/Svg/Constants';
import { RefObject, useEffect, useContext, useCallback } from 'react';
import { ParamContext } from '../../Context/Params';
import { SvgContext } from '../../Context/Svg';
import { SvgTasks } from '../../Tasks/Svg';
import { shapeTypes } from '../../Types/Bases';
import { getMouseCoordinates } from '../commons';
export function useClickEvent(ref: RefObject<SVGSVGElement>): void {
  const params = useContext(ParamContext);
  const { createPolygon, editable } = useContext(SvgContext);
  const onClick = (event: MouseEvent) => {
    switch (params.activeType) {
      case 'draw':
        createShape(event);
        break;
      // case 'drawing':
      //   createPoint(DrawElements.Polygon, event);
      //   break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (editable) {
      ref.current?.addEventListener('click', onClick, true);
    }
    return () => {
      ref.current?.removeEventListener('click', onClick, true);
    };
  }, [params.activeType, editable, params.drawType]);

  const createShape = (event: MouseEvent) => {
    PolygonInserter(event);
    //     switch (params.drawType) {
    //   case DrawElements.Polygon:
    //     PolygonInserter(event);
    //     break;

    //   default:
    //     break;
    // }
  };

  const PolygonInserter = (event: MouseEvent) => {
    const cursor = getMouseCoordinates(event, ref.current);
    createPolygon(cursor);
    const proccess = SvgTasks.Type(params, 'click');
    params.updateActiveType(proccess.type);
  };
}

// export function useContextMenu(ref: RefObject<SVGSVGElement>): void {
//   const params = useContext(ParamContext);
//   const { editable } = useContext(SvgContext);
//   const contextMenu = (e: Event) => {
//     e.preventDefault();
//     // const proccess = SvgTasks.Type(params, 'context');
//     // params.updateActiveType(proccess.type);
//   };
//   useEffect(() => {
//     if (editable) {
//       ref.current?.addEventListener('contextmenu', contextMenu, true);
//     }
//     return () => {
//       ref.current?.removeEventListener('contextmenu', contextMenu, true);
//     };
//   }, [params.activeType, editable]);
// }

export function useMoveEvent(ref: RefObject<SVGSVGElement>) {
  const params = useContext(ParamContext);
  const { movePoint, editable } = useContext(SvgContext);
  const mouseOnMove = (event: MouseEvent) => {
    const proccess = SvgTasks.Type(params, 'click');
    switch (proccess.type) {
      // case 'polygon_drawing':
      //   trackMouse(DrawElements.Polygon, event);
      //   break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (editable) {
      ref.current?.addEventListener('mousemove', mouseOnMove, true);
    }
    return () => {
      ref.current?.removeEventListener('mousemove', mouseOnMove, true);
    };
  }, [params.activeType, editable]);
}

export function useKeyDown(ref: RefObject<SVGSVGElement>): void {
  const params = useContext(ParamContext);
  const { editable } = useContext(SvgContext);
  const keydown = (e: Event) => {
    e.preventDefault();
  };
  useEffect(() => {
    if (editable) {
      ref.current?.addEventListener('keydown', keydown, true);
    }
    return () => {
      ref.current?.removeEventListener('keydown', keydown, true);
    };
  }, [params.activeType, editable]);
}
