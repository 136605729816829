import NotificationRuleForm from '@Forms/NotificationRuleForm';
import { NewCameraRule as NewNotificationRuleType } from '@Types/CameraRules';
import CameraRulesService from '@Services/Api/CameraRules';
import { apiCall } from '@Utils/index';
import FormCard from '@Components/FormCard';
import { NotificationOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const NewNotificationRule = () => {
  let navigate = useNavigate();

  const handleSubmit = async (newNotificationRule: NewNotificationRuleType) => {
    const req = async () =>
      await new CameraRulesService().AddCameraRule(newNotificationRule);

    try {
      await apiCall(req, 'addCameraRule');
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormCard
      titleIcon={<NotificationOutlined />}
      titleKey="addNotificationRule"
    >
      <NotificationRuleForm onFinish={handleSubmit} />
    </FormCard>
  );
};

export default NewNotificationRule;
