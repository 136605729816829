import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_CONFIGS } from './type';
import AdminService from '@Services/Api/Admin';

export const getConfigSchemas = createAsyncThunk(GET_CONFIGS, async () => {
  try {
    const configSchemas = await new AdminService().GetConfigSchemas();
    return configSchemas;
  } catch (err) {
    console.error(err);
    return [];
  }
});
