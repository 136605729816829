import { Divider, Space } from 'antd';
import CardEditItem, { CardEditItemProps } from './CardEditItem';

interface Props {
  items: CardEditItemProps[];
  hideDivider?: boolean;
  textWidth?: string;
}

const CardEditInfo = ({ items, hideDivider, textWidth }: Props) => {
  return (
    <>
      <Space
        className="card-edit-info"
        direction="horizontal"
        align="center"
        size="small"
        wrap
      >
        {items.map((item, index) => (
          <CardEditItem
            key={item.id + '-' + index}
            icon={item.icon}
            name={item.name}
            id={item.id}
            tooltip={item.tooltip}
            routeTo={item.routeTo}
            hasPermission={item.hasPermission}
            textWidth={textWidth}
          />
        ))}
      </Space>
      {!hideDivider && <Divider />}
    </>
  );
};

export default CardEditInfo;
