import { UploadOutlined } from '@ant-design/icons';
import { IUploadVersionFileForm } from '@Types/Version';
import { Button, Form, Input, Upload } from 'antd';
import { FormProps } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';

export interface UploadVersionFileFormProps
  extends FormProps<IUploadVersionFileForm> {}

export function UploadVersionFileForm(props: UploadVersionFileFormProps) {
  const { ...restProps } = props;

  const { t } = useTranslation();

  const normFile = (e: any) => {
    return e?.file;
  };

  return (
    <Form
      name="upload-version-form"
      id="upload-version-form"
      labelCol={{ span: 6 }}
      labelWrap
      labelAlign="left"
      wrapperCol={{ span: 24 }}
      {...restProps}
    >
      <Form.Item
        label={t('file')}
        name="file"
        rules={[{ required: true }]}
        valuePropName="file"
        getValueFromEvent={normFile}
      >
        <Upload beforeUpload={() => false} maxCount={1}>
          <Button icon={<UploadOutlined />} size="middle">
            {t('uploadFile')}
          </Button>
        </Upload>
      </Form.Item>

      <Form.Item
        label={t('uploadNote')}
        name="updateNote"
        rules={[{ required: true }]}
      >
        <Input.TextArea rows={6} />
      </Form.Item>
    </Form>
  );
}
