import { PageLogSource } from '@Pages/PageLogs/Enums';
import {
  AndroidOutlined,
  AppleOutlined,
  LaptopOutlined,
} from '@ant-design/icons';
import { Tag } from 'antd';
import './style.scss';

interface IProps {
  source: PageLogSource;
}

export function PageLogSourceTag(props: IProps) {
  const { source = 0 } = props;

  const sources = {
    [PageLogSource.PANEL]: {
      color: 'magenta',
      icon: <LaptopOutlined />,
    },
    [PageLogSource.IOS]: {
      color: 'blue',
      icon: <AppleOutlined />,
    },
    [PageLogSource.ANDROID]: {
      color: 'green',
      icon: <AndroidOutlined />,
    },
  };

  const { color, icon } = sources[source];

  return (
    <Tag className="page-log-source-tag" color={color} icon={icon}>
      {PageLogSource[source]}
    </Tag>
  );
}
