import FormCard from '@Components/FormCard';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import PageSkeletons from '@Components/PageSkeletons';
import { apiCall } from '@Utils';
import LensType from '@Types/LensType';
import LensTypeServices from '@Services/Api/LensType';
import LensForm from '@Forms/LensForm';
import { getLensType } from '@Store/LensType/action';

type Props = {};

export default function LensEdit({}: Props) {
  const { lensId } = useParams();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const lensType = useAppSelector(s => s.LensType.lensType);

  useEffect(() => {
    dispatch(getLensType(parseInt(lensId!)));
  }, [lensId]);

  const handleSubmit = async (newLensType: LensType.NewLensType) => {
    let editedLensType: LensType.NewLensType = {
      Name: newLensType.Name,
    };
    try {
      await apiCall(() => addLensType(editedLensType), 'editedLensType');
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const addLensType = async (newLensType: LensType.NewLensType) => {
    return await new LensTypeServices().UpdateLensType(
      parseInt(lensId!),
      newLensType
    );
  };
  if (lensType.data.length === 0 || lensType.status === 'Pending')
    return <PageSkeletons />;
  return (
    <FormCard titleIcon={<EditOutlined />} titleKey="addLens">
      <LensForm onFinish={handleSubmit} lens={lensType.data} />
    </FormCard>
  );
}
