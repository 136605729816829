import model from './index';
import { Permission } from '@Types/Permission';
class Permissions extends model {
  constructor() {
    super();
    this.Prefix('Permissions');
  }
  /**
   * @description Get all permissions
   * @returns Promise
   */
  async All() {
    return this.Get().Save<Permission[]>();
  }
}

export default Permissions;
