import FormCard from '@Components/FormCard';
import { Form, Input } from 'antd';
import BrandService from '@Services/Api/Brands';
import { ApiKeyRequest } from '@Types/Brand';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import ModalFooter from '@Components/ModalFooter';
import { apiCall } from '@Utils';

const AddApiKey = () => {
  const { t } = useTranslation();
  const { brandId } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (newApiKey: ApiKeyRequest) => {
    try {
      await apiCall(() => addNewApiKey(newApiKey), 'addApiKey');
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const addNewApiKey = async (newApiKey: ApiKeyRequest) => {
    return await new BrandService().NewApiKey(parseInt(brandId!), newApiKey);
  };

  return (
    <FormCard
      titleIcon={<LockOutlined />}
      titleKey="addApiKey"
      className="brand-detail-wrapper"
    >
      <Form onFinish={handleSubmit} name="api-key-form">
        <Form.Item name="Result" label={t('description')}>
          <Input placeholder={t('enterValidReason')} />
        </Form.Item>
      </Form>

      <ModalFooter formKey="api-key-form" />
    </FormCard>
  );
};

export default AddApiKey;
