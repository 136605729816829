import { GenericRole } from '@Enums/Role';
import { useAppSelector } from '@Store/hooks';

/**
 *
 * @returns true if the current user is a test admin. False otherwise.
 */
export default function useIsTestAdmin(): boolean {
  const userRole = useAppSelector(
    s => s.Permission?.userPermissions?.data?.RoleName
  );

  if (userRole === GenericRole.TEST_ADMIN) return true;

  return false;
}
