import {
  AllBeaconsFetchResponse,
  AllBeacon,
  UpdateBeacon,
  GetBeaconParams,
  NewBeacon,
  StoreBeaconsDataSerial,
  BeaconDetailFilterParams,
  ISimilarBeaconsParams,
  ArchivedBeacon,
} from '@Types/Beacon';
import model from './index';
class BeaconsService extends model {
  constructor() {
    super();
    this.Prefix('Beacons');
  }

  /**
   * @param {GetBeaconParams} params
   * @description Get all beacons
   * @returns Promise
   */
  async GetAllBeacons(params: GetBeaconParams) {
    const { archived, ...paginationParams } = params;

    return this.Post(`GetAll`)
      .Params({ archived })
      .Data(paginationParams)
      .offset()
      .Save<AllBeaconsFetchResponse>();
  }

  /**
   *
   * @param newBeacon
   * @description Add a new beacon
   * @returns Promise
   */
  async New(newBeacon: NewBeacon) {
    return this.Post().Data(newBeacon).Save();
  }

  /**
   * @param {string}
   * @description Get code version by  Id
   * @returns Promise
   */

  async GetBeaconById(beaconId: number) {
    return this.Get(`/${beaconId}`).offset().Save<AllBeacon>();
  }

  /**
   * @param {UpdateBeacon} editedBeacon
   * @description Edit Beacon
   * @returns Promise
   */
  async EditBeacon(editedBeacon: UpdateBeacon) {
    return this.Put().Data(editedBeacon).Save();
  }

  /**
   * @param {BeaconDetailFilterParams} params
   * @description Get Beacon Data
   * @returns Promise
   */
  async GetBeaconData(param: BeaconDetailFilterParams) {
    const { beaconId, filterNumber, period } = param;
    return this.PrefixId(beaconId)
      .Get('BeaconsData')
      .Params({
        filterNumber,
        period,
      })
      .offset()
      .Save<StoreBeaconsDataSerial>();
  }

  /**
   * @param {string}
   * @description Get code version by  Id
   * @returns Promise
   */
  async GetSimilarBeacons({ brandId, ...restParams }: ISimilarBeaconsParams) {
    return this.Get(`SimilarBeacons/${brandId}`)
      .Params(restParams)
      .Save<AllBeacon>();
  }

  /**
   * @param {number} receiverId
   * @description Unarchive receiver
   * @returns Promise
   */
  async UnarchiveBeacon(receiverId: number) {
    return this.PrefixId(receiverId).Post('UnArchive').offset().Save();
  }

  /**
   * @param {number} beaconId
   * @description Archive beacon
   * @returns Promise
   */
  async ArchiveBeacon(beaconId: number, data: ArchivedBeacon) {
    return this.PrefixId(beaconId).Post('Archive').Data(data).offset().Save();
  }

  /**
   *
   * @description Delete a beacon.
   * @returns Promise
   */
  async DeleteBeacon(beaconId: number) {
    return this.PrefixId(beaconId).Delete().Save<any>();
  }
}
export default BeaconsService;
