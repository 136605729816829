import { useAppDispatch, useAppSelector } from '@Store/hooks';
import {
  Form,
  Input,
  Button,
  Checkbox,
  Typography,
  Divider,
  FormInstance,
} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.scss';
import { Credentials } from '@Types/User';
import { login as reduxLogin } from '../../Store/Account/action';
import Captcha from './Components/Captcha';

const { Title } = Typography;
interface Props {}

function Login({}: Props): ReactElement {
  const account = useAppSelector(state => state.Account.Account.data);
  const captcha = useAppSelector(state => state.Account.Captcha);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (account.access_token) {
      navigate(from, { replace: true });
    }
  }, [account.access_token]);

  let from = location.state?.from?.pathname || '/';

  const onSubmit = (user: Credentials) => {
    dispatch(reduxLogin(user));
  };

  return (
    <div id="login-page-wrapper">
      <div id="login-form-container">
        <Divider>
          <Title level={3}>{t('loginForm.userLogin')}</Title>
        </Divider>

        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onSubmit}
        >
          <Form.Item
            label="Email"
            name="username"
            rules={[
              ({ getFieldValue }) => ({
                required: true,
                message: t('loginForm.errors.noUsername'),
                type:
                  getFieldValue('username') === 'sysadmin' ? 'string' : 'email',
              }),
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>

          <Form.Item
            label={t('password')}
            name="password"
            rules={[
              { required: true, message: t('loginForm.errors.noPassword') },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          {captcha.required && (
            <Captcha image={`data:image/png;base64,${captcha.image}`} />
          )}
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>{t('rememberMe')}</Checkbox>
            </Form.Item>

            <a className="login-form-forgot" href="/ForgetPassword">
              {t('forgotPw')}
            </a>
          </Form.Item>

          <Form.Item className="login-form-button-container">
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              {t('submit')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Login;
