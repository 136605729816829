import {
  BrandSelector,
  DevicesSection,
  GroupSelector,
  StoreSelector,
} from '@Features/homepage/components';
import { HomepageContainer } from '@Features/homepage/containers';

export function HomePage() {
  return (
    <HomepageContainer>
      <div className="group-brand-container">
        <GroupSelector />
        <BrandSelector />
      </div>
      <StoreSelector />
      <DevicesSection />
    </HomepageContainer>
  );
}
