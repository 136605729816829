import { Alert, Empty, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { asyncFlow } from '@Types/ReduxTypes';
import PageSkeletons from '@Components/PageSkeletons';

interface Props {
  status: asyncFlow;
  error?: string;
  noData: boolean;
  cards: React.ReactNode;
  containerClassName?: string;
}

const CardGridWrapper = ({
  noData,
  cards,
  status,
  error,
  containerClassName,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  if (status === 'Pending') return <PageSkeletons />;
  if (status === 'Rejected')
    return (
      <Result status="error" title={t('somethingWentWrong')}>
        <Alert message={error || '-'} type="error" />
      </Result>
    );
  if (status === 'fulfilled' && noData)
    return <Empty description={t('noData')} />;

  return (
    <div className={`card-grid-container ${containerClassName || ''}`}>
      {cards}
    </div>
  );
};

export default CardGridWrapper;
