import FormCard from '@Components/FormCard';
import { UserForm } from '@Forms/UserForm';
import UserService from '@Services/Api/User';
import { IUserForm, NewUserForm } from '@Types/User';
import { apiCall } from '@Utils/index';
import { UserAddOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export function CreateUserPage() {
  const navigate = useNavigate();

  async function handleSubmit(values: IUserForm) {
    const brandIds = values.BrandIds!;

    const newUser: NewUserForm = {
      ...values,
      BrandId: brandIds[0],
    };

    const req = () => new UserService().CreateUser(newUser);
    const success = await apiCall(req);

    if (success) {
      navigate(-1);
    }
  }

  return (
    <FormCard titleIcon={<UserAddOutlined />} titleKey="addUser">
      <UserForm onFinish={handleSubmit} />
    </FormCard>
  );
}
