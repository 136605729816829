import useIsEnvAdmin from '@Hooks/useIsEnvAdmin';
import useIsSysAdmin from '@Hooks/useIsSysAdmin';
import AccessDenied from '@Pages/AccessDenied';
import { useAppSelector } from '@Store/hooks';
import { useMemo } from 'react';

interface Props {
  children: JSX.Element;
}

export default function PermissionToViewAdminPanelChecker(props: Props) {
  const { children } = props;

  const isSysAdmin = useIsSysAdmin();
  const isEnvAdmin = useIsEnvAdmin();

  const permissions = useAppSelector(s => s.Permission.userPermissions);

  const hasAtLeastOneAdminPermission = useMemo(() => {
    return permissions.data.Permissions?.some(permission =>
      permission.Name.toLocaleLowerCase().includes('admin')
    );
  }, [permissions.status]);

  if (isSysAdmin || isEnvAdmin || hasAtLeastOneAdminPermission) return children;

  return <AccessDenied />;
}
