import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

function Ticket() {
  return (
    <Layout id="ticket-page">
      <Outlet />
    </Layout>
  );
}

export default Ticket;
