import WifiConfForm from '@Forms/WifiConfForm';
import BoardsService from '@Services/Api/Boards';
import { getBoardWifiConfigiration } from '@Store/Board/action';
import { WifiConfigiration } from '@Types/WifiConfigiration';
import { Button, Col, Divider } from 'antd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import './style.scss';
import { stringToNumber } from '@Utils/Number';
import { createMessage } from '@Utils/index';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import PageSkeletons from '@Components/PageSkeletons';
import { PlusOutlined } from '@ant-design/icons';

type Props = { data: WifiConfigiration[] };

const BoardWifiForm = ({ data }: Props) => {
  const { boardId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [wifiConfStatus, setWifiConfStatus] = useState('');
  const [showAddWiredForm, setShowAddWiredForm] = useState(false);

  async function handleDelete(configurationId: number | undefined) {
    if (configurationId) {
      setWifiConfStatus('Pending');

      await new BoardsService().DeleteWifiConfiguration(
        parseInt(boardId!),
        configurationId
      );
      dispatch(getBoardWifiConfigiration(parseInt(boardId!)));
      createMessage('success', t('successMessage'), configurationId);
      setWifiConfStatus('Success');
    }
  }

  const handleSubmit = async (wifiConf: WifiConfigiration) => {
    const customWifiCustom = {
      ...wifiConf,
      Hidden: wifiConf.Hidden ? 1 : 0,
    };
    setWifiConfStatus('Pending');

    try {
      await new BoardsService().PostBoardWifiConfiguration(
        customWifiCustom,
        stringToNumber(boardId)
      );
      dispatch(getBoardWifiConfigiration(parseInt(boardId!)));
      setWifiConfStatus('Success');
      createMessage('success', t('successMessage'), customWifiCustom);
      setShowAddWiredForm(false);
    } catch (e) {
      setWifiConfStatus('Error');
      console.warn(e);
    }
  };

  if (wifiConfStatus === 'Pending') return <PageSkeletons />;

  return (
    <>
      {data?.map(wifiConfiguration => (
        <Col span={7} className="col-form-board" key={wifiConfiguration?.Id}>
          <WifiConfForm
            name={'board-wifi-form' + wifiConfiguration.Id}
            key={wifiConfiguration.Id}
            wifiConfiguration={wifiConfiguration}
            onSubmit={handleSubmit}
            closeFooter={true}
            customFooter
            handleDelete={() => handleDelete(wifiConfiguration?.Id)}
            deleteButtonShow
            hideGoBackButton
          />
        </Col>
      ))}

      {showAddWiredForm ? (
        <Col span={7} className="col-form-board">
          <WifiConfForm onSubmit={handleSubmit} hideGoBackButton />
        </Col>
      ) : (
        <Col
          span={7}
          className="col-form-board large-add-button-container"
          style={{ minHeight: 286 }}
        >
          <Button
            size="large"
            type="dashed"
            className="large-add-button"
            onClick={() => setShowAddWiredForm(true)}
          >
            <PlusOutlined className="plus" />
          </Button>
        </Col>
      )}
      <Divider />
    </>
  );
};

export default BoardWifiForm;
