import RegionForm from '@Forms/RegionForm';
import { getBrandRegions } from '@Store/Regions/action';
import { useAppSelector } from '@Store/hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

type Props = {};

function RegionEdit({}: Props) {
  const { brandId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBrandRegions(parseInt(brandId!)));
  }, [brandId]);

  return (
    <div>
      <RegionForm />
    </div>
  );
}

export default RegionEdit;
