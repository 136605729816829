import { useAppSelector } from '@Store/hooks';
import { useMemo } from 'react';
import { Permissions } from '@Enums/Permission';

/*
    Used to determine if a user has a given permission
*/
export default function useCheckPermission(permission?: Permissions): boolean {
  const permissions = useAppSelector(s => s.Permission.userPermissions);
  const isCustomRole = useAppSelector(
    s => s.User.currentUserDetails.data.IsCustomRole
  );

  const shouldRender = useMemo(() => {
    if (!isCustomRole) return true;

    return !!permissions.data.Permissions?.some(p => p.Name === permission);
  }, [permissions.status, isCustomRole]);

  if (!permission) return false;
  return shouldRender;
}
