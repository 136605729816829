import { ReactElement } from 'react';
import { DragOutlined } from '@ant-design/icons';
import './style.scss';
type Props = {
  children: ReactElement;
  showDragIcon: boolean;
};

function Draggable({ children, showDragIcon }: Props) {
  return (
    <div className="draggable-wrapper">
      {showDragIcon && <DragElement />}
      {children}
    </div>
  );
}

const DragElement = () => {
  return <DragOutlined className="drag-icon" />;
};

Draggable.defaultProps = {
  showDragIcon: true,
};
export default Draggable;
