import { createReducer } from '@reduxjs/toolkit';
import { getNotificationResponses } from './action';
import { asyncFetchInterface } from '@Types/ReduxTypes';
import { GetNotificationResponses } from '@Types/Notification';

interface IState {
  notificationResponses: asyncFetchInterface<GetNotificationResponses>;
}

const initialState: IState = {
  notificationResponses: {
    data: [],
    status: 'Empty',
  },
};

const NotificationReducer = createReducer(initialState, builder => {
  builder
    .addCase(getNotificationResponses.pending, state => {
      state.notificationResponses = {
        ...state.notificationResponses,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getNotificationResponses.fulfilled, (state, action) => {
      state.notificationResponses.data = action.payload;
      state.notificationResponses.status = 'fulfilled';
    })
    .addCase(getNotificationResponses.rejected, (state, action) => {
      state.notificationResponses = {
        ...state.notificationResponses,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default NotificationReducer;
