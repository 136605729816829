import ArchivedFilter from '@Components/ArchivedFilter';
import { useHomepageContext } from '@Features/homepage/hooks';
import useBoolean from '@Hooks/useBoolean';
import { Divider, Segmented, Space } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BoardsList } from '../BoardsList';
import { CamerasList } from '../CamerasList';
import './style.scss';
import { RefreshButton } from '../RefreshButton';
import { DEVICE_TYPE_QUERY_PARAM } from '@Features/homepage/homepage.constants';

enum DeviceType {
  BOARD = 'board',
  CAMERA = 'camera',
}

export function DevicesSection() {
  const { storeId, getBoards, getCameras, getQueryParam, setQueryParam } =
    useHomepageContext();

  const [deviceType, setDeviceType] = useState<DeviceType>(
    (getQueryParam(DEVICE_TYPE_QUERY_PARAM) as DeviceType) ?? DeviceType.BOARD
  );

  const showArchived = useBoolean();

  const { t } = useTranslation();

  function deviceTypeChangeHandler(newDeviceType: SegmentedValue) {
    setDeviceType(newDeviceType as DeviceType);
    setQueryParam(DEVICE_TYPE_QUERY_PARAM, newDeviceType);
  }

  function handleRefreshClick() {
    switch (deviceType) {
      case DeviceType.BOARD:
        getBoards(showArchived.value);
        break;
      case DeviceType.CAMERA:
        getCameras(showArchived.value);
        break;
    }
  }

  const options = [
    { label: t('board'), value: DeviceType.BOARD },
    { label: t('camera'), value: DeviceType.CAMERA },
  ];

  const content = {
    [DeviceType.BOARD]: <BoardsList showArchived={showArchived.value} />,
    [DeviceType.CAMERA]: <CamerasList showArchived={showArchived.value} />,
  };

  if (!storeId) {
    return <></>;
  }

  return (
    <>
      <Divider type="vertical" className="homepage-divider" />
      <div className="homepage-devices-section">
        <Space>
          <Segmented
            options={options}
            value={deviceType}
            onChange={deviceTypeChangeHandler}
            onResize={undefined}
            onResizeCapture={undefined}
            size="small"
          />
          <ArchivedFilter
            onClick={showArchived.toggle}
            showArchived={showArchived.value}
            buttonProps={{ size: 'small' }}
          />
          <RefreshButton onClick={handleRefreshClick} />
        </Space>
        {content[deviceType]}
      </div>
    </>
  );
}
