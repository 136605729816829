import { useState } from 'react';

export function useMultipleRowSelector(
  data: { Id: number | string; [key: string]: any }[]
) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  function onSelectChange(newSelectedRowKeys: React.Key[]) {
    setSelectedRowKeys(newSelectedRowKeys);
  }

  function isSelected(key: React.Key) {
    return selectedRowKeys.includes(key);
  }

  function reset() {
    return setSelectedRowKeys([]);
  }

  function onSelect(id: number | string) {
    if (selectedRowKeys.includes(id)) {
      setSelectedRowKeys(prevKeys => [...prevKeys].filter(key => key !== id));
      return;
    }

    setSelectedRowKeys(prevKeys => [...prevKeys, id]);
  }

  function selectAll() {
    if (selectedRowKeys.length === data.length) {
      setSelectedRowKeys([]);
      return;
    }

    setSelectedRowKeys(data ? data.map(d => d.Id) : []);
  }

  const hasSelectedRow = selectedRowKeys.length > 0;

  return {
    selectAll,
    rowSelection: {
      selectedRowKeys,
      onChange: onSelectChange,
    },
    reset,
    onSelect,
    isSelected,
    hasSelectedRow,
    selectedRowKeys,
  };
}
