import { createAction } from '@reduxjs/toolkit';
import { ON_SIDEBAR_TOGGLE, SET_HEADER_TITLE } from './type';

export const onSidebarToggle = createAction(
  ON_SIDEBAR_TOGGLE,
  (isCollapsed: boolean) => {
    return {
      payload: isCollapsed,
    };
  }
);

export const setHeaderTitle = createAction(
  SET_HEADER_TITLE,
  (title?: string | undefined) => {
    return {
      payload: title,
    };
  }
);
