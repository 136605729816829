import { ShapeObject } from '@Components/Svg/Types/Shape';
import { useOutsideClick } from '@Hooks/useOutsideClick';
import { getMouseWindowPosition } from '@Utils/Mouse';
import { useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { ConnectCameraContext } from '../../Contexts/ConnectCameraContext';
import ConnectCameraTypes from '@Types/connectCameras';
import ShapeTypes from '@Components/Svg/Types/Shape';
import './style.scss';
const bodyDom = document.body;
type Props = {
  show: boolean;
  shape?: ConnectCameraTypes.CameraConnectShape;
  onClose: () => void;
  deleteDrawing: (shape: ShapeTypes.ShapeObject) => void;
};

function ShapeContextMenu({
  show = false,
  shape,
  onClose,
  deleteDrawing,
}: Props) {
  const { setCreateShape, setGroupName } = useContext(ConnectCameraContext);
  const contextMenu = useRef(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  useOutsideClick(contextMenu, () => onClose());
  useEffect(() => {
    setMousePosition(getMouseWindowPosition());
  }, [show]);
  const createShape = () => {
    setCreateShape(true);
    setGroupName(shape?.groupName || '');
    onClose();
  };
  const deleteShape = () => {
    if (shape) deleteDrawing(shape);
    onClose();
  };
  return createPortal(
    show ? (
      <div
        ref={contextMenu}
        className="shape-context-menu"
        style={{ top: mousePosition.y, left: mousePosition.x }}
      >
        <div className="shape-context-menu__Item" onClick={createShape}>
          Alan oluştur
        </div>
        <div className="shape-context-menu__Item" onClick={deleteShape}>
          Sil
        </div>
      </div>
    ) : (
      <></>
    ),
    bodyDom
  );
}

export default ShapeContextMenu;
