import {
  IUploadVersionFileForm,
  VersionFile,
  VersionFileGroup,
} from '@Types/Version';
import VersionService from '@Services/Api/Version';
import CardButton from '@Components/CardButton';
import { EditOutlined, SyncOutlined, UploadOutlined } from '@ant-design/icons';
import { Card, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import DeleteButton from '@Components/Card/DeleteButton';
import { apiCall } from '@Utils/index';
import { useGetVersionFiles } from '@Features/versions/hooks';
import CardGridWrapper from '@Components/CardGridWrapper';
import Status from '@Enums/Status';
import VersionFileCard from '@Cards/VersionFileCard';
import './style.scss';
import { useMemo } from 'react';
import {
  UpdateVersionFileGroupModal,
  UploadVersionFileModal,
} from '@Features/versions/modals';
import useBoolean from '@Hooks/useBoolean';
import { UpdateActiveFileModal } from '@Features/versions/modals/UpdateActiveFileModal';
import { getVersionFileGroups } from '@Store/Version/action';
import { useAppDispatch } from '@Store/hooks';

type Props = {
  group: VersionFileGroup;
  versionFiles: VersionFile[];
};

export function VersionFileGroupCard(props: Props) {
  const { group, versionFiles } = props;

  const { t } = useTranslation();

  const updateVersionFileGroupModalState = useBoolean();
  const updateActiveFileModalState = useBoolean();
  const uploadFileModalState = useBoolean();

  const getVersionFiles = useGetVersionFiles(group.Type);

  const dispatch = useAppDispatch();

  async function deleteVersionFileGroup() {
    await apiCall(() => new VersionService().DeleteVersionFileGroup(group.Id));
    dispatch(getVersionFileGroups({ type: group.Type }));
  }

  async function handleVersionUpload(formValues: IUploadVersionFileForm) {
    await apiCall(() =>
      new VersionService().FileUpload({
        ...formValues,
        compId: group.Id,
        type: group.Type,
      })
    );

    getVersionFiles();
  }

  const actions = useMemo(() => {
    const result = [
      <>
        <CardButton
          icon={<UploadOutlined />}
          size="middle"
          tooltipKey="uploadFile"
          onClick={uploadFileModalState.setTrue}
        />
        {uploadFileModalState.value && (
          <UploadVersionFileModal
            modalState={uploadFileModalState}
            onFinish={handleVersionUpload}
          />
        )}
      </>,

      <>
        <CardButton
          icon={<SyncOutlined />}
          size="middle"
          tooltipKey="changeActiveFile"
          onClick={updateActiveFileModalState.setTrue}
        />
        {updateActiveFileModalState.value && (
          <UpdateActiveFileModal
            modalState={updateActiveFileModalState}
            versionFileGroup={group}
            versionFiles={versionFiles}
          />
        )}
      </>,

      <>
        <CardButton
          icon={<EditOutlined />}
          size="middle"
          tooltipKey="update"
          onClick={updateVersionFileGroupModalState.setTrue}
        />
        {updateVersionFileGroupModalState.value && (
          <UpdateVersionFileGroupModal
            modalState={updateVersionFileGroupModalState}
            versionFileGroup={group}
          />
        )}
      </>,
    ];

    if (versionFiles.length === 0) {
      result.push(
        <DeleteButton onConfirm={deleteVersionFileGroup} size="middle" />
      );
    }

    return result;
  }, [
    t,
    versionFiles.length,
    updateVersionFileGroupModalState.value,
    updateActiveFileModalState.value,
    uploadFileModalState.value,
    group,
  ]);

  return (
    <Card className="version-file-group-card" actions={actions} size="small">
      <Typography.Title className="group-name" level={5}>
        {group.Name}
      </Typography.Title>

      <div className="grid-content-wrapper custom-scrollbar">
        <CardGridWrapper
          noData={versionFiles.length === 0}
          status={Status.success}
          cards={
            <>
              {versionFiles.map(versionFile => (
                <VersionFileCard
                  key={versionFile.Id}
                  versionFile={versionFile}
                  active={versionFile.Id === group.ActiveFile?.Id}
                />
              ))}
            </>
          }
        />
      </div>
    </Card>
  );
}
