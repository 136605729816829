import { Checkbox, Divider, Form, Input, Select } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ModalFooter from '@Components/ModalFooter';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useMemo, useState } from 'react';
import { CameraDetail } from '@Types/Camera';
import { interFaceCameraTypes } from '@Utils/index';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import CardEditInfo from '@Components/CardEditInfo';
import { AimOutlined, HomeOutlined, ShopOutlined } from '@ant-design/icons';
import { CardEditItemProps } from '@Components/CardEditInfo/CardEditItem';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import JsonSchemaForm from '@Components/JsonSchema/Form';
import { IChangeEvent } from '@rjsf/core';
import { ConfigSchemaId, OptionType } from '@Types/Config';
import { convertConfigTypeToEnum } from '@Utils/Config';
import SearchableSelect from '@Components/SearchableSelect';
import { DefaultOptionType } from 'antd/lib/select';
import { InterfaceCameraTypeEnums } from '@Enums/Camera';
import { getBoardDetail, resetCurrentBoard } from '@Store/Board/action';
import { getBoardBrandCameras } from '@Store/Hardware/action';
import { RJSFSchema, ValidationData } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { getAllLensType } from '@Store/LensType/action';
import { noPaginationParams } from '@Hooks/usePagination.ts/constants';

const { Option } = Select;

type Props = {
  camera?: CameraDetail;
  onFinish: (values: any) => void;
};

export default function CameraForm({ onFinish, camera }: Props) {
  const [infoItems, setInfoItems] = useState<CardEditItemProps[]>([]);
  const [selectedConfigId, setSelectedConfigId] = useState<ConfigSchemaId>(
    camera?.ConfigSchemaId || null
  );
  const configSchemas = useAppSelector(s => s.Config.configSchemas.data);
  const board = useAppSelector(s => s.Board.currentBoard.data);
  const boardBrandCameras = useAppSelector(s => s.Hardware.boardBrandCameras);

  const { t } = useTranslation();
  const [form] = useForm();
  const brandPerm = useCheckPermission(Permissions.ADMIN_BRAND_VIEW);
  const storePerm = useCheckPermission(Permissions.ADMIN_STORE_VIEW);
  const boardPerm = useCheckPermission(Permissions.ADMIN_BOARD_VIEW);

  const parametersJson = Form.useWatch('ParametersJson', form);
  const alllensType = useAppSelector(s => s.LensType.alllensType);
  const dispatch = useAppDispatch();

  const [validationData, setValidationData] = useState<
    ValidationData<any> | undefined
  >();

  useEffect(() => {
    dispatch(getBoardBrandCameras());
    dispatch(getAllLensType(noPaginationParams));
  }, []);

  useEffect(() => {
    setInfoItems([
      {
        icon: <HomeOutlined />,
        name: camera?.Brand!,
        id: camera?.BrandId!,
        routeTo: `/brands/${camera?.BrandId}/stores`,
        hasPermission: brandPerm,
      },
      {
        icon: <ShopOutlined />,
        name: camera?.Store!,
        id: camera?.StoreId!,
        routeTo: `/brands/${camera?.BrandId}/stores/${camera?.StoreId}/boards`,
        hasPermission: storePerm,
      },
      {
        icon: <AimOutlined />,
        name: camera?.Board!,
        id: camera?.BoardId!,
        routeTo: `/brands/${camera?.BrandId}/stores/${camera?.StoreId}/boards/${camera?.BoardId}/cameras`,
        hasPermission: boardPerm,
      },
    ]);

    if (camera) {
      dispatch(getBoardDetail(camera?.BoardId));
    }

    form.resetFields();

    return () => {
      dispatch(resetCurrentBoard());
    };
  }, [camera?.Id]);

  function handleCameraTypeChange(value: InterfaceCameraTypeEnums) {
    const boardModelBrandId = board.BoardBrandModelId;

    const boardBrandCameraModel = boardBrandCameras?.data?.find(
      p =>
        p.BoardBrandModelId === boardModelBrandId &&
        p.CameraInterfaceType === value
    );

    if (boardBrandCameraModel) {
      form.setFieldsValue({
        RTSPUrl: boardBrandCameraModel.DefaultURL,
      });
    }
  }

  const passiveCheckboxHandler = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;
    form.setFieldsValue({ Passive: isChecked });
  };

  const selectedConfigHandler = (
    _: any,
    option: DefaultOptionType | DefaultOptionType[]
  ) => {
    form.setFieldsValue({ ParametersJson: undefined });

    const castedOption = option as OptionType;
    setSelectedConfigId(castedOption?.value);
  };

  const formOnChange = (e: IChangeEvent<RJSFSchema>) => {
    form.setFieldsValue({ ParametersJson: e.formData });
  };

  const handleSubmit = (values: any) => {
    //cam type default fisheye type 0
    onFinish({
      ...values,
      Type: 0,
      ParametersJson: JSON.stringify(
        parametersJson?.formData || parametersJson || {}
      ),
    });
  };

  const inputRule = [{ required: true, message: t('cannotBeEmpty') }];

  const initialValues = {
    Name: camera?.Name,
    Type: camera?.Type,
    StoreId: camera?.StoreId,
    BoardId: camera?.BoardId,
    DestinationUrl: camera?.Destination,
    MACAddress: camera?.Configuration.camera.MAC,
    EthernetInterface: camera?.Configuration.camera.Interface,
    RTSPUrl: camera?.Configuration.camera.RTSP,
    CaptureSize: camera?.Configuration.camera.CaptureSize,
    Description: camera?.Description,
    ParametersJson:
      camera && camera.ParametersJson
        ? JSON.parse(camera.ParametersJson)
        : null,
    ConfigSchemaId: camera?.ConfigSchemaId,
    Passive: camera?.Passive,
    InterfaceType: camera?.InterfaceType,
    LensTypeId: camera?.LensTypeId,
  };

  const configSchema = useMemo(() => {
    if (!selectedConfigId) return null;

    try {
      return JSON.parse(
        configSchemas.find(c => c.Id === selectedConfigId)?.Schema!
      );
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [configSchemas, selectedConfigId]);

  return (
    <>
      <Form
        form={form}
        name="camera-form"
        onFinish={handleSubmit}
        className="camera-detail-form-container"
        initialValues={camera ? initialValues : undefined}
      >
        {camera && <CardEditInfo items={infoItems} />}
        <Form.Item label={t('name')} name="Name" rules={inputRule}>
          <Input prefix={<CameraOutlined />} />
        </Form.Item>
        <Form.Item
          label={t('cameraType')}
          name="InterfaceType"
          rules={inputRule}
        >
          <SearchableSelect
            placeholder={t('Select')}
            onChange={handleCameraTypeChange}
          >
            {interFaceCameraTypes.map(cameraType => (
              <Option
                key={cameraType.type}
                value={cameraType.value}
                label={cameraType.type}
              >
                {cameraType.type}
              </Option>
            ))}
          </SearchableSelect>
        </Form.Item>

        <Form.Item
          label={t('destinationUrl')}
          name="DestinationUrl"
          rules={inputRule}
        >
          <Input />
        </Form.Item>
        <Form.Item label="MAC Address" name="MACAddress">
          <Input />
        </Form.Item>
        <Form.Item
          label="Ethernet Interface"
          name="EthernetInterface"
          rules={inputRule}
        >
          <Input />
        </Form.Item>
        <Form.Item label="RTSP Url" name="RTSPUrl">
          <Input />
        </Form.Item>
        <Form.Item label={t('lens')} name="LensTypeId">
          <SearchableSelect placeholder={t('Select')}>
            {alllensType?.data?.Data?.map(lens => (
              <Option value={lens.Id} key={lens.Id}>
                {lens.Name}
              </Option>
            ))}
          </SearchableSelect>
        </Form.Item>
        <Form.Item label={t('captureSize')} name="CaptureSize">
          <Input />
        </Form.Item>
        <Form.Item label={t('description')} name="Description">
          <Input />
        </Form.Item>
        <Form.Item label={t('nonActive')} name="Passive">
          <Checkbox
            onChange={passiveCheckboxHandler}
            defaultChecked={camera?.Passive}
          />
        </Form.Item>
        <Divider />
        <Form.Item label={t('config')} name="ConfigSchemaId">
          <SearchableSelect allowClear onChange={selectedConfigHandler}>
            {configSchemas
              .filter(
                c => c.ConfigSchemaType === convertConfigTypeToEnum('camera')
              )
              .map(c => (
                <Option value={c.Id} key={c.Id}>
                  {c.Name}
                </Option>
              ))}
          </SearchableSelect>
        </Form.Item>

        <Form.Item
          name="ParametersJson"
          rules={[
            {
              validator: async (
                _,
                formData: IChangeEvent<RJSFSchema> | null
              ) => {
                if (!selectedConfigId) return Promise.resolve();

                const validatedData = validator.validateFormData(
                  formData,
                  configSchema
                );

                if (validatedData.errors.length === 0) {
                  setValidationData(undefined);
                  return Promise.resolve();
                }

                setValidationData(validatedData);
                return Promise.reject(t('notValidConfig'));
              },
            },
          ]}
        />

        <Form.Item labelCol={{ offset: 2 }} label={t('schema')}>
          {selectedConfigId ? (
            <JsonSchemaForm
              schema={configSchema}
              onChange={formOnChange}
              formData={parametersJson}
              omitExtraData
              liveOmit
              extraErrors={validationData?.errorSchema}
            />
          ) : (
            '-'
          )}
        </Form.Item>

        <Divider />
      </Form>

      <ModalFooter formKey="camera-form" />
    </>
  );
}
