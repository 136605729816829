import { BeaconRuleEnum } from '@Features/beaconRules/enums';
import { Form, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';

type BeaconRuleValueSelectorProps = {
  type: BeaconRuleEnum;
};

export function BeaconRuleValueSelector({
  type,
}: BeaconRuleValueSelectorProps) {
  const { t } = useTranslation();

  const labels = {
    [BeaconRuleEnum.Closed]: t('beaconRules.closedLabel'),
    [BeaconRuleEnum.LowBatteryLevel]: t('beaconRules.batteryLabel'),
    [BeaconRuleEnum.SignalFixedAreaLongTime]: t('beaconRules.fixedSignalLabel'),
  };

  const minValues = {
    [BeaconRuleEnum.Closed]: 1,
    [BeaconRuleEnum.LowBatteryLevel]: 0,
    [BeaconRuleEnum.SignalFixedAreaLongTime]: 1,
  };

  const inputRule = [{ required: true, message: t('cannotBeEmpty') }];

  return (
    <Form.Item label={labels[type]} name="Value" rules={inputRule}>
      <InputNumber min={minValues[type]} />
    </Form.Item>
  );
}
