import { Badge, BadgeProps } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.scss';
interface Props extends BadgeProps {
  isOn: boolean;
  className?: string;
}

const StatusBadge = ({ isOn, className }: Props) => {
  const { t } = useTranslation();
  return (
    <Badge
      status={isOn ? 'success' : 'error'}
      count={t(isOn ? 'on' : 'off')}
      className={'status-badge ' + className}
    />
  );
};

export default StatusBadge;
