import Status from '@Enums/Status';
import { CreateReportButton, ReportsTable } from '@Features/reports/components';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getAllReports } from '@Store/Reports/action';
import { Card, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import SortByFilter from './Components/SortByFilter';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { FilterOption } from '@Types/Pagination';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';
import { useEffect, useState } from 'react';
import { getAllUsers } from '@Store/User/action';
import { noPaginationParams } from '@Hooks/usePagination.ts/constants';
import AutoReportsService from '@Services/Api/AutoReportsService';

export default function ReportsPage() {
  const [reportTypeOptions, setReportTypeOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const reports = useAppSelector(s => s.Reports.reports);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const allUsers = useAppSelector(state => state.User.allUsers);

  useEffect(() => {
    dispatch(getAllUsers(noPaginationParams));

    (async () => {
      const result = await new AutoReportsService().GetReportEnums();
      const autoReportTypes = result
        ?.find(r => r.Name === 'AutoReportTypes')
        ?.Serial.map(o => ({ label: o.Name, value: o.Id }));

      if (autoReportTypes) {
        setReportTypeOptions(autoReportTypes);
      }
    })();
  }, []);

  const pageConfigResult = useUserPageConfig('reports', {
    orderBy: 'asc',
    sortKey: 'Name',
  });

  const filterOptions: FilterOption[] = [
    { field: 'Id', label: 'ID', type: FilterType.NUMBER },
    {
      field: 'AutoReportType',
      label: t('reportType'),
      type: FilterType.NUMBER_ARRAY_SELECT,
      options: reportTypeOptions,
    },
    {
      field: 'BrandId',
      label: t('brand') + ' ID',
      type: FilterType.NUMBER,
    },
    { field: 'Brand.Name', label: t('brand'), type: FilterType.TEXT },
    {
      field: 'AutoReportReceivers.UserId',
      label: t('users'),
      type: FilterType.TEXT_ARRAY_SELECT,
      options: allUsers?.data?.Data?.map(u => ({
        label: u.Email,
        value: u.Id,
      })),
    },
  ];

  const { pagination, refetch } = usePaginatedQuery({
    status: reports.status,
    filterOptions,
    pageConfigResult,
    queryFunction: p => dispatch(getAllReports(p)),
  });

  useSetHeaderTitle(t('reports.reports'));

  return (
    <Card loading={reports.status === Status.pending}>
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />
          <CreateReportButton />
        </div>
        <ReportsTable
          reports={reports.data}
          loading={reports.status === Status.pending}
          refetch={refetch}
        />
        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={reports.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={reports.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
}
