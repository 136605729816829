import { useContext, useEffect } from 'react';
import { SvgContext } from '../Context/Svg';

const useEditable = (editable?: boolean) => {
  const { setEditable } = useContext(SvgContext);
  useEffect(() => {
    setEditable(editable ?? false);
  }, [editable]);
};
export default useEditable;
