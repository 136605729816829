import { UploadOutlined } from '@ant-design/icons';
import CameraRecordsService from '@Services/Api/CameraRecords';
import { CameraRecord } from '@Types/CameraRecords';
import { apiCall } from '@Utils/index';
import { Button, Select, Space } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

type Props = {
  cameraRecord: CameraRecord;
};

export default function AssignTagModal({ cameraRecord }: Props) {
  const [tags, setTags] = useState<string[]>(cameraRecord.Tags || []);

  const { t } = useTranslation();

  function handleTagChange(values: string[]) {
    setTags(values || []);
  }

  async function handleConfirm() {
    try {
      const req = () =>
        new CameraRecordsService().AssignTags(cameraRecord.Id, {
          Tags: tags,
        });
      apiCall(req, 'assignTagsToCameraRecord');
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Space className="assign-tag-modal">
      <Select
        mode="tags"
        value={tags}
        placeholder={t('tags')}
        onChange={handleTagChange}
        className="tag-selector"
      />
      <Button
        icon={<UploadOutlined />}
        type="dashed"
        onClick={handleConfirm}
        title={t('submit')}
      />
    </Space>
  );
}
