import Status from '@Enums/Status';
import { useAppSelector } from '@Store/hooks';
import { IBeaconRulesForm } from '@Types/BeaconRule';
import { FormInstance } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import { useMemo } from 'react';

export function useBeaconRuleSelectedStoreIds(
  form: FormInstance<IBeaconRulesForm>
) {
  const brandReceivers = useAppSelector(s => s.Receiver.brandReceivers);
  const brandBeacons = useAppSelector(s => s.Beacons.brandBeacons);

  const receiverIds = useWatch('ReceiverIds', form) || [];
  const beaconIds = useWatch('BeaconIds', form) || [];

  const storeIds = useMemo(() => {
    if (
      brandReceivers.status !== Status.success ||
      brandBeacons.status !== Status.success
    )
      return [];

    const _storeIds: number[] = [];

    for (const { Id, Beacons } of brandBeacons.data) {
      if (
        beaconIds.some(bId => Beacons.some(b => b.Id === bId)) &&
        !_storeIds.includes(Id)
      ) {
        _storeIds.push(Id);
      }
    }

    for (const { Id, Receivers } of brandReceivers.data) {
      if (
        receiverIds.some(rId => Receivers.some(r => r.Id === rId)) &&
        !_storeIds.includes(Id)
      ) {
        _storeIds.push(Id);
      }
    }

    return _storeIds;
  }, [brandReceivers.status, brandBeacons.status, receiverIds, beaconIds]);

  return storeIds;
}
