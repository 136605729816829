import { createContext, ReactElement, useEffect, useState } from 'react';
import { DrawElements } from '../Constants';
import { shapeTypes } from '../Types/Bases';
import { pointStatusTypes } from '../Types/Point';
import { shapeStatusTypes } from '../Types/Shape';
import { svgStatusTypes } from '../Types/Svg';

export type ParamActionTypes =
  | svgStatusTypes
  | pointStatusTypes
  | shapeStatusTypes;
export interface paramTypes {
  editable: boolean;
  drawing: boolean;
  activeType: ParamActionTypes;
  drawType?: shapeTypes;
}
interface paramContextType extends paramTypes {
  updateActiveType: (status: ParamActionTypes) => void;
  cancel: () => void;
  setDrawType: (drawType: DrawElements | undefined) => void;
}

interface paramContextProviderProps {
  children: ReactElement;
  draw?: shapeTypes;
}

export const ParamContext = createContext<paramContextType>({
  editable: false,
  drawing: false,
  updateActiveType: () => {},
  activeType: 'stable',
  drawType: undefined,
  cancel: () => {},
  setDrawType: (drawType: DrawElements | undefined) => {},
});

export const ParamContextProvider = ({
  children,
}: paramContextProviderProps) => {
  const [params, setParams] = useState<paramTypes>({
    editable: false,
    drawing: false,
    activeType: 'stable',
    drawType: undefined,
  });

  const updateActiveType = (status: ParamActionTypes) => {
    setParams(prevParams => ({ ...prevParams, activeType: status }));
  };

  const setDrawType = (drawType: DrawElements | undefined) => {
    setParams(prevParams => ({
      ...prevParams,
      drawType: drawType,
      activeType: 'draw',
    }));
  };

  const cancel = () => {
    setParams(prevParams => ({
      ...prevParams,
      drawType: undefined,
      activeType: 'stable',
    }));
  };
  return (
    <ParamContext.Provider
      value={{
        ...params,
        setDrawType,
        updateActiveType,
        cancel,
      }}
    >
      {children}
    </ParamContext.Provider>
  );
};
