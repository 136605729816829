import { createContext, useState } from 'react';
import { IFieldSearch, IFieldSearchesContext } from '@Types/FieldSearches';
import FieldSearchService from '@Services/Api/FieldSearches';
import { apiCall } from '@Utils';

const INITIAL_STATE: IFieldSearchesContext = {
  fieldSearches: [],
  setFieldSearches: () => {},
  retrieveFieldSearches: async () => {},
};

export const FieldSearchesContext =
  createContext<IFieldSearchesContext>(INITIAL_STATE);

const FieldSearchesProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [fieldSearches, setFieldSearches] = useState<IFieldSearch[]>([]);

  const retrieveFieldSearches = async () => {
    try {
      const func = () => new FieldSearchService().GetFieldSearches();
      const data = await apiCall(func, 'retrieveFieldSearch');
      setFieldSearches(data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <FieldSearchesContext.Provider
      value={{ fieldSearches, setFieldSearches, retrieveFieldSearches }}
    >
      {children}
    </FieldSearchesContext.Provider>
  );
};

export default FieldSearchesProvider;
