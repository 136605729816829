import { createReducer } from '@reduxjs/toolkit';
import { getAuditLogs } from './action';
import { AllAuditLogs, AllAuditLogsFetchResponse } from '@Types/AuditLogs';
interface StateInterface {
  auditLogs: AllAuditLogs;
}

const initialState: StateInterface = {
  auditLogs: {
    data: {} as AllAuditLogsFetchResponse,
    status: 'Empty',
  },
};

const AuditLogReducer = createReducer(initialState, builder => {
  builder
    .addCase(getAuditLogs.pending, state => {
      state.auditLogs = {
        ...state.auditLogs,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAuditLogs.fulfilled, (state, action) => {
      state.auditLogs = {
        status: 'fulfilled',
        data: action.payload,
      };
    })
    .addCase(getAuditLogs.rejected, (state, action) => {
      state.auditLogs = {
        ...state.auditLogs,
        data: {} as AllAuditLogsFetchResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default AuditLogReducer;
