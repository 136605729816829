import AdminService from '@Services/Api/Admin';
import { getConfigSchemas } from '@Store/Config/action';
import { useAppDispatch } from '@Store/hooks';
import { ConfigSchemaWithoutId } from '@Types/Config';
import { apiCall } from '@Utils/index';
import { useNavigate } from 'react-router-dom';
import ConfigDetail from '../ConfigDetail';

export default function ConfigAdd() {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const addConfigSchema = async (schema: ConfigSchemaWithoutId) => {
    try {
      const req = () => new AdminService().AddConfigSchema(schema);
      await apiCall(req, 'addConfigSchema');
      dispatch(getConfigSchemas());
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  return <ConfigDetail onSubmit={addConfigSchema} />;
}
