import { createReducer } from '@reduxjs/toolkit';
import { getCounties, setSelectedCounty } from './action';
import { asyncFetchInterface } from '@Types/ReduxTypes';
import { County } from '@Types/County';

interface stateInterface {
  counties: asyncFetchInterface<County[]>;
  selectedCounty: County | null;
}

const initialState: stateInterface = {
  counties: {
    data: [],
    status: 'Empty',
  },
  selectedCounty: null,
};

const CountyReducer = createReducer(initialState, builder => {
  builder
    .addCase(getCounties.pending, state => {
      state.counties = {
        ...state.counties,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getCounties.fulfilled, (state, action) => {
      state.counties.data = action.payload;
      state.counties.status = 'fulfilled';
    })
    .addCase(getCounties.rejected, (state, action) => {
      state.counties = {
        ...state.counties,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(setSelectedCounty, (state, action) => {
      state.selectedCounty = action.payload;
    });
});

export default CountyReducer;
