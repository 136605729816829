import useDataDisplay from '@Hooks/useDataDisplay';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getPageLogs } from '@Store/User/action';
import React, { useState } from 'react';
import { convertDateTimeParam } from '@Utils/Api';
import { Card, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import CardGridWrapper from '@Components/CardGridWrapper';
import SortByFilter from './Components/SortByFilter';
import DataDisplayTable from '@Components/DataDisplayTable';
import PageLogCard from '@Cards/PageLogCard';
import './style.scss';
import usePageLogsColumns from './usePageLogsColumns';
import DateRangeSelector, { Dates } from '@Components/DateRangeSelector';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { FilterOption } from '@Types/Pagination';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';

export default function PageLogs() {
  const [sdate, setSDate] = useState<string | null>('03/05/2021');
  const [edate, setEDate] = useState<string | null>('03/05/2022');
  const [stime, setSTime] = useState<string | null>('10:00');
  const [etime, setETime] = useState<string | null>('23:00');
  const [currentDisplay, toggleDisplay] = useDataDisplay('pageLogs');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const pageLogs = useAppSelector(state => state.User.pageLogs);

  const pageConfigResult = useUserPageConfig('pageLogs', {
    orderBy: 'asc',
    sortKey: 'CreatedByUser.UserName',
  });

  const filterOptions: FilterOption[] = [
    {
      field: 'CreatedByUserId',
      label: t('user') + ' ID',
      type: FilterType.NUMBER,
    },
    {
      field: 'CreatedByUser.UserName',
      label: t('user'),
      type: FilterType.TEXT,
    },
    { field: 'PageUrl', label: 'BT Mac Address', type: FilterType.TEXT },
    {
      field: 'BrandId',
      label: t('brand') + ' ID',
      type: FilterType.NUMBER,
    },
    { field: 'Brand.Name', label: t('brand'), type: FilterType.TEXT },
    { field: 'StoreId', label: t('store') + ' ID', type: FilterType.NUMBER },
    { field: 'Store.Name', label: t('store'), type: FilterType.TEXT },
  ];

  const { pagination } = usePaginatedQuery({
    status: pageLogs.status,
    filterOptions,
    pageConfigResult,
    refetchDependencies: [sdate, edate, stime, etime],
    queryFunction: p => {
      if (!sdate || !edate || !stime || !etime) return;
      dispatch(
        getPageLogs({
          filters: p,
          dateTimeParams: convertDateTimeParam({ sdate, edate, stime, etime }),
        })
      );
    },
  });

  const pageLogsTableColumns = usePageLogsColumns({ pageLogs });

  const handleDateSelect = (dates: Dates) => {
    if (dates) {
      let [firstDate, secondDate] = dates;
      setSDate(firstDate ? firstDate.format('DD/MM/YYYY') : null);
      setEDate(secondDate ? secondDate.format('DD/MM/YYYY') : null);
      setSTime(firstDate ? firstDate.format('HH:mm') : null);
      setETime(secondDate ? secondDate.format('HH:mm') : null);
    }
  };

  useSetHeaderTitle(t('pageLogs'));

  return (
    <Card className="page-logs-detail-container">
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <DateRangeSelector onChange={handleDateSelect} />
        </div>

        <CardGridWrapper
          status={pageLogs.status}
          noData={pageLogs.data?.Data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <React.Fragment>
                {pageLogs.data?.Data?.map((pageLog, i) => (
                  <PageLogCard pageLog={pageLog} key={i} />
                ))}
              </React.Fragment>
            ) : (
              <DataDisplayTable
                columns={pageLogsTableColumns}
                dataSource={pageLogs.data?.Data}
              />
            )
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={pageLogs.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={pageLogs.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
}
