import model from './index';
import RoleTypes from '@Types/Role';
import { ApiResponse } from '@Types/apiTypes';

class RoleService extends model {
  constructor() {
    super();
    this.Prefix('Roles');
  }

  /**
   * @description Get all roles.
   * @returns Promise
   */
  async All() {
    return this.Get().offset().Save<RoleTypes.Roles>();
  }

  /**
   * @description Get all roles specifically for user page ONLY.
   * @returns Promise
   */
  async UserRoles() {
    return this.Get('AllRoles').offset().Save<RoleTypes.UserPageRole[]>();
  }

  /**
   * @param {string} roleId
   * @description Get Role.
   * @returns Promise
   */
  async Role(roleId: string) {
    return this.PrefixId(roleId).Get().Save<RoleTypes.Role>();
  }

  /**
   * @param {RoleTypes.NewRole} newRole
   * @description Create a new role.
   * @returns Promise
   */
  async New(newRole: RoleTypes.NewRole) {
    return this.Post().Data(newRole).Save();
  }

  /**
   *
   * @param {RoleTypes.EditRole} newRole
   * @description Edit an existing role.
   * @returns Promise
   */
  async Edit(editedRole: RoleTypes.EditRole) {
    return this.PrefixId(editedRole.RoleId)
      .Put()
      .Data(editedRole)
      .Save<ApiResponse>();
  }

  /**
   *
   * @description Delete a role.
   * @returns Promise
   */
  async DeleteRole(roleId: string) {
    return this.PrefixId(roleId).Delete().Save<any>();
  }
}

export default RoleService;
