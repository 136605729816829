import DeleteButton from '@Components/Card/DeleteButton';
import { Permissions } from '@Enums/Permission';
import useCheckPermission from '@Hooks/useCheckPermission';
import BeaconRulesService from '@Services/Api/BeaconRulesService';
import { apiCall } from '@Utils/index';
import { Space } from 'antd';

interface Props {
  beaconRuleId: number;
  getBeaconRules: Function;
}

export function BeaconRuleActionButtons({
  beaconRuleId,
  getBeaconRules,
}: Props) {
  const beaconRuleActionPermission = useCheckPermission(
    Permissions.ADMIN_BEACON_RULES_ACTION
  );

  const deleteBeaconRule = async () => {
    try {
      const req = () => new BeaconRulesService().DeleteBeaconRule(beaconRuleId);
      await apiCall(req, 'deleteBeaconRule');
      getBeaconRules();
    } catch (e) {
      console.warn(e);
    }
  };

  if (!beaconRuleActionPermission) return <></>;

  return (
    <Space direction="horizontal" size="small" wrap>
      <DeleteButton onConfirm={deleteBeaconRule} />
    </Space>
  );
}
