import { VersionFile } from '@Enums/VersionFileEnums';
import { useAppSelector } from '@Store/hooks';
import { VersionFiles } from '@Types/Version';

export function useVersionFiles(version: VersionFile): {
  query: VersionFiles;
  name: string;
} {
  const mainFiles = useAppSelector(s => s.Version.Main);
  const modelFiles = useAppSelector(s => s.Version.Model);
  const controlFiles = useAppSelector(s => s.Version.Control);
  const beaconFiles = useAppSelector(s => s.Version.Beacon);
  const packageFiles = useAppSelector(s => s.Version.Package);
  const bleFiles = useAppSelector(s => s.Version.Ble);

  const content = {
    [VersionFile.MAIN]: {
      query: mainFiles,
      name: 'Main',
    },
    [VersionFile.MODEL]: {
      query: modelFiles,
      name: 'Model',
    },
    [VersionFile.CONTROL]: {
      query: controlFiles,
      name: 'Control',
    },
    [VersionFile.BEACON]: {
      query: beaconFiles,
      name: 'Beacon',
    },
    [VersionFile.PACKAGE]: {
      query: packageFiles,
      name: 'Package',
    },
    [VersionFile.BLE]: {
      query: bleFiles,
      name: 'Ble',
    },
  };

  return content[version];
}
