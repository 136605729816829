import Status from '@Enums/Status';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import FilledText from '@Components/FilledText';
import { Typography } from 'antd';
import ReceiverActionButtons from '@Cards/ReceiverCard/ReceiverActionButtons';
import { AllReceiver, AllReceivers } from '@Types/Receiver';
import moment from 'moment';
import HierarchyItems from '@Components/Card/HierarchyItems';
import useWindowSize from '@Hooks/useWindowSize';

const { Text } = Typography;

interface Params {
  allReceiver: AllReceivers;
  getAllReceivers: Function;
}

/**
 * @description This "hook" is not a generic hook. It's made to abstract the code and make it much more readable. It will only be used for <AllReceivers/>.
 */
export default function useReceiversColumns(
  props: Params
): ColumnsType<AllReceiver> {
  const { allReceiver, getAllReceivers } = props;

  const receiversActionPerm = useCheckPermission(
    Permissions.ADMIN_RECEIVER_ACTION
  );
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  const receiversTableColumns: ColumnsType<AllReceiver> = useMemo(() => {
    if (allReceiver.status !== Status.success) return [];

    const columns: ColumnsType<AllReceiver> = [
      {
        title: t('ID'),
        dataIndex: 'Id',
      },
      {
        title: t('name'),
        dataIndex: 'Name',
        render: (_: any, receiver: AllReceiver) => (
          <FilledText text={receiver.Name} />
        ),
      },
      {
        title: t('entityIds'),
        dataIndex: 'EntityIds',
        render: (_: any, receiver: AllReceiver) => (
          <HierarchyItems
            brandId={receiver.BrandId}
            storeId={receiver.StoreId}
            brand={receiver.Brand}
            store={receiver.Store}
          />
        ),
        width: windowSize?.width && windowSize.width > 1360 ? 300 : 100,
      },
      {
        title: t('externalId'),
        dataIndex: 'ExternalId',
      },
      {
        title: t('BatteryUpdated'),
        dataIndex: 'BatteryUpdated',
        render: (_: any, receiver: AllReceiver) => (
          <FilledText
            text={
              receiver.BatteryUpdated
                ? receiver.Battery +
                  ' (' +
                  moment(receiver.BatteryUpdated).format('L') +
                  ' ' +
                  moment(receiver.BatteryUpdated).format('LT ') +
                  ')'
                : receiver.Battery + ' ( )'
            }
          />
        ),
      },
      {
        title: t('BtMacAdress'),
        dataIndex: 'BTMacAddress',
        render: (value: string) => <FilledText text={value} />,
      },
      {
        title: t('ReceiverType'),
        dataIndex: 'ReceiverType',
        render: (value: string) => <FilledText text={t(value) as string} />,
      },
    ];

    if (receiversActionPerm) {
      columns.push({
        title: t('actionButtons'),
        dataIndex: 'actionButtons',
        render: (_: any, receiver: AllReceiver) => (
          <ReceiverActionButtons
            {...props}
            receiver={receiver}
            getReceiver={getAllReceivers}
          />
        ),
      });
    }

    return columns;
  }, [allReceiver.status]);

  return receiversTableColumns;
}
