import { FetchedBoard } from '@Types/Board';

export function formatBoardIpText(board: FetchedBoard) {
  if (board.IsEthActive && board.IsWifiActive) {
    return `${board.IP}-w,e`;
  }

  if (board.IsEthActive) {
    return `${board.IP}-e`;
  }

  if (board.IsWifiActive) {
    return `${board.IP}-w`;
  }

  return board.IP;
}
