import DeleteButton from '@Components/Card/DeleteButton';
import CardGridWrapper from '@Components/CardGridWrapper';
import { UseBooleanResult } from '@Hooks/useBoolean';
import BrandsService from '@Services/Api/Brands';
import { getNotificationResponses } from '@Store/Notification/action';
import { useAppSelector } from '@Store/hooks';
import { INotificationResponse } from '@Types/Notification';
import { apiCall } from '@Utils/index';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Form, Input, Modal, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface IProps {
  brandId: number;
  modalState: UseBooleanResult;
}

export function NotificationResponsesModal(props: IProps) {
  const { modalState, brandId } = props;

  const [newNotificationResponse, setNewNotificationResponse] =
    useState<string>('');

  const notificationResponses = useAppSelector(
    s => s.Notification.notificationResponses
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotificationResponses(brandId));
  }, [brandId, dispatch, modalState.value]);

  const { t } = useTranslation();

  async function deleteNotificationResponse(responseId: number) {
    try {
      const req = () =>
        new BrandsService().DeleteNotificationResponse(brandId, responseId);
      await apiCall(req);
      dispatch(getNotificationResponses(brandId));
    } catch (e) {
      console.warn(e);
    }
  }

  async function createNotificationResponse() {
    try {
      const req = () =>
        new BrandsService().CreateNotificationResponse(
          brandId,
          newNotificationResponse
        );
      await apiCall(req);
      dispatch(getNotificationResponses(brandId));
      setNewNotificationResponse('');
    } catch (e) {
      console.warn(e);
    }
  }

  function newNotificationResponseChangeHandler(
    e: ChangeEvent<HTMLInputElement>
  ) {
    setNewNotificationResponse(e.target.value);
  }

  const columns: ColumnsType<INotificationResponse> = [
    {
      align: 'center',
      dataIndex: 'Id',
      title: 'ID',
      sorter: (a, b) => a.Id - b.Id,
    },
    {
      align: 'center',
      dataIndex: 'Text',
      title: t('notificationResponses.response'),
      sorter: (a, b) => a.Text.localeCompare(b.Text),
    },
    {
      align: 'center',
      dataIndex: 'CreatedDate',
      title: t('createdDate'),
      render: (_, item) => item.CreatedDateStr,
      sorter: (a, b) =>
        new Date(a.CreatedDate).getTime() - new Date(b.CreatedDate).getTime(),
    },
    {
      align: 'center',
      dataIndex: 'actionButtons',
      title: t('actionButtons'),
      render: (_, item) => (
        <DeleteButton onConfirm={() => deleteNotificationResponse(item.Id)} />
      ),
    },
  ];

  return (
    <Modal
      title={t('notificationResponses.title')}
      open={modalState.value}
      onOk={modalState.setFalse}
      onCancel={modalState.setFalse}
      cancelButtonProps={{ hidden: true }}
      destroyOnClose
    >
      <Form.Item label={t('notificationResponses.create')}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
          <Input
            onChange={newNotificationResponseChangeHandler}
            value={newNotificationResponse}
          />
          <Button
            onClick={createNotificationResponse}
            type="primary"
            icon={<PlusCircleOutlined />}
            disabled={!newNotificationResponse}
          />
        </div>
      </Form.Item>
      <CardGridWrapper
        status={notificationResponses.status}
        noData={false}
        cards={
          <Table
            dataSource={notificationResponses.data}
            columns={columns}
            pagination={false}
            bordered
            locale={{
              emptyText: <Empty description={t('noData')} />,
            }}
          />
        }
      />
    </Modal>
  );
}
