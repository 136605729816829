export enum CameraRecordStatus {
  // [Display(Name = "Yeni")]
  New = 0,

  // [Display(Name = "Kayıt Başladı")]
  RecordStarted = 1,

  // [Display(Name = "Kayıt Tamamlandı")]
  RecordCompleted = 6,

  // [Display(Name = "Sunucuya Yükleniyor")]
  UploadingToServer = 7,

  // [Display(Name = "Sunucuya Yükleme Hatası")]
  ErrorUploadingToServer = 8,

  // [Display(Name = "Sunucuya Yükleme Tamamlandı")]
  UploadedToServer = 10,
}

export enum CameraRecordProcessStatus {
  // [Display(Name = "Kayıt Bekleniyor")]
  WaitingRecord = 1,

  // [Display(Name = "İşlenmeyi Bekliyor")]
  WaitingProcess = 2,

  // [Display(Name = "İşleniyor")]
  Processing = 3,

  // [Display(Name = "İşleme Hatası")]
  ProcessingError = 9,

  // [Display(Name = "Tamamlandı")]
  Completed = 4,

  // [Display(Name = "Video Silindi")]
  VideoDeleted = 5,
}

export enum LabelingStatus {
  // [Display(Name = "Yeni")]
  New = 0,

  // [Display(Name = "Labelinge Gönderilmeyi Bekliyor")]
  Waiting = 1,

  // [Display(Name = "Labelinge Gönderildi")]
  Sent = 2,
}
