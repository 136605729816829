import { DevicesOption, NewDevices } from '@Types/Devices';
import { convertDevicesTypeEnum } from '@Utils/Devices';
import { Button, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { useEffect, useState } from 'react';

type Props = { onSubmit: (newDevices: NewDevices) => void };
const { Option } = Select;

function NewDevicesForm({ onSubmit }: Props) {
  const { t } = useTranslation();
  const [form] = useForm<NewDevices>();
  const [selectedDevicesType, setselectedDevicesType] = useState<
    number | null
  >();
  const [detailText, setdetailText] = useState('');

  const handleChange = (type: number): void => {
    setselectedDevicesType(type);
  };
  const handleSubmit = (newDevices: NewDevices) => {
    onSubmit(newDevices);
    form.resetFields();
  };
  const customPlaceholder =
    selectedDevicesType === 0 ||
    selectedDevicesType === 1 ||
    selectedDevicesType === 3
      ? 'Mac adresi'
      : selectedDevicesType === 2
      ? 'Telefon numarası'
      : selectedDevicesType === 4 && 'Model';

  const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setdetailText(e.target.value);
  };
  return (
    <Form
      name="new-devices-form"
      className="new-devices-form"
      form={form}
      onFinish={handleSubmit}
    >
      <Form.Item name="Type" label={t('devicesType')} required>
        <Select onChange={handleChange} allowClear>
          {devicesOptions.map(d => (
            <Option value={d.type}>{convertDevicesTypeEnum(d.type)}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="Detail" label={t('devicesDetail')} required>
        <Input
          type="text"
          placeholder={customPlaceholder || ''}
          onChange={inputOnChange}
        />
      </Form.Item>
      <Button
        className="submit-button"
        htmlType="submit"
        disabled={
          selectedDevicesType !== null && detailText !== '' ? false : true
        }
      >
        {t('add')}
      </Button>
    </Form>
  );
}

export default NewDevicesForm;

const devicesOptions: DevicesOption[] = [
  { type: 0, name: 'Modem' },
  { type: 1, name: 'AccessPoint' },
  { type: 2, name: 'DataLine' },
  { type: 3, name: 'POESwitch' },
  { type: 4, name: 'TransactionServer' },
];
