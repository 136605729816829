import { createReducer } from '@reduxjs/toolkit';
import { asyncFetchInterface } from '@Types/ReduxTypes';
import { getAllLensType, getLensType } from './action';
import { AllLensTypeFetchResponse, LensTypes } from '@Types/LensType';
import { PaginationData } from '@Types/index';

interface IInitialState {
  alllensType: asyncFetchInterface<PaginationData<LensTypes>>;
  lensType: asyncFetchInterface<LensTypes[]>;
}

const initialState: IInitialState = {
  alllensType: {
    data: {} as AllLensTypeFetchResponse,
    status: 'Empty',
  },
  lensType: {
    data: [],
    status: 'Empty',
  },
};

const LensTypeReducer = createReducer(initialState, builder => {
  builder
    .addCase(getAllLensType.pending, state => {
      state.alllensType = {
        ...state.alllensType,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllLensType.fulfilled, (state, action) => {
      state.alllensType = {
        status: 'fulfilled',
        data: action.payload as AllLensTypeFetchResponse,
      };
    })
    .addCase(getAllLensType.rejected, (state, action) => {
      state.alllensType = {
        ...state.alllensType,
        data: {} as AllLensTypeFetchResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getLensType.pending, state => {
      state.alllensType = {
        ...state.alllensType,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getLensType.fulfilled, (state, action) => {
      state.lensType.data = action.payload;
      state.lensType.status = 'fulfilled';
    })
    .addCase(getLensType.rejected, (state, action) => {
      state.lensType = {
        ...state.lensType,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default LensTypeReducer;
