import { createReducer } from '@reduxjs/toolkit';
import { OrderBy } from '@Types/Filter';
import { setOrderByFilter } from './action';

interface stateInterface {
  orderBy: OrderBy;
}

const initialState: stateInterface = {
  orderBy: 'asc',
};

const ParamsReducer = createReducer(initialState, builder => {
  builder.addCase(setOrderByFilter, (state, action) => {
    state.orderBy = action.payload;
  });
});

export default ParamsReducer;
