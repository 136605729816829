import { Button, Card, Pagination } from 'antd';
import BrandCard from '@Cards/BrandCard';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SortByFilter from './Components/SortByFilter';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getAllBrands } from '@Store/Brand/action';
import CardGridWrapper from '@Components/CardGridWrapper';
import { PlusCircleOutlined } from '@ant-design/icons';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import useDataDisplay from '@Hooks/useDataDisplay';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import DataDisplayTable from '@Components/DataDisplayTable';
import useBrandsColumns from './useBrandsColumns';
import ArchivedFilter from '@Components/ArchivedFilter';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import './style.scss';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { getUdentifyUsers } from '@Store/User/action';
import { getBrandGroupsWithoutPagination } from '@Store/BrandGroup/action';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';
import { FilterOption } from '@Types/Pagination';

const AllBrands = () => {
  const { t } = useTranslation();
  const [showArchivedBrands, setShowArchivedBrands] = useState<boolean>(false);

  useSetHeaderTitle(
    `${t('allBrands')} - ${
      showArchivedBrands ? t('archivedBrands') : t('unarchivedBrands')
    }`
  );

  const [currentDisplay, toggleDisplay] = useDataDisplay('brands');
  const brands = useAppSelector(state => state.Brand.allBrands);
  const udentifyUsers = useAppSelector(s => s.User.udentifyUsers.data);
  const brandGroups = useAppSelector(s => s.BrandGroup.brandGroups.data?.Data);

  const dispatch = useAppDispatch();

  const pageConfigResult = useUserPageConfig('brands', {
    orderBy: 'asc',
    sortKey: 'Name',
    filters: [],
  });

  useEffect(() => {
    dispatch(getUdentifyUsers());
    dispatch(getBrandGroupsWithoutPagination());
  }, []);

  const filterOptions: FilterOption[] = [
    { field: 'Id', label: 'ID', type: FilterType.NUMBER },
    { field: 'Name', label: t('name'), type: FilterType.TEXT },
    {
      field: 'AccountManagerId',
      label: t('accountManager'),
      type: FilterType.TEXT_ARRAY_SELECT,
      options: udentifyUsers?.map(u => ({
        label: u.Email,
        value: u.Id,
      })),
    },
    {
      field: 'GroupId',
      label: t('group'),
      type: FilterType.NUMBER_ARRAY_SELECT,
      options: brandGroups?.map(u => ({
        label: u.Name,
        value: u.Id,
      })),
    },
  ];

  const { pagination } = usePaginatedQuery({
    status: brands.status,
    filterOptions,
    pageConfigResult,
    refetchDependencies: [showArchivedBrands],
    queryFunction: p =>
      dispatch(getAllBrands({ ...p, isArchived: showArchivedBrands })),
  });

  const brandsTableColumns = useBrandsColumns({ brands });

  const boardActionPerm = useCheckPermission(Permissions.ADMIN_BRAND_ACTION);

  const toggleShowArchievedBrands = () => {
    setShowArchivedBrands(p => !p);
  };

  return (
    <Card>
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <ArchivedFilter
            onClick={toggleShowArchievedBrands}
            showArchived={showArchivedBrands}
          />

          <div className="add-container">
            <Link to="/brand-groups" className="groups-link">
              <Button type="link">{t('groups')}</Button>
            </Link>
            <Link to="new-brand">
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                disabled={!boardActionPerm}
              >
                {t('addBrand')}
              </Button>
            </Link>
          </div>
        </div>

        <CardGridWrapper
          status={brands.status}
          noData={brands.data?.Data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <React.Fragment>
                {brands.data?.Data?.map(brand => (
                  <BrandCard
                    brand={brand}
                    key={brand.Id}
                    isArchived={showArchivedBrands}
                  />
                ))}
              </React.Fragment>
            ) : (
              <DataDisplayTable
                columns={brandsTableColumns}
                dataSource={brands.data?.Data}
              />
            )
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={brands.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={brands.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
};

export default AllBrands;
