import { Permissions } from '@Enums/Permission';
import Status from '@Enums/Status';
import { BeaconRuleEnum } from '@Features/beaconRules/enums';
import useCheckPermission from '@Hooks/useCheckPermission';
import { useAppSelector } from '@Store/hooks';
import { IBeaconRulesForm } from '@Types/BeaconRule';
import { FormInstance } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function useDisableBeaconRuleSendButton(
  form: FormInstance<IBeaconRulesForm>
) {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');

  const brandReceivers = useAppSelector(s => s.Receiver.brandReceivers);
  const brandBeacons = useAppSelector(s => s.Beacons.brandBeacons);

  const type = useWatch('Type', form);
  const receiverIds = useWatch('ReceiverIds', form) || [];
  const beaconIds = useWatch('BeaconIds', form) || [];

  const { t } = useTranslation();

  const beaconRuleActionPermission = useCheckPermission(
    Permissions.ADMIN_BEACON_RULES_ACTION
  );

  useEffect(() => {
    (() => {
      if (!beaconRuleActionPermission) {
        setReason(t('beaconRules.errors.noPerm'));
        setDisabled(true);
        return;
      }

      if (
        brandReceivers.status !== Status.success ||
        brandBeacons.status !== Status.success
      ) {
        setReason('');
        setDisabled(true);
        return;
      }

      switch (type) {
        case BeaconRuleEnum.Closed:
          if (beaconIds.length > 0 || receiverIds.length > 0) {
            setReason('');
            setDisabled(false);
            return;
          }

          setReason(t('beaconRules.errors.atLeastOneDevice'));
          setDisabled(true);
          return;

        case BeaconRuleEnum.LowBatteryLevel:
          if (beaconIds.length > 0 || receiverIds.length > 0) {
            setReason('');
            setDisabled(false);
            return;
          }

          setReason(t('beaconRules.errors.atLeastOneDevice'));
          setDisabled(true);
          return;

        case BeaconRuleEnum.SignalFixedAreaLongTime:
          if (beaconIds.length === 0 || receiverIds.length === 0) {
            setReason(t('beaconRules.errors.equalDeviceCount'));
            setDisabled(true);
            return;
          }

          const beaconStoreIds = brandBeacons.data
            .filter(store =>
              beaconIds.some(beaconId =>
                store.Beacons.some(beacon => beacon.Id === beaconId)
              )
            )
            .map(store => store.Id);

          const receiverStoreIds = brandReceivers.data
            .filter(store =>
              receiverIds.some(receiverId =>
                store.Receivers.some(receiver => receiver.Id === receiverId)
              )
            )
            .map(store => store.Id);

          for (const beaconStoreId of beaconStoreIds) {
            if (!receiverStoreIds.includes(beaconStoreId)) {
              setReason(t('beaconRules.errors.storeIdMatch'));
              setDisabled(true);
              return;
            }
          }

          setReason('');
          setDisabled(false);
          return;

        default:
          setReason('');
          setDisabled(false);
          return;
      }
    })();
  }, [
    brandReceivers.status,
    brandBeacons.status,
    receiverIds,
    beaconIds,
    type,
    beaconRuleActionPermission,
  ]);

  return {
    disabled,
    reason,
  };
}
