import { createAsyncThunk } from '@reduxjs/toolkit';
import BeaconRulesService from '@Services/Api/BeaconRulesService';
import { IBeaconRule } from '@Types/BeaconRule';
import { PaginationParams } from '@Types/Filter';
import { GET_ALL_BEACON_RULES } from './type';

export const getBeaconRules = createAsyncThunk(
  GET_ALL_BEACON_RULES,
  async (params: PaginationParams) => {
    try {
      const cameraRules = await new BeaconRulesService().GetAllBeaconRules(
        params
      );
      return cameraRules;
    } catch (err) {
      console.error(err);
      return [] as IBeaconRule[];
    }
  }
);
