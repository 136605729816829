import DeleteButton from '@Components/Card/DeleteButton';
import EditButton from '@Components/Card/EditButton';
import GatewaysService from '@Services/Api/Gateway';
import { AllGateway } from '@Types/Gateway';
import { Space } from 'antd';

interface Props {
  gateway: AllGateway;
  getGateways: () => void;
}

export default function GatewayActionButtons({ gateway, getGateways }: Props) {
  const deleteGateway = async () => {
    try {
      await new GatewaysService().DeleteGateway(gateway.Id);
      getGateways();
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <Space direction="horizontal" size="small" wrap>
      <EditButton to={`${gateway.Id}`} />
      <DeleteButton onConfirm={deleteGateway} />
    </Space>
  );
}
