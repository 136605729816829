import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

function Brand() {
  return (
    <Layout id="brand-page">
      <Outlet />
    </Layout>
  );
}

export default Brand;
