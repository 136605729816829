import { createReducer } from '@reduxjs/toolkit';
import { getBrandGroups, getBrandGroupsWithoutPagination } from './action';
import { asyncFetchInterface } from '@Types/ReduxTypes';
import { GetAllBrandGroupsData } from '@Types/BrandGroup';

interface State {
  brandGroups: asyncFetchInterface<GetAllBrandGroupsData>;
}

const initialState: State = {
  brandGroups: {
    data: {} as GetAllBrandGroupsData,
    status: 'Empty',
  },
};

const BrandGroupReducer = createReducer(initialState, builder => {
  builder
    .addCase(getBrandGroups.pending, state => {
      state.brandGroups = {
        ...state.brandGroups,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getBrandGroups.fulfilled, (state, action) => {
      state.brandGroups.data = action.payload as GetAllBrandGroupsData;
      state.brandGroups.status = 'fulfilled';
    })
    .addCase(getBrandGroups.rejected, (state, action) => {
      state.brandGroups = {
        ...state.brandGroups,
        data: {} as GetAllBrandGroupsData,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getBrandGroupsWithoutPagination.pending, state => {
      state.brandGroups = {
        ...state.brandGroups,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getBrandGroupsWithoutPagination.fulfilled, (state, action) => {
      state.brandGroups.data = action.payload as GetAllBrandGroupsData;
      state.brandGroups.status = 'fulfilled';
    })
    .addCase(getBrandGroupsWithoutPagination.rejected, (state, action) => {
      state.brandGroups = {
        ...state.brandGroups,
        data: {} as GetAllBrandGroupsData,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default BrandGroupReducer;
