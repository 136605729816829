export const GET_ALL_BEACONS = 'BEACONS/GET_ALL_BEACONS';
export const GET_SELECT_BEACON = 'BEACONS/GET_SELECT_BEACON';

export const GET_STORES_BEACON_DATA = 'GET_STORES_BEACON_DATA';
export const GET_BRAND_BEACONS = 'GET_BRAND_BEACONS';
export const GET_SIMILAR_BEACONS = 'GET_SIMILAR_BEACONS';
export const GET_MULTIPLE_STORES_BEACON_DATA =
  'GET_MULTIPLE_STORES_BEACON_DATA';

export const GET_BEACON_DATA = 'GET_BEACON_DATA';

export const RESET_STORE_BEACON_DATA = 'RESET_STORE_BEACON_DATA';
export const RESET_BEACON_DATA = 'RESET_BEACON_DATA';
