export const stringToNumber = (
  value: number | string | undefined
): number | undefined => {
  if (typeof value === 'number') {
    return value;
  } else if (value === undefined) {
    return value;
  } else if (typeof value === 'string') {
    return parseInt(value);
  }
  return undefined;
};
