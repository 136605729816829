import { AllLensType, NewLensType } from '@Types/LensType';
import model from './index';
import { PaginationParams } from '@Types/Filter';

class LensTypeModelService extends model {
  constructor() {
    super();
    this.Prefix('LensType');
  }

  /**
   * @param data
   * @description Add lens type name
   * @returns Promise
   */
  async CreateLensType(name: NewLensType) {
    return this.Post().Data(name).offset().Save();
  }

  /**
   * @param {number} lensTypeId
   * @description Delete lens type
   * @returns Promise
   */
  async DeleteLensType(lensTypeId: number) {
    return this.Delete(lensTypeId.toString()).Save();
  }

  /**
   *
   * @param {number} lensTypeId
   * @param {NewLensType} editlensType
   * @description Update lens type
   * @returns Promise
   */
  async UpdateLensType(lensTypeId: number, editlensType: NewLensType) {
    return this.PrefixId(lensTypeId).Put().Data(editlensType).Save();
  }

  /**
   * @param {number} lensTypeId
   * @description Get lens type for id
   * @returns Promise
   */
  async GetLensType(lensTypeId: number) {
    return this.Get(`${lensTypeId}`).offset().Save<AllLensType>();
  }
  /**
   * @param params
   * @description Get all lens type
   * @returns Promise
   */
  async GetAlllensType(params: PaginationParams) {
    return this.Post('GetAll').Data(params).offset().Save<AllLensType>();
  }
}

export default LensTypeModelService;
