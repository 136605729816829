import { getBrandImage } from '@Store/Brand/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { asyncFlow } from '@Types/ReduxTypes';
import { useEffect } from 'react';

export interface BrandImage {
  status: asyncFlow;
  image: string;
}

const useBrandImage = (brandId: number): BrandImage => {
  const brandLogo = useAppSelector(state => state.Brand.selectedBrandImage);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getBrandImage(brandId));
  }, []);

  return {
    status: brandLogo.status,
    image: brandLogo.data.image,
  };
};

export default useBrandImage;
