import SetNewPasswordForm from '@Forms/SetNewPasswordForm';
import { SetNewPasswordFormValues } from '@Forms/SetNewPasswordForm';
import { UseBooleanResult } from '@Hooks/useBoolean';
import { Modal } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';

export type SetNewPasswordModalProps = {
  modalState: UseBooleanResult;
  onConfirm: (values: SetNewPasswordFormValues) => void;
};

export default function SetNewPasswordModal({
  modalState,
  onConfirm,
}: SetNewPasswordModalProps) {
  const { t } = useTranslation();

  const [form] = useForm<SetNewPasswordFormValues>();

  const newPasswordValue = useWatch('NewPassword', form);
  const confirmPasswordValue = useWatch('ConfirmPassword', form);

  return (
    <Modal
      open={modalState.value}
      title={t('setNewUserPassword')}
      onCancel={modalState.setFalse}
      onOk={() => onConfirm(form.getFieldsValue())}
      okButtonProps={{
        disabled:
          !newPasswordValue ||
          !confirmPasswordValue ||
          newPasswordValue !== confirmPasswordValue,
      }}
    >
      <SetNewPasswordForm form={form} />
    </Modal>
  );
}
