import { AllCamRecordsFetchResponse } from '@Types/CameraRecords';
import { Collapse, Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

export interface ICameraRecordsSummary {
  totalStatuses: AllCamRecordsFetchResponse['TotalStatuses'];
  className?: string;
}

export default function CameraRecordsSummary({
  totalStatuses,
  className,
}: ICameraRecordsSummary) {
  const { t } = useTranslation();

  function handleCollapseChange(key: string | string[]) {
    localStorage.setItem('cameraRecordsSummary', JSON.stringify(key));
  }

  function findLocalCollapseState(): ['1'] | undefined {
    const localCollapseState = localStorage.getItem('cameraRecordsSummary');
    if (!localCollapseState) {
      return undefined;
    }

    const parsedCollapseState = JSON.parse(localCollapseState);
    if (Array.isArray(parsedCollapseState) && parsedCollapseState.length > 0) {
      return ['1'];
    }

    return undefined;
  }

  const defaultActiveKey = findLocalCollapseState();

  return (
    <Collapse
      className={className}
      onChange={handleCollapseChange}
      defaultActiveKey={defaultActiveKey}
    >
      <Panel key="1" header={t('cameraRecordsTotalStatuses.header')}>
        <Descriptions bordered layout="vertical" size="small">
          {Object.entries(totalStatuses).map(([key, value]) => (
            <Descriptions.Item
              key={key}
              label={t(`cameraRecordsTotalStatuses.${key}`)}
            >
              {value}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Panel>
    </Collapse>
  );
}
