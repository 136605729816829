import { Col, Input, Modal, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  show: boolean;
  onConfirm: () => void;
  inputOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showModalOnCancel: () => void;
};

const ArchiveReasonModal = (props: Props) => {
  const { show, onConfirm, inputOnChange, showModalOnCancel } = props;
  const { t } = useTranslation();
  return (
    <Modal
      open={show}
      title={t('areYouSureToBeArchived')}
      onOk={() => onConfirm()}
      onCancel={showModalOnCancel}
      cancelButtonProps={{ hidden: true }}
    >
      <Row>
        <Col span={8}>
          <Title level={5}>{t('reasonforArchiving')} : </Title>
        </Col>
        <Col span={14}>
          <Input type="text" onChange={e => inputOnChange(e)} />
        </Col>
      </Row>
    </Modal>
  );
};

export default ArchiveReasonModal;
