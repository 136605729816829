import { ITicketDetails } from '@Types/ticket.types';
import { convertApiDate } from '@Utils/index';
import { ChatBubble } from '../ChatBubble';
import './style.scss';
import { useAppSelector } from '@Store/hooks';
import { useEffect, useRef } from 'react';

interface TicketChatProps {
  ticket: ITicketDetails;
}

export function TicketChat(props: TicketChatProps) {
  const { ticket } = props;

  const ref = useRef<HTMLDivElement>(null);

  const userId =
    useAppSelector(s => s.User?.currentUserDetails?.data?.Id) || undefined;

  useEffect(() => {
    ref.current?.scrollTo({
      top: ref.current.scrollHeight,
    });
  }, [ref.current]);

  return (
    <div className="chat-bubbles custom-scrollbar" ref={ref}>
      {ticket.TicketMessages.map(message => (
        <ChatBubble
          date={convertApiDate(message.CreatedDateTime)}
          sender={message.CreatedUserName}
          text={message.Message}
          align={message.CreatedUserId === userId ? 'right' : 'left'}
        />
      ))}
    </div>
  );
}
