export const GET_ALL_CAMERAS_FOR_STORES = 'CAMERA/GET_ALL_CAMERAS_FOR_STORES';
export const GET_ALL_CAMERAS_FOR_BRANDS = 'CAMERA/GET_ALL_CAMERAS_FOR_BRANDS';
export const GET_CAMERAS_SORTED_BY_STORE_IDS =
  'CAMERA/GET_CAMERAS_SORTED_BY_STORE_IDS';
export const GET_ALL_CAMERAS = 'CAMERA/GET_ALL_CAMERAS';

export const SET_SELECTED_CAMERA = 'CAMERA/SET_SELECTED_CAMERA';
export const SET_SELECTED_CAMERAS = 'CAMERA/SET_SELECTED_CAMERAS';
export const SET_ALL_CAMERAS = 'CAMERA/SET_ALL_CAMERAS';

export const GET_CAMERA_DETAIL = 'CAMERA/GET_CAMERA_DETAIL';
export const GET_ARCHIVED_CAMERAS = 'CAMERA/GET_ARCHIVED_CAMERAS';
export const GET_UNARCHIVED_CAMERAS = 'CAMERA/GET_UNARCHIVED_CAMERAS';
export const GET_ALL_ARCHIVED_CAMERAS = 'CAMERA/GET_ALL_ARCHIVED_CAMERAS';
export const GET_ALL_UNARCHIVED_CAMERAS = 'CAMERA/GET_ALL_UNARCHIVED_CAMERAS';
export const GET_ALL_UNARCHIVED_CAMERAS_OF_STORE =
  'CAMERA/GET_ALL_UNARCHIVED_CAMERAS_OF_STORE';
export const GET_ALL_ARCHIVED_CAMERAS_OF_STORE =
  'CAMERA/GET_ALL_ARCHIVED_CAMERAS_OF_STORE';

export const RESET_ALL_CAMERAS = 'CAMERA/RESET_ALL_CAMERAS';
export const RESET_CURRENT_CAMERA = 'CAMERA/RESET_CURRENT_CAMERA';
