import { useTranslation } from 'react-i18next';
import Title from 'antd/lib/typography/Title';
import './style.scss';

type Props = {
  children: JSX.Element;
  titleKey: string;
  extraTitle?: string;
};

const ModalHeader = ({ children, titleKey, extraTitle }: Props) => {
  const { t } = useTranslation();

  return (
    <Title level={3} className="user-modal-header">
      {t(titleKey) + (extraTitle ? ' - ' + extraTitle : '')}
      {children}
    </Title>
  );
};

export default ModalHeader;
