import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GET_ALL_HARDWARE,
  GET_BOARD_BRAND_CAMERAS,
  GET_HARDWARE,
} from './type';
import BoardBrandModel from '@Services/Api/BoardBrandModel';
import { PaginationParams } from '@Types/Filter';

export const getAllHardware = createAsyncThunk(
  GET_ALL_HARDWARE,
  async (params: PaginationParams) => {
    try {
      const hardware = await new BoardBrandModel().GetAllHardware(params);
      return hardware;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getHardware = createAsyncThunk(
  GET_HARDWARE,
  async (hardwareId: number) => {
    try {
      const hardware = await new BoardBrandModel().GetHardware(hardwareId);
      return hardware;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getBoardBrandCameras = createAsyncThunk(
  GET_BOARD_BRAND_CAMERAS,
  async () => {
    try {
      const result = await new BoardBrandModel().GetBoardBrandCameras();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
