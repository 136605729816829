import Status from '@Enums/Status';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { CameraRule, RuleUser } from '@Types/CameraRules';
import FilledText from '@Components/FilledText';
import HierarchyItems from '@Components/Card/HierarchyItems';
import useWindowSize from '@Hooks/useWindowSize';
import { AllCameraRules } from '@Types/CameraRules';
import { CameraRuleType } from '@Enums/CameraRule';
import InfoItem from '@Components/InfoItem';
import CameraRuleActionButtons from '@Cards/CameraRuleCard/CameraRuleActionButtons';
import { Space, Typography } from 'antd';

interface Params {
  cameraRules: AllCameraRules;
  getCameraRules: Function;
}

/**
 * @description This "hook" is not a generic hook. It's made to abstract the code and make it much more readable. It will only be used for <NotificationRules/>.
 */
export default function useNotificationRulesColumns({
  cameraRules,
  getCameraRules,
}: Params): ColumnsType<CameraRule> {
  const windowSize = useWindowSize();
  const { t } = useTranslation();

  const cameraRulesTableColumns: ColumnsType<CameraRule> = useMemo(() => {
    if (cameraRules.status !== Status.success) return [];

    const columns: ColumnsType<CameraRule> = [
      {
        title: 'Id',
        dataIndex: 'Id',
      },
      {
        title: t('entityIds'),
        dataIndex: 'EntityIds',
        render: (_: any, cameraRule: CameraRule) => (
          <HierarchyItems
            brandId={cameraRule.BrandId}
            storeId={cameraRule.StoreId}
            brand={cameraRule.Brand}
            store={cameraRule.Store}
            cameraId={cameraRule.CameraId}
            camera={cameraRule.Camera}
          />
        ),
        width: windowSize?.width && windowSize.width > 1360 ? 300 : 200,
      },
      {
        title: t('rule'),
        dataIndex: 'Type',
        render: (_: CameraRuleType, rule: CameraRule) => (
          <FilledText
            text={
              t(`notificationRuleType.${rule.Type}`, {
                number: rule.Number || '-',
              }) as string
            }
          />
        ),
      },
      {
        title: t('users'),
        dataIndex: 'Users',
        render: (users: RuleUser[]) => (
          <InfoItem
            content={
              <Space direction="vertical">
                {users.map(user => (
                  <Typography.Text key={user.Id} style={{ color: 'white' }}>
                    {user.Username}
                  </Typography.Text>
                ))}
              </Space>
            }
          />
        ),
      },
      {
        title: t('actionButtons'),
        dataIndex: 'actionButtons',
        render: (_: any, cameraRule: CameraRule) => (
          <CameraRuleActionButtons
            cameraRuleId={cameraRule.Id}
            getCameraRules={getCameraRules}
          />
        ),
      },
    ];
    return columns;
  }, [cameraRules.status]);

  return cameraRulesTableColumns;
}
