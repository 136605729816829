import { DrawElements } from '@Components/Svg/Constants';
import { shapeTasksTypes } from '@Components/Svg/Tasks/Shape';
import { shapeTypes } from '@Components/Svg/Types/Bases';
import { RefObject, useEffect, useCallback, useContext } from 'react';
import { ParamContext } from '../../Context/Params';
import { shapeContext } from '../../Context/Shape';
import { SvgContext } from '../../Context/Svg';
import { PointTasks } from '../../Tasks/Point';
import { ShapeObject } from '../../Types/Shape';
import { getMouseCoordinates } from '../commons';

export function useMouseDown(
  ref: RefObject<SVGCircleElement>,
  queue: number,
  svgRef?: RefObject<SVGSVGElement>
) {
  const params = useContext(ParamContext);
  const { setMove } = useContext(shapeContext);
  const mouseDown = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      switch (params.activeType) {
        case 'stable':
          draggable();
          break;

        default:
          break;
      }
    },
    [params.activeType, params.updateActiveType, setMove]
  );
  const draggable = () => {
    const proccess = PointTasks.Type(params, 'point_mouseDown');
    params.updateActiveType(proccess.type);
    setMove(queue);
  };
  useEffect(() => {
    ref.current?.addEventListener('mousedown', mouseDown, true);
    return () => {
      ref.current?.removeEventListener('mousedown', mouseDown, true);
    };
  }, [params.activeType]);
}

export function useMouseUp(
  ref: RefObject<SVGCircleElement>,
  svgRef?: RefObject<SVGSVGElement>
) {
  const params = useContext(ParamContext);
  const { setMove, move, updateShape } = useContext(shapeContext);

  useEffect(() => {
    const mouseUp = (e: Event) => {
      if (params.activeType === 'point_mouseMove') {
        params.updateActiveType('stable');
        setMove();
        updateShape();
      }
    };
    ref.current?.addEventListener('mouseup', mouseUp, true);
    // svgRef.current?.addEventListener('mouseleave', mouseUp, true);
    return () => {
      ref.current?.removeEventListener('mouseup', mouseUp, true);
      // svgRef.current?.removeEventListener('mouseleave', mouseUp, true);
    };
  }, [params.activeType, params.updateActiveType, setMove, updateShape]);
}

export function useMouseMove(
  ref: RefObject<SVGCircleElement>,
  shape: ShapeObject,
  queue: number,
  svgRef?: RefObject<SVGSVGElement>
) {
  const params = useContext(ParamContext);
  const { editable } = useContext(SvgContext);
  const { move, movePoint } = useContext(shapeContext);
  const mouseMoveEvent = (event: MouseEvent) => {
    event.preventDefault();
    if (move === undefined) return;
    trackMouse(event);
  };
  const trackMouse = useCallback(
    (event: MouseEvent) => {
      // if(selectedObject !== event.target) return ;
      // const proccess = PointTasks.Type(params, 'point_mouseMove');
      // if (params.activeType !== 'point_mouseMove') {
      //   params.updateActiveType(proccess.type);
      // }
      switch (params.activeType) {
        case 'point_mouseMove':
          MovePoint(event);
          break;
        case 'polygon_drawing':
          MovePoint(event);
          break;
        case 'draw':
          MovePoint(event);
          break;
        default:
          break;
      }
    },
    [params.activeType, movePoint]
  );
  const MovePoint = (event: MouseEvent) => {
    const cursor = getMouseCoordinates(event, svgRef?.current);
    movePoint(queue, cursor);
  };
  useEffect(() => {
    if (editable && svgRef && move === queue) {
      svgRef.current?.addEventListener('mousemove', mouseMoveEvent, true);
    }
    return () => {
      if (svgRef) {
        svgRef.current?.removeEventListener('mousemove', mouseMoveEvent, true);
      }
    };
  }, [editable, move]);
}

export function usePointOnClick(
  ref: RefObject<SVGCircleElement>,
  svgRef?: RefObject<SVGSVGElement>
): void {
  const params = useContext(ParamContext);
  const { editable } = useContext(SvgContext);
  const { addCorner } = useContext(shapeContext);
  const onClick = (event: MouseEvent) => {
    switch (params.activeType) {
      // case 'polygon_drawing':
      //   createPoint(DrawElements.Polygon, event);
      // break;
      case 'polygon_drawing':
        pointInserter(event);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (editable) {
      ref?.current?.addEventListener('click', onClick, true);
    }
    return () => {
      ref?.current?.removeEventListener('click', onClick, true);
    };
  }, [params.activeType, editable]);
  const createPoint = (event: MouseEvent) => {
    const proccess = shapeTasksTypes.Type(params, 'shape_add_corner');
    params.updateActiveType(proccess.type);
    switch (params.drawType) {
      case DrawElements.Polygon:
        pointInserter(event);
        break;

      default:
        break;
    }
  };
  const pointInserter = (event: MouseEvent) => {
    // const proccess = PointTasks.Type(params, 'point_add');
    // params.updateActiveType(proccess.type);
    const cursor = getMouseCoordinates(event, svgRef?.current);
    addCorner(cursor, true);
  };
}

export function useContextMenu(ref: RefObject<SVGCircleElement>) {
  const params = useContext(ParamContext);
  const { createOnEnd } = useContext(shapeContext);

  useEffect(() => {
    const contextMenu = (e: Event) => {
      e.preventDefault();
      switch (params.activeType) {
        case 'polygon_drawing':
          createOnEnd();
          break;
        default:
          break;
      }
    };
    ref.current?.addEventListener('contextmenu', contextMenu, true);
    return () => {
      ref.current?.removeEventListener('contextmenu', contextMenu, true);
    };
  }, [params.activeType, createOnEnd, params.cancel]);
}
