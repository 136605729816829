import { RefObject } from 'react';
import { DrawElements } from '../Constants';
import { ShapeProps } from '../Types/Shape';
import Line from './Line';
import Polygon from './Polygon';

interface ShapeElementProviderProps extends ShapeProps {}

const ShapeElementProvider = ({ ...shapeProps }: ShapeElementProviderProps) => {
  switch (shapeProps.Type) {
    case DrawElements.Line:
      return <Line {...shapeProps} />;
    default:
      return <Polygon {...shapeProps} />;
  }
};

export default ShapeElementProvider;
