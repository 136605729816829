import { User } from '@Types/User';
import Uxios, { axiosBuilder } from '@udentify/uxios';
import qs from 'qs';
import { AxiosResponse } from 'axios';
export { axiosBuilder };
const baseURL = process.env.REACT_APP_API_BASE;
const resolveToken = (loginResponse: AxiosResponse<User>) => {
  return loginResponse.data.access_token;
};
const RefreshTokenRequest = async (axios: any) => {
  const Account = JSON.parse(localStorage.getItem('Account') || '{}') as User;
  let data = qs.stringify({
    grant_type: 'refresh_token',
    refresh_token: Account.refresh_token,
  });

  try {
    const res: AxiosResponse<User> = await axios.post(baseURL + '/Token', data);

    if (res) {
      localStorage.setItem('Account', JSON.stringify(res.data));
    }

    return res;
  } catch {
    localStorage.clear();
    window.location.reload();
  }
};
export default class UdentifyApi extends Uxios {
  constructor() {
    super({
      axiosConfig: {
        baseURL,
        WithCredentials: false,
        refreshTokenUrl: '/Token',
        resolveToken,
        RefreshTokenRequest,
      },
    });
  }
  offset() {
    this.Params(
      Object.assign({}, this._params, {
        tzoffset: new Date().getTimezoneOffset() * -1,
      })
    );
    return this;
  }
}

new UdentifyApi();
