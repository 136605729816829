import { Button, Popover } from 'antd';
import {
  MoreOutlined,
  EyeOutlined,
  ClockCircleOutlined,
  SendOutlined,
  ForkOutlined,
  ReloadOutlined,
  DownloadOutlined,
  FormOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';
import { CameraRecordProcess, CameraRecordTable } from '@Types/CameraRecords';
import { useContext, useMemo } from 'react';
import { ActionButtonProps } from '@Components/ActionButton';
import ActionButtonColumn from '../ActionButtonColumn';
import CameraRecordsService from '@Services/Api/CameraRecords';
import { apiCall } from '@Utils/index';
import { useTranslation } from 'react-i18next';
import SetConfigModal from '../SetConfigModal';
import { ConfigSchemaId } from '@Types/Config';
import CamerasService from '@Services/Api/Cameras';
import { CameraRecordProcessStatus } from '@Enums/CameraRecord';
import { shouldShowReprocessButton } from '@Utils/CameraRecords';
import SetNoteModal from '../SetNoteModal';
import ResultsModal from '../ResultsModal';
import { RecordProcessContext } from '@Pages/CameraRecords/Contexts';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';

type Props = {
  recordProcess: CameraRecordProcess;
  record: CameraRecordTable;
  getCameraRecords: Function;
};

const RecordProcessActionButton = ({
  recordProcess,
  record,
  getCameraRecords,
}: Props) => {
  const { setShowConfigModal, setShowNoteModal, setShowResultsModal } =
    useContext(RecordProcessContext);

  const { t } = useTranslation();

  const markAsWatched = async () => {
    const req = async () =>
      await new CameraRecordsService().MarkAsWatched({
        recordId: record.data.Id,
        versionId: recordProcess.VersionId,
      });

    try {
      await apiCall(req, 'markRecordProcessAsWatched');
      getCameraRecords();
    } catch (err) {
      console.warn(err);
    }
  };

  const resetCameraRecord = async () => {
    const req = async () =>
      await new CameraRecordsService().ResetCameraRecord({
        recordId: record.data.Id,
        versionId: recordProcess.VersionId,
      });
    try {
      await apiCall(req, 'resetCameraRecord');
    } catch (err) {
      console.warn(err);
    }
  };

  const setConfig = async (config: string, configSchemaId: ConfigSchemaId) => {
    const req = async () => {
      await new CameraRecordsService().AddConfigToVersion({
        recordId: record.data.Id,
        versionId: recordProcess.VersionId,
        payload: { Config: config, ConfigSchemaId: configSchemaId },
      });
    };
    try {
      await apiCall(req, 'setConfig');
      setShowConfigModal(false);
      getCameraRecords();
    } catch (err) {
      console.warn(err);
    }
  };

  const recordLabelingSent = async () => {
    const req = async () =>
      await new CameraRecordsService().RecordLabelingSent({
        recordId: record.data.Id,
        versionId: recordProcess.VersionId,
      });

    try {
      await apiCall(req, 'recordLabelingSent');
      getCameraRecords();
    } catch (err) {
      console.warn(err);
    }
  };

  const setAsWaitingForLabeling = async () => {
    const req = async () =>
      await new CameraRecordsService().RecordLabelingWaiting({
        recordId: record.data.Id,
        versionId: recordProcess.VersionId,
      });

    try {
      await apiCall(req, 'setAsWaitingForLabeling');
      getCameraRecords();
    } catch (err) {
      console.warn(err);
    }
  };

  const downloadCameraRecord = async () => {
    const req = async () =>
      await new CamerasService().DownloadCameraRecord({
        cameraId: record.data.CameraId,
        recordId: record.data.Id,
        versionId: recordProcess.VersionId,
      });

    try {
      await apiCall(req, 'downloadCameraRecord');
    } catch (err) {
      console.warn(err);
    }
  };
  const camRecordActions = useCheckPermission(
    Permissions.ADMIN_CAMERA_RECORDS_ACTION
  );
  const upperColumn = useMemo(() => {
    if (!recordProcess?.Id) return [];

    const buttons = [
      {
        tooltip: t('recordProcessActions.setAsWaitingForLabeling'),
        icon: <ClockCircleOutlined />,
        onClick: setAsWaitingForLabeling,
        hasPermission: camRecordActions,
      },
      {
        tooltip: t('recordProcessActions.recordLabelingSent'),
        icon: <SendOutlined />,
        onClick: recordLabelingSent,
        hasPermission: camRecordActions,
      },
      {
        tooltip: t('recordProcessActions.recordProcessResults'),
        icon: <FileDoneOutlined />,
        onClick: () => setShowResultsModal(true),
      },
    ] as ActionButtonProps[];

    // Show watch button if the record is NOT already watched
    if (!recordProcess.IsWatched) {
      buttons.push({
        tooltip: t('recordProcessActions.markAsWatched'),
        icon: <EyeOutlined />,
        onClick: markAsWatched,
        hasPermission: camRecordActions,
      });
    }

    return buttons;
  }, [recordProcess?.Id]);

  const bottomColumn = useMemo(() => {
    if (!recordProcess?.Id) return [];

    const buttons = [
      {
        tooltip: t('recordProcessActions.setNote'),
        icon: <FormOutlined />,
        onClick: () => setShowNoteModal(true),
        hasPermission: camRecordActions,
      },
      {
        tooltip: t('recordProcessActions.setConfig'),
        icon: <ForkOutlined />,
        onClick: () => setShowConfigModal(true),
        hasPermission: camRecordActions,
      },
    ] as ActionButtonProps[];

    // Show reprocess button if conditions are met
    if (shouldShowReprocessButton(recordProcess, record)) {
      buttons.push({
        tooltip: t('recordProcessActions.resetCameraRecord'),
        icon: <ReloadOutlined />,
        onClick: resetCameraRecord,
        hasPermission: camRecordActions,
      });
    }

    // Show download button if the record is completed
    if (recordProcess.Status === CameraRecordProcessStatus.Completed) {
      buttons.push({
        tooltip: t('recordProcessActions.downloadCameraRecord'),
        icon: <DownloadOutlined />,
        onClick: downloadCameraRecord,
      });
    }

    return buttons;
  }, [recordProcess?.Id]);
  return (
    <>
      <Popover
        placement="top"
        content={<ActionButtonColumn actionButtons={upperColumn} />}
        trigger="click"
      >
        <Popover
          placement="bottom"
          content={<ActionButtonColumn actionButtons={bottomColumn} />}
          trigger="click"
        >
          <Button shape="circle" icon={<MoreOutlined />} />
        </Popover>
      </Popover>

      <SetConfigModal onConfirm={setConfig} recordProcess={recordProcess} />
      <SetNoteModal
        cameraId={record.data.CameraId}
        recordProcessId={recordProcess.Id}
      />
      <ResultsModal recordProcess={recordProcess} />
    </>
  );
};

export default RecordProcessActionButton;
