import { useState, useEffect } from 'react';
import { asyncFetchInterface } from '@Types/ReduxTypes';

function useRequest<T, P extends Array<string | number | undefined>>(
  request: (...params: P) => Promise<T>,
  params: P,
  initleData?: Partial<T>
): asyncFetchInterface<T> & { update: () => void } {
  const [result, setResult] = useState<asyncFetchInterface<T | unknown>>({
    data: initleData,
    status: 'Empty',
    ErrorMessage: '',
  });
  useEffect(() => {
    RequestFlow();
  }, [...params]);

  const RequestFlow = () => {
    setResult(prevResult => ({
      ...prevResult,
      data: initleData,
      status: 'Pending',
      ErrorMessage: '',
    }));
    request(...params)
      .then(res => {
        setResult(prevResult => ({
          ...prevResult,
          status: 'fulfilled',
          data: res,
        }));
      })
      .catch(err => {
        setResult(prevResult => ({
          ...prevResult,
          status: 'Rejected',
          ErrorMessage: err,
        }));
      });
  };
  return {
    data: result.data as T,
    status: result.status,
    ErrorMessage: result.ErrorMessage,
    update: RequestFlow,
  };
}

export default useRequest;
