import { paramTypes } from '../../Context/Params';
import { PointEventInterface, pointStatusTypes } from '../../Types/Point';
// import { svgStatusTypes } from '../../Types/Svg';

interface PointTasksType {
  Type: (
    params: paramTypes,
    eventType: pointStatusTypes
  ) => PointEventInterface;
}

export const PointTasks: PointTasksType = {
  Type(params, eventType) {
    switch (eventType) {
      case 'point_mouseDown':
        return mouseDown(params);
      case 'point_mouseUp':
        return mouseUp(params);
      case 'point_mouseMove':
        return mouseMove(params);
      case 'point_add':
        return pointAdd(params);
      case 'point_context':
        return contextMenu(params);
      default:
        break;
    }
    return { type: params.activeType } as PointEventInterface;
  },
};

const mouseDown = (params: paramTypes): PointEventInterface => {
  switch (params.activeType) {
    case 'stable':
      return { type: 'point_mouseMove' };
    default:
      return { type: params.activeType };
  }
};

const mouseUp = (params: paramTypes): PointEventInterface => {
  switch (params.activeType) {
    case 'point_mouseMove':
      return { type: 'point_mouseUp' };
    default:
      return { type: params.activeType };
  }
};

const mouseMove = (params: paramTypes): PointEventInterface => {
  switch (params.activeType) {
    case 'point_mouseMove':
      return { type: 'point_mouseMove' };
    default:
      return { type: params.activeType };
  }
};

const pointAdd = (params: paramTypes): PointEventInterface => {
  switch (params.activeType) {
    case 'point_add':
      return { type: 'polygon_drawing' };
    default:
      return { type: params.activeType };
  }
};

const contextMenu = (params: paramTypes): PointEventInterface => {
  switch (params.activeType) {
    case 'point_context':
      return { type: 'stable' };
    default:
      return { type: params.activeType };
  }
};
