import AppRoutes from '@Routes/index';
import trTR from 'antd/lib/locale/tr_TR';
import enUS from 'antd/lib/locale/en_GB';
import 'moment/locale/tr';
import 'moment/locale/en-gb';
import './App.css';
import { useMemo } from 'react';
import { Locale } from 'antd/lib/locale-provider';
import { ConfigProvider } from 'antd';

const App = () => {
  const antdLang: Locale = useMemo(() => {
    switch (localStorage.getItem('i18nextLng')) {
      case 'en-US':
        return enUS;
      case 'tr-TR':
        return trTR;
    }
    return enUS;
  }, []);

  return (
    <ConfigProvider locale={antdLang}>
      <AppRoutes />
    </ConfigProvider>
  );
};

export default App;
