export const GET_ALL_VERSIONS = 'VERSIONS/GET_ALL_VERSIONS';
export const GET_MAIN_FILE = 'VERSIONS/GET_MAIN_FILE';
export const GET_CONTROL_FILE = 'VERSIONS/GET_CONTROL_FILE';
export const GET_MODEL_FILE = 'VERSIONS/GET_MODEL_FILE';
export const GET_BEACON_FILE = 'VERSIONS/GET_BEACON_FILE';
export const GET_CODE_VERSION = 'VERSIONS/GET_CODE_VERSION';
export const GET_PACKAGE_FILES = 'VERSIONS/GET_PACKAGE_FILES';
export const GET_BLE_FILES = 'VERSIONS/GET_BLE_FILES';
export const RESET_CODE_VERS = 'VERSIONS/RESET_CODE_VERS';
export const GET_VERSION_FILE_GROUPS = 'GET_VERSION_FILE_GROUPS';

export const GET_MAIN_FILE_GROUPS = 'VERSIONS/GET_MAIN_FILE_GROUPS';
export const GET_CONTROL_FILE_GROUPS = 'VERSIONS/GET_CONTROL_FILE_GROUPS';
export const GET_MODEL_FILE_GROUPS = 'VERSIONS/GET_MODEL_FILE_GROUPS';
export const GET_BEACON_FILE_GROUPS = 'VERSIONS/GET_BEACON_FILE_GROUPS';
