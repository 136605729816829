export enum DrawElements {
  Polygon = 0,
  Line = 1,
}

export enum Keycodes {
  Escape = 'Escape',
}

export {};
