import { useGetStoresOfBrands } from '@Hooks/api';
import Status from '@Enums/Status';
import BrandService from '@Services/Api/Brand';
import { Camera } from '@Types/Camera';
import { useEffect, useState } from 'react';

export function useGetCamerasOfStores(
  brandIds: number[],
  stores: ReturnType<typeof useGetStoresOfBrands>
) {
  const [status, setStatus] = useState<Status>(Status.empty);
  const [data, setData] = useState<Camera[]>([]);

  useEffect(() => {
    fetchCameras();
  }, [brandIds.length, stores.data, stores.status]);

  async function fetchCameras() {
    if (stores.status !== Status.success) {
      return;
    }

    setStatus(Status.pending);

    try {
      // brand ids that was not fetched before. we only fetch those we did not already cache.
      const nonCachedBrandIds = brandIds.filter(brandId => {
        const storeExists = stores.data.some(
          s => !s.Archived && s.BrandId === brandId
        );

        if (!storeExists) {
          return false;
        }

        const cameraAlreadyCached = data?.some(camera => {
          const store = stores.data.find(s => s.Id === camera.StoreId);
          return brandId === store?.BrandId;
        });

        return !cameraAlreadyCached;
      });

      // fetch request for all these non cached brand ids
      const allCamerasPromise = await Promise.all(
        nonCachedBrandIds.map(id => new BrandService().GetCameras(id))
      );

      // push new cameras to the data array
      setData(prevCameras => [...prevCameras, ...allCamerasPromise.flat()]);

      setStatus(Status.success);
    } catch (e) {
      setStatus(Status.error);
      console.error(e);
    }
  }

  function resetData() {
    setData([]);
  }

  return {
    status,
    data,
    reset: resetData,
  };
}
