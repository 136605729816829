import { ReactElement, useContext, useRef } from 'react';
import { PointProps } from './Types/Point';
import {
  useMouseDown,
  useMouseUp,
  useMouseMove,
  usePointOnClick,
  useContextMenu,
} from './Events/Point';
function Point({
  Coordinates,
  svgRef,
  shape,
  queue,
}: PointProps): ReactElement {
  const circleRef = useRef<SVGCircleElement>(null);
  useMouseDown(circleRef, queue, svgRef);
  useMouseUp(circleRef, svgRef);
  useMouseMove(circleRef, shape, queue, svgRef);
  usePointOnClick(circleRef, svgRef);
  useContextMenu(circleRef);
  return (
    <circle
      ref={circleRef}
      cx={Coordinates.x}
      cy={Coordinates.y}
      r="5"
      stroke="black"
      strokeWidth="2"
      fill="red"
    />
  );
}

export default Point;
