
export enum Variant {
  error = 'error',
  success = 'success',
  warning = 'warning',
}
enum Status {
  pending = 'Pending',
  success = 'fulfilled',
  error = 'Rejected',
  empty = 'Empty',
}

export default Status;
