import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import './style.scss';

function GoBackButton() {
  const { t } = useTranslation();
  let navigate = useNavigate();

  return (
    <Button
      type="text"
      onClick={() => navigate(-1)}
      className="back-btn-container"
    >
      <ArrowLeftOutlined />
      <Title level={4} style={{ marginBottom: 0 }}>
        {t('goBack')}
      </Title>
    </Button>
  );
}

export default GoBackButton;
