import { Button, Col, Divider, Row, Typography } from 'antd';
import { useMemo, useState } from 'react';
import './style.scss';
import StaticIpForm from '../StaticIpForm';
import { IpConfigurations, WifiConfigiration } from '@Types/WifiConfigiration';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';

type Props = {
  ipConfigs?: IpConfigurations[];
  boardWifiConfigs?: WifiConfigiration[];
  storeWifiConfig?: WifiConfigiration;
};

const StaticIp = ({ ipConfigs, storeWifiConfig, boardWifiConfigs }: Props) => {
  const [showAddWiredForm, setShowAddWiredForm] = useState(false);

  const { t } = useTranslation();

  const wirelessConfigs = useMemo(() => {
    const configs: WifiConfigiration[] = [];

    if (storeWifiConfig) {
      configs.push(storeWifiConfig);
    }

    if (boardWifiConfigs) {
      configs.push(...boardWifiConfigs);
    }

    return configs;
  }, [storeWifiConfig, boardWifiConfigs]);

  const wiredIpConfigs = ipConfigs?.filter(c => c.Name.includes('wired'));

  return (
    <div className="RadioItems">
      <Col span={24}>
        <Col span={24}>
          <Typography.Title level={5}>{t('wireless')}</Typography.Title>
          <Row gutter={[32, 16]}>
            {wirelessConfigs.map((wirelessConfig, index) => {
              const ipConfig = ipConfigs?.find(
                c => c.WirelessConfId === wirelessConfig.Id
              );

              return (
                <Col span={7} className="staticCol" key={index}>
                  <StaticIpForm
                    interfaceLabel="SSID"
                    interfaceInput={wirelessConfig.SSID}
                    wifiConfigId={wirelessConfig.Id}
                    initialValue={{
                      ...ipConfig,
                      NetworkInterface:
                        wirelessConfig.SSID ?? ipConfig?.NetworkInterface,
                      DnsList: (ipConfig?.DNSServer ?? '').split(','),
                    }}
                    name={wirelessConfig.Name}
                  />
                </Col>
              );
            })}
          </Row>
        </Col>

        <Divider />

        <Col span={24}>
          <Typography.Title level={5}>{t('wired')}</Typography.Title>
          <Row gutter={[32, 16]}>
            {wiredIpConfigs?.map(d => (
              <Col span={7} className="staticCol" key={d?.Id}>
                <StaticIpForm
                  initialValue={{
                    ...d,
                    DnsList: (d?.DNSServer ?? '').split(','),
                  }}
                  name={d.Name}
                />
              </Col>
            ))}

            {showAddWiredForm ? (
              <Col span={7} className="staticCol">
                <StaticIpForm setAddButtonClick={setShowAddWiredForm} />
              </Col>
            ) : (
              <Col span={7} className="staticCol large-add-button-container">
                <Button
                  size="large"
                  type="dashed"
                  className="large-add-button"
                  onClick={() => setShowAddWiredForm(true)}
                >
                  <PlusOutlined className="plus" />
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Col>
    </div>
  );
};

export default StaticIp;
