import StackBarOptions from '@Charts/Options/StackBarOptions';
import { BeaconsData, BeaconDataLabels } from '@Types/Beacon';
import { ReactElement } from 'react';
import { Chart } from 'react-chartjs-2';

type Props = {
  data: BeaconsData;
  Label?: BeaconDataLabels[];
  tooltipLabel?: string;
};

function Charts({ data, Label, tooltipLabel }: Props): ReactElement {
  var dataSets = [
    {
      type: 'bar' as const,
      label: tooltipLabel,
      data: data.Serial,
      backgroundColor: '#196ebd',
    },
  ];
  const convert = {
    labels: Label?.map(i => i.Date),
    datasets: [...dataSets],
  };
  return <Chart type="bar" data={convert} options={StackBarOptions} />;
}
export default Charts;
