import { useTranslation } from 'react-i18next';
import { Divider, Empty, Modal, Space, Tag, Typography } from 'antd';
import {
  CameraRecordProcess,
  ICameraRecordProcessStatusHistory,
  ILine,
  IPolyLine,
} from '@Types/CameraRecords';
import Table, { ColumnsType } from 'antd/lib/table';
import { convertCameraRecordProcessEnum } from '@Utils/CameraRecords';
import { convertApiDate } from '@Utils';
import { useContext } from 'react';
import { RecordProcessContext } from '@Pages/CameraRecords/Contexts';

type Props = {
  recordProcess: CameraRecordProcess;
};

export default function ResultsModal({ recordProcess }: Props) {
  const { showResultsModal, setShowResultsModal } =
    useContext(RecordProcessContext);

  const closeModal = () => {
    setShowResultsModal(false);
  };

  const { t } = useTranslation();

  const lineColumns: ColumnsType<ILine> = [
    {
      title: 'LineId',
      dataIndex: 'LineId',
    },
    {
      title: t('positiveCount'),
      dataIndex: 'PositiveCount',
    },
    {
      title: t('negativeCount'),
      dataIndex: 'NegativeCount',
    },
  ];

  const polyLineColumns: ColumnsType<IPolyLine> = [
    {
      title: 'PolyLineId',
      dataIndex: 'PolyLineId',
    },
    {
      title: t('inCount'),
      dataIndex: 'InCount',
    },
    {
      title: t('outCount'),
      dataIndex: 'OutCount',
    },
  ];

  const processHistoryColumns: ColumnsType<ICameraRecordProcessStatusHistory> =
    [
      {
        title: t('createdDate'),
        dataIndex: 'CreatedDate',
        render: value => convertApiDate(value),
      },
      {
        title: t('BeforeStatus'),
        dataIndex: 'BeforeStatus',
        render: value => convertCameraRecordProcessEnum(value),
      },
      {
        title: t('CurrentStatus'),
        dataIndex: 'CurrentStatus',
        render: value => convertCameraRecordProcessEnum(value),
      },
    ];

  return (
    <Modal
      title={t('cameraRecordProcessResults')}
      open={showResultsModal}
      onOk={closeModal}
      onCancel={closeModal}
      cancelButtonProps={{ hidden: true }}
      width="max-content"
    >
      <Space direction="vertical">
        <Space direction="horizontal" size="large">
          <Space direction="vertical" size="small">
            <Typography.Title level={5}>FpsIsTrue</Typography.Title>
            <Tag color="gold">
              {typeof recordProcess.FpsIsTrue === 'boolean'
                ? JSON.stringify(recordProcess.FpsIsTrue)
                : '-'}
            </Tag>
          </Space>
          <Space direction="vertical" size="small">
            <Typography.Title level={5}>
              {t('DetectedPeopleCount')}
            </Typography.Title>
            <Tag color="magenta">
              {recordProcess.DetectedPeopleCount || '-'}
            </Tag>
          </Space>
        </Space>

        <Divider />

        <Space direction="horizontal" size="large" align="start" wrap>
          <Table
            columns={lineColumns}
            dataSource={recordProcess.LineCounts}
            pagination={false}
            bordered
            locale={{
              emptyText: <Empty description={t('noData')} />,
            }}
          />
          <Table
            columns={polyLineColumns}
            dataSource={recordProcess.PolyLineCounts}
            pagination={false}
            bordered
            locale={{
              emptyText: <Empty description={t('noData')} />,
            }}
          />
          <Table
            columns={processHistoryColumns}
            dataSource={recordProcess.CameraRecordProcessStatusHistories}
            pagination={false}
            bordered
            locale={{
              emptyText: <Empty description={t('noData')} />,
            }}
          />
        </Space>
      </Space>
    </Modal>
  );
}
