import { Button, SelectProps } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type Args = { showSelectAll?: boolean } & Pick<
  SelectProps,
  'options' | 'value' | 'onChange'
>;

export function useSelectAll({
  showSelectAll = true,
  options = [],
  value,
  onChange,
}: Args) {
  const { t } = useTranslation();

  const handleSelectAll = useCallback(() => {
    onChange?.(
      options.map(option => option.value),
      options
    );
  }, [onChange, options]);

  const handleUnselectAll = useCallback(() => {
    onChange?.([], []);
  }, [onChange]);

  const enchancedOptions = useMemo(() => {
    if (!showSelectAll) return options;

    return [
      {
        label: !value?.length ? (
          <Button type="link" onClick={() => handleSelectAll()}>
            {t('selectAll')}
          </Button>
        ) : (
          <Button type="link" onClick={() => handleUnselectAll()}>
            {t('unselectAll')}
          </Button>
        ),
        options,
      },
    ];
  }, [
    handleSelectAll,
    handleUnselectAll,
    options,
    showSelectAll,
    value?.length,
    t,
  ]);

  return {
    options: enchancedOptions,
    value,
    onChange,
  };
}
