import DeleteButton from '@Components/Card/DeleteButton';
import FormCard from '@Components/FormCard';
import WifiConfForm from '@Forms/WifiConfForm';
import {
  getStoreWifiConfigiration,
  resetStoreWifiConfig,
} from '@Store/Store/action';
import { useAppSelector } from '@Store/hooks';
import { WifiOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import './style.scss';
import StoreService from '@Services/Api/Store';
import { WifiConfigiration } from '@Types/WifiConfigiration';
import { stringToNumber } from '@Utils/Number';
import { useTranslation } from 'react-i18next';

type Props = {};

function WifiConfigirations({}: Props) {
  const wifiConfStore = useAppSelector(s => s.Store.wifiConfStore);
  const { storeId } = useParams();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    if (storeId) {
      dispatch(getStoreWifiConfigiration(parseInt(storeId!)));
    }

    return () => {
      dispatch(resetStoreWifiConfig());
    };
  }, [storeId]);

  async function handleDelete() {
    await new StoreService().DeleteWifiConfiguration(parseInt(storeId!));
    dispatch(getStoreWifiConfigiration(parseInt(storeId!)));
  }

  const handleSubmit = async (wifiConf: WifiConfigiration) => {
    const customWifiCustom = {
      ...wifiConf,
      Id: wifiConfStore?.data?.WifiConfiguration?.Id,
      Hidden: wifiConf.Hidden ? 1 : 0,
    };
    try {
      await new StoreService().PostWifiConfiguration(
        customWifiCustom,
        stringToNumber(storeId)
      );
      dispatch(getStoreWifiConfigiration(parseInt(storeId!)));
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <FormCard
        titleIcon={<WifiOutlined />}
        titleKey="Wifi Konfigürasyonu"
        className="wifi-configiration-wrapper"
        extraButtons={
          <div className="deleteButton">
            <DeleteButton
              onConfirm={handleDelete}
              optionalMessage={t('doYouAccept')}
              size="middle"
            />
          </div>
        }
      >
        {' '}
        <WifiConfForm
          wifiConfiguration={wifiConfStore?.data?.WifiConfiguration}
          status={wifiConfStore.status}
          onSubmit={handleSubmit}
        />
      </FormCard>
    </>
  );
}

export default WifiConfigirations;
