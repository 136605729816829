import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_CITIES, SET_SELECTED_CITY } from './type';
import { City } from '@Types/City';
import AdminService from '@Services/Api/Admin';

export const getCities = createAsyncThunk(
  GET_CITIES,
  async (countryId: number) => {
    try {
      const cities = await new AdminService().GetCities(countryId);
      return cities;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const setSelectedCity = createAction(
  SET_SELECTED_CITY,
  (city: City | null) => {
    return {
      payload: city,
    };
  }
);
