import StatusBadge from '@Components/StatusBadge';
import { FetchedBoard } from '@Types/Board';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import './style.scss';
import { Divider, Space, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import CardButton from '@Components/CardButton';
import { apiCall } from '@Utils/index';
import BoardsService from '@Services/Api/Boards';

interface IProps {
  onRefetch: () => void;
  board: FetchedBoard;
}

export function BoardStatusInstalledIndicator(props: IProps) {
  const { board, onRefetch } = props;
  const { t } = useTranslation();

  const setInstalledStatus = (status: boolean) => async () => {
    await apiCall(() =>
      new BoardsService().SetInstalledStatus(board.Id, status)
    );
    onRefetch();
  };

  return (
    <div className="board-status-installed-indicator">
      <StatusBadge isOn={board.Status} />
      <Divider type="vertical" style={{ marginInline: 4 }} />
      {board.Installed ? (
        <Space size={2} align="center">
          <Tooltip title={t('hadInstalled')}>
            <CheckCircleOutlined
              style={{ color: '#28B463', fontSize: '23px' }}
            />
          </Tooltip>
          <CardButton
            onClick={setInstalledStatus(false)}
            icon={<ReloadOutlined />}
            tooltipKey="setAsUninstalled"
          />
        </Space>
      ) : (
        <Space size={2}>
          <Tooltip title={t('notHadInstalled')}>
            <CloseCircleOutlined
              style={{ color: '#909497', fontSize: '23px' }}
            />
          </Tooltip>
          <CardButton
            onClick={setInstalledStatus(true)}
            icon={<ReloadOutlined />}
            tooltipKey="setAsInstalled"
          />
        </Space>
      )}
    </div>
  );
}

function Label() {
  const { t } = useTranslation();
  return (
    <div>
      {t('status')}
      <Divider type="vertical" style={{ marginInline: 4 }} />
      {t('installed')}
    </div>
  );
}

BoardStatusInstalledIndicator.Label = Label;
