import { UserBase } from '@Types/User';
import model from './index';

class UsersService extends model {
  constructor() {
    super();
    this.Prefix('Users');
  }

  /**
,   * @description Get all users
   * @returns Promise
   */
  async GetAll(storeIdsQuery: string) {
    return this.Get(storeIdsQuery).Save<UserBase[]>();
  }

  /**
   *
   * @param {string} params
   * @description Get all users of given stores
   * @returns Promise
   */
  async GetAllUsersOfStores(params: string) {
    return this.Get(`All/${params}`).Save<UserBase[]>();
  }
}

export default UsersService;
