import React, { Suspense } from 'react';
import 'moment/locale/tr';
import 'moment/locale/en-ca';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './Container/App';
import reportWebVitals from './reportWebVitals';
import Store from './Store';
import { Provider } from 'react-redux';
import './i18n';
import ULoading from './Components/ULoading';
import { BrowserRouter } from 'react-router-dom';
import { Chart as ChartJS, registerables } from 'chart.js';
import {
  create_x_session_id,
  is_exist_x_session_id,
} from '@Utils/x_session_id_creator';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '@Pages/ErrorPage';

ChartJS.register(...registerables);

ReactDOM.render(
  <Suspense fallback={<ULoading className={'fade-in'} />}>
    <Provider store={Store}>
      <React.StrictMode>
        <BrowserRouter basename={process.env.REACT_APP_SUB_APP_URL}>
          <ErrorBoundary FallbackComponent={ErrorPage} onError={console.error}>
            <App />
          </ErrorBoundary>
        </BrowserRouter>
      </React.StrictMode>
    </Provider>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Define new User Agent if it does not exist.
// This section used for capthca
if (!is_exist_x_session_id()) {
  create_x_session_id();
}
