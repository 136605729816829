import AutoReportsService from '@Services/Api/AutoReportsService';
import { AutoReportReceiver } from '@Types/Report';
import { apiCall } from '@Utils/index';
import { Table, TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import SubscribeButton from '../SubscribeButton';
import moment from 'moment';

type Props = {
  UnsubscribeUsers?: AutoReportReceiver[];
  reportId?: number;
};

const UnsubscribeList = ({
  UnsubscribeUsers = [],
  reportId = undefined,
}: Props) => {
  const [controlledUsers, setControlledUsers] = useState(UnsubscribeUsers);
  const { t } = useTranslation();
  function removeFromTable(userId: string) {
    setControlledUsers(prevUsers =>
      prevUsers.filter(user => user.UserId !== userId)
    );
  }
  async function Subscribe(userId: string) {
    try {
      if (!reportId) return;
      const req = () => new AutoReportsService().Subscribe(reportId, userId);
      await apiCall(req, 'subscribe', () => removeFromTable(userId));
    } catch (e) {}
  }
  const columns: TableColumnsType<AutoReportReceiver> = [
    {
      title: t('email'),
      dataIndex: 'UserEmail',
      key: 'UserEmail',
    },
    {
      title: t('date'),
      dataIndex: 'UnSubscribeDate',
      key: 'UnSubscribeDate',
      render: value => moment(value).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: t('action'),
      render: (_, user) => (
        <SubscribeButton onConfirm={() => Subscribe(user.UserId)} />
      ),
    },
  ];
  return <Table dataSource={controlledUsers} columns={columns} />;
};

export default UnsubscribeList;
