import Card from '@Components/Card';
import { serialized } from '@Types/Coordinates';
import ComposedChart from '@Charts/ComposedChart';
import { CameraStatusesDetail } from '@Types/Camera';
import TimeLineCard from '@Cards/TimeLineCard';
import PageSkeletons from '@Components/PageSkeletons';
import HierarchyItems from '@Components/Card/HierarchyItems';
import CamTimeLineCard from '@Cards/CamTimeLineCard';
type Props = {
  data: serialized;
  Label?: serialized;
  lineData?: serialized;
  lineLabel?: string;
  barLabel?: string;
  timeLineCardData?: CameraStatusesDetail;
  status?: string;
};

function CoordinateCard({
  data,
  Label,
  lineData,
  lineLabel,
  barLabel,
  timeLineCardData,
  status,
}: Props) {
  if (status === 'Pending') return <PageSkeletons />;
  return data.Name !== 'Labels' ? (
    <Card
      content={{
        firsItem: {
          text: data.Name + ' - ' + data.StoreName,
        },
        secondItem: {
          custom: (
            <HierarchyItems storeId={data.StoreId} store={data.StoreName} />
          ),
        },
      }}
      isDetailAlwaysOpen
      chartContent={
        <>
          <ComposedChart
            data={data}
            Label={Label}
            lineData={lineData}
            lineLabel={lineLabel}
            barLabel={barLabel}
          />
          <TimeLineCard data={timeLineCardData} Label={['Activities']} />
          <CamTimeLineCard data={timeLineCardData} Label={['Activities']} />
        </>
      }
    />
  ) : (
    <div></div>
  );
}

export default CoordinateCard;
