import { Form, Modal, Input } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/lib/form/Form';

type Props = {
  show: boolean;
  onCancel: () => void;
  onOk: (values: { Name: string; groupName: string }) => void;
  initialValues?: { Name: string; groupName: string };
};
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

function PolygonSettings({ show, onCancel, onOk, initialValues }: Props) {
  const { t } = useTranslation();
  const [form] = useForm();
  useEffect(() => {
    if (show) {
      form.setFieldsValue(initialValues);
    }
  }, [show]);

  const modalOnOk = () => {
    form.submit();
  };
  return (
    <Modal
      title={t('modal.title.polygonGroup')}
      open={show}
      onOk={modalOnOk}
      onCancel={onCancel}
      destroyOnClose
      maskClosable={false}
    >
      <Form
        {...layout}
        onFinish={onOk}
        form={form}
        preserve={false}
        initialValues={initialValues}
      >
        <Form.Item name="groupName" label={t('groupName')}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="Name"
          label={t('polygonName')}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default PolygonSettings;
