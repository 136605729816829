import { ReportForm } from '@Features/reports/forms';
import AutoReportsService from '@Services/Api/AutoReportsService';
import { ReportCreate, ReportCreateForm } from '@Types/Report';
import { apiCall } from '@Utils/index';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

export default function CreateReportPage() {
  let navigate = useNavigate();

  async function handleSubmit(report: ReportCreateForm) {
    report.ActionTime = moment(report.ActionTime, 'HH:mm').format('HH:mm');
    try {
      const emails: ReportCreate['AutoReportReceivers'] = Object.keys(
        report.AutoReportReceivers ?? {}
      ).reduce<ReportCreate['AutoReportReceivers']>(
        (storeUserIdPairs, storeId) => {
          if (report.AutoReportReceivers[storeId] !== undefined) {
            report.AutoReportReceivers[storeId]?.map(Ids =>
              storeUserIdPairs.push({
                StoreId: parseInt(storeId),
                UserId: Ids,
              })
            );
          }
          return storeUserIdPairs;
        },
        []
      );
      const sysEmails: ReportCreate['AutoReportReceivers'] = Object.keys(
        report.AutoReportReceiversSys ?? {}
      ).reduce<ReportCreate['AutoReportReceivers']>(
        (storeUserIdPairs, storeId) => {
          if (report.AutoReportReceiversSys[storeId] !== undefined) {
            report.AutoReportReceiversSys[storeId]?.map(Ids =>
              storeUserIdPairs.push({
                StoreId: parseInt(storeId),
                UserId: Ids,
              })
            );
          }
          return storeUserIdPairs;
        },
        []
      );
      const AutoReportReceivers = [...emails, ...sysEmails];
      const req = () =>
        new AutoReportsService().CreateAutoReport({
          ...report,
          AutoReportReceivers,
        });
      await apiCall(req, 'createReport');
      navigate(-1);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    // <FormCard titleIcon={<PaperClipOutlined />} titleKey="reports.createReport">
    <ReportForm onSubmit={handleSubmit} titleKey={'reports.createReport'} />
    // </FormCard>
  );
}
