import Brands from './Brands';
import Brand from './Brand';
import Stores from './Stores';
import Store from './Store';
import Boards from './Boards';
import Board from './Board';
import Cameras from './Cameras';
import Camera from './Camera';
import ApiKeyList from './ApiKeyList';
import Users from './Users';
import Roles from './Roles';
import CameraRecords from './CameraRecords';
import Versions from './Versions';
import Beacons from './Beacon';
import Gateways from './Gateways';
import Data from './Data';
import Configs from './Configs';
import Receiver from './Receiver';
import BeaconData from './BeaconData';
import NotificationRule from './NotificationRule';
import Region from './Region';
import Hardware from './Hardware';
import Lens from './Lens';
import Ticket from './Ticket';

const Outlets = {
  Ticket,
  Brands,
  Brand,
  Stores,
  Store,
  Boards,
  Board,
  Cameras,
  Camera,
  ApiKeyList,
  Users,
  Roles,
  CameraRecords,
  Versions,
  Beacons,
  Gateways,
  Data,
  Configs,
  Receiver,
  BeaconData,
  NotificationRule,
  Region,
  Hardware,
  Lens,
};

export default Outlets;
