import { PaperClipOutlined } from '@ant-design/icons';
import FormCard from '@Components/FormCard';
import PageSkeletons from '@Components/PageSkeletons';
import Status from '@Enums/Status';
import { ReportForm } from '@Features/reports/forms';
import AutoReportsService from '@Services/Api/AutoReportsService';
import { getUserStores } from '@Store/User/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import {
  AutoReportReceiver,
  IReport,
  IUpdateReportForm,
  ReportCreate,
  ReportCreateForm,
} from '@Types/Report';
import { apiCall } from '@Utils/index';
import { message } from 'antd';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function EditReportPage() {
  const dispatch = useAppDispatch();
  const [report, setReport] = useState<{ data?: IReport; status: Status }>({
    status: Status.empty,
  });
  const userStoresAndEmails = useAppSelector(s => s.User.userStoresAndEmails);

  let navigate = useNavigate();
  const { reportId } = useParams() as { reportId: string };

  useEffect(() => {
    setReport({ data: undefined, status: Status.pending });
    (async () => {
      try {
        if (isNaN(parseInt(reportId))) {
          throw message.error('Report ID must be a number.');
        } else {
          const data = await new AutoReportsService().GetAutoReport(
            parseInt(reportId)
          );
          setReport({ data, status: Status.success });
        }
      } catch (e) {
        setReport({ data: undefined, status: Status.error });
      }
    })();
  }, [reportId]);

  useEffect(() => {
    if (report.status === Status.success)
      dispatch(getUserStores(report.data!.BrandId));
  }, [report.status]);
  async function handleSubmit(report: ReportCreateForm) {
    report.ActionTime = moment(report.ActionTime, 'HH:mm').format('HH:mm');
    try {
      if (isNaN(parseInt(reportId))) {
        throw message.error('Report ID must be a number.');
      }
      const emails: ReportCreate['AutoReportReceivers'] = Object.keys(
        report.AutoReportReceivers ?? {}
      ).reduce<ReportCreate['AutoReportReceivers']>(
        (storeUserIdPairs, storeId) => {
          if (report.AutoReportReceivers[storeId] !== undefined) {
            report.AutoReportReceivers[storeId]?.map(Ids =>
              storeUserIdPairs.push({
                StoreId: parseInt(storeId),
                UserId: Ids,
              })
            );
          }
          return storeUserIdPairs;
        },
        []
      );
      const sysEmails: ReportCreate['AutoReportReceivers'] = Object.keys(
        report.AutoReportReceiversSys ?? {}
      ).reduce<ReportCreate['AutoReportReceivers']>(
        (storeUserIdPairs, storeId) => {
          if (report.AutoReportReceiversSys[storeId] !== undefined) {
            report.AutoReportReceiversSys[storeId]?.map(Ids =>
              storeUserIdPairs.push({
                StoreId: parseInt(storeId),
                UserId: Ids,
              })
            );
          }
          return storeUserIdPairs;
        },
        []
      );
      const AutoReportReceivers = [...emails, ...sysEmails];
      const req = () =>
        new AutoReportsService().UpdateAutoReport(parseInt(reportId), {
          ...report,
          Id: report.Id!,
          AutoReportReceivers,
        });
      await apiCall(req, 'editReport');
      navigate(-1);
    } catch (e) {}
  }
  const seperateSysIds = (): ReportCreateForm | undefined => {
    if (
      !report.data ||
      report.status !== Status.success ||
      userStoresAndEmails.status !== Status.success
    )
      return undefined;
    return {
      ...report.data,
      AutoReportReceiversSys: report.data.AutoReportReceivers.filter(
        reciver => {
          const users = userStoresAndEmails.data.find(
            stores => stores.StoreId === reciver.StoreId
          )?.Users;

          if (!users) {
            return false;
          }

          const user = users.find(usr => usr.UserId === reciver.UserId);

          if (!user) {
            return false;
          }

          return user?.IsSys;
        }
      ).reduce<ReportCreateForm['AutoReportReceiversSys']>((recivers, usr) => {
        if (!recivers || !recivers[usr.StoreId])
          return {
            ...recivers,
            [usr.StoreId]: [usr.UserId],
          };

        recivers[usr.StoreId]?.push(usr.UserId);
        return recivers;
      }, {}),
      AutoReportReceivers: report.data.AutoReportReceivers.filter(reciver => {
        const users = userStoresAndEmails.data.find(
          stores => stores.StoreId === reciver.StoreId
        )?.Users;

        if (!users) {
          return false;
        }

        const user = users.find(usr => usr.UserId === reciver.UserId);

        if (!user) {
          return false;
        }

        return !user?.IsSys;
      }).reduce<ReportCreateForm['AutoReportReceivers']>((recivers, usr) => {
        if (!recivers || !recivers[usr.StoreId])
          return {
            ...recivers,
            [usr.StoreId]: [usr.UserId],
          };
        recivers[usr.StoreId]?.push(usr.UserId);
        return recivers;
      }, {}),
    };
  };
  const initialReport = useMemo(seperateSysIds, [
    report.status,
    userStoresAndEmails.status,
  ]);
  const getUnSubcribeUsers = () => {
    return report.data?.AutoReportReceivers.filter(
      reciever => reciever.IsUnSubscribe
    ).reduce<AutoReportReceiver[]>((UnSubcribeUsers, reciever) => {
      if (
        !UnSubcribeUsers.find(
          UnSubcribeUser => UnSubcribeUser.UserId === reciever.UserId
        )
      ) {
        UnSubcribeUsers.push(reciever);
      }
      return UnSubcribeUsers;
    }, []);
  };
  const unSubscribedUserIds = useMemo(getUnSubcribeUsers, [report.status]);
  if (report.status !== Status.success || !initialReport)
    return <PageSkeletons />;

  return (
    <ReportForm
      onSubmit={handleSubmit}
      unSubscribedUsers={unSubscribedUserIds}
      report={initialReport}
      titleKey="reports.editReport"
    />
  );
}
