import DeleteButton from '@Components/Card/DeleteButton';
import { DevicesData, NewDevices } from '@Types/Devices';
import { convertDevicesTypeEnum } from '@Utils/Devices';
import { Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type Props = {
  data: DevicesData;
  handleDeleteDevicesClick: (index: number) => void;
};

function DevicesTable({ data, handleDeleteDevicesClick }: Props) {
  const { t } = useTranslation();
  const columns: ColumnsType<NewDevices> = [
    {
      align: 'center',
      dataIndex: 'Name',
      title: t('name'),
      render: (_, device) => convertDevicesTypeEnum(device.Type),
    },

    {
      align: 'center',
      dataIndex: 'Detail',
      title: t('devicesDetail'),
    },
    {
      align: 'center',
      dataIndex: 'CreatedByUser',
      title: t('recordProcess.User'),
    },
    {
      align: 'center',
      dataIndex: 'ActionTime',
      title: t('recordProcess.CreatedDate'),
      render: value => moment(value).format('DD/MM/YYYY'),
    },
    {
      align: 'center',
      title: t('actionButtons'),
      dataIndex: 'actionButtons',
      render: (value, row, index) => (
        <Space>
          <DeleteButton onConfirm={() => handleDeleteDevicesClick(index)} />
        </Space>
      ),
    },
  ];

  return (
    <Table
      dataSource={data}
      className="record-process-table"
      columns={columns}
      pagination={false}
    />
  );
}

export default DevicesTable;
