import { Product } from '@Types/Permission';
import { Row, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';
import RenderCategories from './RenderCategories';

interface Props {
  products: Product[];
  onCheckboxChange: (e: CheckboxChangeEvent) => void;
}

export default function RenderProducts({ products, onCheckboxChange }: Props) {
  const { t } = useTranslation();

  return (
    <>
      {products.map(p => (
        <Row key={p.name}>
          <Typography.Title level={4} className="product-title" underline>
            {t(p.name)}
          </Typography.Title>
          <RenderCategories
            categories={p.categories}
            onCheckboxChange={onCheckboxChange}
          />
        </Row>
      ))}
    </>
  );
}
