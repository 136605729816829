import ModalFooter from '@Components/ModalFooter';
import SearchableSelect from '@Components/SearchableSelect';
import { getAllHardware } from '@Store/Hardware/action';
import {
  getBeaconGroups,
  getControlGroups,
  getMainGroups,
  getModelGroups,
} from '@Store/Version/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import {
  AllVersion,
  VersionForm as IVersionForm,
  VersionFileGroup,
} from '@Types/Version';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Select, Space } from 'antd';
import { FormProps, useForm } from 'antd/lib/form/Form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { noPaginationParams } from '@Hooks/usePagination.ts/constants';

const DEFAULT_IMAGE_VERSION_VALUE = 1;

type Props = FormProps<IVersionForm> & {
  onFinish?: (values: any) => void;
  onFetch?: () => void;
  codeVersion?: AllVersion;
};

export default function VersionForm(props: Props) {
  const { onFinish, onFetch, codeVersion, ...restProps } = props;

  const { t } = useTranslation();
  const [form] = useForm();

  useEffect(() => {
    if (onFetch) {
      onFetch();
    }

    getHardwares();
    dispatch(getMainGroups());
    dispatch(getModelGroups());
    dispatch(getControlGroups());
    dispatch(getBeaconGroups());
  }, []);

  const dispatch = useAppDispatch();

  const mainFiles = useAppSelector(s => s.Version.mainGroups.data);
  const modelFiles = useAppSelector(s => s.Version.modelGroups.data);
  const controlFiles = useAppSelector(s => s.Version.controlGroups.data);
  const beaconFiles = useAppSelector(s => s.Version.beaconGroups.data);
  const hardwaresQuery = useAppSelector(s => s.Hardware.allHardware);

  function getHardwares() {
    dispatch(getAllHardware(noPaginationParams));
  }

  function formatFileTypeLabel(versionFileGroup: VersionFileGroup) {
    if (!versionFileGroup.ActiveFile) return versionFileGroup.Name;

    return `${versionFileGroup.Name} - ${versionFileGroup.ActiveFile?.Name}`;
  }

  const hardwareOptions = hardwaresQuery.data?.Data?.map(hardware => ({
    label: hardware.Name,
    value: hardware.Id,
  }));

  return (
    <Form
      form={form}
      name="version-form"
      id="version-form"
      onFinish={onFinish}
      initialValues={{
        ...props.initialValues,
        HardwareId:
          codeVersion?.HardwareId === 0 ? undefined : codeVersion?.HardwareId,
        ImageVersion: codeVersion?.ImageVersion ?? [
          DEFAULT_IMAGE_VERSION_VALUE,
        ],
      }}
      {...restProps}
    >
      <Form.Item label={t('hardware')} name="HardwareId" rules={rules}>
        <SearchableSelect options={hardwareOptions} />
      </Form.Item>

      <Form.Item label={t('codeVersionName')} name="Name" rules={rules}>
        <Input required />
      </Form.Item>
      <Form.Item label={t('version')} name="Version" rules={rules}>
        <Input />
      </Form.Item>

      <Form.Item
        required
        label={t('imageVersion')}
        rules={[{ required: true }]}
      >
        <Form.List
          name="ImageVersion"
          rules={[
            {
              validator: async (_, value) => {
                if (Array.isArray(value) && value.length > 0) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  t('atLeastXRequired', {
                    x: DEFAULT_IMAGE_VERSION_VALUE,
                    label: t('imageVersion').toLowerCase(),
                  })
                );
              },
            },
          ]}
        >
          {(fields, operations, { errors }) => (
            <div
              style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}
            >
              {fields.map((field, i) => (
                <Space key={field.key} size={2}>
                  <Form.Item style={{ margin: 0 }} name={[i]} label="">
                    <InputNumber
                      min={1}
                      defaultValue={DEFAULT_IMAGE_VERSION_VALUE}
                    />
                  </Form.Item>
                  <Button
                    type="text"
                    onClick={() => operations.remove(field.name)}
                    style={{ paddingInline: 8 }}
                  >
                    <CloseOutlined />
                  </Button>
                </Space>
              ))}
              <Form.ErrorList errors={errors} />
              <Button
                type="dashed"
                onClick={() => operations.add(DEFAULT_IMAGE_VERSION_VALUE)}
                block
              >
                <PlusCircleOutlined />
              </Button>
            </div>
          )}
        </Form.List>
      </Form.Item>

      <Form.Item label={'Main'} name="CompMainId">
        <SearchableSelect
          placeholder={t('selectMain')}
          className="form-select-item"
          allowClear
          showSearch
        >
          {mainFiles.map(main => (
            <Select.Option key={main.Id} value={main.Id} label={main.Name}>
              {formatFileTypeLabel(main)}
            </Select.Option>
          ))}
        </SearchableSelect>
      </Form.Item>
      <Form.Item label={'Model'} name="CompModelId">
        <SearchableSelect
          placeholder={t('selectModel')}
          className="form-select-item"
          allowClear
          showSearch
        >
          {modelFiles.map(model => (
            <Select.Option key={model.Id} value={model.Id} label={model.Name}>
              {formatFileTypeLabel(model)}
            </Select.Option>
          ))}
        </SearchableSelect>
      </Form.Item>
      <Form.Item label={'Control'} name="CompControlId">
        <SearchableSelect
          placeholder={t('selectControl')}
          className="form-select-item"
          allowClear
          showSearch
        >
          {controlFiles.map(control => (
            <Select.Option
              key={control.Id}
              value={control.Id}
              label={control.Name}
            >
              {formatFileTypeLabel(control)}
            </Select.Option>
          ))}
        </SearchableSelect>
      </Form.Item>
      <Form.Item label={'Beacon '} name="CompBeaconId">
        <SearchableSelect
          placeholder={t('selectBeacon')}
          className="form-select-item"
          allowClear
          showSearch
        >
          {beaconFiles.map(beacon => (
            <Select.Option
              key={beacon.Id}
              value={beacon.Id}
              label={beacon.Name}
            >
              {formatFileTypeLabel(beacon)}
            </Select.Option>
          ))}
        </SearchableSelect>
      </Form.Item>
      <ModalFooter formKey="version-form" />
    </Form>
  );
}

const rules = [{ required: true }];
