import Status from '@Enums/Status';
import { AllFetchedStores, FetchedStore } from '@Types/Store';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import HierarchyItems from '@Components/Card/HierarchyItems';
import { useTranslation } from 'react-i18next';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import StoreActionButtons from '@Cards/StoreCard/StoreActionButtons';
import CardInfo from '@Components/Card/CardInfo';
import { InfoOutlined, WifiOutlined } from '@ant-design/icons';
import { BoardCountInfo } from '@Components/BoardCountInfo';

interface Params {
  stores: AllFetchedStores;
  isArchived: boolean;
  getStores: () => void;
}

/**
 *
 * @description This "hook" is not a generic hook. It's made to abstract the code and make it much more readable. It will only be used for <StoresDetail/>.
 * @returns
 */
export default function useStoresColumns(
  params: Params
): ColumnsType<FetchedStore> {
  const { stores } = params;
  const storeActionPerm = useCheckPermission(Permissions.ADMIN_STORE_ACTION);
  const { t } = useTranslation();

  const storesTableColumns: ColumnsType<FetchedStore> = useMemo(() => {
    if (stores.status !== Status.success) return [];

    const columns: ColumnsType<FetchedStore> = [
      {
        title: t('name'),
        dataIndex: 'StoreName',
      },
      {
        title: t('notifications'),
        dataIndex: 'nonuploadedPhotos',
        render: (_: any, store: FetchedStore) => (
          <>
            {store.HasDismissPhoto && (
              <CardInfo
                title={t('unloadedPhotoExists')}
                icon={<InfoOutlined />}
              />
            )}
            {store.WifiConfiguration?.SSID && (
              <CardInfo
                title={store.WifiConfiguration?.SSID}
                icon={<WifiOutlined />}
              />
            )}
          </>
        ),
      },
      {
        title: t('entityIds'),
        dataIndex: 'entityIds',
        render: (_: any, store: FetchedStore) => (
          <HierarchyItems
            brandId={store.BrandId}
            brand={store.BrandName}
            storeId={store.StoreId}
            store={store.StoreName}
          />
        ),
      },
      {
        title: t('passiveBoards'),
        dataIndex: 'passiveBoards',
        render: (_: any, store: FetchedStore) => <BoardCountInfo {...store} />,
      },
    ];

    if (storeActionPerm) {
      columns.push({
        title: t('actionButtons'),
        dataIndex: 'actionButtons',
        render: (_: any, store: FetchedStore) => (
          <StoreActionButtons {...params} store={store} />
        ),
      });
    }

    return columns;
  }, [stores.status]);

  return storesTableColumns;
}
