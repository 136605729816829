import { CameraTypeEnums, InterfaceCameraTypeEnums } from '../Enums/Camera';

export function convertCameraEnumToText(cameraType: CameraTypeEnums) {
  switch (cameraType) {
    case 0:
      return 'Fisheye';
    case 2:
      return 'Flat';
    case 3:
      return 'Personnel Tracking';
  }

  return 'Unknown';
}
export function convertInterfaceCameraEnumToText(
  cameraType: InterfaceCameraTypeEnums
) {
  switch (cameraType) {
    case 0:
      return 'CSI';
    case 1:
      return 'USB';
  }

  return 'Unknown';
}
