import FormCard from '@Components/FormCard';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import PageSkeletons from '@Components/PageSkeletons';
import { apiCall } from '@Utils';
import HardwareForm from '@Forms/HardwareForm';
import HardwareTypes from '@Types/Hardware';
import BoardBrandModel from '@Services/Api/BoardBrandModel';
import { getHardware } from '@Store/Hardware/action';

type Props = {};

export default function HardwareEdit({}: Props) {
  const { hardwareId } = useParams();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const hardware = useAppSelector(s => s.Hardware.hardware);

  useEffect(() => {
    dispatch(getHardware(parseInt(hardwareId!)));
  }, [hardwareId]);

  const handleSubmit = async (newHardware: HardwareTypes.NewHardware) => {
    let editedHardware: HardwareTypes.NewHardware = {
      Name: newHardware.Name,
    };
    try {
      await apiCall(() => addHardware(editedHardware), 'editHardware');
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const addHardware = async (newHardware: HardwareTypes.NewHardware) => {
    return await new BoardBrandModel().UpdateHardware(
      parseInt(hardwareId!),
      newHardware
    );
  };

  if (Object.keys(hardware).length === 0 || hardware.status === 'Pending')
    return <PageSkeletons />;
  return (
    <FormCard titleIcon={<EditOutlined />} titleKey="addHardware">
      <HardwareForm onFinish={handleSubmit} hardware={hardware.data} />
    </FormCard>
  );
}
