import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import { useTranslation } from 'react-i18next';

type EditSelectedsButtonProps = ButtonProps & {};

export function EditSelectedsButton({
  onClick,
  ...restProps
}: EditSelectedsButtonProps) {
  const { t } = useTranslation();

  return (
    <Button
      onClick={onClick}
      danger
      type="primary"
      icon={<EditOutlined />}
      {...restProps}
    >
      {t('editSelecteds')}
    </Button>
  );
}
