import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GET_ALL_STORES_FOR_BRANDS,
  GET_ALL_STORES_OF_BRAND,
  SET_SELECTED_STORE,
  SET_SELECTED_STORES,
  GET_UNARCHIVED_STORES,
  GET_ARCHIVED_STORES,
  GET_STORE_DETAIL,
  RESET_ALL_STORES,
  GET_ALL_UNARCHIVED_STORES,
  GET_ALL_ARCHIVED_STORES,
  RESET_CURRENT_STORE,
  GET_STORES_CAMERA_STATUS,
  GET_MULTIPLE_STORES_CAMERA_STATUS,
  GET_STORE_WIFI_CONF,
  GET_STORE_UNARCHIVE_CAMERAS,
  RESET_STORE_WIFI_CONFIG,
} from './type';
import BrandService from '../../Services/Api/Brand';
import {
  GetStoresParams,
  Store,
  StoreUnArchiveCamerasTypes,
} from '@Types/Store';
import StoresService from '@Services/Api/Stores';
import BrandsService from '@Services/Api/Brands';
import { PaginationParams } from '@Types/Filter';
import {
  CoordinatesFilterParams,
  MultipleCoordinatesFilterParams,
} from '@Types/Coordinates';
import { RootState } from '..';
import StoreService from '@Services/Api/Store';

export const getAllStoresOfBrands = createAsyncThunk(
  GET_ALL_STORES_FOR_BRANDS,
  async (brandIds: number[], { getState }) => {
    try {
      const oldAllStores = (getState() as RootState).Store.allStores.data ?? [];

      const allStoresPromise = await Promise.all(
        brandIds.map(brandId => new BrandService().GetStores(brandId))
      );

      const allStores: Store[] = JSON.parse(JSON.stringify(oldAllStores));

      for (const stores of allStoresPromise) {
        allStores.push(
          ...stores.filter(store => !allStores.some(s => s.Id === store.Id))
        );
      }

      return allStores;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getAllStoresOfBrand = createAsyncThunk(
  GET_ALL_STORES_OF_BRAND,
  async (brandId: number) => {
    try {
      const allStores = await new BrandService().GetStores(brandId);
      return allStores;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const setSelectedStore = createAction(
  SET_SELECTED_STORE,
  (selectedStore: Store) => {
    return {
      payload: selectedStore,
    };
  }
);

export const setSelectedStores = createAction(
  SET_SELECTED_STORES,
  (selectedStores: Store[]) => {
    return {
      payload: selectedStores,
    };
  }
);

export const getUnarchivedStores = createAsyncThunk(
  GET_UNARCHIVED_STORES,
  async (params: GetStoresParams) => {
    try {
      const allStores = await new BrandsService().GetAllUnarchivedStores(
        params.filters,
        params.brandId
      );
      return allStores;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getArchivedStores = createAsyncThunk(
  GET_ARCHIVED_STORES,
  async (params: GetStoresParams) => {
    try {
      const allStores = await new BrandsService().GetAllArchivedStores(
        params.filters,
        params.brandId
      );
      return allStores;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getStoreDetail = createAsyncThunk(
  GET_STORE_DETAIL,
  async (storeId: string) => {
    try {
      const store = await new StoresService().GetStoreDetails(storeId);
      return store;
    } catch (err) {
      console.error(err);
      return {};
    }
  }
);

export const getAllUnarchivedStores = createAsyncThunk(
  GET_ALL_UNARCHIVED_STORES,
  async (params: PaginationParams) => {
    try {
      const allStores = await new StoresService().GetAllUnarchived(params);
      return allStores;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getAllArchivedStores = createAsyncThunk(
  GET_ALL_ARCHIVED_STORES,
  async (params: PaginationParams) => {
    try {
      const allStores = await new StoresService().GetAllArchived(params);
      return allStores;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getStoresCameraStatus = createAsyncThunk(
  GET_STORES_CAMERA_STATUS,
  async (params: CoordinatesFilterParams) => {
    try {
      const storesCameraStatus =
        await new StoresService().GetStoresCameraStatus(params);
      return storesCameraStatus;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getMultipleStoresCameraStatus = createAsyncThunk(
  GET_MULTIPLE_STORES_CAMERA_STATUS,
  async (params: MultipleCoordinatesFilterParams) => {
    try {
      const storesCameraStatus =
        await new BrandService().GetMultipleStoresCameraStatus(params);
      return storesCameraStatus;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getStoreWifiConfigiration = createAsyncThunk(
  GET_STORE_WIFI_CONF,
  async (storeId: number) => {
    try {
      const storeWifiConfig = await new StoreService().GetNetworkConfiguration(
        storeId
      );
      return storeWifiConfig;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getUnArchiveStoreCameras = createAsyncThunk(
  GET_STORE_UNARCHIVE_CAMERAS,
  async (params: StoreUnArchiveCamerasTypes) => {
    try {
      const storeCameras = await new StoreService().UnArchiveStoreCameras(
        params
      );
      return storeCameras;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const resetAllStores = createAction(RESET_ALL_STORES);
export const resetCurrentStore = createAction(RESET_CURRENT_STORE);
export const resetStoreWifiConfig = createAction(RESET_STORE_WIFI_CONFIG);
