import { Card, Row, Space, Spin, Tabs } from 'antd';
import {
  CameraOutlined,
  ShopOutlined,
  VideoCameraOutlined,
  ControlOutlined,
} from '@ant-design/icons';
import TabItem from '@Components/TabItem';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@Store/hooks';
import AddButton from '@Components/AddButton';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
const { TabPane } = Tabs;

export default function Configs() {
  let navigate = useNavigate();
  let location = useLocation();
  const { t } = useTranslation();
  const schemas = useAppSelector(s => s.Config.configSchemas);

  useEffect(() => {
    if (location.pathname === '/configs' || location.pathname === '/configs/')
      navigate('camera');
  }, [location.pathname]);

  const handleTabClick = (activeTab: string) => {
    navigate(activeTab || 'camera');
  };

  /**
   *    Pathname may be something like "/configs/store", "/configs/camera",
   *    This function returns it as a value like "store" or "camera".
   */
  const findActiveKey = () => {
    return location.pathname.split('/').pop();
  };

  const defaultActiveKey = useMemo(() => findActiveKey(), []);

  useSetHeaderTitle(t('configs'));

  if (schemas.status === 'Pending') return <Spin />;

  return (
    <Tabs
      onTabClick={handleTabClick}
      defaultActiveKey={defaultActiveKey}
      className="config-tabs-wrapper"
    >
      {TABS.map(({ icon, key, path }) => (
        <TabPane tab={<TabItem icon={icon} translationKey={key} />} key={path}>
          <Card
            title={
              <Row className="card-grid-header" justify="space-between">
                <Link
                  to={`${findActiveKey()}/new`}
                  hidden={!showAddForRoutes.includes(location.pathname)}
                  style={{ marginLeft: 'auto' }}
                >
                  <AddButton />
                </Link>
              </Row>
            }
          >
            <Outlet />
          </Card>
        </TabPane>
      ))}
    </Tabs>
  );
}

const TABS = [
  { icon: <CameraOutlined />, key: 'camera', path: 'camera' },
  { icon: <VideoCameraOutlined />, key: 'cameraRecord', path: 'camera-record' },
  { icon: <ShopOutlined />, key: 'store', path: 'store' },
  // { icon: <BranchesOutlined />, key: 'version', path: 'version' },
];

// Add config schema button will be shown for only these routes
const showAddForRoutes = [
  '/configs/camera',
  '/configs/camera-record',
  '/configs/store',
  '/configs/version',
];
