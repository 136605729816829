import { VideoCameraAddOutlined } from '@ant-design/icons';
import FormCard from '@Components/FormCard';
import { useNavigate } from 'react-router-dom';
import {
  NewCameraRecord as NewCamRecordType,
  NewCameraRecordMultipleDates,
} from '@Types/CameraRecords';
import NewCameraRecordForm from '@Forms/NewCameraRecordForm';
import { apiCall } from '@Utils/index';
import CameraRecordsService from '@Services/Api/CameraRecords';

const NewCameraRecord = () => {
  let navigate = useNavigate();

  const handleSubmit = async (cameraRecord: NewCameraRecordMultipleDates) => {
    try {
      await apiCall(() => addCameraRecord(cameraRecord), 'addCameraRecord');
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const addCameraRecord = async (
    cameraRecord: NewCameraRecordMultipleDates
  ) => {
    return await new CameraRecordsService().AddCameraRecordMultipleDates(
      cameraRecord
    );
  };

  return (
    <FormCard
      titleIcon={<VideoCameraAddOutlined />}
      titleKey="addCameraRecord"
      className="camera-detail-wrapper"
    >
      <NewCameraRecordForm onFinish={handleSubmit} />
    </FormCard>
  );
};

export default NewCameraRecord;
