import { IDateRangeForm } from '@Components/MultipleDateRangeSelector/types';
import { Button, DatePicker, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import './style.scss';

export interface INewDateRangeFormProps {
  onSubmit: (dateRange: IDateRangeForm) => void;
}

export default function NewDateRangeForm({ onSubmit }: INewDateRangeFormProps) {
  const { t } = useTranslation();

  const [form] = useForm<IDateRangeForm>();

  const startDateValue = Form.useWatch('startDate', form);
  const durationValue = Form.useWatch('duration', form);

  const handleSubmit = (dateRange: IDateRangeForm) => {
    // Send values
    onSubmit(dateRange);

    // Reset form
    form.resetFields();
  };

  return (
    <Form
      name="new-date-range-form"
      className="new-date-range-form"
      form={form}
      onFinish={handleSubmit}
      labelCol={{ span: 10 }}
    >
      <Form.Item name="startDate" label={t('startDate')} required>
        <DatePicker
          className="form-select-item"
          showTime={{ format: 'HH:mm' }}
          format="YYYY-MM-DD HH:mm"
          placeholder={t('selectDate')}
        />
      </Form.Item>
      <Form.Item name="duration" label={t('duration')} required>
        <Input type="number" min={1} max={30} placeholder={t('minute')} />
      </Form.Item>
      <Button
        className="submit-button"
        htmlType="submit"
        disabled={!startDateValue || !durationValue}
      >
        {t('add')}
      </Button>
    </Form>
  );
}
