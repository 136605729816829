import { useMemo, cloneElement } from 'react';
import { useAppSelector } from '@Store/hooks';
import { Permissions } from '@Enums/Permission';
import Status from '@Enums/Status';

type Props = {
  permission: Permissions;
  children: React.ReactElement;
  fallback?: React.ReactElement;
  override?: boolean;
};

function PermissionCheck({ permission, children, fallback, override }: Props) {
  const { data, status } = useAppSelector(
    state => state.Permission.allPermissions
  );

  const { IsCustomRole } = useAppSelector(
    state => state.User.currentUserDetails.data
  );

  const show = useMemo(
    () =>
      status === Status.success && !IsCustomRole
        ? true
        : data.some(perm => perm.Name === permission),
    [Status.success]
  );

  if (override) {
    let childOverrideProps = {};
    if (!show) {
      childOverrideProps = {
        onClick: () => {},
        href: undefined,
        style: { userSelect: 'none', cursor: 'default' },
      };
    }
    return cloneElement(children, childOverrideProps);
  }

  return show ? children : fallback ? fallback : <></>;
}

export default PermissionCheck;
