import { Tabs, TabsProps } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;
interface Props<T> extends TabsProps {
  onChange: (value: string) => void;
  tabContent?: T[];
  children?: ReactNode;
  extraContent?: ReactNode;
}
const Tab = <T extends string>({
  onChange,
  tabContent,
  children,
  ...restProps
}: Props<T>) => {
  const { t } = useTranslation();

  const _onChange = (value: string) => {
    onChange(value);
  };
  return (
    <Tabs
      defaultActiveKey="0"
      style={{ marginBottom: 32 }}
      type="card"
      onChange={e => _onChange(e)}
      destroyInactiveTabPane={true}
      {...restProps}
    >
      {tabContent?.map((key, i) => (
        <TabPane tab={t(key)} key={i}>
          {children}
        </TabPane>
      ))}
    </Tabs>
  );
};

export default Tab;
