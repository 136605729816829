import Card from '@Components/Card';
import { useTranslation } from 'react-i18next';
import { CameraRule } from '@Types/CameraRules';
import HierarchyItems from '@Components/Card/HierarchyItems';
import InfoItem from '@Components/InfoItem';
import { Space, Typography } from 'antd';
import './style.scss';
import CameraRuleActionButtons from './CameraRuleActionButtons';

interface Props extends CameraRule {
  getCameraRules: Function;
  onSelect: (cameraRuleId: number) => void;
  isSelected: boolean;
}

export default function CameraRuleCard({
  Brand,
  BrandId,
  Store,
  StoreId,
  Camera,
  CameraId,
  Id,
  Type,
  Users,
  Number,
  getCameraRules,
  isSelected,
  onSelect,
}: Props) {
  const { t } = useTranslation();

  return (
    <Card
      key={Id}
      className="camera-rule-card"
      isSelectable
      isSelected={isSelected}
      onSelect={() => onSelect(Id)}
      content={{
        firsItem: {
          custom: (
            <Space direction="vertical">
              <Typography.Text strong>{Id}</Typography.Text>
              {t(`notificationRuleType.${Type}`, { number: Number || '-' })}
            </Space>
          ),
        },
        secondItem: {
          custom: (
            <HierarchyItems
              brandId={BrandId}
              storeId={StoreId}
              cameraId={CameraId}
              brand={Brand}
              store={Store}
              camera={Camera}
              textWidth="15ch"
            />
          ),
        },
      }}
      details={[
        {
          key: { text: t('users') },
          value: {
            text: (
              <InfoItem
                content={
                  <Space direction="vertical">
                    {Users.map(user => (
                      <Typography.Text key={user.Id} style={{ color: 'white' }}>
                        {user.Username}
                      </Typography.Text>
                    ))}
                  </Space>
                }
              />
            ),
          },
        },
      ]}
      extraButtons={
        <CameraRuleActionButtons
          cameraRuleId={Id}
          getCameraRules={getCameraRules}
        />
      }
    />
  );
}
