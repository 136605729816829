import CustomFilter from '@Components/CustomFilter';
import { CustomFilterProps } from '@Types/Filter';

export const CustomFilterBeaconRules = ({
  onChange,
  searches,
}: CustomFilterProps) => {
  return (
    <CustomFilter
      onChange={onChange}
      filters={searches}
      initialKeys={[
        'Id',
        'BrandName',
        'BrandId',
        'StoreName',
        'StoreId',
        'BeaconId',
        'BeaconName',
        'ReceiverId',
        'ReceiverName',
        'Type',
        'Email',
      ]}
    />
  );
};
