import EditButton from '@Components/Card/EditButton';
import { AllBeacon } from '@Types/Beacon';
import { Space } from 'antd';
import CardButton from '@Components/CardButton';
import BarChartOutlined from '@ant-design/icons/lib/icons/BarChartOutlined';
import DeleteButton from '@Components/Card/DeleteButton';
import ArchiveReasonModal from '@Components/ArchiveReasonModal';
import { useState } from 'react';
import BeaconsService from '@Services/Api/Beacons';
import { FolderOpenOutlined, InboxOutlined } from '@ant-design/icons';
import { Popconfirm } from '@Components/Popconfirm';
interface Props {
  beacon: AllBeacon;
  isArchived?: boolean;
  getBeacon?: Function;
}

export default function BeaconActionButtons({
  beacon,
  isArchived,
  getBeacon,
}: Props) {
  const [inputValue, setInputValue] = useState('');
  const [showModal, setShowModal] = useState<boolean>(false);

  async function handleDelete() {
    await new BeaconsService().DeleteBeacon(beacon.Id);
    getBeacon && getBeacon();
  }
  const onConfirm = async () => {
    const data = {
      Reason: inputValue,
    };
    try {
      await new BeaconsService().ArchiveBeacon(beacon.Id, data);
      getBeacon && getBeacon();
      setShowModal(false);
    } catch (e) {
      console.warn(e);
    }
  };

  const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const toggleArchiveType = async () => {
    if (isArchived) {
      await new BeaconsService().UnarchiveBeacon(beacon.Id);
      getBeacon && getBeacon();
    }
    setShowModal(!showModal);
  };

  return (
    <>
      {' '}
      <CardButton
        icon={<BarChartOutlined />}
        tooltipKey={'navigation.data'}
        to={`${beacon.Id}/data`}
      />
      <Space direction="horizontal" size="small" wrap>
        <EditButton to={`${beacon.Id}`} />
        <Popconfirm disabled={!isArchived} onConfirm={toggleArchiveType}>
          <CardButton
            icon={isArchived ? <InboxOutlined /> : <FolderOpenOutlined />}
            tooltipKey={isArchived ? 'unarchive' : 'archive'}
            onClick={!isArchived ? toggleArchiveType : () => {}}
          />
        </Popconfirm>
        <DeleteButton
          onConfirm={handleDelete}
          optionalMessage={'doYouAcceptReceiver'}
        />
      </Space>
      <ArchiveReasonModal
        show={showModal}
        onConfirm={() => onConfirm()}
        inputOnChange={e => inputOnChange(e)}
        showModalOnCancel={() => setShowModal(false)}
      />
    </>
  );
}
