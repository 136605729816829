import {
  CameraRecordProcessTable,
  CameraRecordTable,
} from '@Types/CameraRecords';
import { Empty, Table, Typography } from 'antd';
import { CAMERA_RECORD_PROCESS_KEYS } from '@Constants/CameraRecords';
import { useContext, useMemo } from 'react';
import {
  convertCameraRecordProcessEnum,
  convertCameraRecordProcessLabelingEnum,
} from '@Utils/CameraRecords';
import { useTranslation } from 'react-i18next';
import { convertApiDate } from '@Utils/index';
import RecordProcessMainActionButton from '../RecordProcessActionButton';
import { useAppSelector } from '@Store/hooks';
import { RecordProcessContext } from '@Pages/CameraRecords/Contexts';
import './style.scss';

type Props = {
  record: CameraRecordTable;
  getCameraRecords: Function;
};

const RecordProcessTable = ({ record, getCameraRecords }: Props) => {
  const { setShowResultsModal } = useContext(RecordProcessContext);

  const codeVersions = useAppSelector(s => s.CodeVersion.codeVersions.data);
  const { t } = useTranslation();

  const formatCodeVersion = (versionId: number): string => {
    const version = codeVersions.find(v => v.Id === versionId);
    return `${version?.Name} (${version?.Version})`;
  };

  const formattedProcesses = useMemo(() => {
    if (!record?.data?.Id) return [];

    // Note: 'recordProcess-' was used as a prefix to prevent dataIndex collisions with parent table columns (which is used for records)
    const formattedData = record.data.CameraRecordProcesses.map(p => ({
      'recordProcess-Id': p.Id,
      'recordProcess-CreatedDate': convertApiDate(p.CreatedDate) || '-',
      'recordProcess-CurrLabelingState': convertCameraRecordProcessLabelingEnum(
        p.CurrLabelingState
      ),
      'recordProcess-IsWatched': t(p.IsWatched ? 'watched' : 'notWatched'),

      'recordProcess-Status': convertCameraRecordProcessEnum(p.Status),
      'recordProcess-User': p.User || '-',
      'recordProcess-VersionId': formatCodeVersion(p.VersionId) || '-',
      'recordProcess-WatchedByUser': p.WatchedByUser || '-',
      'recordProcess-WatchedTime': p.WatchedTime
        ? convertApiDate(p.WatchedTime)
        : '-',
      'recordProcess-Config': p.Config || '-',
      data: p,
      key: p.Id,
    }));

    return formattedData;
  }, [record?.data?.Id]);

  const keys = CAMERA_RECORD_PROCESS_KEYS.map(key => ({
    title: t('recordProcess.' + key),
    dataIndex: 'recordProcess-' + key,
    onCell: () => {
      return {
        onClick: () => setShowResultsModal(true),
        className: 'record-process-clickable-row',
      };
    },
  }));

  const action = {
    title: t('action'),
    dataIndex: 'recordProcess-action',
    align: 'center',
    onCell: () => {
      return {
        onClick: () => {},
        className: '',
      };
    },
    render: (_: string, recordProcess: CameraRecordProcessTable) => (
      <RecordProcessMainActionButton
        record={record}
        recordProcess={recordProcess.data}
        getCameraRecords={getCameraRecords}
      />
    ),
  };

  return (
    <Table
      className="record-process-table"
      columns={[...keys, action]}
      dataSource={formattedProcesses}
      pagination={false}
      locale={{
        emptyText: <Empty description={t('noData')} />,
      }}
    />
  );
};

export default RecordProcessTable;
