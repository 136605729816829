import { PlusCircleOutlined } from '@ant-design/icons';
import HardwareTypes from '@Types/Hardware';
import HardwareService from '@Services/Api/BoardBrandModel';
import FormCard from '@Components/FormCard';
import { apiCall } from '@Utils';
import { useNavigate } from 'react-router-dom';
import HardwareForm from '@Forms/HardwareForm';

const NewHardware = () => {
  let navigate = useNavigate();

  const handleSubmit = async (newHardware: HardwareTypes.NewHardware) => {
    let newHardwareData: HardwareTypes.NewHardware = {
      Name: newHardware.Name,
    };
    try {
      await apiCall(() => addHardware(newHardwareData), 'newHardware');
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const addHardware = async (newHardware: HardwareTypes.NewHardware) => {
    return await new HardwareService().CreateBoardBrandModel(newHardware);
  };

  return (
    <FormCard titleIcon={<PlusCircleOutlined />} titleKey="addHardware">
      <HardwareForm onFinish={handleSubmit} />
    </FormCard>
  );
};

export default NewHardware;
