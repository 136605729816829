import UserTypes, { Account, Accounts, Credentials } from '@Types/User';
import model from './index';
import AccountTypes from '@Types/Account';
import { Permission } from '@Types/Permission';
import qs from 'qs';
import axios from 'axios';
import { get_x_session_id } from '@Utils/x_session_id_creator';

const baseURL = process.env.REACT_APP_API_BASE;
class AccountService extends model {
  constructor() {
    super();
    this.Prefix('');
  }
  /**
   * @description Get all users.
   * @returns Promise
   */
  async All() {
    return this.Get('GetAllUsers').Save<Accounts>();
  }

  /**
   *
   * @param user
   * @description Login with user credentials
   * @returns Promise
   */
  async Login(user: Credentials) {
    let data = qs.stringify({
      grant_type: 'password',
      username: user.username,
      password: user.password,
      captcha_value: user.captcha_value,
    });
    this._setPath(baseURL);
    return this.Post(`Token`)
      .Data(data)
      .Save<Account>({
        headers: {
          x_session_id: get_x_session_id(),
        },
      });
  }
  /**
   *
   * @description Account Role
   * @returns Promise
   */
  async Role() {
    return this.Get('GetRole').Save<UserTypes.Role>();
  }

  /**
   *
   * @param ResetData
   * @description Initialize Password
   * @returns Promise
   */
  async ResetPassword(ResetData: AccountTypes.ResetPassword) {
    return this.Post('ResetPassword').Data(ResetData).Save();
  }

  async SendResetPasswordEmail(Email: UserTypes.Email) {
    return this.Post('SendResetPasswordEmail')
      .Data(Email)
      .setHeaderContentType('application/json')
      .Save();
  }

  /**
   *
   * @param ResetData
   * @description Validate Reset Password from token
   * @returns Promise
   */
  async ValidateResetPasswordToken(Token: string) {
    return this.Post('ValidateResetPasswordToken')
      .Data(`"${Token}"`)
      .setHeaderContentType('application/json')
      .Save<UserTypes.validatedUser>();
  }

  /**
   *
   * @param {AccountTypes.changePassword} changePasswordValues
   * @description Change user password
   * @returns Promise
   */
  async changePassword(changePasswordValues: AccountTypes.changePassword) {
    return this.Post('ChangePassword').Data(changePasswordValues).Save();
  }

  /**
   *
   * @description -  Token revoke
   * @returns Promise
   */
  async Logout() {
    return this.Post('RevokeToken')
      .Data({})
      .Save({ headers: { 'Content-Type': 'application/json' } });
  }
}

export default AccountService;
