import { NotificationOutlined } from '@ant-design/icons';
import FormCard from '@Components/FormCard';
import { BeaconRuleForm } from '@Features/beaconRules/forms';
import BeaconRulesService from '@Services/Api/BeaconRulesService';
import { CreateBeaconRule } from '@Types/BeaconRule';
import { apiCall } from '@Utils/index';
import { useNavigate } from 'react-router-dom';
import './style.scss';

export function CreateBeaconRulePage() {
  let navigate = useNavigate();

  async function handleSubmit(form: CreateBeaconRule) {
    try {
      const req = () => new BeaconRulesService().CreateBeaconRule(form);
      const res = await apiCall(req, 'createBeaconRule');
      if (res) {
        navigate(-1);
      }
    } catch (e) {}
  }

  return (
    <FormCard
      className="create-beacon-rule-page"
      titleIcon={<NotificationOutlined />}
      titleKey="beaconRules.createBeaconRule"
    >
      <BeaconRuleForm onSubmit={handleSubmit} />
    </FormCard>
  );
}
