import {
  BarsOutlined,
  FolderOpenOutlined,
  InboxOutlined,
  LockOutlined,
  ShopOutlined,
  NotificationOutlined,
} from '@ant-design/icons';
import EditButton from '@Components/Card/EditButton';
import CardButton from '@Components/CardButton';
import { BrandId, FetchedBrand } from '@Types/Brand';
import { Space } from 'antd';
import BrandService from '@Services/Api/Brand';
import { getAllBrands } from '@Store/Brand/action';
import { useAppDispatch } from '@Store/hooks';
import { NotificationResponsesModal } from '@Features/notification/modals';
import useBoolean from '@Hooks/useBoolean';
import { Popconfirm } from '@Components/Popconfirm';

export enum BrandActionButtonEnums {
  ALL,
  STORES,
  API_KEYS,
  STORE_PRODUCTS,
  NOTIFICATION_RESPONSES,
  ARCHIVE,
  EDIT,
}

interface Props {
  brand: FetchedBrand;
  isArchived?: boolean;
  hiddenButtons?: BrandActionButtonEnums[];
}

export default function BrandActionButtons(props: Props) {
  const { brand, isArchived, hiddenButtons } = props;

  const dispatch = useAppDispatch();

  const notificationResponsesState = useBoolean();

  const toggleArchiveType = async (brandId: BrandId) => {
    try {
      if (isArchived) {
        //not use
        await new BrandService().UnarchiveBrand(brandId);
      } else {
        await new BrandService().ArchiveBrand(brandId);
      }
      dispatch(getAllBrands());
    } catch (e) {
      console.warn(e);
    }
  };

  if (hiddenButtons?.includes(BrandActionButtonEnums.ALL)) {
    return <></>;
  }

  return (
    <Space direction="horizontal" size="small" wrap>
      {!hiddenButtons?.includes(BrandActionButtonEnums.STORES) && (
        <CardButton
          icon={<ShopOutlined />}
          tooltipKey="stores"
          to={`/brands/${brand.Id}/stores`}
        />
      )}
      {!hiddenButtons?.includes(BrandActionButtonEnums.API_KEYS) && (
        <CardButton
          icon={<LockOutlined />}
          tooltipKey="apiKeyList"
          to={`/brands/${brand.Id}/api-key-list`}
        />
      )}
      {!hiddenButtons?.includes(BrandActionButtonEnums.STORE_PRODUCTS) && (
        <CardButton
          icon={<BarsOutlined />}
          tooltipKey="storeProducts"
          to={`/brands/${brand.Id}/store-products`}
        />
      )}

      {!hiddenButtons?.includes(
        BrandActionButtonEnums.NOTIFICATION_RESPONSES
      ) && (
        <>
          <CardButton
            icon={<NotificationOutlined />}
            tooltipKey="notificationResponses.title"
            onClick={notificationResponsesState.setTrue}
          />
          {notificationResponsesState.value && (
            <NotificationResponsesModal
              brandId={brand.Id}
              modalState={notificationResponsesState}
            />
          )}
        </>
      )}

      {!hiddenButtons?.includes(BrandActionButtonEnums.ARCHIVE) &&
        (!isArchived ? (
          <Popconfirm onConfirm={() => toggleArchiveType(brand.Id)}>
            <CardButton icon={<FolderOpenOutlined />} tooltipKey={'archive'} />
          </Popconfirm>
        ) : (
          <CardButton
            icon={<InboxOutlined />}
            tooltipKey={'unarchive'}
            to={`/brands/${brand.Id}/unarchive`}
          />
        ))}

      {!hiddenButtons?.includes(BrandActionButtonEnums.EDIT) && (
        <EditButton to={`/brands/${brand.Id}`} />
      )}
    </Space>
  );
}
