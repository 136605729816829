import { VersionFile } from '@Types/Version';
import Card from '@Components/Card';
import { calculateTimeRange } from '@Utils/helpers';
import VersionService from '@Services/Api/Version';
import CardButton from '@Components/CardButton';
import { DownloadOutlined } from '@ant-design/icons';
import { message, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGetVersionFiles } from '@Features/versions/hooks';
import FilledText from '@Components/FilledText';
import './style.scss';

type Props = {
  versionFile: VersionFile;
  active?: boolean;
};

export default function VersionFileCard({ versionFile, active }: Props) {
  const { t } = useTranslation();

  const getVersionFiles = useGetVersionFiles(versionFile.Type);

  const deleteVersion = async () => {
    try {
      await new VersionService().DeleteFile(versionFile.Id);
      getVersionFiles();
    } catch (e) {
      console.warn(e);
    }
  };

  const downloadFile = async () => {
    try {
      await new VersionService().DownloadVersion(versionFile.MD5);
      message.success(t('successMessage'));
    } catch (error) {
      message.error(t('errorMessage'));
    }
  };

  return (
    <Card
      className={`version-file-card ${active ? 'active' : ''}`}
      content={{
        firsItem: {
          text: versionFile.Name,
        },
        secondItem: {
          custom: (
            <div className="info-col">
              {versionFile.UpdateNote && (
                <Typography.Text
                  className="update-note"
                  title={t('uploadNote')}
                >
                  {versionFile.UpdateNote}
                </Typography.Text>
              )}

              <div className="info-row">
                {calculateTimeRange(versionFile.CreatedDate)}
                <FilledText text={versionFile.Size} />
              </div>
            </div>
          ),
        },
      }}
      disableDetail
      onClickDelete={deleteVersion}
      extraButtons={
        <CardButton
          icon={<DownloadOutlined />}
          tooltipKey="downloadFile"
          onClick={downloadFile}
        />
      }
    />
  );
}
