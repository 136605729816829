import BoardsService from '@Services/Api/Boards';
import MqttService from '@Services/Api/Mqtt';
import { BoardId } from '@Types/Board';
import { apiCall } from '@Utils/index';
import { Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  boardId: BoardId;
  show: boolean;
  onClose: () => void;
}

const SSHTunnelModal = ({ show, boardId, onClose }: Props) => {
  const [port, setPort] = useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    const getNextPort = async () => {
      const { p } = await new MqttService().GetNextPort();
      setPort(p || 0);
    };

    if (show) getNextPort().catch(console.warn);
  }, [show]);

  const openSSHTunnel = async () => {
    try {
      const req = () => new BoardsService().SSHTunnel(boardId, port);
      apiCall(req, 'openSSHTunnel');
    } catch (e) {
      console.warn(e);
    }
  };

  const portChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPort(e.target.valueAsNumber);
  };

  return (
    <Modal
      open={show}
      title={t('openSSHTunnel')}
      onOk={openSSHTunnel}
      onCancel={onClose}
      okButtonProps={{ disabled: !port }}
    >
      <Input type="number" min={0} onChange={portChangeHandler} value={port} />
    </Modal>
  );
};

export default SSHTunnelModal;
