import { PaginationParams } from '@Types/Filter';
import { UserPermissions } from '@Types/Permission';
import {
  AllUsersFetchResponse,
  EditUserForm,
  NewUser,
  UserFetchResponse,
  ResetPasswordUser,
  SetNewPasswordParams,
  UserStoresEmail,
  GetStoreUserParams,
  UserEmails,
  UserDetail,
  NewUserForm,
} from '@Types/User';
import model from './index';
import UserTypes from '@Types/User';
import { ApiResponse } from '@Types/apiTypes';
import { AllPageLogsFetchResponse, PageLogsParams } from '@Types/PageLogs';

class UserService extends model {
  constructor() {
    super();
    this.Prefix('User');
  }

  /**
   *
   * @param newUser
   * @description Add a new user
   * @returns Promise
   */
  async CreateUser(newUser: NewUserForm) {
    return this.Post().Data(newUser).Save();
  }

  /**
   *
   * @param newUser
   * @description Edit a user
   * @returns Promise
   */
  async EditUser(userId: string, newUser: EditUserForm) {
    return this.Put(userId).Data(newUser).Save();
  }

  /**
   *
   * @param userId
   * @description Delete a user
   * @returns Promise
   */
  async DeleteUser(userId: string) {
    return this.PrefixId(userId).Delete().Save<any>();
  }

  /**
   *
   * @description Get user details
   * @returns Promise
   */
  async GetDetailsV2() {
    return this.Get('DetailsV2').Data(null).Save<UserDetail>();
  }

  /**
   *
   * @description Set config of the current user as string
   * @returns Promise
   */
  async SetPanelConfig(newConfig: string) {
    return this.Post('PanelConfig')
      .Data(newConfig)
      .setHeaderContentType('text/plain')
      .Save();
  }

  /**
   * @param searchParams
   * @description Get all users with server side pagination
   * @returns Promise
   */
  async GetAll(searchParams: PaginationParams) {
    return this.Post('GetAll')
      .Data(searchParams)
      .offset()
      .Save<AllUsersFetchResponse>();
  }

  /**
   * @param {string} userId
   * @description Get a user by userId
   * @returns Promise
   */
  async GetUser(userId: string) {
    return this.Get(userId).offset().Save<UserFetchResponse>();
  }

  /**
   * @description Get permissions of the current user
   * @returns Promise
   */
  async GetPermissions() {
    return this.Get('Permissions').Save<UserPermissions>();
  }

  /**
   *
   * @param mailReset
   * @description Mail Reset Password
   * @returns Promise
   */
  async MailResetPassword(mailReset: UserTypes.Email) {
    return this.Post('MailResetPasswordToken')
      .Data(mailReset)
      .setHeaderContentType('text/plain')
      .Save<ApiResponse>();
  }

  /**
   *
   * @param resetToken
   * @description Mail Reset Password
   * @returns Promise
   */
  async ResetPasswordEmail(resetToken: string) {
    return this.Post('ResetPasswordEmail')
      .Data(resetToken)
      .setHeaderContentType('text/plain')
      .Save<ResetPasswordUser>();
  }

  /**
   *
   * @param resetToken
   * @description
   * @returns Promise
   */
  async ResetPassword(resetToken: UserTypes.NewPassword) {
    return this.Post('ResetPassword').Data(resetToken).Save<ApiResponse>();
  }

  /**
   *
   * @param {PageLogsParams} params
   * @description Used to fetch how much time the user spends on which page.
   * @returns Promise
   */
  async PageLogs(params: PageLogsParams) {
    return this.Post(`PageLogs${params.dateTimeParams}`)
      .Data(params.filters)
      .offset()
      .Save<AllPageLogsFetchResponse>();
  }

  /**
   *
   * @param {SetNewPasswordParams} params
   * @description Set a new password for a user.
   * @returns Promise
   */
  async SetNewPassword(params: SetNewPasswordParams) {
    return this.Post('SetPassword').Data(params).offset().Save();
  }
  /**
   *
   * @param {number} brandId
   * @description Get User Stores for reports create
   * @returns Promise
   */
  async GetUserStores(brandId: number) {
    return this.Get('GetUserStores')
      .Params({ brandId })
      .Save<UserStoresEmail>();
  }
  /**
   *
   * @param {GetStoreUserParams} params
   * @description Get User Store get only users
   * @returns Promise
   */
  async GetStoreUsers(params: GetStoreUserParams) {
    const { onlyuser, brandId } = params;
    return this.Get('GetUserStores')
      .Params({ brandId, onlyuser })
      .Save<UserEmails[]>();
  }
}

export default UserService;
