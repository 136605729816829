import { CameraId } from '@Types/Camera';
import {
  RecordProcessNote,
  SetRecordProcessNoteParams,
} from '@Types/CameraRecords';
import model from './index';

class CameraService extends model {
  constructor() {
    super();
    this.Prefix('Camera');
  }

  /**
   * @param {CameraId} cameraId
   * @description Get photo of a camera
   * @returns Promise
   */
  async GetPhoto(cameraId: CameraId) {
    if (cameraId === undefined) {
      return Promise.reject('Camera id is required!');
    }
    return this.PrefixId(cameraId).Get('Photo').offset().getImage();
  }

  /**
   * @param {CameraId} cameraId
   * @param {RecordProcessNote} recordProcessNote
   * @description Set a record process' note message
   * @returns Promise
   */
  async SetRecordProcessNote(
    cameraId: CameraId,
    recordProcessNote: SetRecordProcessNoteParams
  ) {
    return this.PrefixId(cameraId)
      .Post('SetRecordProcessNote')
      .Data(recordProcessNote)
      .Save();
  }

  /**
   * @param {CameraId} cameraId
   * @param {number} recordprocessId
   * @description Get a record process' note message
   * @returns Promise
   */
  async GetRecordProcessNote(cameraId: CameraId, recordprocessId: number) {
    return this.PrefixId(cameraId)
      .Get(`GetRecordProcessNote/${recordprocessId}`)
      .Save<RecordProcessNote>();
  }
}

export default CameraService;
