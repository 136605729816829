import { useEffect } from 'react';
import {
  UsePaginatedQueryOptions,
  UsePaginatedQueryResult,
} from '@Types/Pagination';
import { usePagination } from '@Hooks/usePagination.ts';
import Status from '@Enums/Status';
import {
  convertFiltersToApiFormat,
  createOrderBy,
} from '@Components/PaginationFilter/pagination-filter-utils';
import { useDebounce } from '@Hooks/useDebounce';

export function usePaginatedQuery(
  args: UsePaginatedQueryOptions
): UsePaginatedQueryResult {
  const {
    status,
    queryFunction,
    pageConfigResult,
    filterOptions,
    refetchDependencies = [],
  } = args;

  const pagination = usePagination({ pageConfigResult });

  const debouncedPagination = useDebounce(pagination);

  const { FilterBy, OrderBy, PageIndex, PageSize, SortBy } =
    debouncedPagination;

  function fetch() {
    queryFunction({
      PageIndex,
      PageSize,
      OrderBy: createOrderBy(SortBy, OrderBy),
      FilterBy: convertFiltersToApiFormat(
        FilterBy.map(filter => {
          const filterOption = filterOptions.find(
            o => o.field === filter.field
          )!;

          return {
            ...filter,
            ...filterOption,
            value: filterOption?.formatValueBeforeApiRequest
              ? filterOption.formatValueBeforeApiRequest(filter.value)
              : filter.value,
          };
        })
      ),
    });
  }

  useEffect(() => {
    status !== Status.pending && fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FilterBy, OrderBy, PageIndex, PageSize, SortBy, ...refetchDependencies]);

  return { refetch: fetch, pagination: debouncedPagination };
}
