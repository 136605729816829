import { ReactElement } from 'react';
import Editor, { EditorProps } from './Editor';
interface Props extends EditorProps {
  title?: string;
}

function JsonSchema({ title, ...editorProps }: Props): ReactElement {
  return <Editor {...editorProps} />;
}

JsonSchema.defaultProps = {
  title: 'Json Schema',
};

export default JsonSchema;
