import Text, { TextProps } from 'antd/lib/typography/Text';

interface IProps extends TextProps {
  text: string;
}

export function SecondItemText({ text, ...restProps }: IProps) {
  return (
    <Text className="second-item-text" italic {...restProps}>
      {text}
    </Text>
  );
}
