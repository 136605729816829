import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useMemo } from 'react';
import { IFieldSearch } from '@Types/FieldSearches';
import FieldSearchService from '@Services/Api/FieldSearches';
import { apiCall } from '@Utils/index';
import { Spin } from 'antd';
import FormCard from '@Components/FormCard';
import { EditOutlined } from '@ant-design/icons';
import EditFieldSearchForm, {
  EditFieldSearchFormType,
} from '../FieldSearches/Components/EditFieldSearchForm';

export default function EditFieldSearchPage() {
  const [fieldSearches, setFieldSearches] = useState<IFieldSearch[]>();

  useEffect(() => {
    (async () => {
      await retrieveFieldSearches();
    })();
  }, []);

  const { fieldSearchId } = useParams() as { fieldSearchId: string };
  const navigate = useNavigate();

  const retrieveFieldSearches = async () => {
    try {
      const func = () => new FieldSearchService().GetFieldSearches();
      const data = await apiCall(func, 'retrieveFieldSearch');
      setFieldSearches(data);
    } catch (err) {
      console.error(err);
    }
  };

  const updateFieldSearchItem = async (form: EditFieldSearchFormType) => {
    try {
      const func = () =>
        new FieldSearchService().UpdateFieldSearch({
          Id: fieldSearch!.Id,
          KeywordsArr: form.KeywordsArr,
          Permissions: form.Permissions,
          Route: form.Route,
          Title: [
            { Name: form.TitleTr, Language: 'tr' },
            { Name: form.TitleEn, Language: 'en' },
            { Name: form.TitleDe, Language: 'de' },
          ],
        });
      await apiCall(func, 'updateFieldSearch');
      navigate(-1);
    } catch (err) {
      console.error(err);
    }
  };

  const fieldSearch = useMemo(() => {
    return fieldSearches?.find(
      fieldSearch => fieldSearch.Id.toString() === fieldSearchId
    );
  }, [fieldSearchId, fieldSearches]);

  if (!fieldSearch) return <Spin />;

  return (
    <FormCard
      titleIcon={<EditOutlined />}
      titleKey="editFieldSearch"
      showGoBack
    >
      <EditFieldSearchForm
        fieldSearch={fieldSearch}
        onSubmit={updateFieldSearchItem}
      />
    </FormCard>
  );
}
