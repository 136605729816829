import { Option } from 'antd/lib/mentions';
import {
  UserOutlined,
  MailOutlined,
  ApartmentOutlined,
  CalendarOutlined,
  HomeOutlined,
  ClusterOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import SortByFilter from '@Components/SortByFilter';
type props = {
  onChange: (value: string) => void;
  defaultValue: string;
};
const SortByFilterUsers = ({ onChange, defaultValue }: props) => {
  const { t } = useTranslation();
  const iconStyle = { paddingLeft: 6 };

  return (
    <SortByFilter defaultValue={defaultValue} onChange={onChange}>
      <Option value="Name">
        {t('name')}
        <UserOutlined style={iconStyle} />
      </Option>
      <Option value="Email">
        {t('email')}
        <MailOutlined style={iconStyle} />
      </Option>
      <Option value="RegisterDate">
        {t('CreatedDate')}
        <CalendarOutlined style={iconStyle} />
      </Option>
    </SortByFilter>
  );
};

export default SortByFilterUsers;
