import {
  AllReceiversFetchResponse,
  AllReceiver,
  EditedReceiver,
  NewReceiver,
  GetReceiverParams,
  ArchivedReceiver,
} from '@Types/Receiver';
import model from './index';
class ReceiverService extends model {
  constructor() {
    super();
    this.Prefix('Receivers');
  }

  /**
   * @param {GetReceiverParams} params
   * @description Get all Receiver
   * @returns Promise
   */
  async GetAllReceiver(params: GetReceiverParams) {
    const { archived, ...paginationParams } = params;
    return this.Post(`GetAll`)
      .Params({ archived })
      .Data(paginationParams)
      .offset()
      .Save<AllReceiversFetchResponse>();
  }

  /**
   *
   * @param newReceiver
   * @description Add a new receiver
   * @returns Promise
   */
  async New(newReceiver: NewReceiver) {
    return this.Post().Data(newReceiver).Save();
  }

  /**
   * @param {string}
   * @description Get code receiver by  Id
   * @returns Promise
   */

  async GetReceiverById(receiverId: number) {
    return this.Get(`/${receiverId}`).offset().Save<AllReceiver>();
  }
  /**
   * @param {string} receiverId
   * @param {EditedReceiver} editedReceiver
   * @description Edited Receiver
   * @returns Promise
   */
  async EditReceiver(receiverId: number, editedBeacon: EditedReceiver) {
    return this.Put(`/${receiverId}`).Data(editedBeacon).Save();
  }

  /**
   * @param {number} receiverId
   * @description Unarchive receiver
   * @returns Promise
   */
  async UnarchiveReceiver(receiverId: number) {
    return this.PrefixId(receiverId).Post('UnArchive').offset().Save();
  }

  /**
   * @param {number} receiverId
   * @description Archive receiver
   * @returns Promise
   */
  async ArchiveReceiver(receiverId: number, data: ArchivedReceiver) {
    return this.PrefixId(receiverId).Post('Archive').Data(data).offset().Save();
  }

  /**
   *
   * @description Delete a receiver.
   * @returns Promise
   */
  async DeleteReceiver(receiverId: number) {
    return this.PrefixId(receiverId).Delete().Save<any>();
  }
}
export default ReceiverService;
