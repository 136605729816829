import SearchableSelect from '@Components/SearchableSelect';
import { useTranslation } from 'react-i18next';
import './style.scss';

type IsWatchedFilterProps = {
  onChange: (status: 'true' | 'false' | undefined) => void;
  value: any;
};

export function IsWatchedFilter({ onChange, value }: IsWatchedFilterProps) {
  const { t } = useTranslation();

  const options = [
    { value: 'true', label: t('watched') },
    { value: 'false', label: t('notWatched') },
  ];

  return (
    <SearchableSelect
      onChange={onChange}
      className="is-watched-filter-dropdown"
      value={value}
      allowClear
      options={options}
      placeholder={t('watchedFilterPlaceholder')}
    />
  );
}
