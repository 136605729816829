import useHover from '@Hooks/useHover';
import DeleteButton from './DeleteButton';
import DetailedRow from './DetailedRow';
import EditButton from './EditButton';
import { CardContent, CardDetails } from './types';
import './style.scss';
import Text from 'antd/lib/typography/Text';
import { Avatar, Checkbox, Col, Image } from 'antd';
import { useState } from 'react';
import { FirstItemText } from './FirstItemText';
import { SecondItemText } from './SecondItemText';
import CardInfo, { CardInfoProps } from './CardInfo';

export type CardProps = {
  onClickDelete?: () => void;
  details?: CardDetails;
  content: CardContent;
  editUrl?: string;
  disableDetail?: boolean;
  className?: string;
  extraButtons?: React.ReactElement;
  thumbnail?: string | null;
  largeThumbnailRequest?: () => Promise<string>;
  subHeader?: boolean;
  isDetailAlwaysOpen?: boolean;
  hideActionButtons?: boolean;
  chartContent?: React.ReactElement;
  isSelectable?: boolean;
  isSelected?: boolean;
  onSelect?: () => void;
  onClick?: () => void;
  cardInfoItems?: CardInfoProps[];
  activeBorders?: boolean;
};

export default function Card(props: CardProps) {
  const {
    onClickDelete,
    details,
    content,
    editUrl,
    disableDetail,
    className,
    extraButtons,
    thumbnail,
    subHeader,
    largeThumbnailRequest,
    isDetailAlwaysOpen = true,
    hideActionButtons,
    chartContent,
    isSelectable,
    isSelected,
    onSelect,
    onClick,
    cardInfoItems,
    activeBorders,
  } = props;

  const [image, setImage] = useState<string>();
  const [cardRef, isCardHovered] = useHover<HTMLDivElement>();
  const [detailRef, isDetailHovered] = useHover<HTMLDivElement>();

  const onClickThumbnail = async (show: boolean) => {
    if (show && largeThumbnailRequest) {
      const image = await largeThumbnailRequest();
      setImage(image);
    }
  };

  function handleActionButtonsClick(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
  }

  return (
    <Col className="card-wrapper">
      <div
        className={`card-container ${
          isDetailAlwaysOpen ? 'remove-height-full' : ''
        } ${className || (subHeader && 'subHeader') || ''} ${
          onClick ? 'clickable' : ''
        } ${activeBorders ? 'active-borders' : ''}`}
        ref={cardRef}
        onClick={onClick}
      >
        {isSelectable && <Checkbox checked={isSelected} onClick={onSelect} />}
        {cardInfoItems && (
          <div className="buttons-notification-container">
            {cardInfoItems?.map((item, i) => (
              <div style={{opacity: (item.showOnHoverOnly ? (isCardHovered ? 1 : 0) : 1) }}>
                <CardInfo key={i} {...item} />
              </div>
            ))}
          </div>
        )}

        {!hideActionButtons && (
          <div
            className="buttons-container"
            style={{ opacity: isCardHovered ? 1 : 0 }}
            onClick={handleActionButtonsClick}
          >
            {extraButtons && extraButtons}
            {editUrl && <EditButton to={editUrl} />}
            {onClickDelete && <DeleteButton onConfirm={onClickDelete} />}
          </div>
        )}

        {typeof thumbnail === 'string' && (
          <Avatar
            shape="square"
            className="thumbnail-image"
            src={
              <Image
                preview={{
                  src: image || 'images/image-not-found.png',
                  onVisibleChange: onClickThumbnail,
                }}
                src={thumbnail || 'images/image-not-found.png'}
              />
            }
          />
        )}

        <div className="card-second-row">
          <div className="first-item">
            {content.firsItem?.custom || (
              <FirstItemText
                text={content.firsItem?.text}
                {...content.firsItem.props}
              />
            )}
          </div>

          {content.secondItem &&
            (content.secondItem.custom || (
              <SecondItemText
                {...content.secondItem.props}
                text={content.secondItem.text}
              />
            ))}
        </div>
        <div>
          {content?.subItem && <Text italic>{content.subItem.text}</Text>}
        </div>
      </div>
      {!disableDetail && (
        <div
          className={
            isDetailAlwaysOpen ? 'force-opened-details' : 'details-container'
          }
          style={{
            opacity:
              isCardHovered || isDetailHovered || isDetailAlwaysOpen ? 1 : 0,
            display:
              isCardHovered || isDetailHovered || isDetailAlwaysOpen
                ? 'flex'
                : 'none',
          }}
          ref={detailRef}
        >
          {chartContent && <div>{chartContent}</div>}
          {details?.map((detail, i) => (
            <div className="info-row" key={i}>
              <Text strong {...detail.key.props}>
                {detail.key.text}
              </Text>

              {detail.value.isDetailedRow ? (
                <DetailedRow items={detail.value.items!} />
              ) : detail.value.isCustom ? (
                detail.value.text
              ) : (
                <Text className="info-item-text" {...detail.value.props}>
                  {detail.value.text}
                </Text>
              )}
            </div>
          ))}
        </div>
      )}
    </Col>
  );
}
