import ArchiveReasonModal from '@Components/ArchiveReasonModal';
import DeleteButton from '@Components/Card/DeleteButton';
import EditButton from '@Components/Card/EditButton';
import CardButton from '@Components/CardButton';
import { Popconfirm } from '@Components/Popconfirm';
import ReceiverService from '@Services/Api/Receivers';
import { AllReceiver } from '@Types/Receiver';
import { FolderOpenOutlined, InboxOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  receiver: AllReceiver;
  isArchived?: boolean;
  getReceiver?: Function;
}

export default function ReceiverActionButtons({
  receiver,
  isArchived,
  getReceiver,
}: Props) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');

  const { t } = useTranslation();

  async function handleDelete() {
    await new ReceiverService().DeleteReceiver(receiver.Id);
    getReceiver && getReceiver();
  }

  const toggleArchiveType = async () => {
    if (isArchived) {
      await new ReceiverService().UnarchiveReceiver(receiver.Id);
      getReceiver && getReceiver();
    }
    setShowModal(!showModal);
  };

  const onConfirm = async () => {
    const data = {
      Reason: inputValue,
    };
    try {
      await new ReceiverService().ArchiveReceiver(receiver.Id, data);
      getReceiver && getReceiver();
      setShowModal(false);
    } catch (e) {
      console.warn(e);
    }
  };

  const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <>
      <Space direction="horizontal" size="small" wrap>
        <EditButton to={`${receiver.Id}`} />
        <Popconfirm disabled={!isArchived} onConfirm={toggleArchiveType}>
          <CardButton
            icon={isArchived ? <InboxOutlined /> : <FolderOpenOutlined />}
            tooltipKey={isArchived ? 'unarchive' : 'archive'}
            onClick={!isArchived ? toggleArchiveType : () => {}}
          />
        </Popconfirm>
        <DeleteButton
          onConfirm={handleDelete}
          optionalMessage={t('doYouAcceptReceiver')}
        />
      </Space>
      <ArchiveReasonModal
        show={showModal}
        onConfirm={() => onConfirm()}
        inputOnChange={e => inputOnChange(e)}
        showModalOnCancel={() => setShowModal(false)}
      />
    </>
  );
}
