import { Layout, Spin } from 'antd';
import Navbar from '@Container/Navbar';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import './style.scss';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { useEffect } from 'react';
import {
  getAllPermissions,
  getUserPermissions,
} from '@Store/Permission/action';
import Header from '@Container/Header';
import ContentLoader from '@Components/ContentLoader';
import { getUserDetails } from '@Store/User/action';
import { getConfigSchemas } from '@Store/Config/action';
import { getCountries } from '@Store/Country/action';
import PermissionToViewAdminPanelChecker from '@HOC/PermissionToViewAdminPanelChecker';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isSidebarCollapsed } = useAppSelector(state => state.Dashboard);
  const permissions = useAppSelector(state => state.Permission.allPermissions);
  const userDetails = useAppSelector(state => state.User.currentUserDetails);
  const configSchemas = useAppSelector(state => state.Config.configSchemas);

  useEffect(() => {
    dispatch(getAllPermissions());
    dispatch(getUserPermissions());
    dispatch(getUserDetails());
    dispatch(getConfigSchemas());
    dispatch(getCountries());
  }, []);

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '') {
      navigate('/' + location.search);
    }
  }, [location.pathname]);

  return (
    <Layout className="layout-wrapper">
      <ContentLoader
        status={permissions.status}
        errorMessage={permissions.ErrorMessage}
        customLoader={<Spin spinning />}
      >
        <ContentLoader
          status={userDetails.status}
          errorMessage={userDetails.ErrorMessage}
          customLoader={<Spin spinning />}
        >
          <ContentLoader
            status={configSchemas.status}
            errorMessage={configSchemas.ErrorMessage}
            customLoader={<Spin spinning />}
          >
            <Navbar />
            <Layout
              className={`content-wrapper ${
                isSidebarCollapsed ? 'small-sidebar' : ''
              }`}
            >
              <Header />
              <div className="content-inner">
                <PermissionToViewAdminPanelChecker>
                  <Outlet />
                </PermissionToViewAdminPanelChecker>
              </div>
            </Layout>
          </ContentLoader>
        </ContentLoader>
      </ContentLoader>
    </Layout>
  );
};

export default Dashboard;
