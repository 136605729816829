import Status from '@Enums/Status';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import { Role, Roles } from '@Types/Role';
import { asyncFetchInterface } from '@Types/ReduxTypes';
import RoleActionButtons from '@Cards/RoleCard/RoleActionButtons';

interface Params {
  roles: asyncFetchInterface<Roles>;
  getRoles: () => void;
}

/**
 * @description This "hook" is not a generic hook. It's made to abstract the code and make it much more readable. It will only be used for <AllRoles/>.
 */
export default function useRolesColumns(props: Params): ColumnsType<Role> {
  const { roles } = props;

  const rolesActionPerm = useCheckPermission(
    Permissions.ADMIN_CUSTOM_ROLE_ACTION
  );
  const { t } = useTranslation();

  const rolesTableColumns: ColumnsType<Role> = useMemo(() => {
    if (roles.status !== Status.success) return [];

    const columns: ColumnsType<Role> = [
      {
        title: t('ID'),
        dataIndex: 'RoleId',
      },
      {
        title: t('name'),
        dataIndex: 'RoleName',
      },
    ];

    if (rolesActionPerm) {
      columns.push({
        title: t('actionButtons'),
        dataIndex: 'actionButtons',
        render: (_: any, role: Role) => (
          <RoleActionButtons {...props} role={role} />
        ),
      });
    }

    return columns;
  }, [roles.status]);

  return rolesTableColumns;
}
