import { FetchedBrandWithArchivedInfo } from '../../Types/Brand';
import { createReducer } from '@reduxjs/toolkit';

import {
  getAllApiKeys,
  getAllArchivedAndUnarchivedBrands,
  getAllAvailableBrands,
  getAllBrands,
  getAllBrandsWithoutPagination,
  getBrandById,
  getBrandHomePagePhoto,
  getBrandImage,
  getBrandsRegions,
  getStoreProducts,
  getUnArchiveBrandCameras,
  resetCurrentBrand,
  setCurrentBrand,
  setSelectedBrand,
  setSelectedBrands,
} from './action';
import {
  AllBrands,
  AllBrandsFetchResponse,
  Brand,
  BrandImage,
  BrandPhoto,
  BrandImageResponse,
  CurrentBrand,
  AllAvailableBrands,
  ApiKeys,
  ApiKeyResponse,
  StoreProduct,
  StoreProducts,
  BrandPhotoResponse,
  UnArchiveBrandCameras,
} from '@Types/Brand';
import Status from '@Enums/Status';
import { Regions } from '@Types/Region';
import { asyncFetchInterface } from '@Types/ReduxTypes';

interface stateInterface {
  allBrands: AllBrands;
  allAvailableBrands: AllAvailableBrands;
  selectedBrand: Brand;
  selectedBrands: Brand[];
  selectedBrandImage: BrandImage;
  selectedBrandHomePagePhoto: BrandPhoto;
  currentBrand: CurrentBrand;
  allApiKeys: ApiKeys;
  storeProducts: StoreProducts;
  allArchivedAndUnarchivedBrands: {
    data: FetchedBrandWithArchivedInfo[];
    status: Status;
  };
  allBrandsRegion: asyncFetchInterface<Regions[]>;
  unArchiveBrandCameras: asyncFetchInterface<UnArchiveBrandCameras[]>;
}

const initialState: stateInterface = {
  allBrands: {
    data: {} as AllBrandsFetchResponse,
    status: 'Empty',
  },
  allAvailableBrands: {
    data: [] as Brand[],
    status: 'Empty',
  },
  currentBrand: {
    data: {} as Brand,
    status: 'Empty',
  },
  selectedBrand: {} as Brand,
  selectedBrandImage: {
    data: {
      brandId: null,
      image: '',
    },
    status: 'Empty',
  },
  selectedBrandHomePagePhoto: {
    data: {
      brandId: null,
      photo: '',
    },
    status: 'Empty',
  },
  selectedBrands: [] as Brand[],
  allApiKeys: {
    data: [] as ApiKeyResponse[],
    status: 'Empty',
  },
  storeProducts: {
    data: [] as StoreProduct[],
    status: 'Empty',
  },
  allArchivedAndUnarchivedBrands: {
    data: [],
    status: Status.empty,
  },
  allBrandsRegion: {
    data: [],
    status: 'Empty',
  },
  unArchiveBrandCameras: { data: [], status: Status.empty },
};

const BrandReducer = createReducer(initialState, builder => {
  builder
    .addCase(getAllBrands.pending, state => {
      state.allBrands = {
        ...state.allBrands,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllBrands.fulfilled, (state, action) => {
      state.allBrands.data = action.payload as AllBrandsFetchResponse;
      state.allBrands.status = 'fulfilled';
    })
    .addCase(getAllBrands.rejected, (state, action) => {
      state.allBrands = {
        ...state.allBrands,
        data: {} as AllBrandsFetchResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getAllBrandsWithoutPagination.pending, state => {
      state.allBrands = {
        ...state.allBrands,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllBrandsWithoutPagination.fulfilled, (state, action) => {
      state.allBrands.data = action.payload as AllBrandsFetchResponse;
      state.allBrands.status = 'fulfilled';
    })
    .addCase(getAllBrandsWithoutPagination.rejected, (state, action) => {
      state.allBrands = {
        ...state.allBrands,
        data: {} as AllBrandsFetchResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getAllAvailableBrands.pending, state => {
      state.allAvailableBrands = {
        ...state.allAvailableBrands,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllAvailableBrands.fulfilled, (state, action) => {
      state.allAvailableBrands.data = action.payload;
      state.allAvailableBrands.status = 'fulfilled';
    })
    .addCase(getAllAvailableBrands.rejected, (state, action) => {
      state.allAvailableBrands = {
        ...state.allAvailableBrands,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getAllArchivedAndUnarchivedBrands.pending, state => {
      state.allArchivedAndUnarchivedBrands = {
        ...state.allArchivedAndUnarchivedBrands,
        status: Status.pending,
      };
    })
    .addCase(getAllArchivedAndUnarchivedBrands.fulfilled, (state, action) => {
      state.allArchivedAndUnarchivedBrands.data = action.payload;
      state.allArchivedAndUnarchivedBrands.status = Status.success;
    })
    .addCase(getAllArchivedAndUnarchivedBrands.rejected, state => {
      state.allArchivedAndUnarchivedBrands = {
        ...state.allArchivedAndUnarchivedBrands,
        data: [],
        status: Status.error,
      };
    })
    .addCase(getBrandById.pending, state => {
      state.currentBrand = {
        ...state.currentBrand,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getBrandById.fulfilled, (state, action) => {
      state.currentBrand.data = action.payload as Brand;
      state.currentBrand.status = 'fulfilled';
    })
    .addCase(getBrandById.rejected, (state, action) => {
      state.currentBrand = {
        ...state.currentBrand,
        data: {} as Brand,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getBrandImage.pending, state => {
      state.selectedBrandImage = {
        ...state.selectedBrandImage,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getBrandImage.fulfilled, (state, action) => {
      state.selectedBrandImage.data = action.payload as BrandImageResponse;
      state.selectedBrandImage.status = 'fulfilled';
    })
    .addCase(getBrandImage.rejected, (state, action) => {
      state.selectedBrandImage = {
        ...state.selectedBrandImage,
        data: {} as BrandImageResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getBrandHomePagePhoto.pending, state => {
      state.selectedBrandHomePagePhoto = {
        ...state.selectedBrandHomePagePhoto,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getBrandHomePagePhoto.fulfilled, (state, action) => {
      state.selectedBrandHomePagePhoto.data =
        action.payload as BrandPhotoResponse;
      state.selectedBrandHomePagePhoto.status = 'fulfilled';
    })
    .addCase(getBrandHomePagePhoto.rejected, (state, action) => {
      state.selectedBrandHomePagePhoto = {
        ...state.selectedBrandHomePagePhoto,
        data: {} as BrandPhotoResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(setSelectedBrand, (state, action) => {
      state.selectedBrand = action.payload;
    })
    .addCase(setCurrentBrand, (state, action) => {
      state.currentBrand.data = action.payload;
    })
    .addCase(resetCurrentBrand, (state, action) => {
      state.currentBrand = {
        ...state.currentBrand,
        data: {} as Brand,
        status: 'Empty',
        ErrorMessage: undefined,
      };
    })
    .addCase(setSelectedBrands, (state, action) => {
      state.selectedBrands = action.payload;
    })
    .addCase(getAllApiKeys.pending, state => {
      state.allApiKeys = {
        ...state.allApiKeys,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllApiKeys.fulfilled, (state, action) => {
      state.allApiKeys.data = action.payload;
      state.allApiKeys.status = 'fulfilled';
    })
    .addCase(getAllApiKeys.rejected, (state, action) => {
      state.allApiKeys = {
        ...state.allApiKeys,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getStoreProducts.pending, state => {
      state.storeProducts = {
        ...state.storeProducts,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getStoreProducts.fulfilled, (state, action) => {
      state.storeProducts.data = action.payload;
      state.storeProducts.status = 'fulfilled';
    })
    .addCase(getStoreProducts.rejected, (state, action) => {
      state.storeProducts = {
        ...state.storeProducts,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getBrandsRegions.pending, state => {
      state.allBrandsRegion = {
        ...state.allBrandsRegion,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getBrandsRegions.fulfilled, (state, action) => {
      state.allBrandsRegion.data = action.payload;
      state.allBrandsRegion.status = 'fulfilled';
    })
    .addCase(getBrandsRegions.rejected, (state, action) => {
      state.allBrandsRegion = {
        ...state.allBrandsRegion,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getUnArchiveBrandCameras.pending, state => {
      state.unArchiveBrandCameras = {
        ...state.unArchiveBrandCameras,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getUnArchiveBrandCameras.fulfilled, (state, action) => {
      state.unArchiveBrandCameras.data =
        action.payload as UnArchiveBrandCameras[];
      state.unArchiveBrandCameras.status = 'fulfilled';
    })
    .addCase(getUnArchiveBrandCameras.rejected, (state, action) => {
      state.unArchiveBrandCameras = {
        ...state.unArchiveBrandCameras,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default BrandReducer;
