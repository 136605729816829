import { createReducer } from '@reduxjs/toolkit';
import { asyncFetchInterface } from '@Types/ReduxTypes';
import { getAllHardware, getBoardBrandCameras, getHardware } from './action';
import {
  AllHardwareFetchResponse,
  BoardBrandCamera,
  Hardwares,
} from '@Types/Hardware';
import { PaginationData } from '@Types/index';

interface IInitialState {
  allHardware: asyncFetchInterface<PaginationData<Hardwares>>;
  hardware: asyncFetchInterface<Hardwares[]>;
  boardBrandCameras: asyncFetchInterface<BoardBrandCamera[]>;
}

const initialState: IInitialState = {
  allHardware: {
    data: {} as AllHardwareFetchResponse,
    status: 'Empty',
  },
  hardware: {
    data: [],
    status: 'Empty',
  },
  boardBrandCameras: {
    data: [],
    status: 'Empty',
  },
};

const HardwareReducer = createReducer(initialState, builder => {
  builder
    .addCase(getAllHardware.pending, state => {
      state.allHardware = {
        ...state.allHardware,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllHardware.fulfilled, (state, action) => {
      state.allHardware = {
        status: 'fulfilled',
        data: action.payload as AllHardwareFetchResponse,
      };
    })
    .addCase(getAllHardware.rejected, (state, action) => {
      state.allHardware = {
        ...state.allHardware,
        data: {} as AllHardwareFetchResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getHardware.pending, state => {
      state.hardware = {
        ...state.hardware,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getHardware.fulfilled, (state, action) => {
      state.hardware.data = action.payload;
      state.hardware.status = 'fulfilled';
    })
    .addCase(getHardware.rejected, (state, action) => {
      state.hardware = {
        ...state.hardware,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getBoardBrandCameras.pending, state => {
      state.boardBrandCameras = {
        ...state.boardBrandCameras,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getBoardBrandCameras.fulfilled, (state, action) => {
      state.boardBrandCameras = {
        status: 'fulfilled',
        data: action.payload as BoardBrandCamera[],
      };
    })
    .addCase(getBoardBrandCameras.rejected, (state, action) => {
      state.boardBrandCameras = {
        ...state.boardBrandCameras,
        data: [] as BoardBrandCamera[],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default HardwareReducer;
