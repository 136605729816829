import { useTranslation } from 'react-i18next';
import { Modal, Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import './style.scss';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getCodeVersions } from '@Store/CodeVersion/action';
import { CameraRecordProcess, CameraRecordTable } from '@Types/CameraRecords';
import { CodeVersion } from '@Types/CodeVersion';
import SearchableSelect from '@Components/SearchableSelect';
const { Option } = Select;

type Props = {
  onConfirm: (version: number) => void;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  show: boolean;
  record: CameraRecordTable;
};

const ProcessNewVersionModal = ({
  onConfirm,
  onCancel,
  show,
  record,
}: Props) => {
  const [version, setVersion] = useState<number>();
  const versions = useAppSelector(s => s.CodeVersion.codeVersions);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (show) dispatch(getCodeVersions());
  }, [show]);

  const confirm = () => {
    onConfirm(version!);
  };

  // Filter versions that has been already listed as a camera record process
  const filteredVersions = useMemo(() => {
    return versions.data.filter(
      version =>
        !record.data.CameraRecordProcesses.map(p => p.VersionId).includes(
          version.Id
        )
    );
  }, [versions.status, record?.data?.Id]);

  return (
    <Modal
      title={t('processWithNewVersion')}
      open={show}
      onOk={confirm}
      onCancel={onCancel}
      okButtonProps={{ disabled: version === undefined }}
    >
      <SearchableSelect
        onChange={setVersion}
        className="version-modal-select"
        value={version}
      >
        {filteredVersions.map(version => (
          <Option value={version.Id} key={version.Id} label={version.Name}>
            {version.Name + ' ' + version.Version}
          </Option>
        ))}
      </SearchableSelect>
    </Modal>
  );
};

export default ProcessNewVersionModal;
