import FormCard from '@Components/FormCard';
import WifiConfForm from '@Forms/WifiConfForm';
import { getStoreWifiConfigiration } from '@Store/Store/action';
import { useAppSelector } from '@Store/hooks';
import {
  ForkOutlined,
  GlobalOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  WifiOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import './style.scss';
import { Col, Row, Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { getBoardWifiConfigiration } from '@Store/Board/action';
import BoardWifiForm from '@Pages/Boards/Components/BoardWifiForm';
import StaticWifiForm from '../../../../Forms/StaticWifiForm';
import StaticIp from '@Pages/Boards/Components/StaticIp';

function NetworkConfigiration() {
  const wifiConfStore = useAppSelector(s => s.Store.wifiConfStore);
  const wifiConfBoard = useAppSelector(s => s.Board.wifiConfBoard);

  const { storeId, boardId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (storeId) {
      dispatch(getStoreWifiConfigiration(parseInt(storeId!)));
    }
  }, [storeId]);

  useEffect(() => {
    if (boardId) {
      dispatch(getBoardWifiConfigiration(parseInt(boardId!)));
    }
  }, [boardId]);

  return (
    <>
      <FormCard
        titleIcon={<ForkOutlined />}
        titleKey="Ağ Konfigürasyonu"
        className="network-configiration"
      >
        <Tabs defaultActiveKey="wifi" tabBarStyle={{ marginBottom: 16 }}>
          <TabPane
            tab={
              <span>
                <WifiOutlined /> WiFi Konfigürasyonu
              </span>
            }
            key="wifi"
          >
            <Row gutter={[32, 16]}>
              {wifiConfStore?.data?.WifiConfiguration && (
                <Col span={7} className="col-form-board">
                  <StaticWifiForm
                    wifiConfiguration={wifiConfStore?.data?.WifiConfiguration}
                  />
                </Col>
              )}
              {wifiConfBoard?.data?.WifiConfigurations && (
                <BoardWifiForm data={wifiConfBoard?.data?.WifiConfigurations} />
              )}
            </Row>
          </TabPane>
          <TabPane
            tab={
              <span>
                <GlobalOutlined /> Statik IP
              </span>
            }
            key="ip"
          >
            <StaticIp
              ipConfigs={wifiConfBoard?.data?.IpConfigurations}
              boardWifiConfigs={wifiConfBoard?.data?.WifiConfigurations}
              storeWifiConfig={wifiConfStore?.data?.WifiConfiguration}
            />
          </TabPane>
        </Tabs>
      </FormCard>
    </>
  );
}

export default NetworkConfigiration;
