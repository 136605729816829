import ActionButton from '@Components/ActionButton';
import CameraRecordsService from '@Services/Api/CameraRecords';
import { CameraRecordTable } from '@Types/CameraRecords';
import { apiCall } from '@Utils/index';
import { useState } from 'react';
import ProcessNewVersionModal from '../ProcessNewVersionModal';
import { VideoCameraAddOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface Props {
  record: CameraRecordTable;
  getCameraRecords: Function;
}
export default function ProcessNewVersionButton({
  record,
  getCameraRecords,
}: Props) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { t } = useTranslation();

  const recordWithNewVersion = async (version: number) => {
    const req = async () =>
      await new CameraRecordsService().RecordWithNewVersion({
        recordId: record.data.Id,
        versionId: version,
      });

    try {
      await apiCall(req, 'recordProcessWithNewVersion');
      getCameraRecords();
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <>
      <ActionButton
        onClick={() => setShowModal(true)}
        icon={<VideoCameraAddOutlined />}
        tooltip={t('processWithNewVersion')}
      />
      <ProcessNewVersionModal
        onConfirm={recordWithNewVersion}
        onCancel={() => setShowModal(false)}
        show={showModal}
        record={record}
      />
    </>
  );
}
