import useIsSysAdmin from './useIsSysAdmin';
import useIsTestAdmin from './useIsTestAdmin';

/**
 * @description Checks if the user is a valid admin for the current environment.
 * @returns `true` if the current user is a test admin or a system admin for NON-PRODUCTION environment
 * or if the current user is a system admin for PRODUCTION environment.
 */
export default function useIsEnvAdmin(): boolean {
  const isSysAdmin = useIsSysAdmin();
  const isTestAdmin = useIsTestAdmin();

  if (process.env.NODE_ENV === 'production') {
    return isSysAdmin;
  }

  return isTestAdmin || isSysAdmin;
}
