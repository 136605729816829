import { ReactElement, Children, cloneElement, ReactNode } from 'react';
import { Skeleton, Spin } from 'antd';

import ReduxtTypes from '@Types/ReduxTypes';
import ErrorMessage from '../Error';
import PageSkeletons from '@Components/PageSkeletons';
import { useTranslation } from 'react-i18next';

export interface ContentLoaderBase {
  status: ReduxtTypes.asyncFlow | ReduxtTypes.asyncFlow[];
  errorMessage?: string;
}
export interface ContentLoaderProps extends ContentLoaderBase {
  customLoader?: ReactElement;
  children?: ReactNode;
}

function ContentLoader({
  children,
  status,
  errorMessage,
  customLoader,
}: ContentLoaderProps): ReactElement {
  const { t } = useTranslation();
  const renderChildren = () => {
    return Children.map(children, child => cloneElement(child as ReactElement));
  };

  if (status === 'Rejected') {
    return <ErrorMessage>{errorMessage ?? t('errorMessage')}</ErrorMessage>;
  }

  if (status === 'fulfilled') {
    return <>{renderChildren()}</>;
  }

  return <PageSkeletons loader={customLoader} />;
}

export default ContentLoader;
