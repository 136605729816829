import { Col, ColProps, Row, RowProps, Typography } from 'antd';
import { TextProps } from 'antd/lib/typography/Text';

const { Text } = Typography;

interface Props extends TextProps {
  text: string | number | undefined | null;
  filler?: string;
  rowProps?: RowProps;
  textColProps?: ColProps;
}

/**
 * @description This component is used to prevent re-writing the same boilerplate hundreds of times.
 * Instead of writing -> <Typography.Text>{text || '-'}</Typography.Text>
 * We can use this component -> <FilledText text={text} />
 *
 * This also allows us to use generic "filler" through our application.
 * We could change the default value ('-') here and it will be changed everywhere.
 */
export default function FilledText(props: Props) {
  const {
    text,
    filler = '-',
    copyable,
    rowProps,
    textColProps,
    ...restProps
  } = props;

  const filledText = text ? (text as string) : filler;

  return (
    <Row gutter={2} wrap={false} {...rowProps}>
      <Col>
        {copyable && (
          <Text
            copyable={{
              text:
                typeof copyable === 'object' && 'text' in copyable
                  ? copyable.text
                  : filledText,
            }}
          />
        )}
      </Col>
      <Col {...textColProps}>
        <Text title={filledText} {...restProps}>
          {text || filler}
        </Text>
      </Col>
    </Row>
  );
}
