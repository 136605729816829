import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  Credentials,
  Account as AccountType,
  Icaptcha,
} from '../../Types/User';
import { CAPTCHA_CLEAR, CAPTCHA_REQUIRED, LOGIN, LOGOUT } from './type';
import Account from '../../Services/Api/Account';
import { axiosBuilder } from '../../Services/Api';
import { apiCall } from '@Utils/index';

export const login = createAsyncThunk(
  LOGIN,
  async (userCreds: Credentials, { dispatch }) => {
    try {
      const req = async () => await new Account().Login(userCreds);
      const account = await apiCall(req, 'login', undefined, {
        returnError: true,
      });
      if (!account.access_token) {
        if (account.response.headers.capctha_required === 'true') {
          dispatch(
            capctha_required({
              image: account.response.headers.capctha_imagebase64,
              required: account.response.headers.capctha_required,
              expire: account.response.headers.capctha_expire,
            })
          );
        }
        return;
      }
      axiosBuilder.setAutHeader(account.access_token);
      if (userCreds.remember) {
        localStorage.setItem('Account', JSON.stringify(account));
      }
      dispatch(capctha_clear());
      return account;
    } catch (err) {
      console.warn(err);
    }
  }
);

export const capctha_required = createAction(
  CAPTCHA_REQUIRED,
  (captcha: Icaptcha) => {
    return {
      payload: captcha,
    };
  }
);

export const capctha_clear = createAction(CAPTCHA_CLEAR);

export const logout = createAction(LOGOUT);
