import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Input, List, Tooltip } from 'antd';
import RegionsServices from '@Services/Api/Regions';
import { apiCall } from '@Utils/index';
import { getBrandRegions } from '@Store/Regions/action';
import DeleteButton from '@Components/Card/DeleteButton';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { EditRegion, Regions } from '@Types/Region';
import GoBackButton from '@Components/GoBackButton';

type Props = { selectedBrandName?: string; selectedBrandId?: number };

function RegionTable({ selectedBrandName, selectedBrandId }: Props) {
  const regions = useAppSelector(s => s.Regions.regions);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [editRegionShow, seteditRegionShow] = useState(false);
  const [newRegion, setnewRegion] = useState<{
    Name: string;
    Id: number | null;
  }>({
    Name: '',
    Id: null,
  });

  const handleDelete = async (regionId: number) => {
    try {
      const func = () => new RegionsServices().DeleteRegion(regionId);
      await apiCall(func, 'deleteRegionBrand');
      if (selectedBrandId) {
        dispatch(getBrandRegions(selectedBrandId));
      }
    } catch (err) {
      console.error(err);
    }
  };
  const editOnClick = async (region: Regions) => {
    setnewRegion({ Name: region.Name, Id: region.Id });
    seteditRegionShow(true);
  };
  const closeOnClick = () => {
    seteditRegionShow(false);
  };
  const submitOnClick = async () => {
    const editRegion: EditRegion = {
      BrandId: selectedBrandId!,
      Name: newRegion.Name,
    };
    try {
      const func = () =>
        new RegionsServices().UpdateRegion(newRegion.Id!, editRegion);
      seteditRegionShow(false);
      await apiCall(func, 'editRegion');

      dispatch(getBrandRegions(selectedBrandId!));
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      {regions.data.length > 0 && selectedBrandId ? (
        <List
          header={<h3>Bölgeler - {selectedBrandName} </h3>}
          itemLayout="horizontal"
          className="regionTable"
          dataSource={regions.data}
          renderItem={region => (
            <List.Item key={region.Id}>
              <List.Item.Meta
                title={
                  editRegionShow && newRegion.Id === region.Id ? (
                    <Input
                      onChange={e =>
                        setnewRegion({
                          Name: e.target.value,
                          Id: newRegion.Id,
                        })
                      }
                      value={newRegion.Name}
                    />
                  ) : (
                    <p>{region.Name}</p>
                  )
                }
                description={
                  <>
                    {editRegionShow && newRegion.Id === region.Id ? (
                      <>
                        <Tooltip title={t('submit')}>
                          <Button
                            shape="circle"
                            icon={<CheckOutlined />}
                            onClick={() => submitOnClick()}
                          />
                        </Tooltip>

                        <Tooltip title={t('cancel')}>
                          <Button
                            shape="circle"
                            icon={<CloseOutlined />}
                            onClick={() => closeOnClick()}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <Tooltip title={t('edit')}>
                          <Button
                            shape="circle"
                            icon={<EditOutlined />}
                            onClick={() => editOnClick(region)}
                          />
                        </Tooltip>
                        <DeleteButton
                          onConfirm={() => handleDelete(region.Id)}
                        />
                      </>
                    )}
                  </>
                }
              />
            </List.Item>
          )}
        />
      ) : (
        <></>
      )}
      <GoBackButton />
    </>
  );
}

export default RegionTable;
