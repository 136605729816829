export const GET_ALL_STORES_FOR_BRANDS = 'STORE/GET_ALL_STORES_FOR_BRANDS';
export const GET_ALL_STORES_OF_BRAND = 'STORE/GET_ALL_STORES_OF_BRAND';
export const GET_STORE_DETAIL = 'STORE/GET_STORE_DETAIL';
export const GET_UNARCHIVED_STORES = 'STORE/GET_UNARCHIVED_STORES';
export const GET_ALL_UNARCHIVED_STORES = 'STORE/GET_ALL_UNARCHIVED_STORES';
export const GET_ALL_ARCHIVED_STORES = 'STORE/GET_ALL_ARCHIVED_STORES';
export const GET_ARCHIVED_STORES = 'STORE/GET_ARCHIVED_STORES';

export const SET_ALL_STORES = 'STORE/SET_ALL_STORES';
export const SET_SELECTED_STORE = 'STORE/SET_SELECTED_STORE';
export const SET_SELECTED_STORES = 'STORE/SET_SELECTED_STORES';

export const RESET_ALL_STORES = 'STORE/RESET_ALL_STORES';
export const RESET_CURRENT_STORE = 'STORE/RESET_CURRENT_STORE';
export const RESET_STORE_WIFI_CONFIG = 'STORE/RESET_STORE_WIFI_CONFIG';

export const GET_STORES_CAMERA_STATUS = 'GET_STORES_CAMERA_STATUS';
export const GET_MULTIPLE_STORES_CAMERA_STATUS =
  'GET_MULTIPLE_STORES_CAMERA_STATUS';

export const GET_STORE_WIFI_CONF = 'GET_STORE_WIFI_CONF';
export const GET_STORE_UNARCHIVE_CAMERAS = 'GET_STORE_UNARCHIVE_CAMERAS';
