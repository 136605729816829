import { Coordinates } from '../../Types/Bases';
import { Points } from '../../Types/Shape';
import { ShapeTasks } from '../Shape';

export const TextTasks = {
  getTextPosition(Points: Points): Coordinates {
    //Operations

    return ShapeTasks.centroid(Points);
  },
};
