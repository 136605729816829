import { createAsyncThunk } from '@reduxjs/toolkit';
import { ALL_ROLES, ALL_USER_ROLES, NEW_ROLE } from './type';
import Role from '../../Services/Api/Role';
import { NewRole } from '@Types/Role';

export const createNewRole = createAsyncThunk(
  NEW_ROLE,
  async (newRole: NewRole) => {
    try {
      await new Role().New(newRole);
    } catch (err) {
      console.error(err);
    }
  }
);

export const getAllRoles = createAsyncThunk(ALL_ROLES, async () => {
  try {
    const allRoles = await new Role().All();
    return allRoles;
  } catch (err) {
    console.error(err);
    return [];
  }
});

export const getAllUserRoles = createAsyncThunk(ALL_USER_ROLES, async () => {
  try {
    const allUserRoles = await new Role().UserRoles();
    return allUserRoles;
  } catch (err) {
    console.error(err);
    return [];
  }
});
