import { HomeOutlined, CameraOutlined, ShopOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import { EntityFilter } from '@Pages/CameraRecords/types';
import './style.scss';

type Props = {
  entityIds: EntityFilter;
  setEntityIds: React.Dispatch<React.SetStateAction<EntityFilter>>;
};

const EntityIdFilter = ({ entityIds, setEntityIds }: Props) => {
  const { t } = useTranslation();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEntityIds(oldEntityIds => ({
      ...oldEntityIds,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <Input
        name="brandId"
        onChange={handleInputChange}
        value={entityIds.brandId}
        type="number"
        min={0}
        placeholder={t('brand') + ' ID'}
        prefix={<HomeOutlined />}
        className="entity-id-input"
      />

      <Input
        name="storeId"
        onChange={handleInputChange}
        value={entityIds.storeId}
        type="number"
        min={0}
        placeholder={t('store') + ' ID'}
        prefix={<ShopOutlined />}
        className="entity-id-input"
      />

      <Input
        name="cameraId"
        onChange={handleInputChange}
        value={entityIds.cameraId}
        type="number"
        min={0}
        placeholder={t('camera') + ' ID'}
        prefix={<CameraOutlined />}
        className="entity-id-input"
      />
    </>
  );
};

export default EntityIdFilter;
