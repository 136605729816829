import { Option } from 'antd/lib/mentions';
import {
  MailOutlined,
  ApartmentOutlined,
  ControlOutlined,
  ForkOutlined,
  OneToOneOutlined,
  NumberOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import SortByFilter from '@Components/SortByFilter';
type props = {
  onChange: (value: string) => void;
  defaultValue: string;
};
const SortByFilterVersion = ({ onChange, defaultValue }: props) => {
  const { t } = useTranslation();
  const iconStyle = { paddingLeft: 6 };

  return (
    <SortByFilter defaultValue={defaultValue} onChange={onChange}>
      <Option value="Id">
        Id
        <NumberOutlined style={iconStyle} />
      </Option>
      <Option value="Name">
        {t('versionName')}
        <ForkOutlined style={iconStyle} />
      </Option>
      <Option value="ComponentMain.Name">
        {'Main'}
        <MailOutlined style={iconStyle} />
      </Option>
      <Option value="ComponentModel.Name">
        {'Model'}
        <ApartmentOutlined style={iconStyle} />
      </Option>
      <Option value="ComponentControl.Name">
        {t('control')}
        <ControlOutlined style={iconStyle} />
      </Option>
      <Option value="Hardware.Name">
        {t('hardware')}
        <OneToOneOutlined style={iconStyle} />
      </Option>
    </SortByFilter>
  );
};

export default SortByFilterVersion;
