import { FetchedBrand } from '@Types/Brand';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { Tag } from 'antd';

interface BoardCountInfoProps
  extends Pick<
    FetchedBrand,
    | 'BoardCount'
    | 'PassiveBoardCount'
    | 'NotInstalledBoardCount'
    | 'AtLeast3DaysPassiveBoardCount'
  > {}

export function BoardCountInfo(props: BoardCountInfoProps) {
  const {
    AtLeast3DaysPassiveBoardCount,
    BoardCount,
    NotInstalledBoardCount,
    PassiveBoardCount,
  } = props;

  const installedBoardCount = BoardCount - NotInstalledBoardCount;
  const activeBoardCount = installedBoardCount - PassiveBoardCount;

  const { t } = useTranslation();

  return (
    <div className="board-count-info">
      <div className="board-count-info-column">
        <Tag title={t('boardCount')}>{BoardCount}</Tag>
      </div>

      <div className="board-count-info-column">
        <Tag
          color="#f48db4"
          title={t('offBoards')}
          hidden={PassiveBoardCount === 0}
        >
          {PassiveBoardCount}
        </Tag>

        <Tag
          color="#FCD47D"
          title={t('NotInstalledBoardCount')}
          hidden={NotInstalledBoardCount === 0}
        >
          {NotInstalledBoardCount}
        </Tag>
      </div>

      <div className="board-count-info-column">
        <Tag
          color="#B03052"
          title={t('AtLeast3DaysPassiveBoardCount')}
          hidden={AtLeast3DaysPassiveBoardCount === 0}
        >
          {AtLeast3DaysPassiveBoardCount}
        </Tag>

        <Tag color="#7ED4AD" title={t('onBoards')}>
          {activeBoardCount}
        </Tag>
      </div>
    </div>
  );
}
