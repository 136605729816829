import ModalFooter from '@Components/ModalFooter';
import PageSkeletons from '@Components/PageSkeletons';
import SearchableSelect from '@Components/SearchableSelect';
import { useMacAdressRules } from '@Hooks/useMacAdressRules';
import { getSimilarBeacons } from '@Store/Beacons/action';
import { getAllAvailableBrands } from '@Store/Brand/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getAllStoresOfBrands, resetAllStores } from '@Store/Store/action';
import { AllBeacon } from '@Types/Beacon';
import { Form, Input, Select } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onFinish?: (values: any) => void;
  beaconData?: AllBeacon;
  allEditForm?: boolean;
};
export default function BeaconForm({
  onFinish,
  beaconData,
  allEditForm,
}: Props) {
  const { t } = useTranslation();
  const macAdressRules = useMacAdressRules();
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const brands = useAppSelector(s => s.Brand.allAvailableBrands);
  const stores = useAppSelector(s => s.Store.allStores);
  const similarBeacons = useAppSelector(s => s.Beacons.similarBeacons);
  const brandId = useWatch('BrandId', form);

  useEffect(() => {
    dispatch(getAllAvailableBrands());
  }, []);

  useEffect(() => {
    if (brandId) dispatch(getAllStoresOfBrands([brandId]));
  }, [brandId]);

  useEffect(() => {
    if (beaconData?.BrandId) {
      dispatch(getAllStoresOfBrands([beaconData.BrandId]));
      dispatch(
        getSimilarBeacons({
          brandId: beaconData.BrandId,
          externalId: beaconData.ExternalId,
          macAddress: beaconData.BTMacAddress,
        })
      );
    }
  }, [beaconData]);

  const handleBrandChange = () => {
    form.resetFields(['StoreIds']);
    dispatch(resetAllStores());
  };

  if (stores.status === 'Pending' && brands.status === 'Pending')
    return <PageSkeletons />;

  return (
    <Form
      form={form}
      name="beacon-form"
      onFinish={onFinish}
      initialValues={beaconData}
    >
      <Form.Item label={t('name')} name="Name" required>
        <Input required />
      </Form.Item>
      <Form.Item label={t('surname')} name="Surname" required>
        <Input />
      </Form.Item>
      {!beaconData && (
        <>
          <Form.Item label={t('brand')} required name={'BrandId'}>
            <SearchableSelect
              placeholder={t('selectBrand')}
              allowClear
              showSearch
              onChange={handleBrandChange}
            >
              {brands.data.map(brand => (
                <Select.Option
                  key={brand.Id}
                  value={brand.Id}
                  label={brand.Name}
                >
                  {brand.Name}
                </Select.Option>
              ))}
            </SearchableSelect>
          </Form.Item>
          <Form.Item label={t('store')} required name={'StoreIds'}>
            <SearchableSelect
              placeholder={t('selectStore')}
              mode="multiple"
              allowClear
              maxTagCount={1}
              showSearch
            >
              {stores.data.map(store => (
                <Select.Option
                  key={store.Id}
                  value={store.Id}
                  label={store.Name}
                >
                  {store.Name}
                </Select.Option>
              ))}
            </SearchableSelect>
          </Form.Item>
          <Form.Item
            label={'Bt Mac Address'}
            name="BtMacAddress"
            required
            rules={macAdressRules}
          >
            <Input />
          </Form.Item>
          <Form.Item label={t('externalId')} name="ExternalId">
            <Input />
          </Form.Item>{' '}
        </>
      )}
      {/* {beaconData && (
        <Form.Item label={t('beacons')} required name="BeaconIds">
          <SearchableSelect
            allowClear
            showSearch
            mode="multiple"
            options={similarBeacons.data.map(beacon => ({
              value: beacon.Id,
              label: `${beacon.Id} - ${beacon.Name}`,
            }))}
          />
        </Form.Item>
      )} */}
      <ModalFooter formKey="beacon-form" allEditForm={allEditForm} />
    </Form>
  );
}
