import { Option } from 'antd/lib/mentions';
import {
  HomeOutlined,
  ShopOutlined,
  NumberOutlined,
  CameraOutlined,
  EyeOutlined,
  CalendarOutlined,
  AreaChartOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import SortByFilter from '@Components/SortByFilter';

type Props = {
  onChange: (value: string) => void;
  defaultValue: string;
};

const SortByFilterUsers = ({ onChange, defaultValue }: Props) => {
  const { t } = useTranslation();
  const iconStyle = { paddingLeft: 6 };

  return (
    <SortByFilter defaultValue={defaultValue} onChange={onChange}>
      <Option value="Id">
        Id
        <NumberOutlined style={iconStyle} />
      </Option>
      <Option value="Camera.Store.BrandId">
        {t('brand')}
        <HomeOutlined style={iconStyle} />
      </Option>
      <Option value="Camera.StoreId">
        {t('store')}
        <ShopOutlined style={iconStyle} />
      </Option>
      <Option value="CameraId">
        {t('camera')}
        <CameraOutlined style={iconStyle} />
      </Option>
      <Option value="WatchedByUser.Email">
        {t('watchedByUser')}
        <EyeOutlined style={iconStyle} />
      </Option>
      <Option value="CreatedDate">
        {t('date')}
        <CalendarOutlined style={iconStyle} />
      </Option>
      <Option value="CameraRecordStatus">
        {t('status')}
        <AreaChartOutlined style={iconStyle} />
      </Option>
    </SortByFilter>
  );
};

export default SortByFilterUsers;
