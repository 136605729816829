import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

function Brands() {
  return (
    <Layout id="brands-page">
      <Outlet />
    </Layout>
  );
}

export default Brands;
