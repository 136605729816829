import Card from '@Components/Card';
import EditButton from '@Components/Card/EditButton';
import { Space } from 'antd';
import DeleteButton from '@Components/Card/DeleteButton';
import LensTypeServices from '@Services/Api/LensType';
import { apiCall } from '@Utils/index';
import { LensTypes } from '@Types/LensType';

type Props = {
  lensType: LensTypes;
  getLensType: () => void;
};
export default function HardwareCard({ lensType, getLensType }: Props) {
  const deleteLensType = async () => {
    try {
      const req = () => new LensTypeServices().DeleteLensType(lensType.Id);
      await apiCall(req, 'deleteLensType');
      getLensType();
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <Card
      disableDetail
      subHeader={true}
      extraButtons={
        <Space direction="horizontal" size="small" wrap>
          <EditButton to={`${lensType.Id}`} />
          <DeleteButton onConfirm={deleteLensType} />
        </Space>
      }
      content={{
        firsItem: {
          text: lensType.Name,
        },
      }}
    />
  );
}
