import { Card, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { BarChartOutlined } from '@ant-design/icons';
import { CoordinatesCameraFilterParams } from '@Types/Coordinates';
import CardGridWrapper from '@Components/CardGridWrapper';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import {
  getCamerasCoordinate,
  getCamerasLineCoordinate,
} from '@Store/Coordinates/action';
import CoordinateCard from '@Cards/CoordinateCard';
import { useParams } from 'react-router-dom';
import SelectedFilters, { IDataForm } from '@Pages/Data/SelectedFilters';
import { getCameraDetail, resetCurrentCamera } from '@Store/Camera/action';
import { CameraDetail } from '@Types/Camera';
import { getStoresCameraStatus } from '@Store/Store/action';

function CameraCoordinates() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { cameraId } = useParams();
  const currentCamera = useAppSelector(
    s => s.Camera.currentCamera.data
  ) as CameraDetail;
  const currentCameraStatus = useAppSelector(
    s => s.Camera.currentCamera.status
  );

  const cameraCoordinate = useAppSelector(s => s.Coordinate.cameraCoordinate);
  const cameraLineCoordinate = useAppSelector(
    s => s.Coordinate.cameraLineCoordinate
  );
  const storesCameraStatus = useAppSelector(s => s.Store.storesCameraStatus);

  useEffect(() => {
    if (cameraId) {
      dispatch(getCameraDetail(parseInt(cameraId)));
      let lineDefaultParam: CoordinatesCameraFilterParams = {
        cameraId: parseInt(cameraId!),
        type: 1,
        filterNumber: 48,
        period: 0,
      };

      let defaultParam: CoordinatesCameraFilterParams = {
        cameraId: parseInt(cameraId!),
        type: 0,
        filterNumber: 48,
        period: 0,
      };
      dispatch(getCamerasCoordinate(defaultParam));
      dispatch(getCamerasLineCoordinate(lineDefaultParam));

      return () => {
        dispatch(resetCurrentCamera());
      };
    }
  }, [cameraId]);

  useEffect(() => {
    if (currentCamera?.StoreId) {
      let defaultParam = {
        storeId: currentCamera.StoreId,
        type: 0,
        filterNumber: 48,
        period: 0,
      };

      dispatch(getStoresCameraStatus(defaultParam));
    }
  }, [currentCameraStatus]);

  const onFinish = async (data: IDataForm) => {
    let param: CoordinatesCameraFilterParams = {
      cameraId: parseInt(cameraId!),
      type: 0,
      filterNumber: data.filterNumber || 48,
      period: data.period || 0,
    };
    let lineParam: CoordinatesCameraFilterParams = {
      cameraId: parseInt(cameraId!),
      type: 1,
      filterNumber: data.filterNumber || 48,
      period: data.period || 0,
    };
    dispatch(getCamerasCoordinate(param));
    dispatch(getCamerasLineCoordinate(lineParam));

    if (currentCamera?.StoreId) {
      dispatch(
        getStoresCameraStatus({ ...param, storeId: currentCamera.StoreId })
      );
    }
  };

  return (
    <Card
      className="Data"
      title={
        <Row className="card-grid-header">
          <BarChartOutlined />
          {cameraId && currentCamera
            ? currentCamera.Brand +
              ' -> ' +
              currentCamera.Store +
              ' -> ' +
              currentCamera.Name
            : t('navigation.data')}
        </Row>
      }
    >
      <div className="grid-content-wrapper">
        <div className="filter-row selectedFilters">
          <SelectedFilters onFinish={onFinish} />
        </div>
      </div>
      <div className="card-grid-container">
        <CardGridWrapper
          status={cameraCoordinate.status}
          noData={cameraCoordinate.data?.length === 0}
          cards={
            <React.Fragment>
              {cameraCoordinate.data.map((data, i) => (
                <CoordinateCard
                  data={data}
                  Label={cameraCoordinate.data.find(i => i.Name === 'Labels')}
                  lineData={cameraLineCoordinate.data[i]}
                  lineLabel={t('line.Data')}
                  barLabel={t('coordinate.Data')}
                  timeLineCardData={storesCameraStatus?.data.find(
                    cam => cam.CameraId === parseInt(cameraId!)
                  )}
                  status={currentCameraStatus}
                />
              ))}
            </React.Fragment>
          }
        />
      </div>
    </Card>
  );
}

export default CameraCoordinates;
