import Status from '@Enums/Status';
import BrandService from '@Services/Api/Brand';
import { Store } from '@Types/Store';
import { useEffect, useState } from 'react';

export function useGetStoresOfBrands(brandIds: number[]) {
  const [status, setStatus] = useState<Status>(Status.empty);
  const [data, setData] = useState<Store[]>([]);

  useEffect(() => {
    fetchStores();
  }, [brandIds.length]);

  async function fetchStores() {
    setStatus(Status.pending);

    try {
      // brand ids that was not fetched before. we only fetch those we did not already cache.
      const nonCachedBrandIds = brandIds.filter(
        brandId => !data.some(store => store.BrandId === brandId)
      );

      // fetch request for all these non cached brand ids
      const allStoresPromise = await Promise.all(
        nonCachedBrandIds.map(id => new BrandService().GetStores(id))
      );

      // push new stores to the data array
      setData(prevStores =>
        allStoresPromise.reduce((acc, store) => [...acc, ...store], prevStores)
      );

      setStatus(Status.success);
    } catch (e) {
      setStatus(Status.error);
      console.error(e);
    }
  }

  function resetData() {
    setData([]);
  }

  return {
    status,
    data,
    reset: resetData,
  };
}
