import { useTranslation } from 'react-i18next';

interface Props {
  icon: React.ReactElement;
  translationKey: string;
}

/*
    Used as a generic component to represent tabs with icons for multi-tab pages
*/
export default function TabItem({ icon, translationKey }: Props) {
  const { t } = useTranslation();

  return (
    <span>
      {icon}
      {t(translationKey)}
    </span>
  );
}
