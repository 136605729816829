import {
  AllBrandsFetchResponse,
  Brand,
  EditedBrand,
  ApiKeyRequest,
  ApiKeyResponse,
  NewBrand,
  StoreProduct,
  GetBrandsParams,
} from '@Types/Brand';
import { PaginationParams } from '@Types/Filter';
import { FetchedStoresResponse } from '@Types/Store';
import model from './index';
import { GetNotificationResponses } from '@Types/Notification';

class BrandsService extends model {
  constructor() {
    super();
    this.Prefix('Brands');
  }

  /**
   * @description Get all brands
   * @returns Promise
   */
  async GetAll(params: GetBrandsParams) {
    const { isArchived, ...paginationParams } = params;
    return this.Post('GetAll')
      .Params({ isArchived })
      .Data(paginationParams)
      .offset()
      .Save<AllBrandsFetchResponse>();
  }

  /**
   * @param {string} brandId
   * @description Get a brand by its Id
   * @returns Promise
   */

  async GetBrandById(brandId: number) {
    return this.Get(`${brandId}`).offset().Save<Brand>();
  }

  /**
   * @param {NewBrand} newBrand
   * @description Add new brand
   * @returns Promise
   */
  async AddBrand(newBrand: NewBrand) {
    return this.Post(``).Data(newBrand).Save();
  }

  /**
   * @param {string} brandId
   * @param {EditedBrand} editedBrand
   * @description Add new brand
   * @returns Promise
   */
  async EditBrand(brandId: string, editedBrand: EditedBrand) {
    return this.Put(`${brandId}`).Data(editedBrand).Save();
  }

  /**
   * @param {number} brandId
   * @description Add Brand Logo
   * @returns Promise
   */
  async AddBrandLogo(brandId: number, image: File) {
    return this.Post(`${brandId}/UploadLogo`).formData({ logo: image }).Save();
  }

  /**
   * @param {number} brandId
   * @description Get store products of a brand
   * @returns Promise
   */
  async GetStoreProducts(brandId: number) {
    return this.Get(`${brandId}/StoreProducts`).offset().Save<StoreProduct[]>();
  }

  /**
   * @param {number} brandId
   * @description Create new API Key
   * @returns Promise
   */
  async NewApiKey(brandId: number, apiKey: ApiKeyRequest) {
    return this.Post(`${brandId}/ApiKeys`).Data(apiKey).Save<ApiKeyResponse>();
  }

  /**
   * @param {number} brandId
   * @param {string} apiKey
   * @description Delete API Key
   * @returns Promise
   */
  async DeleteApiKey(brandId: number, apiKey: string) {
    return this.Delete(`${brandId}/ApiKeys/${apiKey}`).Save();
  }

  /**
   * @param {number} brandId
   * @description Get all API Keys
   * @returns Promise
   */
  async GetAllApiKeys(brandId: number) {
    return this.Get(`${brandId}/ApiKeys`).Save<ApiKeyResponse[]>();
  }

  /**
   * @param {PaginationParams} params
   * @param {number} brandId
   * @description Get all unarchieved stores
   * @returns Promise
   */
  async GetAllUnarchivedStores(params: PaginationParams, brandId: string) {
    return this.Post(`${brandId}/Stores`)
      .Data(params)
      .offset()
      .Save<FetchedStoresResponse>();
  }

  /**
   * @param {PaginationParams} params
   * @param {number} brandId
   * @description Get all archieved stores
   * @returns Promise
   */
  async GetAllArchivedStores(params: PaginationParams, brandId: string) {
    return this.Post(`${brandId}/ArchivedStores`)
      .Data(params)
      .offset()
      .Save<FetchedStoresResponse>();
  }

  async GetNotificationResponses(brandId: number) {
    return this.PrefixId(brandId)
      .Get('NotificationResponses')
      .Save<GetNotificationResponses>();
  }

  async CreateNotificationResponse(brandId: number, text: string) {
    return this.PrefixId(brandId)
      .Post('NotificationResponses')
      .Data({ Text: text })
      .Save();
  }

  async DeleteNotificationResponse(brandId: number, responseId: number) {
    return this.PrefixId(brandId)
      .Delete(`NotificationResponses/${responseId}`)
      .Save();
  }
}

export default BrandsService;
