import Card from '@Components/Card';
import CardButton from '@Components/CardButton';
import { ConfigBase } from '@Types/Config';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import './style.scss';
import AdminService from '@Services/Api/Admin';
import { apiCall } from '@Utils/index';
import { useAppDispatch } from '@Store/hooks';
import { getConfigSchemas } from '@Store/Config/action';

type Props = {
  data: ConfigBase;
};

export default function ConfigCard({ data: { Id, Description, Name } }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const deleteConfigSchema = async () => {
    try {
      const req = () => new AdminService().DeleteConfigSchema(Id);
      apiCall(req, 'deleteConfigSchema');
      dispatch(getConfigSchemas());
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <Card
      key={Id}
      className="config-card"
      content={{ firsItem: { text: Name } }}
      onClickDelete={deleteConfigSchema}
      details={[
        { key: { text: t('description') }, value: { text: Description } },
      ]}
      extraButtons={
        <CardButton icon={<EditOutlined />} tooltipKey="edit" to={`${Id}`} />
      }
    />
  );
}
