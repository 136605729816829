import { createReducer } from '@reduxjs/toolkit';
import {
  AllCamRecords,
  AllCamRecordsFetchResponse,
} from '@Types/CameraRecords';
import { getAllCamRecords } from './action';

interface stateInterface {
  allCamRecords: AllCamRecords;
}

const initialState: stateInterface = {
  allCamRecords: {
    data: {} as AllCamRecordsFetchResponse,
    status: 'Empty',
  },
};

const CameraRecordReducer = createReducer(initialState, builder => {
  builder
    .addCase(getAllCamRecords.pending, state => {
      state.allCamRecords = {
        ...state.allCamRecords,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllCamRecords.fulfilled, (state, action) => {
      state.allCamRecords.data = action.payload as AllCamRecordsFetchResponse;
      state.allCamRecords.status = 'fulfilled';
    })
    .addCase(getAllCamRecords.rejected, (state, action) => {
      state.allCamRecords = {
        ...state.allCamRecords,
        data: {} as AllCamRecordsFetchResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default CameraRecordReducer;
