import { createReducer } from '@reduxjs/toolkit';
import {
  getArchivedBoards,
  getUnarchivedBoards,
  resetAllBoards,
  getAllUnarchivedBoards,
  getAllArchivedBoards,
  getBoardDetail,
  resetCurrentBoard,
  getBoardWifiConfigiration,
} from './action';
import {
  AllFetchedBoards,
  Board,
  CurrentBoard,
  FetchedBoardsResponse,
} from '@Types/Board';
import { asyncFetchInterface } from '@Types/ReduxTypes';
import { NetworkConfigirationData } from '@Types/WifiConfigiration';

interface stateInterface {
  fetchedBoards: AllFetchedBoards;
  currentBoard: CurrentBoard;
  wifiConfBoard: asyncFetchInterface<NetworkConfigirationData>;
}

const initialState: stateInterface = {
  fetchedBoards: {
    data: {} as FetchedBoardsResponse,
    status: 'Empty',
  },
  currentBoard: {
    data: {} as Board,
    status: 'Empty',
  },
  wifiConfBoard: { data: {} as NetworkConfigirationData, status: 'Empty' },
};

const BoardReducer = createReducer(initialState, builder => {
  builder
    .addCase(getUnarchivedBoards.pending, state => {
      state.fetchedBoards = {
        ...state.fetchedBoards,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getUnarchivedBoards.fulfilled, (state, action) => {
      state.fetchedBoards.data = action.payload as FetchedBoardsResponse;
      state.fetchedBoards.status = 'fulfilled';
    })
    .addCase(getUnarchivedBoards.rejected, (state, action) => {
      state.fetchedBoards = {
        ...state.fetchedBoards,
        data: {} as FetchedBoardsResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getArchivedBoards.pending, state => {
      state.fetchedBoards = {
        ...state.fetchedBoards,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getArchivedBoards.fulfilled, (state, action) => {
      state.fetchedBoards.data = action.payload as FetchedBoardsResponse;
      state.fetchedBoards.status = 'fulfilled';
    })
    .addCase(getArchivedBoards.rejected, (state, action) => {
      state.fetchedBoards = {
        ...state.fetchedBoards,
        data: {} as FetchedBoardsResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(resetAllBoards, state => {
      state.fetchedBoards = {
        data: {} as FetchedBoardsResponse,
        status: 'Empty',
        ErrorMessage: undefined,
      };
    })
    .addCase(resetCurrentBoard, state => {
      state.currentBoard = {
        data: {} as Board,
        status: 'Empty',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllUnarchivedBoards.pending, state => {
      state.fetchedBoards = {
        ...state.fetchedBoards,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllUnarchivedBoards.fulfilled, (state, action) => {
      state.fetchedBoards.data = action.payload as FetchedBoardsResponse;
      state.fetchedBoards.status = 'fulfilled';
    })
    .addCase(getAllUnarchivedBoards.rejected, (state, action) => {
      state.fetchedBoards = {
        ...state.fetchedBoards,
        data: {} as FetchedBoardsResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getAllArchivedBoards.pending, state => {
      state.fetchedBoards = {
        ...state.fetchedBoards,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllArchivedBoards.fulfilled, (state, action) => {
      state.fetchedBoards.data = action.payload as FetchedBoardsResponse;
      state.fetchedBoards.status = 'fulfilled';
    })
    .addCase(getAllArchivedBoards.rejected, (state, action) => {
      state.fetchedBoards = {
        ...state.fetchedBoards,
        data: {} as FetchedBoardsResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getBoardDetail.pending, state => {
      state.currentBoard = {
        ...state.currentBoard,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getBoardDetail.fulfilled, (state, action) => {
      state.currentBoard.data = action.payload as Board;
      state.currentBoard.status = 'fulfilled';
    })
    .addCase(getBoardDetail.rejected, (state, action) => {
      state.currentBoard = {
        ...state.currentBoard,
        data: {} as Board,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getBoardWifiConfigiration.pending, state => {
      state.wifiConfBoard = {
        ...state.wifiConfBoard,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getBoardWifiConfigiration.fulfilled, (state, action) => {
      state.wifiConfBoard.data = action.payload as NetworkConfigirationData;
      state.wifiConfBoard.status = 'fulfilled';
    })
    .addCase(getBoardWifiConfigiration.rejected, (state, action) => {
      state.wifiConfBoard = {
        ...state.wifiConfBoard,
        data: {},
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default BoardReducer;
