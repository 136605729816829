import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_ALL_REPORTS } from './type';
import AutoReportsService from '@Services/Api/AutoReportsService';
import { PaginationParams } from '@Types/Filter';

export const getAllReports = createAsyncThunk(
  GET_ALL_REPORTS,
  async (params: PaginationParams) => {
    try {
      const reports = await new AutoReportsService().GetAllAutoReports(params);
      return reports;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
