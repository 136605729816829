import { AimOutlined } from '@ant-design/icons';
import FormCard from '@Components/FormCard';
import StoresService from '@Services/Api/Stores';
import { useNavigate, useParams } from 'react-router-dom';
import { NewBoard } from '@Types/Board';
import BoardForm from '@Forms/BoardForm';
import { apiCall } from '@Utils/index';

const AddBoard = () => {
  const { storeId } = useParams();
  let navigate = useNavigate();

  const handleSubmit = async (newBoard: NewBoard) => {
    try {
      await apiCall(() => addNewBoard(newBoard), 'defaultProcess');
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const addNewBoard = async (newBoard: NewBoard) => {
    return await new StoresService().AddNewBoard(newBoard, parseInt(storeId!));
  };

  return (
    <FormCard titleIcon={<AimOutlined />} titleKey="addBoard">
      <BoardForm onFinish={handleSubmit} />
    </FormCard>
  );
};

export default AddBoard;
