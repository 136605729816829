import { Option } from 'antd/lib/mentions';
import {
  ApartmentOutlined,
  WifiOutlined,
  NumberOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import SortByFilter from '@Components/SortByFilter';
type props = {
  onChange: (value: string) => void;
  defaultValue: string;
};
const SortByFilterVersion = ({ onChange, defaultValue }: props) => {
  const { t } = useTranslation();
  const iconStyle = { paddingLeft: 6 };
  return (
    <SortByFilter defaultValue={defaultValue} onChange={onChange}>
      <Option value="Id">
        {'Id'}
        <NumberOutlined style={iconStyle} />
      </Option>
      <Option value="ExternalId">
        {t('externalId')}
        <NumberOutlined style={iconStyle} />
      </Option>
      <Option value="BTMacAddress">
        {'Bt Mac Address'}
        <ApartmentOutlined style={iconStyle} />
      </Option>
      <Option value="BatteryFullPRC">
        Battery
        <WifiOutlined style={iconStyle} />
      </Option>
    </SortByFilter>
  );
};

export default SortByFilterVersion;
