import { PlusCircleOutlined } from '@ant-design/icons';
import FormCard from '@Components/FormCard';
import GatewayTypes from '@Types/Gateway';
import GatewaysService from '@Services/Api/Gateway';
import GatewayForm from '@Forms/GatewayForm';
import { apiCall } from '@Utils';
import { useNavigate } from 'react-router-dom';

const NewGateway = () => {
  let navigate = useNavigate();

  const handleSubmit = async (newGateway: GatewayTypes.NewGateway) => {
    let newGatewayData: GatewayTypes.NewGateway = {
      ExternalId: newGateway.ExternalId,
      StoreId: newGateway.StoreId,
      Setup: newGateway.Setup,
    };
    try {
      await apiCall(() => addNewGateway(newGatewayData), 'addGateway');
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const addNewGateway = async (newGateway: GatewayTypes.NewGateway) => {
    return await new GatewaysService().New(newGateway);
  };

  return (
    <FormCard titleIcon={<PlusCircleOutlined />} titleKey="addGateway">
      <GatewayForm onFinish={handleSubmit} />
    </FormCard>
  );
};

export default NewGateway;
