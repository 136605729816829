import { Alert, Button, Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

type Props = {
  message: string;
  apiError: string;
};

const ErrorAlert = ({ message, apiError }: Props) => {
  const [showApiError, setShowApiError] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <Col className="error-alert-container">
      <Row>
        <Col span={18}>
          <Text className="error-message">{t(message)}</Text>
        </Col>
        <Col span={6}>
          <Button type="default" onClick={() => setShowApiError(s => !s)}>
            {t(showApiError ? 'hide' : 'show')}
          </Button>
        </Col>
      </Row>
      {showApiError && (
        <Alert className="error-alert" message={apiError} type="error" />
      )}
    </Col>
  );
};

export default ErrorAlert;
