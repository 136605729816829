import { ReactElement, memo } from 'react';
import MEditor, { EditorProps as MEditorProps } from '@monaco-editor/react';

interface Props extends MEditorProps {}
function EditorMemorize(props: Props): ReactElement {
  return <MEditor {...props} />;
}

EditorMemorize.defaultProps = {
  height: 300,
  defaultLanguage: 'json',
  defaultValue: '{\n\t"key": "value"\n}',
  theme: 'vs-dark',
  options: {
    tabSize: 2,
  },
};

export type EditorProps = Props;
const Editor = memo(EditorMemorize);
export default Editor;
