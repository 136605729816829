import CameraRulesService from '@Services/Api/CameraRules';
import { apiCall } from '@Utils/index';

export default function useNotificationRulesActions() {
  const deleteCameraRules = async (cameraRuleIds: number[]) => {
    try {
      const req = () =>
        new CameraRulesService().DeleteCameraRule(cameraRuleIds);
      await apiCall(req, 'deleteCameraRule');
    } catch (e) {
      console.warn(e);
    }
  };

  return {
    deleteCameraRules,
  };
}
