import Status from '@Enums/Status';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { asyncFetchInterface } from '@Types/ReduxTypes';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import { GetTicketsResponse, ITicket } from '@Types/ticket.types';
import {
  TicketActionButtons,
  TicketStatusTag,
} from '@Features/tickets/components';
import { TicketType } from '@Enums/ticket.enums';
import { convertApiDate } from '@Utils/index';

interface Params {
  tickets: asyncFetchInterface<GetTicketsResponse>;
  onRefetch: () => void;
}

export default function useTicketsTableColumns({
  tickets,
  onRefetch,
}: Params): ColumnsType<ITicket> {
  const { t } = useTranslation();

  const actionPermission = useCheckPermission(Permissions.ADMIN_TICKET_ACTION);

  const ticketsTableColumns: ColumnsType<ITicket> = useMemo(() => {
    if (tickets.status !== Status.success) return [];

    const columns: ColumnsType<ITicket> = [
      {
        title: t('createdBy'),
        dataIndex: 'CreatedUserName',
      },
      {
        title: t('createdAt'),
        dataIndex: 'CreatedDateTime',
        render: (_, item) => convertApiDate(item.CreatedDateTime),
      },
      {
        title: t('status'),
        dataIndex: 'status',
        render: (_, item) => <TicketStatusTag status={item.TicketStatus} />,
      },
      {
        title: t('title'),
        dataIndex: 'type',
        render: (_, item) => t(`ticketTypes.${TicketType[item.TicketType]}`),
      },
    ];

    if (actionPermission) {
      columns.push({
        title: t('actionButtons'),
        dataIndex: 'actionButtons',
        render: (_: any, item) => (
          <TicketActionButtons ticket={item} onRefetch={onRefetch} />
        ),
      });
    }

    return columns;
  }, [tickets.status, t, actionPermission]);

  return ticketsTableColumns;
}
