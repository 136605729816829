import { asyncFetchInterface } from '@Types/ReduxTypes';
import { createReducer } from '@reduxjs/toolkit';
import { getBeaconRules } from './action';
import { IBeaconRule } from '@Types/BeaconRule';
import { PaginationData } from '@Types/index';

interface stateInterface {
  beaconRules: asyncFetchInterface<PaginationData<IBeaconRule>>;
}

const initialState: stateInterface = {
  beaconRules: {
    data: {} as PaginationData<IBeaconRule>,
    status: 'Empty',
  },
};

const beaconRulesReducer = createReducer(initialState, builder => {
  builder
    .addCase(getBeaconRules.pending, state => {
      state.beaconRules = {
        ...state.beaconRules,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getBeaconRules.fulfilled, (state, action) => {
      state.beaconRules.data = action.payload as PaginationData<IBeaconRule>;
      state.beaconRules.status = 'fulfilled';
    })
    .addCase(getBeaconRules.rejected, (state, action) => {
      state.beaconRules = {
        ...state.beaconRules,
        data: {} as PaginationData<IBeaconRule>,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default beaconRulesReducer;
