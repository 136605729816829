import { ReactElement } from 'react';
import { TextProps } from './Types/Text';

function Text({ Coordinates, Name }: TextProps): ReactElement {
  return (
    <text x={Coordinates.x} y={Coordinates.y}>
      {Name}
    </text>
  );
}

export default Text;
