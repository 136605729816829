import { Permissions } from '@Enums/Permission';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { SortedPermissions } from '@Types/Permission';
import { useEffect, useMemo } from 'react';
import { permissionsLayout } from '@Constants/Permission';
import { getAllPermissions } from '@Store/Permission/action';
import Status from '@Enums/Status';

export default function usePermissions() {
  const permissions = useAppSelector(state => state.Permission.allPermissions);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (permissions.status === Status.empty) dispatch(getAllPermissions());
  }, [permissions.status]);

  const sortedPermissions = useMemo(() => {
    const sortedPerms: SortedPermissions = [...permissionsLayout];

    for (const perm of permissions.data) {
      // Skip staticly categorized permissions
      if (perm.Name in Permissions) continue;

      // Add uncategorized permissions to 'OTHERS' tab
      const index = sortedPerms.findIndex(a => a.name === 'OTHERS');
      const newPermissions = sortedPerms[index].content as Permissions[];
      newPermissions.push(perm.Name);
      sortedPerms[index].content = newPermissions;
    }

    return sortedPerms;
  }, [permissions.status]);

  return {
    sortedData: sortedPermissions,
    data: permissions.data,
    status: permissions.status,
  };
}
