import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getArchivedStores, getUnarchivedStores } from '@Store/Store/action';
import { getBrandById, resetCurrentBrand } from '@Store/Brand/action';
import StoresDetail from '@Pages/Stores/Components/StoresDetail';
import { Spin } from 'antd';

const BrandStores = () => {
  const currentBrand = useAppSelector(s => s.Brand.currentBrand.data);
  const { brandId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getBrandById(parseInt(brandId!)));

    return () => {
      dispatch(resetCurrentBrand());
    };
  }, []);

  if (!currentBrand.Id) return <Spin />;
  return (
    <StoresDetail
      getUnarchivedStoresAction={getUnarchivedStores}
      getArchivedStoresAction={getArchivedStores}
      title={currentBrand.Name}
    />
  );
};

export default BrandStores;
