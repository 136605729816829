import { createReducer } from '@reduxjs/toolkit';
import { getCameraRules } from './action';
import {
  AllCameraRules,
  AllCameraRulesFetchResponse,
} from '@Types/CameraRules';

interface stateInterface {
  cameraRules: AllCameraRules;
}

const initialState: stateInterface = {
  cameraRules: {
    data: {} as AllCameraRulesFetchResponse,
    status: 'Empty',
  },
};

const CameraRulesReducer = createReducer(initialState, builder => {
  builder
    .addCase(getCameraRules.pending, state => {
      state.cameraRules = {
        ...state.cameraRules,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getCameraRules.fulfilled, (state, action) => {
      state.cameraRules.data = action.payload as AllCameraRulesFetchResponse;
      state.cameraRules.status = 'fulfilled';
    })
    .addCase(getCameraRules.rejected, (state, action) => {
      state.cameraRules = {
        ...state.cameraRules,
        data: {} as AllCameraRulesFetchResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default CameraRulesReducer;
