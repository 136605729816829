import { VersionFile } from '@Enums/VersionFileEnums';
import { useAppDispatch } from '@Store/hooks';
import {
  getBeaconFile,
  getBleFiles,
  getControlFile,
  getMainFile,
  getModelFile,
  getPackageFiles,
} from '@Store/Version/action';

export function useGetVersionFiles(versionFileType: VersionFile) {
  const dispatch = useAppDispatch();

  function get() {
    if (versionFileType === VersionFile.MAIN) {
      dispatch(getMainFile(true));
    }
    if (versionFileType === VersionFile.MODEL) {
      dispatch(getModelFile(true));
    }
    if (versionFileType === VersionFile.CONTROL) {
      dispatch(getControlFile(true));
    }
    if (versionFileType === VersionFile.BEACON) {
      dispatch(getBeaconFile(true));
    }
    if (versionFileType === VersionFile.BLE) {
      dispatch(getBleFiles(true));
    }
    if (versionFileType === VersionFile.PACKAGE) {
      dispatch(getPackageFiles(true));
    }
  }

  return get;
}
