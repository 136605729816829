import { useTranslation } from 'react-i18next';
import './style.scss';
import { Button, Form } from 'antd';
import Meta from 'antd/lib/card/Meta';
import GoBackButton from '@Components/GoBackButton';

type Props = {
  formKey: string;
  sendButtonDisabled?: boolean;
  allEditForm?: boolean;
  hideSendButton?: boolean;
  hideGoBackButton?: boolean;
  sendButtonText?: string;
  children?: React.ReactNode;
};

const ModalFooter = ({
  formKey,
  sendButtonDisabled,
  hideGoBackButton,
  hideSendButton,
  allEditForm,
  sendButtonText,
  children,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Meta
      className="modal-footer-wrapper"
      description={
        <div className="modal-footer-container">
          {!allEditForm && !hideGoBackButton && <GoBackButton />}

          {!hideSendButton && (
            <Form.Item
              className="submit-btn-container"
              style={{ marginBottom: 0 }}
            >
              <Button
                className="form-submit-button"
                type="primary"
                htmlType="submit"
                form={formKey}
                disabled={sendButtonDisabled}
              >
                {sendButtonText ?? t('submit')}
              </Button>
            </Form.Item>
          )}

          {children}
        </div>
      }
    />
  );
};

export default ModalFooter;
