import * as React from 'react';
import { Form } from 'antd';
import { FormItemProps } from 'antd/lib/form';

interface HardwareListFormItemProps extends FormItemProps {
  readonly editing: boolean;
  item?: React.ReactNode;
}

export const HardwareListFormItem: React.FC<HardwareListFormItemProps> =
  props => {
    const { editing, item, ...rest } = props;
    return (
      <Form.Item {...rest}>
        {editing ? props.children : item ?? <Dummy />}
      </Form.Item>
    );
  };

interface DummyProps {
  readonly value?: any;
}

const Dummy: React.FC<DummyProps> = props => (
  <div style={{ paddingLeft: 12 }}>{props.value}</div>
);
