import { CameraOutlined } from '@ant-design/icons';
import FormCard from '@Components/FormCard';
import { useNavigate, useParams } from 'react-router-dom';
import { NewCamera } from '@Types/Camera';
import CameraForm from '@Forms/CameraForm';
import CamerasService from '@Services/Api/Cameras';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getBoardDetail } from '@Store/Board/action';
import { apiCall } from '@Utils';

const AddCamera = () => {
  const currentBoard = useAppSelector(s => s.Board.currentBoard.data);
  const dispatch = useAppDispatch();
  const { storeId, boardId } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(getBoardDetail(parseInt(boardId!)));
  }, []);

  /**
   * @description storeId does not exist in a path like "/all-boards/2161/cameras/new-camera"
   * so we get the board detail from the backend and use its storeId to create a new camera
   */
  const handleSubmit = async (newCamera: NewCamera) => {
    try {
      await apiCall(
        () =>
          addNewCamera({
            ...newCamera,
            StoreId: storeId ? parseInt(storeId) : currentBoard.StoreId,
            BoardId: parseInt(boardId!),
          }),
        'addCamera'
      );
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const addNewCamera = async (newCamera: NewCamera) => {
    return await new CamerasService().AddNewCamera(newCamera);
  };

  return (
    <FormCard titleIcon={<CameraOutlined />} titleKey="addCamera">
      <CameraForm onFinish={handleSubmit} />
    </FormCard>
  );
};

export default AddCamera;
