import { DevicesEnums } from '@Enums/Devices';
import i18n from '../i18n';

export function convertDevicesTypeEnum(devicesType: DevicesEnums): string {
  switch (devicesType) {
    case 0:
      return i18n.t('modem');
    case 1:
      return i18n.t('accessPoint');
    case 2:
      return i18n.t('dataLine');
    case 3:
      return i18n.t('POESwitch');
    case 4:
      return i18n.t('transactionServer');
  }
  return '-';
}
