import { FormProps, withTheme } from '@rjsf/core';
import { Theme as AntDTheme } from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';

const AntForm = withTheme(AntDTheme);

interface Props extends Omit<FormProps, 'validator'> {}

function Form({ ...props }: Props) {
  return (
    <div className="json-schema-form">
      <AntForm
        {...props}
        validator={validator}
        children={true} // This prop hides the submit button
      />
    </div>
  );
}

export default Form;
