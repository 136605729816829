import model from './index';
import { ApiResponse } from '@Types/apiTypes';
import {
  CreateFieldSearchRequest,
  RetrieveFieldSearchResponse,
  UpdateFieldSearchRequest,
  FieldSearchId,
} from '@Types/FieldSearches';

class FieldSearchService extends model {
  constructor() {
    super();
    this.Prefix('Admin/FieldSearches');
  }

  /**
   * @description Get all field searches.
   * @returns Promise
   */
  async GetFieldSearches() {
    return this.Get().offset().Save<RetrieveFieldSearchResponse[]>();
  }

  /**
   * @param {FieldSearchId} fieldSearchId
   * @description Retrieve a field search.
   * @returns Promise
   */
  async GetFieldSearch(fieldSearchId: FieldSearchId) {
    return this.PrefixId(fieldSearchId)
      .Get()
      .Save<RetrieveFieldSearchResponse>();
  }

  /**
   * @param {CreateFieldSearchRequest} newFieldSearch
   * @description Create a field search.
   * @returns Promise
   */
  async NewFieldSearch(newFieldSearch: CreateFieldSearchRequest) {
    return this.Post().Data(newFieldSearch).Save();
  }

  /**
   *
   * @param {UpdateFieldSearchRequest} updatedFieldSearch
   * @description Update an existing field search.
   * @returns Promise
   */
  async UpdateFieldSearch(updatedFieldSearch: UpdateFieldSearchRequest) {
    return this.PrefixId(updatedFieldSearch.Id)
      .Put()
      .Data(updatedFieldSearch)
      .Save<ApiResponse>();
  }

  /**
   *
   * @description Delete a field search.
   * @returns Promise
   */
  async DeleteFieldSearch(fieldSearchId: FieldSearchId) {
    return this.PrefixId(fieldSearchId).Delete().Save<any>();
  }
}

export default FieldSearchService;
