import useRequest from '@Hooks/useRequest';
import CameraType from '@Types/Camera';
import Camera from '@Services/Api/Camera';
import Svg, { Image, Wrapper } from '@Components/Svg';
import SvgProvider from '@Components/Svg/SvgProvider';
import AbsoluteFillPolygonAdd from '@Components/Svg/Components/AbsoluteFillPolygonAdd';
import ContentLoader from '@Components/ContentLoader';
import {
  useContext,
  useState,
  forwardRef,
  ReactElement,
  PropsWithChildren,
  useImperativeHandle,
  ForwardedRef,
} from 'react';
import { ConnectCameraContext } from '../../Contexts/ConnectCameraContext';
import {
  onContextMenu,
  onShapeCreated,
  ShapesType,
} from '@Components/Svg/Types/Svg';
import PolygonSettings from '../PolygonSettings';
import SvgShapesContextProvider from '@Components/Svg/Components/SvgShapesContextProvider';
import SvgShapeTypes from '@Components/Svg/Types/Shape';
import ShapeContextMenu from '../ShapeContextMenu';
import ConnectCameraTypes from '@Types/connectCameras';
import { Skeleton } from 'antd';
function CameraImage({
  shapeOnUpdate,
  defaultShapes,
  ...cameraProperties
}: CameraType.Camera & {
  shapeOnUpdate?: (shape: ShapesType) => void;
  defaultShapes: ShapesType;
}) {
  const { createShape, setCreateShape, groupName } =
    useContext(ConnectCameraContext);
  const [Shapes, setShapes] = useState<ShapesType>(defaultShapes);
  const _shapeOnUpdate = (shape: ShapesType) => {
    setShapes(shape);
    if (shapeOnUpdate) {
      shapeOnUpdate(shape);
    }
  };
  const cameraImage = useRequest<string, [CameraType.CameraId]>(
    params => new Camera().GetPhoto(params),
    [cameraProperties.Id]
  );

  const [contextMenuOpt, setContextMenuOpt] = useState<{
    show: boolean;
    shape: ConnectCameraTypes.CameraConnectShape | undefined;
  }>({
    show: false,
    shape: undefined,
  });
  const [selectedShape, setSelectedShape] = useState<SvgShapeTypes.ShapeObject>(
    getDefaultShape()
  );

  const [showPolygonSettings, setShowPolygonSettings] = useState(false);

  const onShapeCreated: onShapeCreated = shape => {
    setSelectedShape(shape);
    setShowPolygonSettings(true);
  };
  const polygonSettingsOnCancel = () => {
    setSelectedShape(getDefaultShape());
    setShowPolygonSettings(false);
  };
  const onContextMenu: onContextMenu = shape => {
    setContextMenuOpt({ show: true, shape });
  };
  const contextOnClose = () => {
    setContextMenuOpt({ show: false, shape: undefined });
  };
  return (
    <ContentLoader
      status={cameraImage.status}
      errorMessage={'Photo Not Found!'}
      customLoader={<Skeleton.Image />}
    >
      <SvgProvider
        getShapes={shapes => _shapeOnUpdate(shapes)}
        defaultShapes={Shapes}
      >
        <AbsoluteFillPolygonAdd
          show={createShape}
          onClick={() => setCreateShape(false)}
        />
        <Wrapper>
          <Image src={cameraImage.data} />
          <Svg
            Shapes={Shapes}
            editable
            onShapeCreated={onShapeCreated}
            onContextMenu={onContextMenu}
          />
        </Wrapper>
        <SvgShapesContextProvider>
          {({ Shapes, updateShape, deleteDrawing }) => (
            <>
              <PolygonSettings
                show={showPolygonSettings}
                onCancel={() => {
                  polygonSettingsOnCancel();
                  deleteDrawing(selectedShape);
                }}
                onOk={values => {
                  updateShape({
                    ...selectedShape,
                    ...values,
                    CameraId: cameraProperties.Id,
                  });
                  setShowPolygonSettings(false);
                }}
                initialValues={{ groupName, ...selectedShape }}
              />
              <ShapeContextMenu
                {...contextMenuOpt}
                onClose={contextOnClose}
                deleteDrawing={deleteDrawing}
              />
            </>
          )}
        </SvgShapesContextProvider>
      </SvgProvider>
    </ContentLoader>
  );
}

const getDefaultShape = () => ({
  Color: '',
  Id: -1,
  Name: '',
  Points: [],
  Type: 1,
});

CameraImage.defaultProps = {
  defaultShapes: [],
};
export default CameraImage;
