import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const createConfirmModal = (title: string, onOk: any) => {
  Modal.confirm({
    title: title,
    icon: <ExclamationCircleOutlined />,
    onOk,
    onCancel() {},
  });
};

export default createConfirmModal;
