import ConfigCard from '@Cards/ConfigCard';
import CardGridWrapper from '@Components/CardGridWrapper';
import Status from '@Enums/Status';
import { useAppSelector } from '@Store/hooks';
import { ConfigPageParams } from '@Types/Config';
import { convertConfigTypeToEnum } from '@Utils/Config';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export default function ConfigGrid() {
  const configSchemas = useAppSelector(s => s.Config.configSchemas);
  const { entity } = useParams() as ConfigPageParams;

  const filteredConfigSchemas = useMemo(() => {
    if (configSchemas.status !== Status.success) return [];
    return configSchemas.data.filter(
      d => d.ConfigSchemaType === convertConfigTypeToEnum(entity!)
    );
  }, [configSchemas.status, entity]);

  return (
    <div className="grid-content-wrapper">
      <CardGridWrapper
        status={configSchemas.status}
        noData={filteredConfigSchemas.length === 0}
        cards={filteredConfigSchemas.map(c => (
          <ConfigCard data={c} />
        ))}
      />
    </div>
  );
}
