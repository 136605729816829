import Status from '@Enums/Status';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import FilledText from '@Components/FilledText';
import { AllAuditLogs, AuditLog } from '@Types/AuditLogs';
import InfoItem from '@Components/InfoItem';

interface Params {
  auditLogs: AllAuditLogs;
}

/**
 * @description This "hook" is not a generic hook. It's made to abstract the code and make it much more readable. It will only be used for <AuditLogs/>.
 */
export default function useAuditLogsColumns({
  auditLogs,
}: Params): ColumnsType<AuditLog> {
  const { t } = useTranslation();

  const auditLogsTableColumns: ColumnsType<AuditLog> = useMemo(() => {
    if (auditLogs.status !== Status.success) return [];

    const columns: ColumnsType<AuditLog> = [
      {
        title: t('UserName'),
        dataIndex: 'UserName',
        render: (value: string) => <FilledText text={value} />,
      },
      {
        title: t('URL'),
        dataIndex: 'Url',
        render: (value: string) => <FilledText text={value} />,
      },
      {
        title: t('Type'),
        dataIndex: 'Type',
        render: (value: string) => <FilledText text={t(value) as string} />,
      },
      {
        title: t('ActionData'),
        dataIndex: 'ActionData',
        render: (value: number) => <InfoItem content={value} />,
      },
      {
        title: t('Message'),
        dataIndex: 'Message',
        render: (value: number) => <InfoItem content={value} />,
      },
      {
        title: t('UserAgent'),
        dataIndex: 'UserAgent',
        render: (value: string) => <InfoItem content={value} />,
      },
      {
        title: t('createdDate'),
        dataIndex: 'CreatedDate',
        render: (value: string) => <FilledText text={value} />,
      },
    ];
    return columns;
  }, [auditLogs.status]);

  return auditLogsTableColumns;
}
