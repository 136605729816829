import { WifiConfigiration } from '@Types/WifiConfigiration';
import {
  CopyOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
} from '@ant-design/icons';
import { Checkbox, Divider, Form, Input, Tooltip, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { useState } from 'react';
import { createMessage } from '@Utils/index';

const { Text } = Typography;

type Props = { wifiConfiguration?: WifiConfigiration };

function StaticWifiForm({ wifiConfiguration }: Props) {
  const [form] = useForm();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCopy = (copyText: string | undefined) => {
    if (copyText) {
      navigator.clipboard.writeText(copyText);
      createMessage('success', t('copiedToClipboard'), copyText);
    }
  };

  return (
    <Form
      form={form}
      name="wifi-conf-static-form"
      className="wifi-conf-static-form-container"
      initialValues={wifiConfiguration}
    >
      <Text strong>Lokasyon Konfigürasyonu</Text>
      <Divider />
      {wifiConfiguration?.Name && (
        <Form.Item label={t('name')}>
          {wifiConfiguration?.Name ?? '-'}
        </Form.Item>
      )}
      <Form.Item label={'SSID'} name="SSID" required>
        <Input
          disabled={true}
          addonAfter={
            wifiConfiguration ? (
              <Tooltip title="Kopyala">
                <CopyOutlined
                  onClick={() => handleCopy(wifiConfiguration?.SSID)}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            ) : (
              <></>
            )
          }
        />
      </Form.Item>
      <Form.Item label={t('password')} name="Password">
        <Input
          prefix={<LockOutlined className="wifi-form-item-icon" />}
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          autoComplete="new-password"
          disabled
          addonAfter={
            wifiConfiguration ? (
              <Tooltip title="Kopyala">
                <CopyOutlined
                  onClick={() => handleCopy(wifiConfiguration?.Password)}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            ) : (
              <></>
            )
          }
          suffix={
            <span
              className="wifi-form-item-icon"
              onClick={togglePasswordVisibility}
              style={{ cursor: 'pointer' }}
            >
              {showPassword ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
            </span>
          }
        />
      </Form.Item>
      <Form.Item label={'Hidden'} name="Hidden" valuePropName="checked">
        <Checkbox disabled={true} />
      </Form.Item>
    </Form>
  );
}

export default StaticWifiForm;
