import Status from '@Enums/Status';
import { useAppSelector } from '@Store/hooks';
import { IBeaconRulesForm, IStoreBeacon } from '@Types/BeaconRule';
import { Form, FormInstance, TreeDataNode, TreeSelect } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type BeaconRuleBeaconSelectorProps = {
  disabled?: boolean;
  form: FormInstance<IBeaconRulesForm>;
};

enum SelectKind {
  SELECT_ALL = '-1',
  UNSELECT_ALL = '-2',
}

export function BeaconRuleBeaconSelector({
  disabled,
  form,
}: BeaconRuleBeaconSelectorProps) {
  const [checkedValues, setCheckedValues] = useState<string[]>([]);

  const { t } = useTranslation();

  const beacons = useAppSelector(s => s.Beacons.brandBeacons);

  const handleCheckboxChange = (values: string[]) => {
    setCheckedValues(values);
    form.resetFields(['UserIds'] as (keyof IBeaconRulesForm)[]);
    form.setFieldsValue({ BeaconIds: findBeaconIds(values) });
  };

  const handleSelect = (value: string) => {
    if (value === SelectKind.SELECT_ALL) {
      handleCheckboxChange(treeData.map(item => item.key as string));
    }

    if (value === SelectKind.UNSELECT_ALL) {
      handleCheckboxChange([]);
    }
  };

  const calculateTreeData = (): TreeDataNode[] => {
    const newTreeData = [];

    const storeBeacons: IStoreBeacon[] = JSON.parse(
      JSON.stringify(beacons.data)
    );

    const sortedStoreBeacons = storeBeacons.sort((a, b) =>
      a.Name.localeCompare(b.Name)
    );

    for (const storeBeacon of sortedStoreBeacons) {
      newTreeData.push({
        title: storeBeacon.Name,
        value: '0-' + storeBeacon.Id,
        key: '0-' + storeBeacon.Id,
        children: storeBeacon.Beacons.map(b => ({
          title: b.Name + ` (${b.Id})`,
          value: '1-' + b.Id,
          key: '1-' + b.Id,
        })).sort((a, b) => (a.title >= b.title ? 1 : -1)),
      });
    }

    return newTreeData.sort((a, b) => (a.title >= b.title ? 1 : -1));
  };

  function findBeaconIds(values: string[]) {
    if (beacons.status !== Status.success) {
      return [];
    }

    return values.reduce((acc, value) => {
      try {
        const [entityKind, id] = value.split('-');

        const entity = entityKind === '0' ? 'store' : 'beacon';

        switch (entity) {
          case 'store':
            const beaconStore = beacons.data.find(b => b.Id === Number(id));
            if (beaconStore) {
              acc.push(...beaconStore.Beacons.map(b => b.Id));
            }
            return acc;

          case 'beacon':
            acc.push(Number(id));
            return acc;

          default:
            return acc;
        }
      } catch {
        return acc;
      }
    }, [] as number[]);
  }

  const treeData = useMemo(() => {
    if (beacons.status !== Status.success) {
      return [];
    }

    return calculateTreeData();
  }, [beacons.status]);

  const allSelected = beacons.data?.every(storeBeacon =>
    checkedValues.includes('0-' + storeBeacon.Id)
  );

  return (
    <>
      <Form.Item name="BeaconIds" noStyle />
      <Form.Item label={t('beacons')}>
        <TreeSelect
          treeData={[
            {
              title: allSelected ? t('unselectAll') : t('selectAll'),
              value: allSelected
                ? SelectKind.UNSELECT_ALL
                : SelectKind.SELECT_ALL,
            },
            ...treeData,
          ]}
          value={checkedValues}
          onSelect={handleSelect}
          onChange={handleCheckboxChange}
          allowClear
          treeCheckable={true}
          showCheckedStrategy={TreeSelect.SHOW_PARENT}
          showSearch={false}
          maxTagCount={2}
          disabled={disabled}
          loading={beacons.status === Status.pending}
        />
      </Form.Item>
    </>
  );
}
