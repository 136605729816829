import { createReducer } from '@reduxjs/toolkit';
import { clearTicket, getTicketDetails, getTickets } from './action';
import { asyncFetchInterface } from '@Types/ReduxTypes';
import { GetTicketsResponse, ITicketDetails } from '@Types/ticket.types';
import Status from '@Enums/Status';
import { CLEAR_TICKET } from './type';

interface State {
  tickets: asyncFetchInterface<GetTicketsResponse>;
  ticket: asyncFetchInterface<ITicketDetails>;
}

const initialState: State = {
  tickets: {
    data: {} as GetTicketsResponse,
    status: Status.empty,
  },
  ticket: {
    data: {} as ITicketDetails,
    status: Status.empty,
  },
};

const TicketReducer = createReducer(initialState, builder => {
  builder
    .addCase(getTickets.pending, state => {
      state.tickets = {
        ...state.tickets,
        status: Status.pending,
        ErrorMessage: undefined,
      };
    })
    .addCase(getTickets.fulfilled, (state, action) => {
      state.tickets.data = action.payload as GetTicketsResponse;
      state.tickets.status = Status.success;
    })
    .addCase(getTickets.rejected, (state, action) => {
      state.tickets = {
        ...state.tickets,
        data: {} as GetTicketsResponse,
        status: Status.error,
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getTicketDetails.pending, state => {
      state.ticket = {
        ...state.ticket,
        status: Status.pending,
        ErrorMessage: undefined,
      };
    })
    .addCase(getTicketDetails.fulfilled, (state, action) => {
      state.ticket.data = action.payload as ITicketDetails;
      state.ticket.status = Status.success;
    })
    .addCase(getTicketDetails.rejected, (state, action) => {
      state.ticket = {
        ...state.ticket,
        data: {} as ITicketDetails,
        status: Status.error,
        ErrorMessage: action.error.message,
      };
    })
    .addCase(clearTicket, state => {
      state.ticket = {
        ...state.ticket,
        data: {} as ITicketDetails,
        status: Status.empty,
        ErrorMessage: undefined,
      };
    });
});

export default TicketReducer;
