// Record status for assign color and variant
import { ReactNode } from 'react';

// Usteps base props
interface UStepsProps {
  prefixCls?: string;
  children: ReactNode;
}
/**
 * @description
 * Auto resizes by parent
 * @param UStepsProps
 * @returns JSX
 */
const USteps = ({ children, prefixCls = 'UD' }: UStepsProps) => {
  const baseCls = `${prefixCls}-USteps-container`;
  return <div className={baseCls}>{children}</div>;
};

export default USteps;
