import { LockOutlined } from '@ant-design/icons';
import DeleteButton from '@Components/Card/DeleteButton';
import EditButton from '@Components/Card/EditButton';
import CardButton from '@Components/CardButton';
import { SetNewPasswordFormValues } from '@Forms/SetNewPasswordForm';
import useBoolean from '@Hooks/useBoolean';
import SetNewPasswordModal from '@Modals/SetNewPasswordModal';
import UserService from '@Services/Api/User';
import { UserFetchResponse } from '@Types/User';
import { apiCall } from '@Utils/index';
import { Space } from 'antd';

interface Props {
  user: UserFetchResponse;
  getUsers: () => void;
}

export default function UserActionButtons({ user, getUsers }: Props) {
  const newPasswordModalState = useBoolean();

  const onDeleteModalConfirm = async () => {
    try {
      await new UserService().DeleteUser(user.Id);
      getUsers();
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitSetNewPassword = async (values: SetNewPasswordFormValues) => {
    const req = () =>
      new UserService().SetNewPassword({ ...values, UserId: user.Id });
    try {
      await apiCall(req, 'setNewPassword');
      newPasswordModalState.setFalse();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Space direction="horizontal" size="small" wrap>
      <EditButton to={`${user.Id}`} />
      <DeleteButton onConfirm={onDeleteModalConfirm} />
      <CardButton
        icon={<LockOutlined />}
        onClick={newPasswordModalState.setTrue}
        tooltipKey="setNewUserPassword"
      />
      <SetNewPasswordModal
        modalState={newPasswordModalState}
        onConfirm={onSubmitSetNewPassword}
      />
    </Space>
  );
}
