import { Permissions as PermissionsEnum } from '@Enums/Permission';
import useIsEnvAdmin from '@Hooks/useIsEnvAdmin';
import usePermissions from '@Hooks/usePermissions';
import { Application, Category, Product } from '@Types/Permission';
import { Checkbox, Divider, Form, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Title from 'antd/lib/typography/Title';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import RenderCategories from './RenderCategories';
import RenderPermissions from './RenderPermissions';
import RenderProducts from './RenderProducts';

interface Props {
  intlValues: {
    RoleName: string | undefined;
    Permissions: string[];
  };
  onCheckboxChange: (e: CheckboxChangeEvent) => void;
  values: string[];
}

const Permissions = ({ intlValues, onCheckboxChange, values }: Props) => {
  const { sortedData } = usePermissions();
  const { t } = useTranslation();
  const isEnvAdmin = useIsEnvAdmin();

  function filterVisibleToAdmin({ onlyVisibleToAdmins }: Application) {
    // Show all applications if onlyVisibleToAdmins is not set (means no need for filtering for those apps)
    if (!onlyVisibleToAdmins) return true;

    // Check if current user is an env admin
    return isEnvAdmin;
  }

  return (
    <Form.Item name="Permissions">
      {sortedData.filter(filterVisibleToAdmin).map(app => {
        let content = <></>;
        switch (app.contentType) {
          case 'product':
            content = (
              <RenderProducts
                products={app.content as Product[]}
                onCheckboxChange={onCheckboxChange}
              />
            );
            break;
          case 'category':
            content = (
              <Row>
                <RenderCategories
                  categories={app.content as Category[]}
                  onCheckboxChange={onCheckboxChange}
                />
              </Row>
            );
            break;
          case 'permission':
            content = (
              <Row>
                <RenderPermissions
                  permissions={app.content as PermissionsEnum[]}
                  onCheckboxChange={onCheckboxChange}
                />
              </Row>
            );
            break;
        }
        return (
          <Fragment key={app.name}>
            <Title level={3}>{t(app.name)}</Title>
            <Checkbox.Group
              defaultValue={intlValues.Permissions}
              value={values}
              className="checkbox"
            >
              {content}
            </Checkbox.Group>
            <Divider />
          </Fragment>
        );
      })}
    </Form.Item>
  );
};

export default Permissions;
