import ModalFooter from '@Components/ModalFooter';
import { IBrandGroupForm } from '@Types/BrandGroup';
import { Form, FormProps, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.scss';

type Props = FormProps<IBrandGroupForm> & {};

export function BrandGroupForm(props: Props) {
  const { ...restProps } = props;

  const [form] = Form.useForm<IBrandGroupForm>();

  const { t } = useTranslation();

  return (
    <Form
      id="brand-group-form"
      className="brand-group-form"
      form={form}
      labelCol={{ span: 8 }}
      labelWrap
      labelAlign="left"
      wrapperCol={{ span: 24 }}
      {...restProps}
    >
      <Form.Item name="Name" label={t('name')} rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <ModalFooter
        sendButtonText={t('save')}
        formKey="brand-group-form"
        hideGoBackButton
      />
    </Form>
  );
}
