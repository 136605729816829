import {
  ChangeActiveFileForm,
  ChangeActiveFileFormProps,
  IChangeActiveFileForm,
} from '@Features/versions/forms';
import { UseBooleanResult } from '@Hooks/useBoolean';
import VersionService from '@Services/Api/Version';
import { useAppDispatch } from '@Store/hooks';
import { getVersionFileGroups } from '@Store/Version/action';
import { apiCall } from '@Utils/index';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

interface IProps extends ChangeActiveFileFormProps {
  modalState: UseBooleanResult;
}

export function UpdateActiveFileModal(props: IProps) {
  const { modalState, ...restProps } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  async function handleSubmit(values: IChangeActiveFileForm) {
    try {
      await apiCall(() =>
        new VersionService().ChangeActiveFile({
          componentId: props.versionFileGroup.Id,
          ...values,
        })
      );
      dispatch(getVersionFileGroups({ type: props.versionFileGroup.Type }));
      modalState.setFalse();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Modal
      title={t('changeActiveFile')}
      open={modalState.value}
      onOk={modalState.setFalse}
      onCancel={modalState.setFalse}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
      footer={false}
      destroyOnClose
    >
      <ChangeActiveFileForm
        onFinish={handleSubmit}
        initialValues={{ fileId: props.versionFileGroup.ActiveFile?.Id }}
        {...restProps}
      />
    </Modal>
  );
}
