import Status from '@Enums/Status';
import UserService from '@Services/Api/User';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { setUserDetails } from '@Store/User/action';
import { FilterItem } from '@Types/Pagination';
import { IUserConfig } from '@Types/User';
import { useMemo } from 'react';

type PageConfig = {
  orderBy: 'asc' | 'desc';
  sortKey: string;
  filters?: { field: FilterItem['field']; value: FilterItem['value'] }[];
};

export type UserPageConfigResult = {
  onPageConfigChange: (value: Partial<PageConfig>) => void;
  pageConfig: PageConfig;
};

export function useUserPageConfig(
  pageName: string,
  defaultConfig: PageConfig
): UserPageConfigResult {
  const userDetails = useAppSelector(s => s.User.currentUserDetails);

  const userConfig = useMemo(() => {
    if (
      userDetails.status !== Status.success ||
      !userDetails?.data?.PanelConfig
    )
      return undefined;

    let parsedPanelConfig;
    try {
      const parsed = JSON.parse(
        userDetails.data.PanelConfig.replace(/\s/g, '') // Remove whitespaces
      );
      parsedPanelConfig = parsed;
    } catch (e) {
      return undefined;
    }

    if (typeof parsedPanelConfig !== 'object') return undefined;

    return parsedPanelConfig as IUserConfig;
  }, [userDetails.status, userDetails.data]);

  const dispatch = useAppDispatch();

  const pageConfig =
    userConfig?.adminPanel?.pageConfigs &&
    userConfig?.adminPanel?.pageConfigs[pageName];

  async function handlePageConfigChange(values: Partial<PageConfig>) {
    const { orderBy, sortKey, filters } = values;

    const newUserConfig: IUserConfig = {
      ...userConfig,
      adminPanel: {
        ...userConfig?.adminPanel,
        pageConfigs: {
          ...userConfig?.adminPanel?.pageConfigs,
          [pageName]: {
            ...pageConfig,
            orderBy: orderBy || pageConfig?.orderBy || defaultConfig.orderBy,
            sortKey: sortKey || pageConfig?.sortKey || defaultConfig.sortKey,
            filters: filters || pageConfig?.filters || defaultConfig.filters,
          },
        },
      },
    };

    // No need to send the new user config if the new config is the same with the old config
    if (JSON.stringify(userConfig) !== JSON.stringify(newUserConfig)) {
      await new UserService().SetPanelConfig(JSON.stringify(newUserConfig));
      const user = await new UserService().GetDetailsV2();
      dispatch(setUserDetails(user));
    }
  }

  if (!pageConfig) {
    return {
      onPageConfigChange: handlePageConfigChange,
      pageConfig: defaultConfig,
    };
  }

  return {
    onPageConfigChange: handlePageConfigChange,
    pageConfig: {
      orderBy: pageConfig.orderBy,
      sortKey: pageConfig.sortKey,
      filters: pageConfig.filters,
    },
  };
}
