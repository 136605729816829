import { useAppSelector } from '@Store/hooks';
import { Navigate, useLocation } from 'react-router-dom';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  let account = useAppSelector(state => state.Account.Account.data);
  let location = useLocation();

  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  if (!account.access_token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
