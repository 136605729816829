import { Variant } from '@Enums/Status';
import { HTMLAttributes, ReactElement, ReactNode } from 'react';

// Ustep base props
interface UStepRecord extends HTMLAttributes<HTMLDivElement> {
  prefixCls?: string;
  maxRecord: number;
  payload: {
    value: number;
    type: Variant;
    [key: string]: any;
  };
  content: ReactElement | string;
}

/**
 * @description Usteps child bar item.
 * Auto resizes by parent
 * @param UStepRecord
 * @returns JSX
 */
const UStep = ({
  prefixCls = 'UD',
  maxRecord = 0,
  payload,
  content,
  ...props
}: UStepRecord) => {
  const { value, type } = payload;
  const baseCls = `${prefixCls}-UStep-child`;
  const variantCls = `${prefixCls}-UStep-variant-${type}`;
  const widthPercent = maxRecord === 0 ? 100 : (value / maxRecord) * 100;

  return (
    <div
      {...props}
      className={`${baseCls} ${variantCls}`}
      style={{
        width: `${widthPercent}%`,
      }}
    >
      {/* {content} */}
    </div>
  );
};

export default UStep;
