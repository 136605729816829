import { Table, Empty, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';

interface Props<T> extends TableProps<T> {
  dataSource: any;
  columns: ColumnsType<any>;
  fetching?: boolean;
}

export default function DataDisplayTable<T>({
  dataSource,
  columns,
  fetching,
  ...restProps
}: Props<T>) {
  const { t } = useTranslation();

  return (
    <Table
      columns={columns.map(c => ({ align: 'center', ...c }))}
      dataSource={dataSource}
      size="small"
      loading={fetching}
      pagination={false}
      sticky
      locale={{
        emptyText: <Empty description={t('noData')} />,
      }}
      {...restProps}
    />
  );
}
