import { createContext, useState } from 'react';

export interface IRecordProcessContext {
  showConfigModal: boolean;
  setShowConfigModal: React.Dispatch<React.SetStateAction<boolean>>;
  showNoteModal: boolean;
  setShowNoteModal: React.Dispatch<React.SetStateAction<boolean>>;
  showResultsModal: boolean;
  setShowResultsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const INITIAL_STATE: IRecordProcessContext = {
  showConfigModal: false,
  setShowConfigModal: () => {},
  showNoteModal: false,
  setShowNoteModal: () => {},
  showResultsModal: false,
  setShowResultsModal: () => {},
};

export const RecordProcessContext =
  createContext<IRecordProcessContext>(INITIAL_STATE);

export interface IRecordProcessProvider {
  children: React.ReactNode;
}

export function RecordProcessProvider({ children }: IRecordProcessProvider) {
  const [showConfigModal, setShowConfigModal] = useState<boolean>(false);
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const [showResultsModal, setShowResultsModal] = useState<boolean>(false);

  return (
    <RecordProcessContext.Provider
      value={{
        showConfigModal,
        setShowConfigModal,
        showNoteModal,
        setShowNoteModal,
        showResultsModal,
        setShowResultsModal,
      }}
    >
      {children}
    </RecordProcessContext.Provider>
  );
}
