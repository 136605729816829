import {
  AllCamRecordsFetchResponse,
  CamRecordRequestBase,
  GetCamRecordsParams,
  NewCameraRecord,
  UpdateVersionStatus,
  UpdateRecordStatus,
  AddVersionConfig,
  NewCameraRecordMultipleDates,
  AssignTagsParams,
} from '@Types/CameraRecords';
import model from './index';

class CameraRecordsService extends model {
  constructor() {
    super();
    this.Prefix('CameraRecords');
  }

  /**
   * @param {GetCamRecordsParams} params
   * @description Get all camera records
   * @returns Promise
   */
  async GetAll(params: GetCamRecordsParams) {
    return this.Post(`GetAll${params.entityIds}`)
      .Data(params.filters)
      .offset()
      .Save<AllCamRecordsFetchResponse>();
  }

  /**
   * @param {NewCameraRecord} newCameraRecord
   * @description Add new camera record
   * @returns Promise
   */
  async AddCameraRecord(newCameraRecord: NewCameraRecord) {
    return this.Post('Add').Data(newCameraRecord).Save();
  }

  /**
   * @param {NewCameraRecordMultipleDates} newCameraRecord
   * @description Add new camera record
   * @returns Promise
   */
  async AddCameraRecordMultipleDates(
    newCameraRecord: NewCameraRecordMultipleDates
  ) {
    return this.Post('AddMultipleDates').Data(newCameraRecord).Save();
  }

  /**
   * @param {CamRecordRequestBase} params
   * @description Mark record as watched
   * @returns Promise
   */
  async MarkAsWatched(params: CamRecordRequestBase) {
    return this.PrefixId(params.recordId)
      .Post(`Versions/${params.versionId}/MarkAsWatchedCameraRecord`)
      .Save();
  }

  /**
   * @param {CamRecordRequestBase} params
   * @description Process an existing record with another version
   * @returns Promise
   */
  async RecordWithNewVersion(params: CamRecordRequestBase) {
    return this.PrefixId(params.recordId)
      .Post(`Versions/${params.versionId}/RecordWithNewVersion`)
      .Save();
  }

  /**
   * @param {UpdateVersionStatus} params
   * @description Update process status
   * @returns Promise
   */
  async SetProcessStatus(params: UpdateVersionStatus) {
    return this.PrefixId(params.recordId)
      .Post(
        `Versions/${params.versionId}/SetProcessStatus?status=${params.status}`
      )
      .Save();
  }

  /**
   * @param {UpdateRecordStatus} params
   * @description Update record status
   * @returns Promise
   */
  async SetRecordStatus(params: UpdateRecordStatus) {
    return this.PrefixId(params.recordId)
      .Post(
        `SetRecordStatus?start=${params.start}&end=${params.end}&status=${params.status}`
      )
      .Save();
  }

  /**
   * @param {CamRecordRequestBase} params
   * @description Re-do the labeling process with a version that was previously labeled
   * @returns Promise
   */
  async ResetCameraRecord(params: CamRecordRequestBase) {
    return this.PrefixId(params.recordId)
      .Post(`Versions/${params.versionId}/ResetCameraRecord`)
      .Save();
  }

  /**
   * @param {AddVersionConfig} params
   * @description Set the config of a version
   * @returns Promise
   */
  async AddConfigToVersion(params: AddVersionConfig) {
    return this.PrefixId(params.recordId)
      .Post(`Versions/${params.versionId}/SetConfig`)
      .Data(params.payload)
      .Save();
  }

  /**
   * @param {CamRecordRequestBase} params
   * @description Mark process as "sent to labeling"
   * @returns Promise
   */
  async RecordLabelingSent(params: CamRecordRequestBase) {
    return this.PrefixId(params.recordId)
      .Put(`Versions/${params.versionId}/RecordLabelingSent`)
      .Save();
  }

  /**
   * @param {CamRecordRequestBase} params
   * @description Mark process as "waiting for labeling"
   * @returns Promise
   */
  async RecordLabelingWaiting(params: CamRecordRequestBase) {
    return this.PrefixId(params.recordId)
      .Post(`Versions/${params.versionId}/RecordLabelingWaiting`)
      .Save();
  }

  /**
   * @param {number} recordId
   * @description Delete camera record
   * @returns Promise
   */
  async DeleteCameraRecord(recordId: number) {
    return this.Delete(recordId.toString()).Save();
  }

  /**
   * @param {AssignTagsParams} params
   * @description Assign tags to a camera record
   * @returns Promise
   */
  async AssignTags(recordId: number, params: AssignTagsParams) {
    return this.PrefixId(recordId).Post('Tags').Data(params).Save();
  }
}

export default CameraRecordsService;
