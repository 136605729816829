import { DateTimeParams } from '@Types/apiTypes';

/**
 * @description Converts a date-time object to api query string
 * @example {}
 */
export function convertDateTimeParam(paramObj: DateTimeParams): string {
  let queryString = '';
  const paramArray = Object.entries(paramObj);

  for (let i = 0; i < paramArray.length; i++) {
    const [paramKey, paramValue] = paramArray[i];
    queryString += `${i === 0 ? '?' : '&'}${paramKey}=${paramValue}`;
  }

  return queryString;
}

export function convertStoreIdsToQueryParam(storeIds: number[]): string {
  let storeIdsString = '';
  for (const storeId of storeIds) storeIdsString += storeId + ',';
  storeIdsString = storeIdsString.slice(0, -1);

  return storeIdsString;
}
