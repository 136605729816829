import BrandDetail from '@Forms/BrandDetail';
import { HomeOutlined } from '@ant-design/icons';
import FormCard from '@Components/FormCard';
import { useAppSelector } from '@Store/hooks';
import BrandService from '@Services/Api/Brands';
import { BrandEditForm, EditedBrand, NewBrand } from '@Types/Brand';
import { apiCall } from '@Utils/index';
import { useNavigate } from 'react-router-dom';

const AddBrand = () => {
  const udentifyUsers = useAppSelector(state => state.User.udentifyUsers.data);
  let navigate = useNavigate();

  const handleSubmit = async (brandForm: BrandEditForm) => {
    const editedBrand: EditedBrand = {
      Name: brandForm.Name,
      AccountManagerId: udentifyUsers.find(
        u => u.Email === brandForm.AccountManager
      )?.Id!,
      GroupId: brandForm.GroupId,
      AccountOperationManagerId: udentifyUsers.find(
        u => u.Email === brandForm.AccountOperationManager
      )?.Id!,
    };
    try {
      await apiCall(
        () => addNewBrand(editedBrand),
        'defaultProcess',
        () => navigate(-1)
      );
    } catch (e) {
      console.warn(e);
    }
  };

  const addNewBrand = async (newBrand: NewBrand) => {
    return await new BrandService().AddBrand(newBrand);
  };

  return (
    <FormCard
      titleIcon={<HomeOutlined />}
      titleKey="addBrand"
      className="brand-detail-wrapper"
    >
      <BrandDetail onFinish={handleSubmit} />
    </FormCard>
  );
};

export default AddBrand;
