import {
  AllVersion,
  AllVersionsFetchResponse,
  ChangeActiveFileParams,
  CreateVersionFileGroupPayload,
  EditedVersion,
  GetVersionFileGroupsParams,
  GetVersionParams,
  NewVersion,
  UpdateVersionFileGroupPayload,
  VersionFile,
  VersionFileUploadPayload,
} from '@Types/Version';
import model from './index';
class VersionService extends model {
  constructor() {
    super();
    this.Prefix('Versions');
  }

  /**
   *
   * @param newVersion
   * @description Add a new version
   * @returns Promise
   */
  async New(newVersion: NewVersion) {
    return this.Post('CodeVersions').Data(newVersion).Save();
  }

  /**
   * @param {GetVersionParams} params
   * @description Get all version records
   * @returns Promise
   */
  async GetAllVersions(params: GetVersionParams) {
    return this.Post(`CodeVersions/GetAll`)
      .Data(params.filters)
      .offset()
      .Save<AllVersionsFetchResponse>();
  }
  /**
   * @param {string} GetVersionParams
   * @description Get code version by  Id
   * @returns Promise
   */

  async GetCodeVersionsById(codeVersionId: number) {
    return this.Get(`CodeVersions/${codeVersionId}`)
      .offset()
      .Save<AllVersion>();
  }
  /**
   * @param {string} codeVersionId
   * @param {EditedVersion} editedVersion
   * @description Edit Version
   * @returns Promise
   */
  async EditVersion(codeVersionId: number, editedVersion: EditedVersion) {
    return this.Put(`CodeVersions/${codeVersionId}`).Data(editedVersion).Save();
  }

  async FileUpload({ file, ...params }: VersionFileUploadPayload) {
    if (file === undefined) {
      return Promise.reject('File not found!!');
    }

    return this.Params(params).Post(`/Files/Upload`).formData({ file }).Save();
  }

  /**
   *
   * @description Delete a file.
   * @returns Promise
   */
  async DeleteFile(field: number) {
    return this.PrefixId(`Files/${field}`).Delete().Save<any>();
  }

  /**
   *
   * @description Download a file.
   * @returns Promise
   */
  async DownloadVersion(hash: string) {
    return this.Get(`Files/${hash}/Download`)
      .ResponseFile()
      .offset()
      .Save({
        responseType: 'arraybuffer',
      } as any) // TODO: Needs reformat
      .then(this.DownloadFile);
  }

  /**
   * @description Delete a version.
   */
  async DeleteVersion(versionId: number) {
    return this.PrefixId(`CodeVersions/${versionId}`).Delete().Save();
  }

  async GetVersionFileGroups(params: GetVersionFileGroupsParams) {
    return this.Params(params).Get(`Components`).offset().Save<any>();
  }

  async ChangeActiveFile({ componentId, fileId }: ChangeActiveFileParams) {
    return this.Post(`Component/${componentId}/ActiveFile/${fileId}`)
      .offset()
      .Save();
  }

  async CreateVersionFileGroup(payload: CreateVersionFileGroupPayload) {
    return this.Post(`Component`).Data(payload).offset().Save();
  }

  async UpdateVersionFileGroup(payload: UpdateVersionFileGroupPayload) {
    return this.Put(`Component`).Data(payload).offset().Save();
  }

  async DeleteVersionFileGroup(componentId: number) {
    return this.Delete(`Component/${componentId}`).offset().Save();
  }

  async GetVersions(type: number, all: boolean) {
    return this.Get('Files')
      .Params({ type, all: all ? 1 : 0 })
      .offset()
      .Save<VersionFile[]>();
  }
}

export default VersionService;
