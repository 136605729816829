import { createReducer } from '@reduxjs/toolkit';
import { onSidebarToggle, setHeaderTitle } from './action';

interface initialStateInterface {
  isSidebarCollapsed: boolean;
  headerTitle: string | undefined;
}

const initialState: initialStateInterface = {
  isSidebarCollapsed: false,
  headerTitle: undefined,
};

const DashboardReducer = createReducer(initialState, builder => {
  builder
    .addCase(onSidebarToggle, (state, action) => {
      state.isSidebarCollapsed = action.payload;
    })
    .addCase(setHeaderTitle, (state, action) => {
      state.headerTitle = action.payload;
    });
});

export default DashboardReducer;
