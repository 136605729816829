import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { Select, Input, Form, Radio, Button, Space } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllAvailableBrands } from '@Store/Brand/action';
import { Brand } from '@Types/Brand';
import { getAllStoresOfBrands, resetAllStores } from '@Store/Store/action';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { useParams } from 'react-router-dom';
import Status from '@Enums/Status';
import './style.scss';
import SearchableSelect from '@Components/SearchableSelect';

export interface IDataForm {
  brandId?: number;
  storeIds?: number[];
  filterNumber: number;
  period: number;
}

type Props = { onFinish?: (values: IDataForm) => void };

export default function SelectedFilters({ onFinish }: Props) {
  const brands = useAppSelector(s => s.Brand.allAvailableBrands);
  const stores = useAppSelector(s => s.Store.allStores);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedBrand, setSelectedBrand] = useState<Brand>();
  const [form] = useForm<IDataForm>();
  const { storeId, cameraId, beaconId } = useParams();
  const storeIdsValue = useWatch('storeIds', form);
  const brandIdValue = useWatch('brandId', form);

  useEffect(() => {
    dispatch(getAllAvailableBrands());
  }, []);

  useEffect(() => {
    form.setFieldsValue({ storeIds: [] });
    dispatch(resetAllStores());

    if (selectedBrand) {
      dispatch(getAllStoresOfBrands([selectedBrand.Id]));
    }
  }, [selectedBrand?.Id]);

  const handleBrandChange = (brandId: number) => {
    form.setFieldsValue({ storeIds: [] });

    if (brandId) {
      setSelectedBrand(brands.data.find(b => b.Id === brandId));
    } else {
      dispatch(resetAllStores());
    }
  };

  const selectAllStoresHandler = useCallback(() => {
    if (
      !storeIdsValue ||
      !Array.isArray(storeIdsValue) ||
      stores.status !== Status.success
    )
      return;
    // Unselect all stores
    if (storeIdsValue.length === stores.data.length) {
      form.setFieldsValue({ storeIds: [] });
      return;
    }
    // Select all stores
    form.setFieldsValue({ storeIds: stores.data.map(store => store.Id) });
  }, [storeIdsValue, stores.status]);

  const disableSendButton = useMemo(() => {
    if (cameraId || storeId) return false;
    return (
      !brandIdValue ||
      !storeIdsValue ||
      (Array.isArray(storeIdsValue) && storeIdsValue.length === 0)
    );
  }, [storeIdsValue, brandIdValue, cameraId]);

  return (
    <Form
      name="customized_form_controls"
      layout="inline"
      onFinish={onFinish}
      form={form}
    >
      {!(storeId || cameraId || beaconId) && (
        <Space direction="horizontal" size="small">
          <Form.Item required name="brandId">
            <SearchableSelect
              placeholder={t('selectBrand')}
              allowClear
              showSearch
              onChange={handleBrandChange}
            >
              {brands.data.map(brand => (
                <Select.Option
                  key={brand.Id}
                  value={brand.Id}
                  label={brand.Name}
                >
                  {brand.Name}
                </Select.Option>
              ))}
            </SearchableSelect>
          </Form.Item>
          <Form.Item required name="storeIds">
            <SearchableSelect
              placeholder={t('selectStore')}
              allowClear
              showSearch
              mode="multiple"
              loading={stores.status === Status.pending}
              maxTagCount={2}
            >
              {stores.data.map(store => (
                <Select.Option
                  key={store.Id}
                  value={store.Id}
                  label={store.Name}
                >
                  {store.Name}
                </Select.Option>
              ))}
            </SearchableSelect>
          </Form.Item>
          <Button
            type="ghost"
            htmlType="button"
            onClick={selectAllStoresHandler}
            disabled={!selectedBrand?.Id}
          >
            {t('selectAll')}
          </Button>
        </Space>
      )}
      <Form.Item
        className="inputName"
        name="filterNumber"
        label={' Son: '}
        initialValue={48}
      >
        <Input type={'number'} />
      </Form.Item>
      <Form.Item name="period" initialValue={0}>
        <Radio.Group>
          <Radio value={0}>{t('hour')}</Radio>
          <Radio value={1}>{t('day')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          size={'middle'}
          htmlType="submit"
          className="login-form-button"
          disabled={disableSendButton}
        >
          {t('submit')}
        </Button>
      </Form.Item>
    </Form>
  );
}
