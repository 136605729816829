import ModalFooter from '@Components/ModalFooter';
import { getAllAvailableBrands } from '@Store/Brand/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getAllStoresOfBrands } from '@Store/Store/action';
import { AllGateway } from '@Types/Gateway';
import { Brand } from '@Types/Brand';
import { Checkbox, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import PageSkeletons from '@Components/PageSkeletons';
import SearchableSelect from '@Components/SearchableSelect';
import Status from '@Enums/Status';

type Props = {
  onFinish?: (values: any) => void;
  gatewayData?: AllGateway;
};
export default function GatewayForm({ onFinish, gatewayData }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const brands = useAppSelector(s => s.Brand.allAvailableBrands);
  const [selectedBrand, setSelectedBrand] = useState<Brand>();
  const stores = useAppSelector(s => s.Store.allStores);

  useEffect(() => {
    dispatch(getAllAvailableBrands());
  }, []);
  const handleBrandChange = (brandId: number) => {
    if (brandId) {
      setSelectedBrand(brands.data.find(b => b.Id === brandId));
    }
  };
  useEffect(() => {
    if (selectedBrand) {
      dispatch(getAllStoresOfBrands([selectedBrand.Id]));
    }
  }, [selectedBrand?.Id]);
  useEffect(() => {
    if (gatewayData?.BrandId) {
      dispatch(getAllStoresOfBrands([gatewayData.BrandId]));
    }
  }, [gatewayData]);
  const passiveCheckboxHandler = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;
    form.setFieldsValue({ Setup: isChecked });
  };
  if (gatewayData?.StoreId) {
    var defaultBrand = stores.data.find(
      i => i.Id == gatewayData?.StoreId
    )?.BrandId;
  }
  if (stores.status === 'Pending' && brands.status === 'Pending')
    return <PageSkeletons />;
  return (
    <Form
      form={form}
      name="user-add-form"
      id="user-add-form"
      onFinish={onFinish}
      className="user-add-container"
      initialValues={gatewayData}
    >
      {!gatewayData && (
        <>
          <Form.Item label={t('brand')} required name={'BrandId'}>
            <SearchableSelect
              placeholder={t('selectBrand')}
              allowClear
              showSearch
              onChange={handleBrandChange}
              defaultValue={defaultBrand}
              loading={brands.status === Status.pending}
            >
              {brands.data.map(brand => (
                <Select.Option
                  key={brand.Id}
                  value={brand.Id}
                  label={brand.Name}
                >
                  {brand.Name}
                </Select.Option>
              ))}
            </SearchableSelect>
          </Form.Item>
          <Form.Item label={t('store')} required name={'StoreId'}>
            <SearchableSelect
              placeholder={t('selectStore')}
              allowClear
              showSearch
              loading={stores.status === Status.pending}
              // value={stores.data.map(s => s.Id === gatewayData?.StoreId)}
            >
              {stores.data.map(store => (
                <Select.Option
                  key={store.Id}
                  value={store.Id}
                  label={store.Name}
                >
                  {store.Name}
                </Select.Option>
              ))}
            </SearchableSelect>
          </Form.Item>
        </>
      )}
      <Form.Item label={t('externalId')} name="ExternalId">
        <Input type="number" />
      </Form.Item>
      <Form.Item label={'Kurulu mu ?'} name="Setup">
        <Checkbox
          onChange={passiveCheckboxHandler}
          defaultChecked={gatewayData?.Setup}
        />
      </Form.Item>

      <ModalFooter formKey="user-add-form" />
    </Form>
  );
}
