import { Tooltip } from 'antd';
import Title, { TitleProps } from 'antd/lib/typography/Title';

interface IProps extends TitleProps {
  text?: string;
}

export function FirstItemText({ text, ...restProps }: IProps) {
  return (
    <Tooltip title={text}>
      <Title level={5} className="first-item-text" ellipsis {...restProps}>
        {text}
      </Title>
    </Tooltip>
  );
}
