import { Popconfirm as AntdPopconfirm, PopconfirmProps } from 'antd';
import { useTranslation } from 'react-i18next';

interface IPopconfirmProps extends Omit<PopconfirmProps, 'title'> {
  title?: React.ReactNode;
}

export function Popconfirm({
  children,
  title,
  ...restProps
}: IPopconfirmProps) {
  const { t } = useTranslation();

  return (
    <AntdPopconfirm
      title={title ?? t('areYouSure')}
      okText={t('yes')}
      cancelText={t('no')}
      {...restProps}
    >
      {children}
    </AntdPopconfirm>
  );
}
