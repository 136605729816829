import { setHeaderTitle } from '@Store/Dashboard/action';
import { useAppDispatch } from '@Store/hooks';
import { useEffect } from 'react';

export function useSetHeaderTitle(title: string) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setHeaderTitle(title));

    return () => {
      dispatch(setHeaderTitle());
    };
  }, [title, dispatch]);
}
