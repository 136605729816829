import { GetNextPortResult } from '@Types/Mqtt';
import model from './index';

class MqttService extends model {
  constructor() {
    super();
    this.Prefix('Mqtt');
  }

  /**
   * @description Get next available port
   * @returns Promise
   */
  async GetNextPort() {
    return this.Get(`GetNextPort`).Save<GetNextPortResult>();
  }
}

export default MqttService;
