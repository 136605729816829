import {
  IVersionFileGroupForm,
  VersionFileGroupForm,
  VersionFileGroupFormProps,
} from '@Features/versions/forms';
import { UseBooleanResult } from '@Hooks/useBoolean';
import VersionService from '@Services/Api/Version';
import { useAppDispatch } from '@Store/hooks';
import { getVersionFileGroups } from '@Store/Version/action';
import { apiCall } from '@Utils/index';
import { message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

interface IProps extends VersionFileGroupFormProps {
  modalState: UseBooleanResult;
}

export function UpdateVersionFileGroupModal(props: IProps) {
  const { modalState, versionFileGroup, ...restProps } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  async function handleSubmit(values: IVersionFileGroupForm) {
    if (!versionFileGroup) {
      message.error('Version file group not found!');
      return;
    }

    try {
      await apiCall(() =>
        new VersionService().UpdateVersionFileGroup({
          ...versionFileGroup,
          ...values,
        })
      );
      dispatch(getVersionFileGroups({ type: versionFileGroup.Type }));
      modalState.setFalse();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Modal
      title={t('updateVersionFormGroup')}
      open={modalState.value}
      onOk={modalState.setFalse}
      onCancel={modalState.setFalse}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
      footer={false}
      destroyOnClose
    >
      <VersionFileGroupForm
        onFinish={handleSubmit}
        versionFileGroup={versionFileGroup}
        {...restProps}
      />
    </Modal>
  );
}
