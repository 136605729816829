import {
  AllRegionFetchResponse,
  AllRegions,
  EditRegion,
  IRegion,
} from '@Types/Region';
import model from './index';
import { PaginationParams } from '@Types/Filter';
import { PaginationData } from '@Types/index';

class RegionsService extends model {
  constructor() {
    super();
    this.Prefix('Regions');
  }

  /**
   * @param data
   * @description Connecting Brand Region
   * @returns Promise
   */
  async CreateRegionBrand(data: IRegion) {
    return this.Post().Data(data).offset().Save();
  }

  /**
   * @param {number} regionId
   * @description Delete region
   * @returns Promise
   */
  async DeleteRegion(regionId: number) {
    return this.Delete(regionId.toString()).Save();
  }

  /**
   *
   * @param {number} regionId
   * @param {EditRegion} editRegion
   * @description Update region
   * @returns Promise
   */
  async UpdateRegion(regionId: number, editRegion: EditRegion) {
    return this.PrefixId(regionId).Put().Data(editRegion).Save();
  }

  /**
   * @param params
   * @description Get all regions
   * @returns Promise
   */
  async GetAllRegions(params: PaginationParams) {
    return this.Post('Getall')
      .Data(params)
      .offset()
      .Save<AllRegionFetchResponse>();
  }
}

export default RegionsService;
