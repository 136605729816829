export enum BoardColor {
  White = 0,
  Black = 1,
  Red = 2,
}

export enum BoardRaySpotType {
  Trifaze = 0,
  Monofaze = 1,
}

export enum BoardAdapterBrand {
  Cisco = 0,
  Dramex = 1,
  Rasp_3_Original = 2,
  Rasp_4_Original = 3,
}
