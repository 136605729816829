import {
  AllHardware,
  BoardBrandCamera,
  CreateBoardBrandModelCameraPayload,
  NewHardware,
} from '@Types/Hardware';
import model from './index';
import { PaginationParams } from '@Types/Filter';

class BoardBrandModelService extends model {
  constructor() {
    super();
    this.Prefix('BoardBrandModel');
  }

  /**
   * @param data
   * @description Add hardware name
   * @returns Promise
   */
  async CreateBoardBrandModel(name: NewHardware) {
    return this.Post().Data(name).offset().Save();
  }

  /**
   * @param {number} hardwareId
   * @description Delete region
   * @returns Promise
   */
  async DeleteHardware(hardwareId: number) {
    return this.Delete(hardwareId.toString()).Save();
  }

  /**
   *
   * @param {number} hardwareId
   * @param {NewHardware} editHardware
   * @description Update hardware
   * @returns Promise
   */
  async UpdateHardware(hardwareId: number, editHardware: NewHardware) {
    return this.PrefixId(hardwareId).Put().Data(editHardware).Save();
  }

  /**
   * @param {number} hardwareId
   * @description Get hardware for id
   * @returns Promise
   */
  async GetHardware(hardwareId: number) {
    return this.Get(`${hardwareId}`).offset().Save<AllHardware>();
  }
  /**
   * @param params
   * @description Get all hardware
   * @returns Promise
   */
  async GetAllHardware(params: PaginationParams) {
    return this.Post('GetAll').Data(params).offset().Save<AllHardware>();
  }

  async CreateBoardBrandModelCamera(
    payload: CreateBoardBrandModelCameraPayload
  ) {
    return this.Post('CreateBoardBrandModelCamera').Data(payload).Save();
  }

  async GetBoardBrandCameras() {
    return this.Post('GetBoardBrandCameras')
      .offset()
      .Save<BoardBrandCamera[]>();
  }
}

export default BoardBrandModelService;
