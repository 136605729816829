import {
  createContext,
  ReactElement,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { Coordinates, shapeTypes } from '../Types/Bases';
import { ShapeObject } from '../Types/Shape';
import { ShapesType } from '../Types/Svg';
import { baseColor, DrawElements } from '../Constants';
import { ParamContext } from './Params';

export interface SvgContextType {
  Shapes: ShapesType;
  setShapes: (shapes: ShapesType, newShape: ShapeObject) => void;
  editable: boolean;
  setEditable: (editable: boolean) => void;
  createPolygon: (points: Coordinates) => void;
  movePoint: (
    Shape: ShapeObject,
    Point: number,
    newCorner: Coordinates
  ) => void;
  updateShape: (newShape: ShapeObject) => void;
  deleteDrawing: (deletedShape: ShapeObject) => void;
}

interface SvgContextProviderProps {
  children: ReactElement;
  ShapeOnChange?: (shape: ShapesType, newShape: ShapeObject) => void;
  defaultShapes?: ShapesType;
}

export const SvgContext = createContext<SvgContextType>({
  Shapes: [],
  setShapes: ([]) => {},
  editable: false,
  setEditable: () => {},
  createPolygon: () => {},
  movePoint: (Shape: ShapeObject, Point: number, newCorner: Coordinates) => {},
  updateShape: (newShape: ShapeObject) => {},
  deleteDrawing: (deletedShape: ShapeObject) => {},
});

export const SvgContextProvider = ({
  children,
  ShapeOnChange = () => {},
  defaultShapes = [],
}: SvgContextProviderProps) => {
  const { drawType } = useContext(ParamContext);

  const [Shapes, setShapes] = useState<ShapesType>(defaultShapes);
  const [editable, setEditable] = useState<boolean>(false);
  const _ShapeOnChange = (
    updateShapes: SetStateAction<ShapesType>,
    newShape: ShapeObject
  ) => {
    return setShapes(prevState => {
      const newShapes =
        typeof updateShapes === 'function'
          ? updateShapes(prevState)
          : updateShapes;
      ShapeOnChange(newShapes, newShape);
      return newShapes;
    });
  };
  const createPolygon = (points: Coordinates) => {
    const newShape: ShapeObject = {
      Id: new Date().getTime(),
      Name: 'Untitled',
      Points: [points],
      Color: baseColor,
      created: true,
      isCreate: true,
      Type: drawType !== undefined ? drawType : DrawElements.Polygon,
    };
    _ShapeOnChange(prevShapes => prevShapes.concat([newShape]), newShape);
  };

  const movePoint = (
    Shape: ShapeObject,
    Point: number,
    newCorner: Coordinates
  ) => {
    if (Point === null) return false;
    const ShapeIdx = Shapes.findIndex(
      s => s.Type === Shape.Type && s.Id === Shape.Id
    );
    if (ShapeIdx === -1) {
      return false;
    }
    let newShapes = Shapes.concat([]);
    newShapes[ShapeIdx].Points[Point] = newCorner;
    newShapes[ShapeIdx].moved = true;
    _ShapeOnChange(newShapes, newShapes[ShapeIdx]);
  };

  const updateShape = (newShape: ShapeObject) => {
    let newShapes = Shapes.concat([]);
    const ShapeIdx = newShapes.findIndex(
      s => s.Type === newShape.Type && s.Id === newShape.Id
    );
    newShapes[ShapeIdx] = { ...newShape };
    newShapes[ShapeIdx].updated = true;
    _ShapeOnChange(newShapes, newShapes[ShapeIdx]);
  };
  const deleteDrawing = (deletedShape: ShapeObject) => {
    let newShapes = Shapes.concat([]);
    newShapes = newShapes.filter(
      s => !(s.Type === deletedShape.Type && s.Id === deletedShape.Id)
    );
    _ShapeOnChange(newShapes, deletedShape);
  };
  return (
    <SvgContext.Provider
      value={{
        setShapes: _ShapeOnChange,
        Shapes,
        setEditable,
        editable,
        createPolygon,
        movePoint,
        updateShape,
        deleteDrawing,
      }}
    >
      {children}
    </SvgContext.Provider>
  );
};
