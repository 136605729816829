import { createReducer } from '@reduxjs/toolkit';
import { getConfigSchemas } from './action';
import { asyncFetchInterface } from '@Types/ReduxTypes';
import Status from '@Enums/Status';
import { ConfigBase } from '@Types/Config';

interface stateInterface {
  configSchemas: asyncFetchInterface<ConfigBase[]>;
}

const initialState: stateInterface = {
  configSchemas: {
    data: [],
    status: 'Empty',
  },
};

const ConfigReducer = createReducer(initialState, builder => {
  builder
    .addCase(getConfigSchemas.pending, state => {
      state.configSchemas = {
        ...state.configSchemas,
        status: Status.pending,
        ErrorMessage: undefined,
      };
    })
    .addCase(getConfigSchemas.fulfilled, (state, action) => {
      state.configSchemas.data = action.payload;
      state.configSchemas.status = Status.success;
    })
    .addCase(getConfigSchemas.rejected, (state, action) => {
      state.configSchemas = {
        ...state.configSchemas,
        data: [],
        status: Status.error,
        ErrorMessage: action.error.message,
      };
    });
});

export default ConfigReducer;
