import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import {
  NEW_USER,
  ALL_USERS,
  SET_PAGINATION_INDEX,
  GET_CURRENT_USER,
  SET_SORT_BY_VALUE,
  SET_CUSTOM_FILTER_VALUE,
  SET_SELECTED_USER,
  GET_UDENTIFY_USERS,
  GET_USER_DETAILS,
  GET_PAGE_LOGS,
  GET_USER_STORES,
  RESET_USER_STORE,
  GET_STORE_USER,
  SET_USER_DETAILS,
} from './type';
import User from '../../Services/Api/User';
import {
  GetStoreUserParams,
  UserFetchResponse,
  NewUser,
  SortBy,
  UserDetail,
} from '@Types/User';

import { CustomFilter } from '@Types/Components';
import { PaginationParams } from '@Types/Filter';
import { AllPageLogsFetchResponse, PageLogsParams } from '@Types/PageLogs';
import UserService from '../../Services/Api/User';
import {
  createFilterBy,
  createOrderBy,
} from '@Components/PaginationFilter/pagination-filter-utils';
import { FilterOperation, OrderBy } from '@Enums/Filter';
import { defaultPaginationParams } from '@Hooks/usePagination.ts/constants';

export const createNewUser = createAsyncThunk(
  NEW_USER,
  async (newUser: NewUser) => {
    try {
      // await new User().New(newUser);
    } catch (err) {
      console.error(err);
    }
  }
);

export const getAllUsers = createAsyncThunk(
  ALL_USERS,
  async (params?: PaginationParams) => {
    try {
      const allUsers = await new User().GetAll(
        params || defaultPaginationParams
      );
      return allUsers;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getUdentifyUsers = createAsyncThunk(
  GET_UDENTIFY_USERS,
  async () => {
    try {
      const allUsers = await new User().GetAll({
        PageIndex: 1,
        PageSize: 200,
        FilterBy: [
          createFilterBy('Brand.Name', FilterOperation.Equal, 'Udentify'),
        ],
        OrderBy: createOrderBy('Name', OrderBy.DESC),
      });
      return allUsers.Data;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getCurrentUser = createAsyncThunk(
  GET_CURRENT_USER,
  async (userId: string) => {
    try {
      const user = await new User().GetUser(userId);
      return user;
    } catch (err) {
      console.error(err);
      return {};
    }
  }
);

export const setPaginationIndex = createAction(
  SET_PAGINATION_INDEX,
  (newIndex: number) => ({ payload: newIndex })
);

export const setSortByValueOfUsers = createAction(
  SET_SORT_BY_VALUE,
  (value: string) => ({ payload: value as SortBy })
);

export const setCustomFilterOfUsers = createAction(
  SET_CUSTOM_FILTER_VALUE,
  (values: CustomFilter<SortBy>[]) => ({ payload: values })
);
export const setSelectedUser = createAction(
  SET_SELECTED_USER,
  (user: UserFetchResponse) => ({ payload: user })
);

export const getUserDetails = createAsyncThunk(GET_USER_DETAILS, async () => {
  try {
    const user = await new User().GetDetailsV2();
    return user;
  } catch (err) {
    console.error(err);
    return {};
  }
});

export const getPageLogs = createAsyncThunk(
  GET_PAGE_LOGS,
  async (params: PageLogsParams) => {
    try {
      const pageLogs = await new User().PageLogs(params);
      return pageLogs;
    } catch (err) {
      console.error(err);
      return {} as AllPageLogsFetchResponse;
    }
  }
);

export const getUserStores = createAsyncThunk(
  GET_USER_STORES,
  async (brandId: number) => {
    try {
      const userStores = await new UserService().GetUserStores(brandId);
      return userStores;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getStoreUsers = createAsyncThunk(
  GET_STORE_USER,
  async (params: GetStoreUserParams) => {
    try {
      if (!params.brandId) return [];
      const storeUsers = await new UserService().GetStoreUsers(params);
      return storeUsers;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const resetUserStores = createAction(RESET_USER_STORE);

export const setUserDetails = createAction(
  SET_USER_DETAILS,
  (userDetails: UserDetail) => ({
    payload: userDetails,
  })
);
