export const CAMERA_RECORD_PROCESS_KEYS = [
  'Id',
  'Status',
  'VersionId',
  'WatchedByUser',
  'IsWatched',
  'CurrLabelingState',
  'WatchedTime',
  'CreatedDate',
  'User',
];
