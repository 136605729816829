import { createReducer } from '@reduxjs/toolkit';
import { getAllRoles, getAllUserRoles } from './action';
import { asyncFetchInterface } from '../../Types/ReduxTypes';
import { Roles, UserPageRole } from '@Types/Role';

interface stateInterface {
  allRoles: asyncFetchInterface<Roles>;
  allUserRoles: asyncFetchInterface<UserPageRole[]>;
}

const initialState: stateInterface = {
  allRoles: {
    data: [],
    status: 'Empty',
  },
  allUserRoles: {
    data: [],
    status: 'Empty',
  },
};

const RoleReducer = createReducer(initialState, builder => {
  builder
    .addCase(getAllRoles.pending, state => {
      state.allRoles = {
        ...state.allRoles,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllRoles.fulfilled, (state, action) => {
      state.allRoles.data = action.payload;
      state.allRoles.status = 'fulfilled';
    })
    .addCase(getAllRoles.rejected, (state, action) => {
      state.allRoles = {
        ...state.allRoles,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getAllUserRoles.pending, state => {
      state.allUserRoles = {
        ...state.allUserRoles,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllUserRoles.fulfilled, (state, action) => {
      state.allUserRoles.data = action.payload;
      state.allUserRoles.status = 'fulfilled';
    })
    .addCase(getAllUserRoles.rejected, (state, action) => {
      state.allUserRoles = {
        ...state.allUserRoles,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default RoleReducer;
