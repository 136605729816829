import { useTranslation } from 'react-i18next';
import CamerasDetail from '@Pages/Cameras/Components/CamerasDetail';
import {
  getAllArchivedCameras,
  getAllUnarchivedCameras,
} from '@Store/Camera/action';

const AllCameras = () => {
  const { t } = useTranslation();

  return (
    <CamerasDetail
      getUnarchivedCamerasAction={getAllUnarchivedCameras}
      getArchivedCamerasAction={getAllArchivedCameras}
      title={t('allCameras')}
    />
  );
};

export default AllCameras;
