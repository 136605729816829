import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_ALL_PERMISSIONS, GET_USER_PERMISSIONS } from './type';
import Permissions from '@Services/Api/Permissions';
import UserService from '@Services/Api/User';

export const getAllPermissions = createAsyncThunk(
  GET_ALL_PERMISSIONS,
  async () => {
    try {
      const allPermissions = await new Permissions().All();
      return allPermissions;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getUserPermissions = createAsyncThunk(
  GET_USER_PERMISSIONS,
  async () => {
    try {
      const permissions = await new UserService().GetPermissions();
      return permissions;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
