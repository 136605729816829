import { Button, ButtonProps, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';

export type DeletePopconfirmProps = ButtonProps & {
  onConfirm?: () => void;
  children?: React.ReactNode;
};

export default function DeletePopconfirm({
  onConfirm,
  children,
  ...restProps
}: DeletePopconfirmProps) {
  const { t } = useTranslation();

  return (
    <Popconfirm
      title={t('doYouWantToDelete')}
      onConfirm={onConfirm}
      okText={t('yes')}
      cancelText={t('no')}
    >
      {children || (
        <Button
          shape="circle"
          className="delete-popconfirm-button"
          {...restProps}
        >
          <DeleteOutlined />
        </Button>
      )}
    </Popconfirm>
  );
}
