import {
  CameraRecordProcessStatus,
  CameraRecordStatus,
  LabelingStatus,
} from '@Enums/CameraRecord';
import { CameraRecordProcess, CameraRecordTable } from '@Types/CameraRecords';
import i18n from '../i18n';

export function convertCameraRecordProcessEnum(
  status: CameraRecordProcessStatus
): string {
  switch (status) {
    case 1:
      return i18n.t('waitingRecord');
    case 2:
      return i18n.t('waitingToBeProcessed');
    case 3:
      return i18n.t('processing');
    case 9:
      return i18n.t('processingError');
    case 4:
      return i18n.t('completed');
    case 5:
      return i18n.t('videoDeleted');
  }
  return '-';
}

export function convertCameraRecordProcessLabelingEnum(
  status: LabelingStatus
): string {
  switch (status) {
    case 0:
      return i18n.t('new');
    case 1:
      return i18n.t('waitingToBeSentToLabeling');
    case 2:
      return i18n.t('sentToLabeling');
  }
  return '-';
}

export function convertCameraRecordEnum(status: CameraRecordStatus): string {
  switch (status) {
    case CameraRecordStatus.New:
      return i18n.t('cameraRecordProcessEnum.new');
    case CameraRecordStatus.RecordStarted:
      return i18n.t('cameraRecordProcessEnum.recordStarted');
    case CameraRecordStatus.RecordCompleted:
      return i18n.t('cameraRecordProcessEnum.recordCompleted');
    case CameraRecordStatus.UploadingToServer:
      return i18n.t('cameraRecordProcessEnum.uploadingToServer');
    case CameraRecordStatus.ErrorUploadingToServer:
      return i18n.t('cameraRecordProcessEnum.uploadingError');
    case CameraRecordStatus.UploadedToServer:
      return i18n.t('cameraRecordProcessEnum.uploadedToServer');
  }
  return '-';
}

/**
 *
 * @param recordProcess
 * @param record
 * @returns {boolean}
 */
export function shouldShowReprocessButton(
  recordProcess: CameraRecordProcess,
  record: CameraRecordTable
): boolean {
  const recordProcessStatusConditions: boolean =
    recordProcess.Status === CameraRecordProcessStatus.Completed ||
    recordProcess.Status === CameraRecordProcessStatus.VideoDeleted ||
    recordProcess.Status === CameraRecordProcessStatus.ProcessingError;

  const recordStatusConditions: boolean =
    record.data.Status === CameraRecordStatus.RecordCompleted ||
    record.data.Status === CameraRecordStatus.UploadedToServer;

  return recordProcessStatusConditions && recordStatusConditions;
}
