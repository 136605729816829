import DeleteButton from '@Components/Card/DeleteButton';
import useNotificationRulesActions from '@Pages/NotificationRules/useNotificationRulesActions';
import { CameraRuleId } from '@Types/CameraRules';
import { Space } from 'antd';

interface Props {
  cameraRuleId: CameraRuleId;
  getCameraRules: Function;
}

export default function CameraRuleActionButtons({
  cameraRuleId,
  getCameraRules,
}: Props) {
  const { deleteCameraRules } = useNotificationRulesActions();

  async function handleDelete() {
    await deleteCameraRules([cameraRuleId]);
    getCameraRules();
  }

  return (
    <Space direction="horizontal" size="small" wrap>
      <DeleteButton onConfirm={handleDelete} />
    </Space>
  );
}
