import {
  VersionFileGroupForm,
  VersionFileGroupFormProps,
} from '@Features/versions/forms';
import { UseBooleanResult } from '@Hooks/useBoolean';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

interface IProps extends VersionFileGroupFormProps {
  modalState: UseBooleanResult;
}

export function CreateVersionFileGroupModal(props: IProps) {
  const { modalState, ...restProps } = props;

  const { t } = useTranslation();

  return (
    <Modal
      title={t('createVersionFormGroup')}
      open={modalState.value}
      onOk={modalState.setFalse}
      onCancel={modalState.setFalse}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
      footer={false}
      destroyOnClose
    >
      <VersionFileGroupForm {...restProps} />
    </Modal>
  );
}
