import { createReducer } from '@reduxjs/toolkit';
import { getAllPermissions, getUserPermissions } from './action';
import { asyncFetchInterface } from '../../Types/ReduxTypes';
import { Permission, UserPermissions } from '@Types/Permission';

interface stateInterface {
  allPermissions: asyncFetchInterface<Permission[]>;
  userPermissions: asyncFetchInterface<UserPermissions>;
}

const initialState: stateInterface = {
  allPermissions: {
    data: [],
    status: 'Empty',
  },
  userPermissions: {
    data: {} as UserPermissions,
    status: 'Empty',
  },
};

const PermissionReducer = createReducer(initialState, builder => {
  builder
    .addCase(getAllPermissions.pending, state => {
      state.allPermissions = {
        ...state.allPermissions,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllPermissions.fulfilled, (state, action) => {
      state.allPermissions.data = action.payload;
      state.allPermissions.status = 'fulfilled';
    })
    .addCase(getAllPermissions.rejected, (state, action) => {
      state.allPermissions = {
        ...state.allPermissions,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getUserPermissions.pending, state => {
      state.userPermissions = {
        ...state.userPermissions,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getUserPermissions.fulfilled, (state, action) => {
      state.userPermissions.data = action.payload as UserPermissions;
      state.userPermissions.status = 'fulfilled';
    })
    .addCase(getUserPermissions.rejected, (state, action) => {
      state.userPermissions = {
        ...state.userPermissions,
        data: {} as UserPermissions,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default PermissionReducer;
