import CardGridWrapper from '@Components/CardGridWrapper';
import { VersionFile } from '@Enums/VersionFileEnums';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { useEffect, useMemo } from 'react';
import { VersionFileGroupCard } from '../VersionFileGroupCard';
import { useVersionFiles } from '@Features/versions/hooks';
import { VersionFile as IVersionFile, VersionFileGroup } from '@Types/Version';
import VersionFileCard from '@Cards/VersionFileCard';
import './style.scss';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { getVersionFileGroups } from '@Store/Version/action';

interface VersionFileGroupListProps {
  versionFileType: VersionFile;
}

type Group = Record<
  number,
  { group: VersionFileGroup; versionFiles: IVersionFile[] }
>;

export function VersionFileGroupList(props: VersionFileGroupListProps) {
  const { versionFileType } = props;

  const { query: versionFiles } = useVersionFiles(versionFileType);

  const versionFileGroups = useAppSelector(s => s.Version.versionFileGroups);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getVersionFileGroups({ type: versionFileType }));
  }, []);

  // match version files with groups by their "ComponentId"
  const { groupless, groups } = useMemo(() => {
    const groups: Group =
      versionFileGroups.data?.reduce<Group>((acc, group) => {
        acc[group.Id] = { group, versionFiles: [] };
        return acc;
      }, {}) ?? {};

    const groupless = [];

    for (const versionFile of versionFiles.data) {
      // version file does not have a group
      if (!versionFile.ComponentId) {
        groupless.push(versionFile);
        continue;
      }

      // version file has a group and found match
      const group = versionFileGroups.data?.find(
        group => group.Id === versionFile.ComponentId
      );

      if (group) {
        groups[versionFile.ComponentId] = {
          ...(groups[versionFile.ComponentId] ?? {}),
          group,
          versionFiles: [
            ...(groups[versionFile.ComponentId]?.versionFiles ?? []),
            versionFile,
          ],
        };
        continue;
      }

      // group exists but not found in fetched groups
      groupless.push(versionFile);
    }

    return {
      groups: Object.entries(groups),
      groupless,
    };
  }, [versionFileGroups.data, versionFiles.data]);

  return (
    <div className="groups-container">
      <div>
        <Typography.Title level={4}>{t('groups')}</Typography.Title>
        <div className="grid-content-wrapper groups-wrapper">
          <CardGridWrapper
            noData={versionFileGroups.data.length === 0}
            status={versionFileGroups.status}
            cards={
              <>
                {groups.map(([groupId, payload]) => (
                  <VersionFileGroupCard key={groupId} {...payload} />
                ))}
              </>
            }
          />
        </div>
      </div>

      {groupless.length > 0 && (
        <div>
          <Typography.Title level={4}>{t('groupless')}</Typography.Title>
          <div className="grid-content-wrapper">
            <CardGridWrapper
              noData={groupless.length === 0}
              status={versionFiles.status}
              cards={
                <>
                  {groupless.map(versionFile => (
                    <VersionFileCard
                      key={versionFile.Id}
                      versionFile={versionFile}
                    />
                  ))}
                </>
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}
