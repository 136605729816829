import { UseBooleanResult } from '@Hooks/useBoolean';
import { FetchedBoard } from '@Types/Board';
import { Button, Card, Modal, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import {
  getBeaconFile,
  getCodeVersionsById,
  getControlFile,
  getMainFile,
  getModelFile,
} from '@Store/Version/action';
import { Popconfirm } from '@Components/Popconfirm';
import { apiCall } from '@Utils/index';
import BoardsService from '@Services/Api/Boards';
import { getBoardDetail } from '@Store/Board/action';
import './style.scss';

const { Title, Text } = Typography;

interface ActiveFileChangeDetailsModalProps {
  modalState: UseBooleanResult;
  board: FetchedBoard;
  getBoards: () => void;
}

export function ActiveFileChangeDetailsModal(
  props: ActiveFileChangeDetailsModalProps
) {
  const { modalState, board, getBoards } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const codeVersion = useAppSelector(s => s.Version.codeVersion);

  const currentBoard = useAppSelector(s => s.Board.currentBoard);

  const mainFiles = useAppSelector(s => s.Version.Main.data);
  const modelFiles = useAppSelector(s => s.Version.Model.data);
  const controlFiles = useAppSelector(s => s.Version.Control.data);
  const beaconFiles = useAppSelector(s => s.Version.Beacon.data);

  useEffect(() => {
    dispatch(getBoardDetail(board.Id));
    dispatch(getCodeVersionsById(board.CodeVersionId));
    dispatch(getMainFile(true));
    dispatch(getModelFile(true));
    dispatch(getControlFile(true));
    dispatch(getBeaconFile(true));
  }, []);

  async function handleUpdateConfirm() {
    try {
      await apiCall(() => new BoardsService().SetActiveFiles(board.Id));
      dispatch(getBoardDetail(board.Id));
      getBoards();
      modalState.setFalse();
    } catch (e) {
      console.log(e);
    }
  }

  const fileTypes = useMemo(() => {
    const currentMain = currentBoard.data?.VersionV2?.Main?.Name;
    const currentModel = currentBoard.data?.VersionV2?.Model?.Name;
    const currentControl = currentBoard.data?.VersionV2?.Control?.Name;
    const currentBeacon = currentBoard.data?.VersionV2?.Beacon?.Name;

    const newMain = mainFiles?.find(
      main => main.Id === codeVersion.data?.CompMain?.ActiveFile?.Id
    );
    const newModel = modelFiles?.find(
      main => main.Id === codeVersion.data?.CompModel?.ActiveFile?.Id
    );
    const newControl = controlFiles?.find(
      main => main.Id === codeVersion.data?.CompControl?.ActiveFile?.Id
    );
    const newBeacon = beaconFiles?.find(
      main => main.Id === codeVersion.data?.CompBeacon?.ActiveFile?.Id
    );

    return [
      {
        label: 'Main',
        currentFile: currentMain,
        newFile: newMain,
        seperator: chooseSeperator(currentMain, newMain?.Name),
      },
      {
        label: 'Model',
        currentFile: currentModel,
        newFile: newModel,
        seperator: chooseSeperator(currentModel, newModel?.Name),
      },
      {
        label: 'Control',
        currentFile: currentControl,
        newFile: newControl,
        seperator: chooseSeperator(currentControl, newControl?.Name),
      },
      {
        label: 'Beacon',
        currentFile: currentBeacon,
        newFile: newBeacon,
        seperator: chooseSeperator(currentBeacon, newBeacon?.Name),
      },
    ];
  }, [
    codeVersion.data,
    mainFiles,
    modelFiles,
    controlFiles,
    beaconFiles,
    currentBoard,
  ]);

  function chooseSeperator(
    currentFile: string | undefined,
    newFile: string | undefined
  ) {
    if (currentFile === newFile) return { text: '=', opacity: 0.3 };

    return { text: '>', opacity: 1 };
  }

  return (
    <Modal
      title={t('updateSoftwareVersion')}
      open={modalState.value}
      onOk={modalState.setFalse}
      onCancel={modalState.setFalse}
      footer={false}
      destroyOnClose
      className="active-file-change-details"
      width={800}
    >
      <Title
        level={3}
        className="board-title"
      >{`${board.Id} - ${board.Name}`}</Title>

      <div className="headers">
        <Text className="file-type-label" />
        <Title level={5} className="file-type-card column-header">
          {t('currentFiles')}
        </Title>
        <Text className="file-type-seperator" />
        <Title level={5} className="file-type-card column-header">
          {t('updatedFiles')}
        </Title>
      </div>

      <div className="file-types">
        {fileTypes.map(item => (
          <div className="file-type">
            <Text className="file-type-label">{item.label}</Text>

            <Card className="file-type-card" size="small">
              <Text>{item.currentFile}</Text>
            </Card>

            <Text
              className="file-type-seperator"
              style={{ opacity: item.seperator.opacity }}
            >
              {item.seperator.text}
            </Text>

            <Card className="file-type-card" size="small">
              <Space direction="vertical" size={0}>
                <Text>{item.newFile?.Name ?? ''}</Text>
                <Text className="update-note" title={t('uploadNote')}>
                  {item.newFile?.UpdateNote ?? ''}
                </Text>
              </Space>
            </Card>
          </div>
        ))}
      </div>

      <div className="update-button-container">
        <Popconfirm onConfirm={handleUpdateConfirm}>
          <Button type="primary" className="update-button">
            {t('update')}
          </Button>
        </Popconfirm>
      </div>
    </Modal>
  );
}
