import { Button, ButtonProps, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type Props = ButtonProps & {
  to?: string;
  locationState?: { [key: string]: any };
  tooltipKey?: string;
  tooltipText?: React.ReactNode;
  icon: React.ReactElement;
};

function CardButton({
  to,
  icon,
  tooltipKey,
  onClick,
  tooltipText,
  locationState,
  ...restProps
}: Props) {
  const { t } = useTranslation();

  return (
    <Tooltip title={tooltipText || t(tooltipKey || '')}>
      {to ? (
        <Link to={to} state={locationState}>
          <Button
            shape="circle"
            icon={icon}
            onClick={onClick}
            size="small"
            {...restProps}
          />
        </Link>
      ) : (
        <Button
          shape="circle"
          icon={icon}
          onClick={onClick}
          size="small"
          {...restProps}
        />
      )}
    </Tooltip>
  );
}

export default CardButton;
