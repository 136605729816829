import { MouseEvent } from 'react';

export const getMouseWindowPosition = (
  Elevent?: MouseEvent | globalThis.MouseEvent
) => {
  var eventDoc, doc, body, pageX, pageY;
  let mousePos = {
    x: 0,
    y: 0,
  };
  let event = Elevent || window.event; // IE-ism
  // If pageX/Y aren't available and clientX/Y are,
  // calculate pageX/Y - logic taken from jQuery.
  // (This is to support old IE)
  if (event === undefined) return mousePos;
  if ((event as any).pageX !== null && (event as any).clientX !== null) {
    eventDoc = (event?.target as Document)?.ownerDocument || document;
    doc = eventDoc.documentElement;
    body = eventDoc.body;
    pageX =
      (event as any).clientX +
      ((doc && doc.scrollLeft) || (body && body.scrollLeft) || 0) -
      ((doc && doc.clientLeft) || (body && body.clientLeft) || 0);
    pageY =
      (event as any).clientY +
      ((doc && doc.scrollTop) || (body && body.scrollTop) || 0) -
      ((doc && doc.clientTop) || (body && body.clientTop) || 0);
    mousePos = {
      x: pageX + 10,
      y: pageY + 10,
    };
  }

  return mousePos;
};
