import { Period } from '@Enums/Period';

export function convertPeriodToText(period: Period) {
  switch (period) {
    case 0:
      return 'daily';
    case 1:
      return 'weekly';
    case 2:
      return 'monthly';
  }
  return 'Unknown';
}
