import CardGridWrapper from '@Components/CardGridWrapper';
import GoBackButton from '@Components/GoBackButton';
import {
  TicketChat,
  TicketMessage,
  TicketMetadata,
} from '@Features/tickets/components';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { clearTicket, getTicketDetails } from '@Store/Ticket/action';
import { Alert, Card, Divider } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import './style.scss';
import { TicketStatus } from '@Enums/ticket.enums';

export function TicketChatPage() {
  const { ticketId } = useParams();

  const ticketQuery = useAppSelector(s => s.Ticket.ticket);

  const ticket =
    Object.keys(ticketQuery.data ?? {}).length === 0
      ? undefined
      : ticketQuery.data;

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getTicketDetails(Number(ticketId)));

    return () => {
      dispatch(clearTicket());
    };
  }, []);

  return (
    <Card
      title={
        <div className="ticket-page-title">
          <GoBackButton />
          {t('ticketMessages')}
        </div>
      }
      className="tickets-page-card"
    >
      <CardGridWrapper
        status={ticketQuery.status}
        noData={ticket === undefined}
        cards={
          ticket && (
            <div className="tickets-content">
              <TicketMetadata ticket={ticket} />
              <Divider style={{ margin: 8 }} />
              <TicketChat ticket={ticket} />

              {ticket.TicketStatus === TicketStatus.CLOSED ? (
                <Alert
                  showIcon
                  type="warning"
                  message={t('ticketActionsAreReadOnly')}
                  style={{ marginTop: 8 }}
                />
              ) : (
                <TicketMessage ticket={ticket} />
              )}
            </div>
          )
        }
      />
    </Card>
  );
}
