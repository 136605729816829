import { Category } from '@Types/Permission';
import { Col, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';
import RenderPermissions from './RenderPermissions';

interface Props {
  categories: Category[];
  onCheckboxChange: (e: CheckboxChangeEvent) => void;
}

export default function RenderCategories({
  categories,
  onCheckboxChange,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      {categories.map(c => (
        <Col sm={6} md={6} lg={4} key={c.name}>
          <Typography.Title level={5} className="category-title">
            {t(c.name)}
          </Typography.Title>
          <RenderPermissions
            permissions={c.permissions}
            disabledPermissions={c.disabledPermissions}
            onCheckboxChange={onCheckboxChange}
          />
        </Col>
      ))}
    </>
  );
}
