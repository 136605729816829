import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  SortDescendingOutlined,
  SortAscendingOutlined,
} from '@ant-design/icons';
import useQuery from '@Hooks/useQuery';
import { useState } from 'react';
import { OrderBy } from '@Enums/Filter';

type Props = {
  onChange: (value: OrderBy) => void;
  defaultValue?: OrderBy;
};

export function PaginationOrderByFilter({ onChange, defaultValue }: Props) {
  const { Params } = useQuery();

  const [value, setValue] = useState<OrderBy>(
    (Params('orderBy') || defaultValue || OrderBy.DESC) as OrderBy
  );

  const { t } = useTranslation();

  const handleChange = (newValue: OrderBy) => () => {
    onChange(newValue);
    setValue(newValue);
  };

  return (
    <div className="order-filter-container">
      <Tooltip title={t('orderBy')}>
        {value === OrderBy.ASC ? (
          <Button
            onClick={handleChange(OrderBy.DESC)}
            icon={<SortAscendingOutlined />}
          />
        ) : (
          <Button
            onClick={handleChange(OrderBy.ASC)}
            icon={<SortDescendingOutlined />}
          />
        )}
      </Tooltip>
    </div>
  );
}
