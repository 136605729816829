import { AllRegions } from '@Types/Region';
import Card from '@Components/Card';
import { useTranslation } from 'react-i18next';
import EditButton from '@Components/Card/EditButton';
import { Space } from 'antd';

type Props = {
  region: AllRegions;
};
export default function RegionCard({ region }: Props) {
  const { t } = useTranslation();

  return (
    <Card
      isDetailAlwaysOpen
      subHeader={true}
      extraButtons={
        <Space direction="horizontal" size="small" wrap>
          <EditButton to={`brand/${region.BrandId}`} />
        </Space>
      }
      content={{
        firsItem: {
          text: region.BrandName,
        },
      }}
      details={[
        {
          key: { text: t('regionCount') },
          value: {
            text: region.Count,
          },
        },
      ]}
    />
  );
}
