import { CheckOutlined, MessageOutlined } from '@ant-design/icons';
import CardButton from '@Components/CardButton';
import { Popconfirm } from '@Components/Popconfirm';
import { Permissions } from '@Enums/Permission';
import { TicketStatus } from '@Enums/ticket.enums';
import useCheckPermission from '@Hooks/useCheckPermission';
import { TicketService } from '@Services/Api/TicketService';
import { ITicket } from '@Types/ticket.types';
import { apiCall } from '@Utils/index';
import { Space } from 'antd';

interface TicketActionButtonsProps {
  ticket: ITicket;
  onRefetch: () => void;
}

export function TicketActionButtons(props: TicketActionButtonsProps) {
  const { ticket, onRefetch } = props;

  const actionPermission = useCheckPermission(Permissions.ADMIN_TICKET_ACTION);

  async function closeTicket() {
    try {
      const req = () =>
        new TicketService().ChangeTicketStatus({
          ticketId: ticket.Id,
          ticketStatus: TicketStatus.CLOSED,
        });
      await apiCall(req);
      onRefetch();
    } catch (e) {
      console.warn(e);
    }
  }

  if (!actionPermission) return <></>;

  return (
    <Space direction="horizontal" size="small" wrap>
      {ticket.TicketStatus !== TicketStatus.CLOSED && (
        <Popconfirm onConfirm={closeTicket}>
          <CardButton icon={<CheckOutlined />} tooltipKey="closeTicket" />
        </Popconfirm>
      )}

      <CardButton
        tooltipKey="goToChat"
        icon={<MessageOutlined />}
        to={`/tickets/${ticket.Id}/chat`}
      />
    </Space>
  );
}
