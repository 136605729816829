import ModalFooter from '@Components/ModalFooter';
import { Form, FormProps, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { VersionFileGroup } from '@Types/Version';

export interface IVersionFileGroupForm {
  Name: string;
}

export type VersionFileGroupFormProps = FormProps<IVersionFileGroupForm> & {
  versionFileGroup?: VersionFileGroup;
};

export function VersionFileGroupForm(props: VersionFileGroupFormProps) {
  const { versionFileGroup, ...restProps } = props;

  const [form] = Form.useForm<IVersionFileGroupForm>();

  const { t } = useTranslation();

  return (
    <Form
      id="version-file-form"
      className="version-file-form"
      form={form}
      labelCol={{ span: 8 }}
      labelWrap
      labelAlign="left"
      wrapperCol={{ span: 24 }}
      initialValues={versionFileGroup}
      {...restProps}
    >
      <Form.Item name="Name" label={t('name')} rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <ModalFooter
        sendButtonText={t('save')}
        formKey="version-file-form"
        hideGoBackButton
      />
    </Form>
  );
}
