import Card from '@Components/Card';
import { useTranslation } from 'react-i18next';
import HierarchyItems from '@Components/Card/HierarchyItems';
import InfoItem from '@Components/InfoItem';
import { Space, Typography } from 'antd';
import './style.scss';
import { BeaconRuleActionButtons } from './BeaconRuleActionButtons';
import { IBeaconRule } from '@Types/BeaconRule';

interface Props {
  beaconRule: IBeaconRule;
  getBeaconRules: Function;
}

export function BeaconRuleCard({ beaconRule, getBeaconRules }: Props) {
  const { t } = useTranslation();

  const {
    BeaconId,
    BeaconName,
    BrandId,
    BrandName,
    Id,
    ReceiverId,
    ReceiverName,
    StoreId,
    StoreName,
    Type,
    Users,
    Value,
  } = beaconRule;

  return (
    <Card
      key={Id}
      className="camera-rule-card"
      content={{
        firsItem: {
          custom: (
            <Space direction="vertical">
              <Typography.Text strong>{Id}</Typography.Text>
              {t(`beaconRules.${Type}`, { number: Number || '-' })}
            </Space>
          ),
        },
        secondItem: {
          custom: (
            <HierarchyItems
              brandId={BrandId}
              storeId={StoreId}
              brand={BrandName}
              store={StoreName}
              textWidth="15ch"
            />
          ),
        },
      }}
      details={[
        {
          key: { text: t('value') },
          value: {
            text: Value,
          },
        },
        {
          key: { text: t('Type') },
          value: {
            text: t(`beaconRules.${Type}`),
          },
        },
        {
          key: { text: t('users') },
          value: {
            text: (
              <InfoItem
                content={
                  <Space direction="vertical">
                    {Users.map(user => (
                      <Typography.Text key={user.Id} style={{ color: 'white' }}>
                        {user.Username}
                      </Typography.Text>
                    ))}
                  </Space>
                }
              />
            ),
          },
        },
        {
          key: { text: t('beacon') },
          value: {
            text: BeaconId ? `${BeaconId} / ${BeaconName}` : '-',
          },
        },
        {
          key: { text: t('receiver') },
          value: {
            text: ReceiverId ? `${ReceiverId} / ${ReceiverName}` : '-',
          },
        },
      ]}
      extraButtons={
        <BeaconRuleActionButtons
          beaconRuleId={Id}
          getBeaconRules={getBeaconRules}
        />
      }
    />
  );
}
