import { createReducer } from '@reduxjs/toolkit';
import {
  AllVersion,
  AllVersions,
  AllVersionsFetchResponse,
  VersionFile,
  VersionFileGroup,
  VersionFiles,
} from '@Types/Version';
import {
  getAllVersions,
  getMainFile,
  getControlFile,
  getModelFile,
  getBeaconFile,
  getCodeVersionsById,
  getPackageFiles,
  getBleFiles,
  resetCodeVersions,
  getVersionFileGroups,
  getMainGroups,
  getModelGroups,
  getBeaconGroups,
  getControlGroups,
} from './action';
import Status from '@Enums/Status';
import { asyncFetchInterface } from '@Types/ReduxTypes';

interface stateInterface {
  allVersions: AllVersions;
  Main: VersionFiles;
  Control: VersionFiles;
  Model: VersionFiles;
  Beacon: VersionFiles;
  Package: VersionFiles;
  Ble: VersionFiles;
  codeVersion: asyncFetchInterface<AllVersion>;
  versionFileGroups: asyncFetchInterface<VersionFileGroup[]>;
  mainGroups: asyncFetchInterface<VersionFileGroup[]>;
  modelGroups: asyncFetchInterface<VersionFileGroup[]>;
  controlGroups: asyncFetchInterface<VersionFileGroup[]>;
  beaconGroups: asyncFetchInterface<VersionFileGroup[]>;
}

const initialState: stateInterface = {
  allVersions: {
    data: {} as AllVersionsFetchResponse,
    status: 'Empty',
  },
  codeVersion: {
    data: {} as AllVersion,
    status: 'Empty',
  },
  Main: {
    data: [] as VersionFile[],
    status: 'Empty',
  },
  Control: {
    data: [] as VersionFile[],
    status: 'Empty',
  },
  Model: {
    data: [] as VersionFile[],
    status: 'Empty',
  },
  Beacon: {
    data: [] as VersionFile[],
    status: 'Empty',
  },
  Package: {
    data: [] as VersionFile[],
    status: 'Empty',
  },
  Ble: {
    data: [] as VersionFile[],
    status: 'Empty',
  },
  versionFileGroups: {
    data: [] as VersionFileGroup[],
    status: 'Empty',
  },
  mainGroups: {
    data: [] as VersionFileGroup[],
    status: 'Empty',
  },
  modelGroups: {
    data: [] as VersionFileGroup[],
    status: 'Empty',
  },
  controlGroups: {
    data: [] as VersionFileGroup[],
    status: 'Empty',
  },
  beaconGroups: {
    data: [] as VersionFileGroup[],
    status: 'Empty',
  },
};

const VersionReducer = createReducer(initialState, builder => {
  builder
    .addCase(getVersionFileGroups.pending, state => {
      state.versionFileGroups = {
        ...state.versionFileGroups,
        status: Status.pending,
        ErrorMessage: undefined,
      };
    })
    .addCase(getVersionFileGroups.fulfilled, (state, action) => {
      state.versionFileGroups.data = action.payload as VersionFileGroup[];
      state.versionFileGroups.status = Status.success;
    })
    .addCase(getVersionFileGroups.rejected, (state, action) => {
      state.versionFileGroups = {
        ...state.versionFileGroups,
        data: [] as VersionFileGroup[],
        status: Status.error,
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getMainGroups.pending, state => {
      state.mainGroups = {
        ...state.mainGroups,
        status: Status.pending,
        ErrorMessage: undefined,
      };
    })
    .addCase(getMainGroups.fulfilled, (state, action) => {
      state.mainGroups.data = action.payload as VersionFileGroup[];
      state.mainGroups.status = Status.success;
    })
    .addCase(getMainGroups.rejected, (state, action) => {
      state.mainGroups = {
        ...state.mainGroups,
        data: [] as VersionFileGroup[],
        status: Status.error,
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getModelGroups.pending, state => {
      state.modelGroups = {
        ...state.modelGroups,
        status: Status.pending,
        ErrorMessage: undefined,
      };
    })
    .addCase(getModelGroups.fulfilled, (state, action) => {
      state.modelGroups.data = action.payload as VersionFileGroup[];
      state.modelGroups.status = Status.success;
    })
    .addCase(getModelGroups.rejected, (state, action) => {
      state.modelGroups = {
        ...state.modelGroups,
        data: [] as VersionFileGroup[],
        status: Status.error,
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getBeaconGroups.pending, state => {
      state.beaconGroups = {
        ...state.beaconGroups,
        status: Status.pending,
        ErrorMessage: undefined,
      };
    })
    .addCase(getBeaconGroups.fulfilled, (state, action) => {
      state.beaconGroups.data = action.payload as VersionFileGroup[];
      state.beaconGroups.status = Status.success;
    })
    .addCase(getBeaconGroups.rejected, (state, action) => {
      state.beaconGroups = {
        ...state.beaconGroups,
        data: [] as VersionFileGroup[],
        status: Status.error,
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getControlGroups.pending, state => {
      state.controlGroups = {
        ...state.controlGroups,
        status: Status.pending,
        ErrorMessage: undefined,
      };
    })
    .addCase(getControlGroups.fulfilled, (state, action) => {
      state.controlGroups.data = action.payload as VersionFileGroup[];
      state.controlGroups.status = Status.success;
    })
    .addCase(getControlGroups.rejected, (state, action) => {
      state.controlGroups = {
        ...state.controlGroups,
        data: [] as VersionFileGroup[],
        status: Status.error,
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getAllVersions.pending, state => {
      state.allVersions = {
        ...state.allVersions,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllVersions.fulfilled, (state, action) => {
      state.allVersions.data = action.payload as AllVersionsFetchResponse;
      state.allVersions.status = 'fulfilled';
    })
    .addCase(getAllVersions.rejected, (state, action) => {
      state.allVersions = {
        ...state.allVersions,
        data: {} as AllVersionsFetchResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getMainFile.pending, state => {
      state.Main = {
        ...state.Main,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getMainFile.fulfilled, (state, action) => {
      state.Main.data = action.payload;
      state.Main.status = 'fulfilled';
    })
    .addCase(getMainFile.rejected, (state, action) => {
      state.Main = {
        ...state.Main,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getControlFile.pending, state => {
      state.Control = {
        ...state.Control,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getControlFile.fulfilled, (state, action) => {
      state.Control.data = action.payload;
      state.Control.status = 'fulfilled';
    })
    .addCase(getControlFile.rejected, (state, action) => {
      state.Control = {
        ...state.Control,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getModelFile.pending, state => {
      state.Model = {
        ...state.Model,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getModelFile.fulfilled, (state, action) => {
      state.Model.data = action.payload;
      state.Model.status = 'fulfilled';
    })
    .addCase(getModelFile.rejected, (state, action) => {
      state.Model = {
        ...state.Model,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getBeaconFile.pending, state => {
      state.Beacon = {
        ...state.Beacon,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getBeaconFile.fulfilled, (state, action) => {
      state.Beacon.data = action.payload;
      state.Beacon.status = 'fulfilled';
    })
    .addCase(getBeaconFile.rejected, (state, action) => {
      state.Beacon = {
        ...state.Beacon,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(resetCodeVersions, state => {
      state.codeVersion = {
        ...state.codeVersion,
        data: {} as AllVersion,
        status: 'Empty',
        ErrorMessage: undefined,
      };
    })
    .addCase(getCodeVersionsById.pending, state => {
      state.codeVersion = {
        ...state.codeVersion,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getCodeVersionsById.fulfilled, (state, action) => {
      state.codeVersion.data = action.payload as AllVersion;
      state.codeVersion.status = 'fulfilled';
    })
    .addCase(getCodeVersionsById.rejected, (state, action) => {
      state.codeVersion = {
        ...state.codeVersion,
        data: {} as AllVersion,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getPackageFiles.pending, state => {
      state.Package = {
        ...state.Package,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getPackageFiles.fulfilled, (state, action) => {
      state.Package.data = action.payload;
      state.Package.status = 'fulfilled';
    })
    .addCase(getPackageFiles.rejected, (state, action) => {
      state.Package = {
        ...state.Package,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getBleFiles.pending, state => {
      state.Ble = {
        ...state.Ble,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getBleFiles.fulfilled, (state, action) => {
      state.Ble.data = action.payload;
      state.Ble.status = 'fulfilled';
    })
    .addCase(getBleFiles.rejected, (state, action) => {
      state.Ble = {
        ...state.Ble,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default VersionReducer;
