import usePermissions from '@Hooks/usePermissions';
import { Permissions } from '@Enums/Permission';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';

interface Props {
  permissions: Permissions[];
  onCheckboxChange: (e: CheckboxChangeEvent) => void;
  disabledPermissions?: Permissions[];
}

export default function RenderPermissions({
  permissions,
  disabledPermissions,
  onCheckboxChange,
}: Props) {
  const { t } = useTranslation();
  const perms = usePermissions();

  if (permissions.length === 0) return <>{t('empty')}.</>;
  return (
    <>
      {perms.data
        .filter(p => permissions.includes(p.Name))
        .map(p => (
          <Checkbox
            value={p.Name}
            onChange={onCheckboxChange}
            key={p.Name}
            disabled={
              disabledPermissions && disabledPermissions.includes(p.Name)
            }
          >
            {t(`permissions.${p.Name}`)}
          </Checkbox>
        ))}
    </>
  );
}
