import { useTranslation } from 'react-i18next';
import { AllVersion } from '@Types/Version';
import Card from '@Components/Card';
import VersionActionButtons from './VersionActionButtons';
import FilledText from '@Components/FilledText';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';

type Props = {
  version: AllVersion;
  getVersions: () => void;
};
export default function VersionCard({ version, getVersions }: Props) {
  const { t } = useTranslation();

  return (
    <Card
      extraButtons={
        <VersionActionButtons version={version} getVersions={getVersions} />
      }
      content={{
        firsItem: {
          text: version.Name,
        },
        secondItem: {
          text: version.Version,
        },
      }}
      isDetailAlwaysOpen
      details={[
        {
          key: { text: 'Id' },
          value: {
            text: version.Id,
          },
        },
        {
          key: { text: 'Main' },
          value: {
            text: (
              <Tooltip title={version.CompMain?.ActiveFile?.Name}>
                <FilledText
                  text={version.CompMain?.Name ?? version.Main?.Name}
                />
              </Tooltip>
            ),
          },
        },
        {
          key: { text: 'Model' },
          value: {
            text: (
              <Tooltip title={version.CompModel?.ActiveFile?.Name}>
                <FilledText
                  text={version.CompModel?.Name ?? version.Model?.Name}
                />
              </Tooltip>
            ),
          },
        },
        {
          key: { text: t('control') },
          value: {
            text: (
              <Tooltip title={version.CompControl?.ActiveFile?.Name}>
                <FilledText
                  text={version.CompControl?.Name ?? version.Control?.Name}
                />
              </Tooltip>
            ),
          },
        },
        {
          key: { text: t('navigation.beacons') },
          value: {
            text: (
              <Tooltip title={version.CompBeacon?.ActiveFile?.Name}>
                <FilledText
                  text={version.CompBeacon?.Name ?? version.Beacon?.Name}
                />
              </Tooltip>
            ),
          },
        },
        {
          key: { text: t('hardware') },
          value: {
            text: <FilledText text={version.Hardware ?? '-'} />,
          },
        },
        {
          key: { text: t('versionsPage.boardCount') },
          value: {
            text: (
              <Link
                to={`/all-boards?sortBy=CodeVersion&orderBy=asc&filters=CodeVersion%3A${version.Name}`}
              >
                {version.BoardCount}
              </Link>
            ),
          },
        },
        {
          key: { text: t('imageVersion') },
          value: {
            text: <FilledText text={version.ImageVersionStr ?? '-'} />,
          },
        },
      ]}
    />
  );
}
