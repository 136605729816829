import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_NOTIFICATION_RESPONSES } from './type';
import BrandsService from '@Services/Api/Brands';

export const getNotificationResponses = createAsyncThunk(
  GET_NOTIFICATION_RESPONSES,
  async (brandId: number) => {
    try {
      const result = await new BrandsService().GetNotificationResponses(
        brandId
      );
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
