import SearchableSelect from '@Components/SearchableSelect';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { Button, Form, Input, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { getUserStores } from '@Store/User/action';
import { useEffect, useState } from 'react';
import { Brand } from '@Types/Brand';
import { getAllAvailableBrands } from '@Store/Brand/action';
import FormCard from '@Components/FormCard';
import { EnvironmentOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import RegionsServices from '@Services/Api/Regions';
import { apiCall } from '@Utils/index';
import { getBrandRegions } from '@Store/Regions/action';
import RegionTable from '@Pages/Settings/Pages/Region/Components/RegionTable';
import { useParams } from 'react-router-dom';
import Status from '@Enums/Status';

type Props = { newRegion?: boolean };

function RegionForm({ newRegion }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { brandId } = useParams();
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>();
  const [regionInput, setregionInput] = useState<string>('');
  const regions = useAppSelector(s => s.Regions.regions);

  const brands = useAppSelector(s => s.Brand.allAvailableBrands);
  const [regionInputControl, setregionInputControl] = useState<boolean>(false);

  const [form] = useForm();

  useEffect(() => {
    dispatch(getAllAvailableBrands());
  }, []);

  useEffect(() => {
    if (brandId) {
      setSelectedBrand(brands.data.find(b => b.Id === parseInt(brandId!)));
    }
  }, []);

  const handleBrandChange = (brandID: number) => {
    if (brandID) {
      dispatch(getUserStores(brandID));
      setSelectedBrand(brands.data.find(b => b.Id === brandID));
      dispatch(getBrandRegions(brandID));
    } else {
      setSelectedBrand(null);
    }
  };
  const handleSubmit = async () => {
    if (regionInput === '') {
      setregionInputControl(!regionInput);
    } else {
      try {
        if (selectedBrand?.Id) {
          const data = {
            BrandId: selectedBrand?.Id,
            Name: regionInput,
          };
          const req = () => new RegionsServices().CreateRegionBrand(data);
          await apiCall(req, 'createRegionBrand');
          setregionInput('');
          dispatch(getBrandRegions(selectedBrand?.Id));
        }
      } catch (e) {
        console.warn(e);
      }
    }
  };
  if (regions.status === Status.pending) {
    return <Spin />;
  }

  const setRegionInputChange = (value: string) => {
    setregionInput(value);
    if (value !== '') {
      setregionInputControl(false);
    }
  };

  return (
    <FormCard titleIcon={<EnvironmentOutlined />} titleKey="createRegion">
      <Form
        form={form}
        name="region-add-form"
        id="region-add-form"
        className="region-add-container"
        onFinish={handleSubmit}
      >
        <Form.Item label={t('brands')} name="BrandId" required>
          <SearchableSelect
            loading={brands.status === Status.pending}
            placeholder={t('selectBrand')}
            allowClear
            showSearch
            onChange={handleBrandChange}
            defaultValue={!newRegion && regions && regions.data[0]?.BrandId}
          >
            {brands.data.map(brand => (
              <Select.Option key={brand.Id} value={brand.Id} label={brand.Name}>
                {brand.Name}
              </Select.Option>
            ))}
          </SearchableSelect>
        </Form.Item>
        <Form.Item label={t('region')} name="Name" required>
          <Input.Group compact>
            <Input
              onChange={e => setRegionInputChange(e.target.value)}
              value={regionInput}
            />
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={handleSubmit}
            ></Button>
          </Input.Group>
          {regionInputControl && (
            <div className="ant-form-item-explain-error">
              {t('cannotBeEmpty')}
            </div>
          )}
        </Form.Item>
      </Form>
      <RegionTable
        selectedBrandName={regions.data[0]?.BrandName}
        selectedBrandId={regions.data[0]?.BrandId}
      />
    </FormCard>
  );
}

export default RegionForm;
