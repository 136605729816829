import ModalFooter from '@Components/ModalFooter';
import { getStoreProducts } from '@Store/Brand/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { Checkbox, Col, Divider, Empty, Form, Row, Spin } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm } from 'antd/lib/form/Form';
import './style.scss';
import { ProductType, StoreProduct } from '@Types/Brand';
import useIsEnvAdmin from '@Hooks/useIsEnvAdmin';

type Props = {
  onFinish: (values: any) => void;
};

function StoreProductsForm({ onFinish }: Props) {
  const [checkedProducts, setCheckedProducts] = useState<string[]>([]);
  const storeProducts = useAppSelector(s => s.Brand.storeProducts);

  const [form] = useForm();
  const { brandId } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isEnvAdmin = useIsEnvAdmin();

  useEffect(() => {
    dispatch(getStoreProducts(parseInt(brandId!)));
  }, []);

  useEffect(() => {
    const initialProducts = [];

    // Set initial values
    if (storeProducts.status === 'fulfilled') {
      for (const product of storeProducts.data) {
        for (const [key, value] of Object.entries(product)) {
          if (key === 'Name' || key === 'Id') continue;
          if (value === true) initialProducts.push(product.Id + '-' + key);
        }
      }
    }

    setCheckedProducts(initialProducts);
  }, [storeProducts.status]);

  if (storeProducts.status !== 'fulfilled') return <Spin />;

  const onAllProductClick = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;
    const productType = e.target.name!; // "all-PeopleCountingProduct"
    const pureProductType = productType.slice(4); // PeopleCountingProduct

    setCheckedProducts(prevS => {
      let newProducts = [...prevS];

      if (isChecked) {
        newProducts = checkAllStoresForProduct(pureProductType, newProducts);
        newProducts.push(productType);
      } else {
        const productIndex = newProducts.findIndex(p => p === productType);
        newProducts.splice(productIndex, 1);
        newProducts = uncheckAllStoresForProduct(pureProductType, newProducts);
      }

      return newProducts;
    });
  };

  const onProductClick = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;
    const productType = e.target.name!;

    setCheckedProducts(prevS => {
      let prevProducts = [...prevS];

      if (isChecked) {
        return [...prevProducts, productType];
      }

      // Remove unchecked product
      const productIndex = prevProducts.indexOf(productType);
      prevProducts.splice(productIndex, 1);

      // Remove all stores checkbox if it was selected
      const allStoresProductName = 'all-' + productType.slice(4);
      const allStoresIndex = prevProducts.indexOf(allStoresProductName);
      if (allStoresIndex !== -1) prevProducts.splice(allStoresIndex, 1);

      return prevProducts;
    });
  };

  const checkAllStoresForProduct = (
    productType: string,
    products: string[]
  ): string[] => {
    const updatedProducts = [...products];

    for (let i = 0; i < storeProducts.data.length; i++) {
      const storeProduct = storeProducts.data[i].Id + '-' + productType;

      if (!updatedProducts.includes(storeProduct)) {
        updatedProducts.push(storeProduct);
      }
    }

    return updatedProducts;
  };

  const uncheckAllStoresForProduct = (
    productType: string,
    products: string[]
  ): string[] => {
    const updatedProducts = [];

    for (const product of products) {
      if (!product.includes(productType)) {
        updatedProducts.push(product);
      }
    }

    return updatedProducts;
  };

  const isChecked = (product: string): boolean => {
    return checkedProducts.includes(product);
  };

  const editProductsForAPI = () => {
    let oldStoreProducts = JSON.parse(
      JSON.stringify(storeProducts.data)
    ) as StoreProduct[];

    // Set every product to false
    for (const storeProduct of oldStoreProducts) {
      for (const key of Object.keys(storeProduct)) {
        if (key === 'Id' || key === 'Name') continue;

        const storeIndex = oldStoreProducts.findIndex(
          p => p.Id === storeProduct.Id
        );
        oldStoreProducts[storeIndex][key as ProductType] = false;
      }
    }

    // Set selected products to "true"
    for (const checkedProduct of checkedProducts) {
      if (checkedProduct.includes('all-')) continue;

      const storeId = parseInt(checkedProduct.split('-')[0]);
      const productType = checkedProduct.split('-')[1] as ProductType;

      // Find store index and update that product to true
      const storeIndex = oldStoreProducts.findIndex(p => p.Id === storeId);
      oldStoreProducts[storeIndex][productType] = true;
    }

    return oldStoreProducts;
  };

  if (storeProducts.data.length === 0)
    return <Empty description={t('noStoreExists')} />;

  return (
    <>
      <Form
        onFinish={() => onFinish(editProductsForAPI())}
        form={form}
        name="store-products-form"
        className="store-products-form"
      >
        <Row>
          <Col span={4}>
            <Title level={5}>{t('store')}</Title>
          </Col>
          <Col span={4}>
            <Title level={5}>{t('peopleCountingProduct')}</Title>
          </Col>
          <Col span={4}>
            <Title level={5}>{t('peopleCountingGenderAgeProduct')}</Title>
          </Col>
          <Col span={4}>
            <Title level={5}>{t('personnelTrackingProduct')}</Title>
          </Col>
          <Col span={4}>
            <Title level={5}>{t('instoreAnalyticsProduct')}</Title>
          </Col>
          <Col span={4}>
            <Title level={5}>{t('checkout')}</Title>
          </Col>
        </Row>

        <Row className="all-store-products-row">
          <Col span={4}>
            <Text strong underline>
              {t('allStores')}
            </Text>
          </Col>
          <Col span={4}>
            <Checkbox
              name="all-PeopleCountingProduct"
              onChange={onAllProductClick}
              checked={checkedProducts.includes('all-PeopleCountingProduct')}
            />
          </Col>
          <Col span={4}>
            <Checkbox
              name="all-PeopleCountingGenderAgeProduct"
              onChange={onAllProductClick}
              checked={checkedProducts.includes(
                'all-PeopleCountingGenderAgeProduct'
              )}
            />
          </Col>
          <Col span={4}>
            <Checkbox
              name="all-PersonnelTrackingProduct"
              onChange={onAllProductClick}
              checked={checkedProducts.includes('all-PersonnelTrackingProduct')}
            />
          </Col>
          <Col span={4}>
            <Checkbox
              name="all-InstoreAnalyticsProduct"
              onChange={onAllProductClick}
              checked={checkedProducts.includes('all-InstoreAnalyticsProduct')}
            />
          </Col>
          <Col span={4}>
            <Checkbox
              name="all-CheckoutProduct"
              onChange={onAllProductClick}
              checked={checkedProducts.includes('all-CheckoutProduct')}
            />
          </Col>
        </Row>
        {storeProducts?.data?.map(product => (
          <Row className="store-product-row" key={product.Id}>
            <Col span={4}>
              <Text italic>{product.Name}</Text>
            </Col>
            <Col span={4}>
              <Checkbox
                name={product.Id + '-PeopleCountingProduct'}
                onChange={onProductClick}
                checked={isChecked(product.Id + '-PeopleCountingProduct')}
              />
            </Col>
            <Col span={4}>
              <Checkbox
                name={product.Id + '-PeopleCountingGenderAgeProduct'}
                onChange={onProductClick}
                checked={isChecked(
                  product.Id + '-PeopleCountingGenderAgeProduct'
                )}
              />
            </Col>
            <Col span={4}>
              <Checkbox
                name={product.Id + '-PersonnelTrackingProduct'}
                onChange={onProductClick}
                checked={isChecked(product.Id + '-PersonnelTrackingProduct')}
              />
            </Col>
            <Col span={4}>
              <Checkbox
                name={product.Id + '-InstoreAnalyticsProduct'}
                onChange={onProductClick}
                checked={isChecked(product.Id + '-InstoreAnalyticsProduct')}
              />
            </Col>
            <Col span={4}>
              <Checkbox
                name={product.Id + '-CheckoutProduct'}
                onChange={onProductClick}
                checked={isChecked(product.Id + '-CheckoutProduct')}
              />
            </Col>
          </Row>
        ))}

        <Divider />
        <ModalFooter
          formKey="store-products-form"
          sendButtonDisabled={!isEnvAdmin}
        />
      </Form>
    </>
  );
}

export default StoreProductsForm;
