import { PlusCircleOutlined } from '@ant-design/icons';
import LensTypes from '@Types/LensType';
import LensTypeService from '@Services/Api/LensType';
import FormCard from '@Components/FormCard';
import { apiCall } from '@Utils';
import { useNavigate } from 'react-router-dom';
import LensTypeForm from '@Forms/LensForm';
import LensForm from '@Forms/LensForm';

const NewLens = () => {
  let navigate = useNavigate();

  const handleSubmit = async (newLensType: LensTypes.NewLensType) => {
    let newLens: LensTypes.NewLensType = {
      Name: newLensType.Name,
    };
    try {
      await apiCall(() => addLensType(newLens), 'newLensType');
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const addLensType = async (newLensType: LensTypes.NewLensType) => {
    return await new LensTypeService().CreateLensType(newLensType);
  };

  return (
    <FormCard titleIcon={<PlusCircleOutlined />} titleKey="addLens">
      <LensForm onFinish={handleSubmit} />
    </FormCard>
  );
};

export default NewLens;
