import { StatusOption } from '@Types/CameraRecords';
import { convertCameraRecordProcessEnum } from '@Utils/CameraRecords';
import { Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.scss';

type Props = {
  onChange: (status: number) => void;
  value: any;
};

export default function RecordProcessStatusFilter({ onChange, value }: Props) {
  const { t } = useTranslation();

  return (
    <Select
      onChange={onChange}
      className="status-dropdown"
      value={value}
      allowClear
      placeholder={t('recordProcessStatusFilter')}
    >
      {statusOptions.map(option => (
        <Select.Option value={option.status} key={option.status}>
          <Tag color={option.color}>
            {convertCameraRecordProcessEnum(option.status)}
          </Tag>
        </Select.Option>
      ))}
    </Select>
  );
}

const statusOptions: StatusOption[] = [
  { status: 1, color: 'lime' },
  { status: 2, color: 'geekblue' },
  { status: 3, color: 'processing' },
  { status: 9, color: 'error' },
  { status: 4, color: 'success' },
  { status: 5, color: 'volcano' },
];
