import React, { ReactElement } from 'react';
import { ParamContextProvider } from './Context/Params';
import { SvgContextProvider } from './Context/Svg';
import { SvgProps } from './Types/Svg';

function SvgProvider({ ...props }: SvgProps): ReactElement {
  return (
    <ParamContextProvider>
      <SvgContextProvider
        ShapeOnChange={props.getShapes}
        defaultShapes={props.defaultShapes}
      >
        {props.children}
      </SvgContextProvider>
    </ParamContextProvider>
  );
}
SvgProvider.defaultProps = {
  getShapes: () => {},
};
export default SvgProvider;
