import FormCard from '@Components/FormCard';
import { BarsOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getBrandById } from '@Store/Brand/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import StoreProductsForm from '@Forms/StoreProductsForm';
import './style.scss';
import { StoreProduct } from '@Types/Brand';
import StoresService from '@Services/Api/Stores';
import { apiCall } from '@Utils';

function StoreProducts() {
  const currentBrand = useAppSelector(s => s.Brand.currentBrand.data);
  const storeProducts = useAppSelector(s => s.Brand.storeProducts.data);

  const dispatch = useAppDispatch();
  const { brandId } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(getBrandById(parseInt(brandId!)));
  }, []);

  const handleSubmit = async (storeProducts: StoreProduct[]) => {
    try {
      await apiCall(
        () => editStoreProducts(storeProducts),
        'editStoreProducts'
      );
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const editStoreProducts = async (storeProducts: StoreProduct[]) => {
    return await new StoresService().EditStoreProducts(storeProducts);
  };

  return (
    <FormCard
      titleIcon={<BarsOutlined />}
      titleKey="storeProducts"
      extraTitle={currentBrand.Name}
      className="store-products-wrapper"
      showGoBack={storeProducts.length === 0}
    >
      <StoreProductsForm onFinish={handleSubmit} />
    </FormCard>
  );
}

export default StoreProducts;
