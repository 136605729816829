import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

function Region() {
  return (
    <Layout id="region-page">
      <Outlet />
    </Layout>
  );
}

export default Region;
