import { Typography } from 'antd';
import './style.scss';

interface ChatBubbleProps {
  text: string;
  date: string;
  sender: string;
  align?: 'left' | 'right';
}

export function ChatBubble(props: ChatBubbleProps) {
  const { date, sender, text, align = 'left' } = props;

  return (
    <div className={`chat-bubble ${align === 'right' ? 'right-bubble' : ''}`}>
      <Typography.Text className="chat-text">{text}</Typography.Text>

      <div className="chat-bubble-info">
        <Typography.Text className="info-text">{sender}</Typography.Text>
        <Typography.Text className="info-text">{date}</Typography.Text>
      </div>
    </div>
  );
}
