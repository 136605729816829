import { Form, Input, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  image: string;
};

const Captcha = ({ image }: Props) => {
  const { t } = useTranslation();
  return (
    <Space direction="vertical" align="center">
      <img src={image} />
      <Form.Item
        label={t('Captcha')}
        name="captcha_value"
        rules={[{ required: true, message: t('loginForm.errors.captcha') }]}
      >
        <Input type="text" placeholder={t('Captcha')} />
      </Form.Item>
    </Space>
  );
};

export default Captcha;
