export const GET_ALL = 'BRAND/GET_ALL';
export const GET_ALL_WITHOUT_PAGINATION = 'BRAND/GET_ALL_WITHOUT_PAGINATION';
export const GET_ALL_AVAILABLE = 'BRAND/GET_ALL_AVAILABLE';
export const GET_ALL_ARCHIVED_AND_UNARCHIVED =
  'BRAND/GET_ALL_ARCHIVED_AND_UNARCHIVED';
export const GET_BRAND = 'BRAND/GET_BRAND';
export const GET_BRAND_IMAGE = 'BRAND/GET_BRAND_IMAGE';
export const GET_BRAND_HOMEPAGE_PHOTO = 'BRAND/GET_BRAND_HOMEPAGE_PHOTO';
export const GET_API_KEY_LIST = 'BRAND/GET_API_KEY_LIST';
export const GET_STORE_PRODUCTS = 'BRAND/GET_STORE_PRODUCTS';

export const SET_SELECTED_BRAND = 'BRAND/SET_SELECTED_BRAND';
export const SET_CURRENT_BRAND = 'BRAND/SET_CURRENT_BRAND';
export const SET_SELECTED_BRANDS = 'BRAND/SET_SELECTED_BRANDS';

export const RESET_SELECTED_BRAND = 'BRAND/RESET_SELECTED_BRAND';

export const GET_BRANDS_REGIONS = 'GET_BRANDS_REGIONS';

export const GET_BRANDS_UNARCHIVE_CAMERAS = 'GET_BRANDS_UNARCHIVE_CAMERAS';
