import Tab from '@Components/Tab';
import VersionService from '@Services/Api/Version';
import { useAppDispatch } from '@Store/hooks';
import { Button } from 'antd';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { getVersionFileGroups } from '@Store/Version/action';
import { PlusCircleOutlined } from '@ant-design/icons';
import { VersionFile } from '@Enums/VersionFileEnums';
import { apiCall } from '@Utils/index';
import { useGetVersionFiles, useVersionFiles } from '@Features/versions/hooks';
import { VersionFileGroupList } from '@Features/versions/components';
import { CreateVersionFileGroupModal } from '@Features/versions/modals';
import { IVersionFileGroupForm } from '@Features/versions/forms';
import useBoolean from '@Hooks/useBoolean';

export default function VersionFilesTable() {
  const [selectedVersion, setSelectedVersion] = useState<VersionFile>(
    VersionFile.MAIN
  );

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { name } = useVersionFiles(selectedVersion);

  const getVersionFiles = useGetVersionFiles(selectedVersion);

  const createVersionFileGroupModalState = useBoolean();

  function handleTabChange(version: VersionFile) {
    setSelectedVersion(version);
  }

  useEffect(() => {
    getVersionFiles();
  }, [selectedVersion]);

  async function handleNewVersionFileGroupClick(values: IVersionFileGroupForm) {
    try {
      await apiCall(() =>
        new VersionService().CreateVersionFileGroup({
          ...values,
          Type: selectedVersion,
        })
      );
      dispatch(getVersionFileGroups({ type: selectedVersion }));
      createVersionFileGroupModalState.setFalse();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="versionFilesTable mt-3">
      <Tab
        onChange={val => handleTabChange(parseInt(val))}
        tabContent={[
          'mainFiles',
          'modelFiles',
          'controlFiles',
          'beaconFiles',
          'packageFiles',
          'bleFiles',
        ]}
        tabBarExtraContent={
          <>
            <Button
              icon={<PlusCircleOutlined />}
              onClick={createVersionFileGroupModalState.setTrue}
            >
              {t('new')} {name}
            </Button>

            {createVersionFileGroupModalState.value && (
              <CreateVersionFileGroupModal
                modalState={createVersionFileGroupModalState}
                onFinish={handleNewVersionFileGroupClick}
              />
            )}
          </>
        }
      >
        <VersionFileGroupList versionFileType={selectedVersion} />
      </Tab>
    </div>
  );
}
