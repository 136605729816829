export enum TicketType {
  NO_DATA,
  REQUEST_REPORT,
  INCORRECT_CALCULATION,
  SOUND_COMING_FROM_DEVICE,
  REQUEST_PANNEL_REVIEW,
  OTHER,
}

export enum TicketReportType {
  WEEKLY,
  MONTHLY,
  CAMPAIGN,
}

export enum TicketStatus {
  PENDING,
  RESPONDED,
  CLOSED,
}
