import FormCard from '@Components/FormCard';
import VersionForm from '@Forms/VersionForm';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getCodeVersionsById, resetCodeVersions } from '@Store/Version/action';
import * as VersionTypes from '@Types/Version';
import VersionService from '@Services/Api/Version';
import PageSkeletons from '@Components/PageSkeletons';
import { apiCall } from '@Utils';
import useBoolean from '@Hooks/useBoolean';
import Status from '@Enums/Status';

type Props = {};

export default function VersionEdit({}: Props) {
  const { versionId } = useParams();
  const dispatch = useAppDispatch();
  const codeVersion = useAppSelector(s => s.Version.codeVersion);
  let navigate = useNavigate();
  const alreadyFetchedState = useBoolean();

  useEffect(() => {
    if (versionId) {
      dispatch(getCodeVersionsById(parseInt(versionId)));
    }

    return () => {
      dispatch(resetCodeVersions());
    };
  }, [versionId]);

  const handleSubmit = async (newVersion: VersionTypes.VersionForm) => {
    let editedVersionData: VersionTypes.NewVersion = {
      Name: newVersion.Name,
      Version: newVersion.Version,
      CompMain: { Id: newVersion.CompMainId },
      CompModel: { Id: newVersion.CompModelId },
      CompBeacon: { Id: newVersion.CompBeaconId },
      CompControl: { Id: newVersion.CompControlId },
      HardwareId: newVersion.HardwareId,
      ImageVersion: newVersion.ImageVersion,
    };
    try {
      await apiCall(() => addVersion(editedVersionData), 'editVersion');
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const addVersion = async (newVersion: VersionTypes.NewVersion) => {
    return await new VersionService().EditVersion(
      parseInt(versionId!),
      newVersion
    );
  };

  if (
    !alreadyFetchedState.value &&
    (codeVersion.status === Status.pending ||
      codeVersion.status === Status.empty)
  ) {
    return <PageSkeletons />;
  }

  return (
    <FormCard
      className="version-form"
      titleIcon={<EditOutlined />}
      titleKey="editVersion"
    >
      <VersionForm
        initialValues={{
          ...codeVersion.data,
          CompMainId: codeVersion.data?.CompMain?.Id,
          CompModelId: codeVersion.data?.CompModel?.Id,
          CompControlId: codeVersion.data?.CompControl?.Id,
          CompBeaconId: codeVersion.data?.CompBeacon?.Id,
        }}
        onFetch={alreadyFetchedState.setTrue}
        onFinish={handleSubmit}
      />
    </FormCard>
  );
}
