import Card from '@Components/Card';
import { useTranslation } from 'react-i18next';
import { AuditLog } from '@Types/AuditLogs';
import { Space, Typography } from 'antd';
import FilledText from '@Components/FilledText';
import InfoItem from '@Components/InfoItem';

const { Text, Title } = Typography;

type Props = {
  auditLog: AuditLog;
};

export default function AuditLogCard({
  auditLog: {
    CreatedDate,
    ActionData,
    IPAddress,
    Message,
    Type,
    Url,
    UserAgent,
    UserName,
  },
}: Props) {
  const { t } = useTranslation();

  return (
    <Card
      className="audit-log-card"
      content={{
        firsItem: {
          custom: (
            <Space direction="vertical" size="small" wrap>
              <Title level={5} title={UserName}>
                {UserName}
              </Title>
              <Text italic title={Url}>
                {Url}
              </Text>
            </Space>
          ),
        },
        secondItem: {
          custom: <FilledText text={t(Type) as string} />,
        },
      }}
      details={[
        {
          key: { text: t('ActionData') },
          value: { text: <InfoItem content={ActionData} /> },
        },
        { key: { text: t('IP Address') }, value: { text: IPAddress } },
        {
          key: { text: t('Message') },
          value: { text: <InfoItem content={Message} /> },
        },
        {
          key: { text: t('UserAgent') },
          value: { text: <InfoItem content={UserAgent} /> },
        },
        { key: { text: t('CreatedDate') }, value: { text: CreatedDate } },
      ]}
    />
  );
}
