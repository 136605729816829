import { BeaconRuleEnum } from '@Features/beaconRules/enums';
import { Form, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.scss';

export function BeaconRuleTypeSelector() {
  const { t } = useTranslation();

  const typeRadioButtons = [
    { value: BeaconRuleEnum.Closed, label: t('beaconRules.closed') },
    {
      value: BeaconRuleEnum.LowBatteryLevel,
      label: t('beaconRules.lowBatteryLevel'),
    },
    {
      value: BeaconRuleEnum.SignalFixedAreaLongTime,
      label: t('beaconRules.signalFixedAreaLongTime'),
    },
  ];

  const inputRule = [{ required: true, message: t('cannotBeEmpty') }];

  return (
    <Form.Item
      className="beacon-rule-type-selector"
      label={t('Type')}
      name="Type"
      rules={inputRule}
    >
      <Radio.Group name="radiogroup">
        {typeRadioButtons.map(({ value, label }) => (
          <Radio key={value} value={value}>
            {label}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
}
