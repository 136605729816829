import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_ALL } from './type';
import { PaginationParams } from '@Types/Filter';
import CameraRecordsService from '@Services/Api/CameraRecords';
import { GetCamRecordsParams } from '@Types/CameraRecords';

export const getAllCamRecords = createAsyncThunk(
  GET_ALL,
  async (params: GetCamRecordsParams) => {
    try {
      const allCamRecords = await new CameraRecordsService().GetAll(params);
      return allCamRecords;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
