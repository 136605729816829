import UdentifyApi from '.';

import {
  GetTicketsRequest,
  GetTicketsResponse,
  IChangeTicketStatusPayload,
  ICreateTicketMessagePayload,
  ICreateTicketPayload,
  ITicketDetails,
} from '@Types/ticket.types';

export class TicketService extends UdentifyApi {
  constructor() {
    super();
    this.Prefix('Ticket');
  }

  async CreateTicket(payload: ICreateTicketPayload) {
    return this.Data(payload).Post('CreateTicket').Save();
  }

  async GetTickets(request: GetTicketsRequest) {
    return this.Data(request)
      .offset()
      .Post('GetAll')
      .Save<GetTicketsResponse>();
  }

  async GetTicket(id: number) {
    return this.PrefixId(id).offset().Get('').Save<ITicketDetails>();
  }

  async CreateTicketMessage(payload: ICreateTicketMessagePayload) {
    return this.Data(payload).Post('CreateTicketMessage').Save();
  }

  async ChangeTicketStatus(payload: IChangeTicketStatusPayload) {
    return this.PrefixId(payload.ticketId)
      .Data(payload)
      .Put(`Status/${payload.ticketStatus}`)
      .Save();
  }

  async GetMyTickets(request: GetTicketsRequest) {
    return this.Data(request)
      .offset()
      .Post('MyTickets')
      .Save<GetTicketsResponse>();
  }
}
