import { useMemo } from 'react';
import { CameraStatusesDetail } from '@Types/Camera';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// import './style.scss';
import { Variant } from '@Enums/Status';
import Title from 'antd/lib/typography/Title';
import { convertApiDate } from '@Utils/index';
import USteps from '@Components/USteps';
import UStep from '@Components/USteps/UStep';
type Props = {
  data?: CameraStatusesDetail;
  Label: any;
};
type recordPayloadType = {
  current: CameraStatusesDetail['BoardWorkerStatus'][0];
  next: CameraStatusesDetail['BoardWorkerStatus'][0];
  startDate?: string;
  endDate?: string;
};
type recordType = Pick<
  RecordProps<recordPayloadType>,
  'value' | 'type' | 'payload'
>;
function CamTimeLineCard({ data, Label }: Props) {
  const { t } = useTranslation();
  const dataSet = useMemo(() => {
    return (
      data &&
      data.BoardWorkerStatus.reduce<recordType[]>((sets, currentDate) => {
        const startDate = moment(currentDate.StartAt);
        const endDate = moment(currentDate.EndAt);
        const diff = moment.duration(endDate.diff(startDate));
        const startDateFormat = convertApiDate(currentDate.StartAt, true);
        const endDateFormat = convertApiDate(currentDate.EndAt, true);
        const diffSecs = diff.asSeconds();
        sets.push({
          value: diffSecs,
          type: currentDate.Status === 0 ? Variant.error : Variant.success,
          payload: {
            current: currentDate,
            next: currentDate,
            startDate: startDateFormat,
            endDate: endDateFormat,
          },
        });
        return sets;
      }, [])
    );
  }, [data]);
  return (
    <>
      <Title level={5} className="first-item-text mt-5">
        {t('camAppStatus')}
      </Title>
      {dataSet && dataSet.length > 0 ? (
        <CameraStatusTimeLine records={dataSet} />
      ) : (
        <h5>-</h5>
      )}
    </>
  );
}
interface CameraStatusTimeLineProps {
  records: recordType[];
}

const CameraStatusTimeLine = ({ records }: CameraStatusTimeLineProps) => {
  const { t } = useTranslation();
  if (records.length === 0) return <></>;
  const firstdate = moment(records[0].payload?.current.StartAt);
  const lastDate = moment(records[records.length - 1].payload?.current.EndAt);
  const duration = moment.duration(lastDate.diff(firstdate)).asSeconds();
  const getDurationString = (record: recordType) => {
    const stringValue =
      t('Camera-Status.OpenClose', {
        startDate: record.payload?.startDate,
        endDate: record.payload?.endDate,
      }) +
      t('status') +
      ':' +
      t('Camera-Status.Duration', {
        duration: moment(record.payload?.current.EndAt).diff(
          moment(record.payload?.current.StartAt),
          'm'
        ),
      }) +
      (record.payload?.current.Status === 0 ? t('off') : t('on'));
    return stringValue;
  };

  return (
    <USteps>
      {records.map((record, index) => (
        <Tooltip
          key={index}
          title={getDurationString(record)}
          color={'#7a7a7a'}
        >
          <UStep
            payload={record}
            maxRecord={duration}
            content={getDurationString(record)}
          />
        </Tooltip>
      ))}
    </USteps>
  );
};
// Ustep record base props
interface RecordProps<T = unknown> {
  value: number;
  type: Variant;
  payload?: T;
}

export default CamTimeLineCard;
