import Card from '@Components/Card';
import HierarchyItems from '@Components/Card/HierarchyItems';
import PageSkeletons from '@Components/PageSkeletons';
import Charts from '@Pages/BeaconData/Charts';
import { BeaconsData, BeaconDataLabels } from '@Types/Beacon';

type Props = {
  data: BeaconsData;
  Label?: BeaconDataLabels[];
  Name: string;
  tooltipLabel?: string;
  status?: string;
};

function BeaconDataCard({ data, Label, Name, tooltipLabel, status }: Props) {
  if (status === 'Pending') return <PageSkeletons />;
  return (
    <Card
      content={{
        firsItem: {
          text: Name,
        },
        secondItem: {
          custom: (
            <HierarchyItems storeId={data.storeId} store={data.storeName} />
          ),
        },
      }}
      isDetailAlwaysOpen
      chartContent={
        <Charts data={data} Label={Label} tooltipLabel={tooltipLabel} />
      }
    />
  );
}

export default BeaconDataCard;
