import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import './style.scss';
import NewDevicesForm from './Components/NewDevicesForm';
import { DevicesData, NewDevices } from '@Types/Devices';
import { useState } from 'react';

type Props = {
  onSubmit: (newDevices: DevicesData) => void;
  devices?: DevicesData;
};

function DevicesSelector({ onSubmit, devices }: Props) {
  const [devicesData, setdevicesData] = useState<DevicesData>(devices || []);

  const handleRangeChange = (newDevices: NewDevices) => {
    const newDevice = [...devicesData];
    newDevice.push(newDevices);
    setdevicesData(newDevice);
    onSubmit(newDevice);
  };
  return (
    <div className="devices-selector">
      <Popover
        trigger="click"
        content={<NewDevicesForm onSubmit={handleRangeChange} />}
      >
        <Button icon={<PlusCircleOutlined />} className="add-devices-button" />
      </Popover>
    </div>
  );
}

export default DevicesSelector;
