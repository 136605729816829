import { HomepageContextProvider } from '@Features/homepage/contexts';
import './style.scss';

interface IProps {
  children: React.ReactNode;
}

export function HomepageContainer({ children }: IProps) {
  return (
    <HomepageContextProvider>
      <div className="home-page-wrapper">{children}</div>
    </HomepageContextProvider>
  );
}
