import { DeleteOutlined, SendOutlined } from '@ant-design/icons';
import CardButton from '@Components/CardButton';
import { TicketService } from '@Services/Api/TicketService';
import { ITicketDetails } from '@Types/ticket.types';
import { apiCall } from '@Utils/index';
import { Input } from 'antd';
import { ChangeEvent, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { getTicketDetails } from '@Store/Ticket/action';
import { useAppDispatch } from '@Store/hooks';
import useBoolean from '@Hooks/useBoolean';

interface TicketMessageProps {
  ticket: ITicketDetails;
}

export function TicketMessage(props: TicketMessageProps) {
  const { ticket } = props;

  const [message, setMessage] = useState<string>('');

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const sending = useBoolean();

  function handleClearClick() {
    setMessage('');
  }

  function handleSendClick(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    sending.setTrue();

    apiCall(
      () =>
        new TicketService().CreateTicketMessage({
          ticketId: ticket.Id,
          message,
        }),
      undefined,
      () => {
        handleClearClick();
        dispatch(getTicketDetails(Number(ticket.Id)));
        sending.setFalse();
      }
    );
  }

  function handleMessageChange(e: ChangeEvent<HTMLInputElement>) {
    setMessage(e.target.value);
  }

  return (
    <div className="chat-sender">
      <Input
        value={message}
        onChange={handleMessageChange}
        placeholder={t('chatMessagePlaceholder')}
      />
      <div className="chat-sender-actions">
        <CardButton
          icon={<DeleteOutlined />}
          size="middle"
          onClick={handleClearClick}
        />
        <CardButton
          icon={<SendOutlined />}
          size="middle"
          onClick={handleSendClick}
          disabled={sending.value || !message}
        />
      </div>
    </div>
  );
}
