import DeleteButton from '@Components/Card/DeleteButton';
import EditButton from '@Components/Card/EditButton';
import useIsEnvAdmin from '@Hooks/useIsEnvAdmin';
import VersionService from '@Services/Api/Version';
import { AllVersion } from '@Types/Version';
import { apiCall } from '@Utils/index';
import { Space } from 'antd';

interface Props {
  version: AllVersion;
  getVersions: () => void;
}

export default function VersionActionButtons({ version, getVersions }: Props) {
  async function handleVersionDelete() {
    const req = () => new VersionService().DeleteVersion(version.Id);
    await apiCall(req, 'deleteVersion');
    getVersions();
  }

  const isEnvAdmin = useIsEnvAdmin();

  if (!isEnvAdmin) {
    return <></>;
  }

  return (
    <Space direction="horizontal" size="small" wrap>
      <EditButton to={`${version.Id}`} />
      {!(version.BoardCount > 0) && (
        <DeleteButton onConfirm={handleVersionDelete} />
      )}
    </Space>
  );
}
