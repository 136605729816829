import Status from '@Enums/Status';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import FilledText from '@Components/FilledText';
import { AllGateway, AllGateways } from '@Types/Gateway';
import GatewayActionButtons from '@Cards/GatewayCard/GatewayActionButtons';
import HierarchyItems from '@Components/Card/HierarchyItems';

interface Params {
  gateways: AllGateways;
  getGateways: () => void;
}

/**
 * @description This "hook" is not a generic hook. It's made to abstract the code and make it much more readable. It will only be used for <Users/>.
 */
export default function useGatewaysColumns(
  props: Params
): ColumnsType<AllGateway> {
  const { gateways } = props;

  const gatewaysActionPerm = useCheckPermission(
    Permissions.ADMIN_GATEWAY_ACTION
  );

  const { t } = useTranslation();

  const gatewaysTableColumns: ColumnsType<AllGateway> = useMemo(() => {
    if (gateways.status !== Status.success) return [];

    const columns: ColumnsType<AllGateway> = [
      {
        title: t('ID'),
        dataIndex: 'Id',
      },
      {
        title: t('entityIds'),
        dataIndex: 'EntityIds',
        render: (_: any, gateway: AllGateway) => (
          <HierarchyItems
            brandId={gateway.BrandId}
            storeId={gateway.StoreId}
            brand={gateway.Brand}
            store={gateway.Store}
          />
        ),
      },
      {
        title: t('Kurulu mu ?'),
        dataIndex: 'Setup',
        render: (value: boolean) => (
          <FilledText text={value === false ? 'Kurulu Değil' : 'Kurulu '} />
        ),
      },
      {
        title: t('LastOnlineDate'),
        dataIndex: 'LastOnlineDate',
        render: (value: string) =>
          t('gatewaySubItemTitle', {
            date: value ? new Date(value) : '-',
          }),
      },
      {
        title: t('ExternalId'),
        dataIndex: 'ExternalId',
        render: (value: string) => <FilledText text={value} />,
      },
      {
        title: t('createdDate'),
        dataIndex: 'CreatedDate',
        render: (value: string) => <FilledText text={value} />,
      },
    ];

    if (gatewaysActionPerm) {
      columns.push({
        title: t('actionButtons'),
        dataIndex: 'actionButtons',
        render: (_: any, gateway: AllGateway) => (
          <GatewayActionButtons {...props} gateway={gateway} />
        ),
      });
    }

    return columns;
  }, [gateways.status]);

  return gatewaysTableColumns;
}
