import { Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.scss';

interface Props {
  children: React.ReactNode;
  defaultValue: string;
  onChange: (value: string) => void;
}

const SortByFilter = ({ children, defaultValue, onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="sort-filter-container">
      <Tooltip title={t('sortBy')}>
        <Select
          placeholder={t('sortBy')}
          defaultValue={defaultValue}
          onChange={onChange}
          dropdownMatchSelectWidth={false}
        >
          {children}
        </Select>
      </Tooltip>
    </div>
  );
};

export default SortByFilter;
