import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

function Gateways() {
  return (
    <Layout id="gateways-page">
      <Outlet />
    </Layout>
  );
}

export default Gateways;
