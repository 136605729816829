import { Option } from 'antd/lib/mentions';
import { HomeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import SortByFilter from '@Components/SortByFilter';

type Props = {
  onChange: (value: string) => void;
  defaultValue: string;
};

const SortByFilterAuditLogs = ({ onChange, defaultValue }: Props) => {
  const { t } = useTranslation();
  const iconStyle = { paddingLeft: 6 };
  return (
    <SortByFilter defaultValue={defaultValue} onChange={onChange}>
      <Option value="Name">
        {t('Name')}
        <HomeOutlined style={iconStyle} />
      </Option>
    </SortByFilter>
  );
};
export default SortByFilterAuditLogs;
