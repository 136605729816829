import RegionForm from '@Forms/RegionForm';
import { resetRegion } from '@Store/Regions/action';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

type Props = {};

function CreateRegion({}: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetRegion());
  }, []);

  return (
    <div>
      <RegionForm newRegion={true} />
    </div>
  );
}

export default CreateRegion;
