import { useTranslation } from 'react-i18next';
import { Divider, Form, Modal, Select } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { CameraRecordProcess } from '@Types/CameraRecords';
import { useAppSelector } from '@Store/hooks';
import { ConfigSchemaId, OptionType } from '@Types/Config';
import JsonSchemaForm from '@Components/JsonSchema/Form';
import { IChangeEvent } from '@rjsf/core';
import { convertConfigTypeToEnum } from '@Utils/Config';
import SearchableSelect from '@Components/SearchableSelect';
import { DefaultOptionType } from 'antd/lib/select';
import { RecordProcessContext } from '@Pages/CameraRecords/Contexts';
import { RJSFSchema } from '@rjsf/utils';

type Props = {
  onConfirm: (config: string, configSchemaId: ConfigSchemaId) => void;
  recordProcess: CameraRecordProcess;
};

const SetConfigModal = ({ onConfirm, recordProcess }: Props) => {
  const [configValues, setConfigValues] = useState(
    recordProcess?.Config ? JSON.parse(recordProcess.Config) : {}
  );
  const [selectedConfigId, setSelectedConfigId] = useState<ConfigSchemaId>(
    recordProcess?.ConfigSchemaId || null
  );

  const { showConfigModal, setShowConfigModal } =
    useContext(RecordProcessContext);

  const configSchemas = useAppSelector(s => s.Config.configSchemas.data);
  const isFirstRender = useRef(true);
  const { t } = useTranslation();

  useEffect(() => {
    // Reset the config values after config schema is changed
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      setConfigValues({});
    }
  }, [selectedConfigId]);

  const confirm = () => {
    const values = JSON.stringify(configValues);
    onConfirm(values, selectedConfigId);
  };

  const selectedConfigHandler = (
    _: any,
    option: DefaultOptionType | DefaultOptionType[]
  ) => {
    const castedOption = option as OptionType;
    setSelectedConfigId(castedOption?.value);
  };

  const formOnChange = (e: IChangeEvent<RJSFSchema>) => {
    setConfigValues(e.formData);
  };

  const handleCancelClick = () => {
    setShowConfigModal(false);
  };

  return (
    <Modal
      title={t('setConfig')}
      open={showConfigModal}
      onOk={confirm}
      onCancel={handleCancelClick}
      okButtonProps={{ disabled: !configValues }}
    >
      <Form.Item label={t('config')} name="ConfigSchemaId">
        <SearchableSelect
          allowClear
          onChange={selectedConfigHandler}
          defaultValue={selectedConfigId}
        >
          {configSchemas
            .filter(
              c =>
                c.ConfigSchemaType === convertConfigTypeToEnum('camera-record')
            )
            .map(c => (
              <Select.Option value={c.Id} key={c.Id} label={c.Name}>
                {c.Name}
              </Select.Option>
            ))}
        </SearchableSelect>
      </Form.Item>
      <Divider />

      <Form.Item
        label={t('schema')}
        name="ParametersJson"
        rules={[
          {
            validator: async (_, value: IChangeEvent<RJSFSchema> | null) => {
              if (!selectedConfigId) return Promise.resolve();

              if (value?.errors.length === 0) return Promise.resolve();

              return Promise.reject(t('notValidConfig'));
            },
          },
        ]}
      >
        {selectedConfigId ? (
          <JsonSchemaForm
            schema={JSON.parse(
              configSchemas.find(c => c.Id === selectedConfigId)?.Schema!
            )}
            onChange={formOnChange}
            formData={configValues}
            omitExtraData
            liveOmit
            liveValidate
          />
        ) : (
          '-'
        )}
      </Form.Item>
    </Modal>
  );
};

export default SetConfigModal;
