import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_ALL_GATEWAY, GET_SELECT_GATEWAY } from './type';
import GatewaysService from '@Services/Api/Gateway';
import { GetGatewayParams } from '@Types/Gateway';

export const getAllGateway = createAsyncThunk(
  GET_ALL_GATEWAY,
  async (params: GetGatewayParams) => {
    try {
      const allGateway = await new GatewaysService().GetAllGateways(params);
      return allGateway;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
export const getGatewayById = createAsyncThunk(
  GET_SELECT_GATEWAY,
  async (gatewayId: number) => {
    try {
      const gateway = await new GatewaysService().GetGatewayById(gatewayId);
      return gateway;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
