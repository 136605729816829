import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function usePageParamsQuery() {
  const location = useLocation();

  const { search } = location;

  const navigate = useNavigate();

  const urlParams = new URLSearchParams(search);

  const Params = (name: string): string | null => {
    return urlParams.get(name);
  };

  const set = (key: string, value: any) => {
    if (urlParams.has(key)) {
      urlParams.set(key, value);
    } else {
      urlParams.append(key, value);
    }
    const to = { pathname: location.pathname, search: urlParams.toString() };
    navigate(to, { replace: true });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ({ Params, set }), [search]);
}
