import FieldSearchService from '@Services/Api/FieldSearches';
import { CreateFieldSearchRequest } from '@Types/FieldSearches';
import { apiCall } from '@Utils';
import { Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useContext } from 'react';
import { FieldSearchesContext } from '../../Contexts';
import AddFieldSearchForm, {
  AddFieldSearchFormType,
} from '../AddFieldSearchForm';
import './style.scss';

export interface IAddFieldSearchItemProps {
  onSubmit: () => void;
}

export default function AddFieldSearchItem({
  onSubmit,
}: IAddFieldSearchItemProps) {
  const { retrieveFieldSearches } = useContext(FieldSearchesContext);

  const [form] = useForm<AddFieldSearchFormType>();

  const addFieldSearchItemHandler = async (values: AddFieldSearchFormType) => {
    try {
      const func = () =>
        new FieldSearchService().NewFieldSearch({
          ...values,
          Title: [
            { Name: values.TitleTr, Language: 'tr' },
            { Name: values.TitleEn, Language: 'en' },
            { Name: values.TitleDe, Language: 'de' },
          ],
        });

      await apiCall(func, 'createFieldSearch');
      await retrieveFieldSearches();

      form.resetFields();

      onSubmit();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Space align="center" direction="vertical">
      <AddFieldSearchForm onSubmit={addFieldSearchItemHandler} form={form} />
    </Space>
  );
}
