import { ReactElement, useContext, useRef } from 'react';
import ShapeElementProvider from './Components/ShapeElementProvider';
import { shapeContext } from './Context/Shape';
import { SvgContext } from './Context/Svg';
import {
  useContextMenu,
  useKeyDown,
  // , useSvgOnClick
} from './Events/Shape';
import Point from './Point';
import { ShapeTasks } from './Tasks/Shape';
import { TextTasks } from './Tasks/Text';
import Text from './Text';
import { shapeTypes } from './Types/Bases';
import { ShapeProps } from './Types/Shape';

function Shape(shapeProp: ShapeProps): ReactElement {
  const ShapeRef = useRef<SVGPathElement | SVGPolygonElement>(null);
  const { editable } = useContext(SvgContext);
  const { shape } = useContext(shapeContext);
  const { svgRef } = shapeProp;
  const { Points, Color, Name } = shape;
  useContextMenu(ShapeRef);
  // useSvgOnClick(svgRef);
  useKeyDown();
  return (
    <g ref={ShapeRef}>
      <ShapeElementProvider {...shape} />
      <Text Coordinates={TextTasks.getTextPosition(Points)} Name={Name} />
      {editable &&
        Points.map((Coordinates, i) => (
          <Point
            key={i}
            shape={shapeProp}
            queue={i}
            Coordinates={Coordinates}
            svgRef={svgRef}
          />
        ))}
    </g>
  );
}

export default Shape;
