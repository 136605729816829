import { useTranslation } from 'react-i18next';
import {
  ShopOutlined,
  HomeOutlined,
  AimOutlined,
  CameraOutlined,
} from '@ant-design/icons';
import { useMemo } from 'react';
import { CardEditItemProps } from '@Components/CardEditInfo/CardEditItem';
import { Permissions } from '@Enums/Permission';
import useCheckPermission from '@Hooks/useCheckPermission';
import CardEditInfo from '@Components/CardEditInfo';

type Props = {
  brandId?: string | number;
  storeId?: string | number;
  boardId?: string | number;
  cameraId?: string | number;
  brand?: string;
  store?: string;
  board?: string;
  camera?: string;
  textWidth?: string;
};

/*
  This component is used to represent ids of the current hierarchy that the card entity itself has.
  
  Example: 
      Store Card can only access brandId and storeId so it represents only 2 of them.
      Camera Card can access brandId, storeId, boardId and cameraId so it represents all 4 of them.
*/
const HierarchyItems = ({
  brandId,
  storeId,
  boardId,
  cameraId,
  brand,
  store,
  board,
  camera,
  textWidth,
}: Props) => {
  const brandPerm = useCheckPermission(Permissions.ADMIN_BRAND_VIEW);
  const storePerm = useCheckPermission(Permissions.ADMIN_STORE_VIEW);
  const boardPerm = useCheckPermission(Permissions.ADMIN_BOARD_VIEW);
  const cameraPerm = useCheckPermission(Permissions.ADMIN_CAMERA_VIEW);

  const items: CardEditItemProps[] = useMemo(() => {
    const temp: CardEditItemProps[] = [];
    if (brandId) {
      temp.push({
        icon: <HomeOutlined />,
        name: brand || '-',
        id: brandId,
        routeTo: `/brands/${brandId}/stores`,
        hasPermission: brandPerm,
      });
    }

    if (storeId) {
      temp.push({
        icon: <ShopOutlined />,
        name: store || '-',
        id: storeId,
        routeTo: `/brands/${brandId}/stores/${storeId}/boards`,
        hasPermission: storePerm && boardPerm,
      });
    }

    if (boardId) {
      temp.push({
        icon: <AimOutlined />,
        name: board || '-',
        id: boardId,
        routeTo: `/brands/${brandId}/stores/${storeId}/boards/${boardId}/cameras`,
        hasPermission: boardPerm && cameraPerm,
      });
    }

    if (cameraId) {
      temp.push({
        icon: <CameraOutlined />,
        name: camera || '-',
        id: cameraId,
        routeTo: `/brands/${brandId}/stores/${storeId}/boards/${boardId}/cameras/${cameraId}`,
        hasPermission: cameraPerm,
      });
    }

    return temp;
  }, [brandId, storeId, boardId, cameraId, brand, store, board, camera]);

  return <CardEditInfo items={items} hideDivider textWidth={textWidth} />;
};

export default HierarchyItems;
