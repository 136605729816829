import ContentLoader from '@Components/ContentLoader';
import FormCard from '@Components/FormCard';
import { UserForm } from '@Forms/UserForm';
import UserService from '@Services/Api/User';
import { getCurrentUser, setSelectedUser } from '@Store/User/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { IUserForm, EditUserForm, UserFetchResponse } from '@Types/User';
import { apiCall } from '@Utils/index';
import { EditOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export function EditUserPage() {
  const { status: userStatus, data: user } = useAppSelector(
    state => state.User.selectedUser
  );

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { userId } = useParams();

  useEffect(() => {
    if (userId) {
      dispatch(getCurrentUser(userId));
    }

    return () => {
      dispatch(setSelectedUser({} as UserFetchResponse));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmit(values: IUserForm) {
    const brandIds =
      values.BrandIds?.length! > 0
        ? values.BrandIds!
        : user.Brands.map(b => b.Id);

    const editedUser: EditUserForm = {
      ...values,
      BrandId: brandIds[0],
    };

    const req = () => new UserService().EditUser(userId!, editedUser);
    const success = await apiCall(req);

    if (success) {
      navigate(-1);
    }
  }

  return (
    <ContentLoader status={userStatus}>
      <FormCard titleIcon={<EditOutlined />} titleKey="editUser">
        <UserForm onFinish={handleSubmit} initialValues={user} />
      </FormCard>
    </ContentLoader>
  );
}
