import { EditOutlined } from '@ant-design/icons';
import FormCard from '@Components/FormCard';
import { Spin } from 'antd';
import { CameraDetail, EditedCamera } from '@Types/Camera';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { useEffect } from 'react';
import { getCameraDetail, resetCurrentCamera } from '@Store/Camera/action';
import CameraForm from '@Forms/CameraForm';
import CamerasService from '@Services/Api/Cameras';
import { apiCall } from '@Utils';

const EditCamera = () => {
  const camera = useAppSelector(
    s => s.Camera.currentCamera.data
  ) as CameraDetail;

  const { cameraId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (cameraId) {
      dispatch(getCameraDetail(parseInt(cameraId)));
    }

    return () => {
      dispatch(resetCurrentCamera());
    };
  }, [cameraId]);

  let navigate = useNavigate();

  const handleSubmit = async (editedCamera: EditedCamera) => {
    try {
      await apiCall(
        () =>
          editCamera({
            ...editedCamera,
            StoreId: camera.StoreId,
            BoardId: camera.BoardId,
          }),
        'editCamera'
      );
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const editCamera = async (editedCamera: EditedCamera) => {
    return new CamerasService().EditCamera(parseInt(cameraId!), editedCamera);
  };

  if (!camera?.Id) return <Spin />;
  return (
    <FormCard
      titleIcon={<EditOutlined />}
      titleKey="editCamera"
      className="camera-detail-wrapper"
    >
      <CameraForm onFinish={handleSubmit} camera={camera} />
    </FormCard>
  );
};

export default EditCamera;
