import { configureStore } from '@reduxjs/toolkit';
import AccountReducer from './Account/reducer';
import BeaconReducer from './Beacons/reducer';
import BoardReducer from './Board/reducer';
import BrandReducer from './Brand/reducer';
import CameraReducer from './Camera/reducer';
import CameraRecordReducer from './CameraRecords/reducer';
import CityReducer from './City/reducer';
import CodeVersionReducer from './CodeVersion/reducer';
import CountryReducer from './Country/reducer';
import CountyReducer from './County/reducer';
import DashboardReducer from './Dashboard/reducer';
import GatewayReducer from './Gateway/reducer';
import ParamsReducer from './Params/reducer';
import PermissionReducer from './Permission/reducer';
import RoleReducer from './Role/reducer';
import StoreReducer from './Store/reducer';
import UserReducer from './User/reducer';
import VersionReducer from './Version/reducer';
import CoordinateReducer from './Coordinates/reducer';
import ConfigReducer from './Config/reducer';
import ReceiverReducer from './Receiver/reducer';
import AuditLogReducer from './AuditLogs/reducer';
import CameraRulesReducer from './CameraRule/reducer';
import ReportsReducer from './Reports/reducer';
import BeaconRulesReducer from './BeaconRule/reducer';
import RegionsReducer from './Regions/reducer';
import HardwareReducer from './Hardware/reducer';
import LensTypeReducer from './LensType/reducer';
import NotificationReducer from './Notification/reducer';
import BrandGroupReducer from './BrandGroup/reducer';
import TicketReducer from './Ticket/reducer';

const reducer = {
  Ticket: TicketReducer,
  BrandGroup: BrandGroupReducer,
  Params: ParamsReducer,
  User: UserReducer,
  Account: AccountReducer,
  Role: RoleReducer,
  Permission: PermissionReducer,
  Dashboard: DashboardReducer,
  Brand: BrandReducer,
  Store: StoreReducer,
  Camera: CameraReducer,
  CameraRecord: CameraRecordReducer,
  Version: VersionReducer,
  Board: BoardReducer,
  CodeVersion: CodeVersionReducer,
  Country: CountryReducer,
  City: CityReducer,
  County: CountyReducer,
  Beacons: BeaconReducer,
  Gateaways: GatewayReducer,
  Coordinate: CoordinateReducer,
  Config: ConfigReducer,
  Receiver: ReceiverReducer,
  AuditLog: AuditLogReducer,
  CameraRules: CameraRulesReducer,
  Reports: ReportsReducer,
  BeaconRules: BeaconRulesReducer,
  Regions: RegionsReducer,
  Hardware: HardwareReducer,
  LensType: LensTypeReducer,
  Notification: NotificationReducer,
};

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
