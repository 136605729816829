import Status from '@Enums/Status';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { AllPageLogs, PageLog } from '@Types/PageLogs';
import FilledText from '@Components/FilledText';
import HierarchyItems from '@Components/Card/HierarchyItems';
import useWindowSize from '@Hooks/useWindowSize';
import { PageLogSourceTag } from './Components/PageLogSourceTag';
import { PageLogSource } from './Enums';

interface Params {
  pageLogs: AllPageLogs;
}

/**
 * @description This "hook" is not a generic hook. It's made to abstract the code and make it much more readable. It will only be used for <PageLogs/>.
 */
export default function usePageLogsColumns({
  pageLogs,
}: Params): ColumnsType<PageLog> {
  const windowSize = useWindowSize();
  const { t } = useTranslation();

  const pageLogsTableColumns: ColumnsType<PageLog> = useMemo(() => {
    if (pageLogs.status !== Status.success) return [];

    const columns: ColumnsType<PageLog> = [
      {
        title: t('UserName'),
        dataIndex: 'UserName',
        render: (value: string) => <FilledText text={value} />,
      },
      {
        title: t('URL'),
        dataIndex: 'Url',
        render: (value: string) => <FilledText text={value} />,
      },
      {
        title: t('seconds'),
        dataIndex: 'Seconds',
        render: (value: number) => <FilledText text={Math.round(value)} />,
      },
      {
        title: t('source'),
        dataIndex: 'Source',
        render: (value: PageLogSource) => <PageLogSourceTag source={value} />,
      },
      {
        title: t('entityIds'),
        dataIndex: 'EntityIds',
        render: (_: any, pageLog: PageLog) => (
          <HierarchyItems
            brandId={pageLog.BrandId}
            storeId={pageLog.StoreId}
            brand={pageLog.Brand}
            store={pageLog.Store}
          />
        ),
        width: windowSize?.width && windowSize.width > 1360 ? 300 : 160,
      },
      {
        title: t('UserId'),
        dataIndex: 'UserId',
        render: (value: string) => <FilledText text={value} />,
      },
      {
        title: t('createdDate'),
        dataIndex: 'CreatedDate',
        render: (value: string) => <FilledText text={value} />,
      },
    ];
    return columns;
  }, [pageLogs.status]);

  return pageLogsTableColumns;
}
