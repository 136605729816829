import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_COUNTIES, SET_SELECTED_COUNTY } from './type';
import { County } from '@Types/County';
import AdminService from '@Services/Api/Admin';

export const getCounties = createAsyncThunk(
  GET_COUNTIES,
  async (cityId: number) => {
    try {
      const cities = await new AdminService().GetCounties(cityId);
      return cities;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const setSelectedCounty = createAction(
  SET_SELECTED_COUNTY,
  (county: County | null) => {
    return {
      payload: county,
    };
  }
);
