import { ReactElement } from 'react';
import ExpandArrows from './ExpandArrows';

function Triangles(): ReactElement {
  return (
    <defs>
      <marker
        id="Triangle"
        markerWidth="24"
        markerHeight="25"
        refX="12"
        refY="12.5"
        orient="auto"
      >
        <ExpandArrows />
      </marker>
    </defs>
  );
}

export default Triangles;
