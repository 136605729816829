import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './style.scss';

export default function CreateReportButton() {
  const { t } = useTranslation();

  return (
    <Link to="/reports/new">
      <Button
        type="primary"
        icon={<PlusCircleOutlined />}
        className="create-report-button"
      >
        {t('reports.createReport')}
      </Button>
    </Link>
  );
}
