import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

function BeaconData() {
  return (
    <Layout id="beaconData-page">
      <Outlet />
    </Layout>
  );
}

export default BeaconData;
