import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

function Data() {
  return (
    <Layout id="data-page">
      <Outlet />
    </Layout>
  );
}

export default Data;
