import { Col, Row } from 'antd';
import ActionButton, { ActionButtonProps } from '@Components/ActionButton';
import './style.scss';

type Props = {
  actionButtons: ActionButtonProps[];
};

const ActionButtonColumn = ({ actionButtons }: Props) => {
  return (
    <Col className="action-button-column">
      {actionButtons.map(
        b =>
          b.hasPermission !== false && (
            <Row justify="center" align="middle" key={b.tooltip}>
              <ActionButton
                icon={b.icon}
                onClick={b.onClick}
                tooltip={b.tooltip}
                tooltipDirection="left"
              />
            </Row>
          )
      )}
    </Col>
  );
};

export default ActionButtonColumn;
