import { ShapesType } from '@Components/Svg/Types/Svg';
import { createContext, ReactElement, useState } from 'react';

interface connectCameraContextType {
  setCreateShape: (status: boolean) => void;
  createShape: boolean;
  groupName: string;
  setGroupName: (groupName: string) => void;
  ExistGroupNames: string[];
}

interface connectCameraContextProviderProps {
  children: ReactElement;
  ExistGroupNames: string[];
}

export const ConnectCameraContext = createContext<connectCameraContextType>({
  setCreateShape: () => {},
  createShape: false,
  groupName: '',
  setGroupName: () => {},
  ExistGroupNames: [],
});

export const ConnectCameraContextProvider = ({
  children,
  ExistGroupNames,
}: connectCameraContextProviderProps) => {
  const [createShape, setCreateShape] = useState(false);
  const [groupName, setGroupName] = useState('');
  return (
    <ConnectCameraContext.Provider
      value={{
        setCreateShape,
        createShape,
        groupName,
        setGroupName,
        ExistGroupNames,
      }}
    >
      {children}
    </ConnectCameraContext.Provider>
  );
};
