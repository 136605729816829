import { createReducer } from '@reduxjs/toolkit';
import { getCodeVersions } from './action';
import { CodeVersion } from '@Types/CodeVersion';
import { asyncFetchInterface } from '@Types/ReduxTypes';

interface stateInterface {
  codeVersions: asyncFetchInterface<CodeVersion[]>;
}

const initialState: stateInterface = {
  codeVersions: {
    data: [],
    status: 'Empty',
  },
};

const CodeVersionReducer = createReducer(initialState, builder => {
  builder
    .addCase(getCodeVersions.pending, state => {
      state.codeVersions = {
        ...state.codeVersions,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getCodeVersions.fulfilled, (state, action) => {
      state.codeVersions.data = action.payload;
      state.codeVersions.status = 'fulfilled';
    })
    .addCase(getCodeVersions.rejected, (state, action) => {
      state.codeVersions = {
        ...state.codeVersions,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default CodeVersionReducer;
