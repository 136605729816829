import {
  TicketReportType,
  TicketStatus,
  TicketType,
} from '@Enums/ticket.enums';
import { ITicketDetails } from '@Types/ticket.types';
import { convertApiDate } from '@Utils/index';
import { Button, List, Space, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TicketStatusTag } from '../TicketStatusTag';
import useBoolean from '@Hooks/useBoolean';

const INITIALLY_VISIBLE_STORE_COUNT = 5;

interface TicketMetadataProps {
  ticket: ITicketDetails;
}

export function TicketMetadata(props: TicketMetadataProps) {
  const { ticket } = props;

  const { t } = useTranslation();

  const seeMore = useBoolean();

  const list = useMemo(() => {
    const result: { label: string; children: React.ReactNode }[] = [
      {
        label: t('ticketType'),
        children: t(`ticketTypes.${TicketType[ticket.TicketType]}`),
      },
      {
        label: t('ticketStatus'),
        children: <TicketStatusTag status={ticket.TicketStatus} />,
      },
      {
        label: t('createdBy'),
        children: ticket.CreatedUserName,
      },
      {
        label: t('createdAt'),
        children: convertApiDate(ticket.CreatedDateTime),
      },
      {
        label: t('description'),
        children: ticket.Description,
      },
    ];

    if (ticket.BrandName) {
      result.push({
        label: t('brand'),
        children: ticket.BrandName,
      });
    }

    if (ticket.Stores) {
      const storeNames = ticket.Stores.map(s => s.StoreName);
      const overflowed = storeNames.length > INITIALLY_VISIBLE_STORE_COUNT;

      // initial stores that the user will see
      const initialStores = overflowed
        ? storeNames.slice(0, INITIALLY_VISIBLE_STORE_COUNT)
        : storeNames;

      // stores that will be visible after "see more" click
      const extraStores = overflowed
        ? storeNames.slice(INITIALLY_VISIBLE_STORE_COUNT, storeNames.length)
        : [];

      result.push({
        label: t('stores'),
        children: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
            }}
          >
            {initialStores.map(store => (
              <p style={{ margin: 0, verticalAlign: 'baseline' }}>{store}</p>
            ))}

            {seeMore.value &&
              extraStores.map(store => <p style={{ margin: 0 }}>{store}</p>)}

            {extraStores.length > 0 &&
              (seeMore.value ? (
                <Button type="link" onClick={seeMore.setFalse}>
                  {t('seeLess')}
                </Button>
              ) : (
                <Button type="link" onClick={seeMore.setTrue}>
                  {t('seeMore')}
                </Button>
              ))}
          </div>
        ),
      });
    }

    if (ticket.TicketReportType !== undefined) {
      result.push({
        label: t('reportType'),
        children: t(TicketReportType[ticket.TicketReportType]),
      });
    }

    if (ticket.CampaignDate) {
      result.push({
        label: t('campaignDate'),
        children: convertApiDate(ticket.CampaignDate),
      });
    }

    if (ticket.TicketStatus === TicketStatus.CLOSED && ticket.ClosedUserName) {
      result.push({
        label: t('userWhoClosedIt'),
        children: ticket.ClosedUserName,
      });
    }

    if (ticket.LastUpdateDate) {
      result.push({
        label: t('lastUpdateDate'),
        children: convertApiDate(ticket.LastUpdateDate),
      });
    }

    return result;
  }, [t, ticket, seeMore]);

  return (
    <List
      grid={{
        column: 2,
      }}
      dataSource={list}
      renderItem={item => (
        <List.Item>
          <Space align="start">
            <Typography.Title level={5} style={{ margin: 0 }}>
              {item.label + ':'}
            </Typography.Title>
            {item.children}
          </Space>
        </List.Item>
      )}
    />
  );
}
