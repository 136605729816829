import moment from 'moment';

function uuidv4(): string {
  return ('' + [1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, ch => {
    let c = Number(ch);
    return (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16);
  });
}
export { uuidv4 };

export function calculateTimeRange(Date: string, endTimeVisible?: boolean) {
  const date = moment(Date).format('L');
  const startTime = moment(Date).format('LT');
  const endTime = moment(Date).format('LT');
  if (endTimeVisible) {
    return `${date} ${startTime}-${endTime}`;
  } else return `${date} ${startTime}`;
}

export const turkishCharacterRegex = (keyword: string) => {
  return keyword
    .replace(/[ıİiI]/g, '[ıİiI]')
    .replace(/[şŞsS]/g, '[şŞsS]')
    .replace(/[çÇcC]/g, '[çÇcC]')
    .replace(/[ğĞgG]/g, '[ğĞgG]')
    .replace(/[öÖoO]/g, '[öÖoO]')
    .replace(/[üÜuU]/g, '[üÜuU]');
};

export function scrollTo(ref: React.RefObject<HTMLElement>) {
  if (ref && ref.current) {
    ref.current.scrollIntoView({ behavior: 'auto', block: 'start' });
  }
}

/**
 * converts `enum CustomEnum { CAM = 0, CSI = 1 }` to `[['CAM', 0], ['CSI', 1]]`
 */
export function enumToIterable(_enum: object) {
  return Object.entries(_enum).filter(([_, value]) => !isNaN(Number(value)));
}
