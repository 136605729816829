import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface Props {
  content: React.ReactNode;
  placement?: TooltipPlacement;
  className?: string;
}

/**
 * @description This component is used for cases where a data is too long to show in a small space. It wraps the data as content and turnes it into a tooltip.
 *  Users see the data when they hover the info circle.
 */
export default function InfoItem({ content, placement, className }: Props) {
  return (
    <Tooltip placement={placement} title={content} className={className}>
      <InfoCircleOutlined />
    </Tooltip>
  );
}
