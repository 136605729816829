import {
  GetAllBrandGroupsData,
  IUpdateBrandGroup,
} from './../../Types/BrandGroup.d';
import { PaginationParams } from '@Types/Filter';
import model from './index';
import { IBrandGroupForm } from '@Types/BrandGroup';

class BrandGroupService extends model {
  constructor() {
    super();
    this.Prefix('BrandGroup');
  }

  async CreateBrandGroup(form: IBrandGroupForm) {
    return this.Post().Data(form).Save();
  }

  async GetAllBrandGroups(params: PaginationParams) {
    return this.Post('GetAll').Data(params).Save<GetAllBrandGroupsData>();
  }

  async UpdateBrandGroup(id: number, data: IUpdateBrandGroup) {
    return this.PrefixId(id).Put().Data(data).Save();
  }

  async DeleteBrandGroup(id: number) {
    return this.PrefixId(id).Delete().Data({ Id: id }).Save();
  }
}

export default BrandGroupService;
