export enum Permissions {
  ADMIN_CAMERA_VIEW = 'ADMIN_CAMERA_VIEW',
  ADMIN_CAMERA_GET_PHOTO = 'ADMIN_CAMERA_GET_PHOTO',
  ADMIN_CAMERA_TAKE_PHOTO = 'ADMIN_CAMERA_TAKE_PHOTO',
  ADMIN_CAMERA_SEND_PING = 'ADMIN_CAMERA_SEND_PING',
  ADMIN_CAMERA_OPEN_SSH = 'ADMIN_CAMERA_OPEN_SSH',
  ADMIN_CAMERA_ADD = 'ADMIN_CAMERA_ADD',
  ADMIN_CAMERA_EDIT = 'ADMIN_CAMERA_EDIT',
  ADMIN_CAMERA_RULES_VIEW = 'ADMIN_CAMERA_RULES_VIEW',
  ADMIN_CAMERA_RULES_ACTION = 'ADMIN_CAMERA_RULES_ACTION',
  ADMIN_BRAND_VIEW = 'ADMIN_BRAND_VIEW',
  ADMIN_STORE_VIEW = 'ADMIN_STORE_VIEW',
  ADMIN_BOARD_VIEW = 'ADMIN_BOARD_VIEW',
  ADMIN_USERS_VIEW = 'ADMIN_USERS_VIEW',
  ADMIN_COORDINATES_VIEW = 'ADMIN_COORDINATES_VIEW',
  ADMIN_BRAND_EMAILS_VIEW = 'ADMIN_BRAND_EMAILS_VIEW',
  ADMIN_SUMMARY_VIEW = 'ADMIN_SUMMARY_VIEW',
  ADMIN_CARD_LIVE_VIEW = 'ADMIN_CARD_LIVE_VIEW',
  ADMIN_CUSTOM_ROLE_VIEW = 'ADMIN_CUSTOM_ROLE_VIEW',
  ADMIN_CAMERA_RECORDS_VIEW = 'ADMIN_CAMERA_RECORDS_VIEW',
  ADMIN_CAMERA_CALIBRATION_VIEW = 'ADMIN_CAMERA_CALIBRATION_VIEW',
  ADMIN_CAMERA_TAKE_PHOTO_FROM_ALL = 'ADMIN_CAMERA_TAKE_PHOTO_FROM_ALL',
  ADMIN_CAMERA_ARCHIVE = 'ADMIN_CAMERA_ARCHIVE',
  ADMIN_BRAND_ACTION = 'ADMIN_BRAND_ACTION',
  ADMIN_USERS_ACTION = 'ADMIN_USERS_ACTION',
  ADMIN_CUSTOM_ROLE_ACTION = 'ADMIN_CUSTOM_ROLE_ACTION',
  ADMIN_STORE_ACTION = 'ADMIN_STORE_ACTION',
  ADMIN_BOARD_ACTION = 'ADMIN_BOARD_ACTION',
  ADMIN_CAMERA_ACTION = 'ADMIN_CAMERA_ACTION',
  ADMIN_CAMERA_RECORDS_ACTION = 'ADMIN_CAMERA_RECORDS_ACTION',
  ADMIN_PERSONELS_VIEW = 'ADMIN_PERSONELS_VIEW',
  ADMIN_BEACON_VIEW = 'ADMIN_BEACON_VIEW',
  ADMIN_BEACON_ACTION = 'ADMIN_BEACON_ACTION',
  ADMIN_GATEWAY_VIEW = 'ADMIN_GATEWAY_VIEW',
  ADMIN_GATEWAY_ACTION = 'ADMIN_GATEWAY_ACTION',
  ADMIN_RECEIVER_VIEW = 'ADMIN_RECEIVER_VIEW',
  ADMIN_RECEIVER_ACTION = 'ADMIN_RECEIVER_ACTION',
  ADMIN_BEACON_RULES_VIEW = 'ADMIN_BEACON_RULES_VIEW',
  ADMIN_BEACON_RULES_ACTION = 'ADMIN_BEACON_RULES_ACTION',
  ADMIN_REGION_VIEW = 'ADMIN_REGION_VIEW',
  ADMIN_REGION_ACTION = 'ADMIN_REGION_ACTION',
  ADMIN_TICKET_VIEW = 'ADMIN_TICKET_VIEW',
  ADMIN_TICKET_ACTION = 'ADMIN_TICKET_ACTION',

  PANEL_PAGE_TICKET_VIEW = 'PANEL_PAGE_TICKET_VIEW',
  PANEL_PAGE_COMPANY = 'PANEL_PAGE_COMPANY',
  PANEL_PAGE_COMPANY_IN_STORE = 'PANEL_PAGE_COMPANY_IN_STORE',
  PANEL_PAGE_COMPANY_CATEGORY = 'PANEL_PAGE_COMPANY_CATEGORY',
  PANEL_PAGE_COMPANY_GENDER_AGE = 'PANEL_PAGE_COMPANY_GENDER_AGE',
  PANEL_PAGE_LOCATION = 'PANEL_PAGE_LOCATION',
  PANEL_PAGE_REPORT = 'PANEL_PAGE_REPORT',
  PANEL_PAGE_AREA = 'PANEL_PAGE_AREA',
  PANEL_PAGE_PERFORMANCE_TABLE = 'PANEL_PAGE_PERFORMANCE_TABLE',
  PANEL_PAGE_TRENDS = 'PANEL_PAGE_TRENDS',
  PANEL_PAGE_SUGGESTION = 'PANEL_PAGE_SUGGESTION',
  PANEL_PAGE_SUGGESTION_DETAIL = 'PANEL_PAGE_SUGGESTION_DETAIL',
  PANEL_PAGE_CHECKOUT = 'PANEL_PAGE_CHECKOUT',
  PANEL_PAGE_DEPARTMENT = 'PANEL_PAGE_DEPARTMENT',
  PANEL_PAGE_SHELF = 'PANEL_PAGE_SHELF',
  PANEL_PAGE_CAMERAS_DETAIL = 'PANEL_PAGE_CAMERAS_DETAIL',
  PANEL_PAGE_CAMERAS_LIVE_AREAS = 'PANEL_PAGE_CAMERAS_LIVE_AREAS',
  PANEL_PAGE_LOCATION_COMPARISON = 'PANEL_PAGE_LOCATION_COMPARISON',
  PANEL_PAGE_CATEGORY_COMPARISON = 'PANEL_PAGE_CATEGORY_COMPARISON',
  PANEL_PAGE_RECEIVER = 'PANEL_PAGE_RECEIVER',
  PANEL_PAGE_LIVE = 'PANEL_PAGE_LIVE',
  PANEL_PAGE_LIVE_CUSTOMER_COUNT = 'PANEL_PAGE_LIVE_CUSTOMER_COUNT',
  PANEL_PAGE_STORE_COUNT = 'PANEL_PAGE_STORE_COUNT',
  PANEL_PAGE_GENDER_AGE_COMPANY = 'PANEL_PAGE_GENDER_AGE_COMPANY',
  PANEL_PAGE_CUSTOMER_GENDER_AGE = 'PANEL_PAGE_CUSTOMER_GENDER_AGE',
  PANEL_PAGE_AGE_GENDER_COMPARISON = 'PANEL_PAGE_AGE_GENDER_COMPARISON',
  PANEL_PAGE_CAMPAIGNS = 'PANEL_PAGE_CAMPAIGNS',
  PANEL_PAGE_CAMPAIGN_ANALYSIS = 'PANEL_PAGE_CAMPAIGN_ANALYSIS',
  PANEL_PAGE_USER = 'PANEL_PAGE_USER',
  PANEL_PAGE_NOTIFICATIONS = 'PANEL_PAGE_NOTIFICATIONS',
  PANEL_PAGE_SALES_CONFIGURATION = 'PANEL_PAGE_SALES_CONFIGURATION',
  PANEL_PAGE_IFRAME_VIEW = 'PANEL_PAGE_IFRAME_VIEW',
  PANEL_PAGE_SETTINGS = 'PANEL_PAGE_SETTINGS',
  PANEL_PAGE_SETTINGS_CAMERA = 'PANEL_PAGE_SETTINGS_CAMERA',
  PANEL_PAGE_SETTINGS_NOTIFICATIONS = 'PANEL_PAGE_SETTINGS_NOTIFICATIONS',
  PANEL_PAGE_SETTINGS_DEPARTMENT = 'PANEL_PAGE_SETTINGS_DEPARTMENT',
  PANEL_PAGE_SETTINGS_WORKING_HOURS = 'PANEL_PAGE_SETTINGS_WORKING_HOURS',
  PANEL_PAGE_SETTINGS_SALES_UPLOADING = 'PANEL_PAGE_SETTINGS_SALES_UPLOADING',
  PANEL_PAGE_SETTINGS_CAMERA_SNAPSHOT = 'PANEL_PAGE_SETTINGS_CAMERA_SNAPSHOT',
  PANEL_PAGE_SETTINGS_FORECAST = 'PANEL_PAGE_SETTINGS_FORECAST',
  PANEL_PAGE_SETTINGS_CAMPAIGN = 'PANEL_PAGE_SETTINGS_CAMPAIGN',
  PANEL_PAGE_SETTINGS_IFRAME = 'PANEL_PAGE_SETTINGS_IFRAME',
  PANEL_PAGE_SETTINGS_UPLOAD_REPORTS = 'PANEL_PAGE_SETTINGS_UPLOAD_REPORTS',
  PANEL_PAGE_DEPARTMENT_AGE_GENDER = 'PANEL_PAGE_DEPARTMENT_AGE_GENDER',
  PANEL_PAGE_CAMERAS = 'PANEL_PAGE_CAMERAS',
  PANEL_PAGE_PERSONNEL_PERSONNEL = 'PANEL_PAGE_PERSONNEL_PERSONNEL',
  PANEL_PAGE_SETTINGS_SKETCH = 'PANEL_PAGE_SETTINGS_SKETCH',
  PANEL_PAGE_PERSONNEL = 'PANEL_PAGE_PERSONNEL',
  PANEL_PAGE_SETTINGS_REPORTS = 'PANEL_PAGE_SETTINGS_REPORTS',

  LABELING_PAGE_PHOTO_LABELING = 'LABELING_PAGE_PHOTO_LABELING',
  LABELING_PAGE_VALIDATION = 'LABELING_PAGE_VALIDATION',
  LABELING_PAGE_VIDEO_CAPTURES = 'LABELING_PAGE_VIDEO_CAPTURES',
}
