import { ShopOutlined } from '@ant-design/icons';
import FormCard from '@Components/FormCard';
import StoreDetail from '@Forms/StoreDetail';
import { EditedStore } from '@Types/Store';
import StoresService from '@Services/Api/Stores';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { useEffect } from 'react';
import { getStoreDetail } from '@Store/Store/action';
import Status from '@Enums/Status';
import PageSkeletons from '@Components/PageSkeletons';
import { apiCall } from '@Utils';

const EditStore = () => {
  const currentStore = useAppSelector(s => s.Store.currentStore);

  const { storeId } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (storeId) {
      dispatch(getStoreDetail(storeId));
    }
  }, [storeId]);

  let navigate = useNavigate();

  const handleSubmit = async (editedStore: EditedStore) => {
    try {
      await apiCall(() => editStore(editedStore), 'editStore');
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const editStore = async (editedStore: EditedStore) => {
    return await new StoresService().EditStore(editedStore, parseInt(storeId!));
  };

  if (currentStore.status === Status.pending) return <PageSkeletons />;
  return (
    <FormCard
      titleIcon={<ShopOutlined />}
      titleKey="editStore"
      className="store-detail-wrapper"
    >
      <StoreDetail onFinish={handleSubmit} store={currentStore.data} />
    </FormCard>
  );
};

export default EditStore;
