import { Button, ButtonProps, Tooltip } from 'antd';
import { InboxOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './style.scss';

interface Props {
  showArchived: boolean;
  onClick: () => void;
  buttonProps?: ButtonProps;
}

const ArchivedFilter = ({ onClick, showArchived, buttonProps }: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('changeArchiveType')} placement="bottom">
      <Button
        onClick={onClick}
        className="archive-toggle-btn"
        icon={showArchived ? <InboxOutlined /> : <FolderOpenOutlined />}
        {...buttonProps}
      />
    </Tooltip>
  );
};

export default ArchivedFilter;
