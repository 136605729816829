import { useContext } from 'react';
import { SvgContext, SvgContextType } from '../Context/Svg';

interface childrenProps extends SvgContextType {}
interface Props {
  children: (props: childrenProps) => JSX.Element;
}

function SvgShapesContextProvider({ children }: Props): JSX.Element {
  const context = useContext(SvgContext);
  return children(context);
}

export default SvgShapesContextProvider;
