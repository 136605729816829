import { createReducer } from '@reduxjs/toolkit';
import { getCountries, setSelectedCountry } from './action';
import { Country } from '@Types/Country';
import { asyncFetchInterface } from '@Types/ReduxTypes';

interface stateInterface {
  countries: asyncFetchInterface<Country[]>;
  selectedCountry: Country | null;
}

const initialState: stateInterface = {
  countries: {
    data: [],
    status: 'Empty',
  },
  selectedCountry: null,
};

const CountryReducer = createReducer(initialState, builder => {
  builder
    .addCase(getCountries.pending, state => {
      state.countries = {
        ...state.countries,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getCountries.fulfilled, (state, action) => {
      state.countries.data = action.payload;
      state.countries.status = 'fulfilled';
    })
    .addCase(getCountries.rejected, (state, action) => {
      state.countries = {
        ...state.countries,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(setSelectedCountry, (state, action) => {
      state.selectedCountry = action.payload;
    });
});

export default CountryReducer;
