import SearchableSelect from '@Components/SearchableSelect';
import AutoReportsService from '@Services/Api/AutoReportsService';
import { ReportEnums } from '@Types/Report';
import { message, Select } from 'antd';
import { useEffect, useState } from 'react';

type Props = {
  onChange: (AutoReportType: number) => void;
  defaultValue?: number;
};

const ReportTypeSelect = ({ onChange, defaultValue }: Props) => {
  const [reportType, setreportType] = useState<ReportEnums[]>([]);
  useEffect(() => {
    (async () => {
      try {
        const res = await new AutoReportsService().GetReportEnums();
        setreportType(res);
      } catch (e) {
        message.error(`Could not get reports`);
      }
    })();
  }, []);
  function handleReportTypeChange(reportType: number) {
    onChange(reportType);
  }

  return (
    <SearchableSelect
      onChange={handleReportTypeChange}
      allowClear
      defaultValue={defaultValue}
      maxTagCount={2}
    >
      {reportType?.map(report =>
        report.Serial.map(repo => (
          <Select.Option label={repo.Name} value={repo.Id}>
            {repo.Name}
          </Select.Option>
        ))
      )}
    </SearchableSelect>
  );
};

export default ReportTypeSelect;
