import { useParams } from 'react-router-dom';
import useRequest from '@Hooks/useRequest';
import CameraService from '@Services/Api/Camera';
import CameraTypes from '@Types/Camera';
import { stringToNumber } from '@Utils/Number';
import { ReactSortable } from 'react-sortablejs';
import {
  forwardRef,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import CameraImage from './Components/CameraImage';
import { Col, Row, Space, InputNumber, Button, RowProps, Skeleton } from 'antd';
import Draggable from '@Components/Draggable';
import './style.scss';
import {
  ConnectCameraContext,
  ConnectCameraContextProvider,
} from './Contexts/ConnectCameraContext';
import PolygonGroupModal from './Components/PolygonGroupModal';
import shapeTypes from '@Components/Svg/Types/Svg';
import StoreService from '@Services/Api/Store';
import createConfirmModal from '@Components/InfoModal';
import { useTranslation } from 'react-i18next';
import ConnectCameraTypes from '@Types/connectCameras';
import createErrorModal from '@Components/ErrorModal';
import ContentLoader from '@Components/ContentLoader';
const CustomComponent = forwardRef<HTMLDivElement, RowProps>((props, ref) => {
  return (
    <Row ref={ref} {...props} className="camera-draggable-wrapper">
      {props.children}
    </Row>
  );
});
function ConnectCameras() {
  const { t } = useTranslation();
  const [allShapes, setAllShapes] = useState<
    | {
        [key: string]: shapeTypes.ShapesType;
      }
    | undefined
  >(undefined);
  const [ShapesByGroupName, setShapesByGroupName] = useState<{
    [key: string]: shapeTypes.ShapesType;
  }>({});
  const { brandId, storeId } = useParams();
  const [TableDimensions, setTableDimensions] = useState({ Row: 0, Col: 0 });
  const [cameraList, setCameraList] = useState<
    ((CameraTypes.Camera | { isEmpty: boolean }) & { id: number })[]
  >([]);
  const cameras = useRequest<CameraTypes.Cameras, Array<number | undefined>>(
    p => new StoreService().GetAll(p),
    [stringToNumber(storeId)],
    []
  );
  const LayoutConfig = useRequest<
    ConnectCameraTypes.ConnectedCameraDetails,
    (number | undefined)[]
  >(
    p => new StoreService().getConnectedCameraConfigs(p),
    [stringToNumber(storeId)],
    { Col: 0, Row: 0, LayoutConfig: '[]', ConnectedCamerasConfig: '{}' }
  );

  useEffect(() => {
    if (LayoutConfig.status === 'fulfilled') {
      const _allShapes = JSON.parse(
        LayoutConfig.data?.ConnectedCamerasConfig ?? '{}'
      ) as {
        [key: string]: shapeTypes.ShapesType;
      };
      const _cameraList = JSON.parse(
        LayoutConfig.data?.LayoutConfig ?? '[]'
      ) as ((CameraTypes.Camera | { isEmpty: boolean }) & { id: number })[];
      setAllShapes(groupedShapesToCamId(_allShapes));
      setShapesByGroupName(getShapesByGroupName(_allShapes));
      if (LayoutConfig.data?.Row && LayoutConfig.data?.Col) {
        setTableDimensions({
          Row: LayoutConfig.data.Row,
          Col: LayoutConfig.data.Col,
        });
        setCameraList(_cameraList);
      }
    }
  }, [LayoutConfig.status]);

  useEffect(() => {
    if (
      cameras.status === 'fulfilled' &&
      TableDimensions.Row === 0 &&
      TableDimensions.Col === 0
    ) {
      const Dimensions = { Row: 1, Col: cameras.data.length };
      setTableDimensions(Dimensions);
      setCameraList(getCameraList(Dimensions));
    }
  }, [cameras.status]);
  const setDimensions = (value: null | number, type: 'Row' | 'Col') => {
    if (typeof value === 'string') return;
    setTableDimensions(prevTableDimensions => ({
      ...prevTableDimensions,
      [type]: value,
    }));
    const Dimensions = { ...TableDimensions, [type]: value };
    setCameraList(getCameraList(Dimensions));
  };
  const getCameraList = (Dimensions: { Row: number; Col: number }) => {
    return getArrayBySize(Dimensions).map((_, index) => {
      if (cameras.data[index]) {
        let camera = cameras.data[index];
        return { ...camera, id: camera.Id };
      } else {
        return { id: index, isEmpty: true };
      }
    });
  };
  const shapeOnUpdate = (camId: number, shapes: shapeTypes.ShapesType) => {
    const _allShapes: { [key: string]: shapeTypes.ShapesType } = {
      ...allShapes,
      [camId]: shapes,
    };
    // Creating object by groupName
    setShapesByGroupName(getShapesByGroupName(_allShapes));
    // Created object by groupName
    setAllShapes(_allShapes);
  };
  const getShapesByGroupName = (shapes: {
    [key: string]: shapeTypes.ShapesType;
  }) => {
    let groupedByName: { [key: string]: shapeTypes.ShapesType } = {};
    Object.keys(shapes).map(camId => {
      if (shapes[camId])
        shapes[camId].map(polygon => {
          if (!groupedByName[polygon.groupName]) {
            groupedByName[polygon.groupName] = [];
          }
          groupedByName[polygon.groupName].push(polygon);
        });
    });
    return groupedByName;
  };
  const groupedShapesToCamId = (groupedByName: {
    [key: string]: shapeTypes.ShapesType;
  }) => {
    let ShapesByCamId: { [key: string]: shapeTypes.ShapesType } = {};
    Object.keys(groupedByName).map(groupName => {
      groupedByName[groupName].map(polygon => {
        const camId = polygon.CameraId;
        if (!ShapesByCamId[camId]) {
          ShapesByCamId[camId] = [];
        }
        ShapesByCamId[camId].push(polygon);
      });
    });
    return ShapesByCamId;
  };
  const saveLayoutConfig = () => {
    return new StoreService().setLayoutConfig(
      {
        Row: TableDimensions.Row,
        Col: TableDimensions.Col,
        LayoutConfig: JSON.stringify(cameraList),
      },
      stringToNumber(storeId)
    );
  };
  const saveConnectedCameraConfig = () => {
    return new StoreService().setConnectedCamerasConfig(
      {
        ConnectedCamerasConfig: JSON.stringify(ShapesByGroupName),
      },
      stringToNumber(storeId)
    );
  };
  const save = () => {
    createConfirmModal(t('doYouAccept'), () =>
      Promise.all([saveLayoutConfig(), saveConnectedCameraConfig()]).catch(
        err => createErrorModal(t('somethingWentWrong'))
      )
    );
  };
  return (
    <ConnectCameraContextProvider
      ExistGroupNames={Object.keys(ShapesByGroupName)}
    >
      <ContentLoader
        status={LayoutConfig.status}
        customLoader={<Skeleton.Image />}
      >
        <div id="connect-cameras-page">
          <Space wrap>
            <InputNumber
              addonBefore="Row"
              placeholder="input search text"
              value={TableDimensions.Row}
              onChange={value => setDimensions(value, 'Row')}
              style={{ width: 304 }}
            />
            <InputNumber
              addonBefore="Col"
              placeholder="input search text"
              value={TableDimensions.Col}
              onChange={value => setDimensions(value, 'Col')}
              style={{ width: 304 }}
            />
            <AddShapeButton />
            <Button type="primary" onClick={save}>
              {' '}
              Değişiklikleri kaydet{' '}
            </Button>
          </Space>
          <div className="draggable-overflow">
            <ReactSortable
              tag={CustomComponent}
              list={cameraList}
              setList={setCameraList}
              handle=".drag-icon"
              style={{
                width: 300 * TableDimensions.Col,
              }}
            >
              {cameraList.map(camera =>
                'isEmpty' in camera || allShapes === undefined ? (
                  <EmptyDraggable key={camera.id} />
                ) : (
                  <DraggableCamera
                    key={camera.id}
                    camera={camera}
                    defaultShapes={allShapes[camera.id]}
                    shapeOnUpdate={shape => shapeOnUpdate(camera.id, shape)}
                  />
                )
              )}
            </ReactSortable>
          </div>
        </div>
      </ContentLoader>
    </ConnectCameraContextProvider>
  );
}

const DraggableCamera = ({
  camera,
  shapeOnUpdate,
  defaultShapes,
}: {
  camera: CameraTypes.Camera;
  shapeOnUpdate: (shape: shapeTypes.ShapesType) => void;
  defaultShapes: shapeTypes.ShapesType;
}) => {
  return (
    <Draggable>
      <>
        <div className="camera-name">{camera.Name}</div>
        <CameraImage
          {...camera}
          defaultShapes={defaultShapes}
          shapeOnUpdate={shapeOnUpdate}
        />
      </>
    </Draggable>
  );
};

const EmptyDraggable = () => {
  return (
    <Draggable>
      <div />
    </Draggable>
  );
};

const getArrayBySize = ({ Row, Col }: { Row: number; Col: number }) => {
  return Array.from(new Float32Array(Row * Col));
};

const AddShapeButton = () => {
  const { setCreateShape, createShape, setGroupName, groupName } =
    useContext(ConnectCameraContext);
  const [ShowGroupModal, setShowGroupModal] = useState(false);
  const AddOnClick = () => {
    setShowGroupModal(true);
  };
  const polygonModalOnCancel = () => {
    setShowGroupModal(false);
    setCreateShape(false);
  };
  const PolygonGroupOnOk = ({ groupName }: { groupName: string }) => {
    setGroupName(groupName);
    setCreateShape(true);
    setShowGroupModal(false);
  };
  return (
    <>
      <PolygonGroupModal
        show={ShowGroupModal}
        onCancel={polygonModalOnCancel}
        onOk={PolygonGroupOnOk}
        initialValues={{ groupName }}
      />
      <Button onClick={AddOnClick}>{createShape ? 'İptal' : 'Ekle'}</Button>
    </>
  );
};

export default ConnectCameras;
