import GoBackButton from '@Components/GoBackButton';
import UserService from '@Services/Api/User';
import {
  Form,
  Input,
  Button,
  Typography,
  Divider,
  Modal,
  Skeleton,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import createConfirmModal from '@Components/InfoModal';
import createSuccessModal from '@Components/SuccessModal';
import createErrorModal from '@Components/ErrorModal';
import { ResetNewPassword, ResetPasswordUser, NewPassword } from '@Types/User';
import { useEffect, useState } from 'react';
import './style.scss';
import PageSkeletons from '@Components/PageSkeletons';

const { Title } = Typography;
type Props = {};

const ResetPassword = (props: Props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();
  const [passwordCode, setpasswordCode] = useState('');
  const [resetPasswordUser, setResetPasswordUser] =
    useState<ResetPasswordUser>();
  const [InvalidUserConfig, setInvalidUserConfig] = useState(false);
  useEffect(() => {
    //url split for code find
    if (location.pathname) {
      const Code = location.pathname.split('/Account/ResetPassword/')[1];
      setpasswordCode(Code);
      tokenCode(Code);
    }
  }, []);
  const tokenCode = async (Code: string) => {
    try {
      if (Code) {
        await new UserService()
          .ResetPasswordEmail(Code)
          .then(res => setResetPasswordUser(res))
          .catch(() => setInvalidUserConfig(true));
      }
    } catch (error) {
      createErrorModal(t('somethingWentWrong'));
      console.error(error);
    }
  };

  const onSubmit = (mailForm: ResetNewPassword) => {
    createConfirmModal(t('doYouAccept'), () => onModalConfirm(mailForm));
  };
  const onModalConfirm = async (mailForm: ResetNewPassword) => {
    let resetPassword: NewPassword = {
      Code: passwordCode,
      Id: resetPasswordUser?.Id,
      InvalidUserConfig: InvalidUserConfig,
      NewPassword: mailForm.password,
      email: resetPasswordUser?.UserName,
    };
    try {
      await new UserService().ResetPassword(resetPassword);
      Modal.destroyAll();
      createSuccessModal(t('processCompleted'));
      navigate('/login');
    } catch (error) {
      createErrorModal(t('somethingWentWrong'));
      console.error(error);
    }
  };

  if (!resetPasswordUser) return <PageSkeletons />;
  return (
    <div id="login-page-wrapper" className="ResetPassword">
      <div id="login-form-container">
        <Divider>
          <Title level={3}>{resetPasswordUser?.UserName}</Title>
        </Divider>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onSubmit}
        >
          <Form.Item
            label="password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item className="login-form-button-container">
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              {t('submit')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
