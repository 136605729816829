import { Anchor, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.scss';

const { Title } = Typography;
const { Link } = Anchor;

export default function Header() {
  const { t } = useTranslation();

  return (
    <div className="field-searches-header">
      <Anchor className="main-pannel-anchor">
        <Link
          href="https://app.udentify.co/Home"
          title={t('fieldSearches.headerPart1')}
        />
      </Anchor>
      <Title level={4}>{t('fieldSearches.headerPart2')}</Title>
    </div>
  );
}
