import { useTranslation } from 'react-i18next';
import CamerasDetail from '@Pages/Cameras/Components/CamerasDetail';
import {
  getAllArchivedCamerasOfStore,
  getAllUnarchivedCamerasOfStore,
} from '@Store/Camera/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getStoreDetail, resetCurrentStore } from '@Store/Store/action';

const AllStoreCameras = () => {
  const currentStore = useAppSelector(s => s.Store.currentStore.data);
  const { storeId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getStoreDetail(storeId!));

    return () => {
      dispatch(resetCurrentStore());
    };
  }, []);
  return (
    <CamerasDetail
      getUnarchivedCamerasAction={getAllUnarchivedCamerasOfStore}
      getArchivedCamerasAction={getAllArchivedCamerasOfStore}
      title={currentStore.Brand + ' -> ' + currentStore.Name}
    />
  );
};

export default AllStoreCameras;
