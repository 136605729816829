import { Button, Card, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import SortByFilter from './Components/SortByFilter';
import CardGridWrapper from '@Components/CardGridWrapper';
import React from 'react';
import { getAllReceiver } from '@Store/Receiver/action';
import ReceiverCard from '@Cards/ReceiverCard';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import useDataDisplay from '@Hooks/useDataDisplay';
import DataDisplayTable from '@Components/DataDisplayTable';
import useReceiversColumns from './useReceiversColumns';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import ArchivedFilter from '@Components/ArchivedFilter';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { FilterOption } from '@Types/Pagination';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';
import { enumToIterable } from '@Utils/helpers';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';
import { ReceiverType } from '@Enums/Receiver';

const Receiver = () => {
  const [currentDisplay, toggleDisplay] = useDataDisplay('receivers');
  const allReceiver = useAppSelector(s => s.Receiver.allReceiver);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showArchivedReceiver, setShowArchivedReceiver] =
    useState<boolean>(false);

  const pageConfigResult = useUserPageConfig('receivers', {
    orderBy: 'asc',
    sortKey: 'Id',
  });

  const filterOptions: FilterOption[] = [
    { field: 'Id', label: 'ID', type: FilterType.NUMBER },
    { field: 'ExternalId', label: t('externalId'), type: FilterType.TEXT },
    { field: 'BTMacAddress', label: 'BT Mac Address', type: FilterType.TEXT },
    {
      field: 'Store.BrandId',
      label: t('brand') + ' ID',
      type: FilterType.NUMBER,
    },
    { field: 'Store.Brand.Name', label: t('brand'), type: FilterType.TEXT },
    { field: 'StoreId', label: t('store') + ' ID', type: FilterType.NUMBER },
    { field: 'Store.Name', label: t('store'), type: FilterType.TEXT },
    {
      field: 'ReceiverType',
      label: t('receiverType'),
      type: FilterType.NUMBER_ARRAY_SELECT,
      options: enumToIterable(ReceiverType).map(([key, value]) => ({
        label: t(key, { defaultValue: key }),
        value: value,
      })),
    },
  ];

  const { pagination, refetch } = usePaginatedQuery({
    status: allReceiver.status,
    filterOptions,
    pageConfigResult,
    refetchDependencies: [showArchivedReceiver],
    queryFunction: p =>
      dispatch(
        getAllReceiver({
          ...p,
          archived: showArchivedReceiver,
        })
      ),
  });

  useSetHeaderTitle(
    `${t('navigation.receiver')} - ${
      showArchivedReceiver ? t('archivedReceiver') : t('unarchivedReceiver')
    }`
  );

  const toggleShowArchievedReceiver = () => {
    setShowArchivedReceiver(p => !p);
  };

  const receiversTableColumns = useReceiversColumns({
    allReceiver,
    getAllReceivers: refetch,
  });

  return (
    <Card>
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <ArchivedFilter
            onClick={toggleShowArchievedReceiver}
            showArchived={showArchivedReceiver}
          />
          <Link to="new-Receiver">
            <Button type="primary" icon={<PlusCircleOutlined />}>
              {t('addReceiver')}
            </Button>
          </Link>
        </div>
        <div className="card-grid-container">
          <CardGridWrapper
            status={allReceiver.status}
            noData={allReceiver.data?.Data?.length === 0}
            cards={
              currentDisplay === 'card' ? (
                <React.Fragment>
                  {allReceiver.data?.Data?.map(receiver => (
                    <ReceiverCard
                      receiver={receiver}
                      isArchived={showArchivedReceiver}
                      getReceiver={refetch}
                    />
                  ))}
                </React.Fragment>
              ) : (
                <DataDisplayTable
                  columns={receiversTableColumns}
                  dataSource={allReceiver.data?.Data}
                />
              )
            }
          />
        </div>
        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={allReceiver.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={allReceiver.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
};

export default Receiver;
