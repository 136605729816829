import { DrawElements, Keycodes } from '@Components/Svg/Constants';
import { ParamContext } from '@Components/Svg/Context/Params';
import { shapeContext } from '@Components/Svg/Context/Shape';
import { SvgContext } from '@Components/Svg/Context/Svg';
import { shapeTasksTypes } from '@Components/Svg/Tasks/Shape';
import {
  RefObject,
  useEffect,
  useContext,
  KeyboardEvent,
  useCallback,
} from 'react';
export function useContextMenu(elementRef: RefObject<any>): void {
  const params = useContext(ParamContext);
  const { editable, Shapes } = useContext(SvgContext);
  const { shape, onContextMenu } = useContext(shapeContext);
  const contextMenu = (e: Event) => {
    e.preventDefault();
    switch (params.activeType) {
      case 'stable':
        onContextMenu(shape, Shapes);
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    if (editable) {
      elementRef.current?.addEventListener('contextmenu', contextMenu, true);
    }
    return () => {
      elementRef.current?.removeEventListener('contextmenu', contextMenu, true);
    };
  }, [params.activeType, editable, shape]);
}

export function useKeyDown(): void {
  const params = useContext(ParamContext);
  const { editable, deleteDrawing } = useContext(SvgContext);
  const { shape } = useContext(shapeContext);
  const keydown: any = (e: KeyboardEvent<Element>): any => {
    switch (e.key) {
      case Keycodes.Escape:
        cancelDrawing();
        break;

      default:
        break;
    }
  };
  const cancelDrawing = () => {
    if (shape.isCreate)
      switch (params.activeType) {
        case 'polygon_drawing':
          const proccess = shapeTasksTypes.Type(params, 'shape_key_down');
          params.updateActiveType(proccess.type);
          deleteDrawing(shape);
          break;

        default:
          break;
      }
  };
  useEffect(() => {
    if (editable) {
      document.addEventListener('keydown', keydown, true);
    }
    return () => {
      document.removeEventListener('keydown', keydown, true);
    };
  }, [params.activeType, editable]);
}
