import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import PageSkeletons from '@Components/PageSkeletons';
import { getBoardDetail, resetCurrentBoard } from '@Store/Board/action';
import CamerasDetail from '@Pages/Cameras/Components/CamerasDetail';
import { getArchivedCameras, getUnarchivedCameras } from '@Store/Camera/action';

const BoardCameras = () => {
  const currentBoard = useAppSelector(s => s.Board.currentBoard.data);
  const { boardId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getBoardDetail(parseInt(boardId!)));

    return () => {
      dispatch(resetCurrentBoard());
    };
  }, []);

  if (!currentBoard?.Id) return <PageSkeletons />;
  return (
    <CamerasDetail
      getUnarchivedCamerasAction={getUnarchivedCameras}
      getArchivedCamerasAction={getArchivedCameras}
      title={
        currentBoard.Brand +
        ' -> ' +
        currentBoard.Store +
        ' -> ' +
        currentBoard.Name
      }
    />
  );
};

export default BoardCameras;
