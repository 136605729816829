import { ReactElement, useRef, useContext } from 'react';
import { SvgProps, SvgPropsContent } from './Types/Svg';
import Shape from './Shape';
import { SvgContextProvider, SvgContext } from './Context/Svg';
import useEditable from './Hooks/useEditable';
import useShapes from './Hooks/useShapes';
import {
  useClickEvent,
  // useContextMenu,
  useMoveEvent,
} from './Events/Svg';
import { ParamContextProvider } from './Context/Params';
import './Styles/Svg.scss';
import { ShapeContextProvider } from './Context/Shape';
import { ShapeObject } from './Types/Shape';
import Triangles from './Components/Triangles';

export function Svg({
  sizeAttributes,
  Shapes,
  onShapeCreated,
  onContextMenu,
  editable,
  ...props
}: SvgPropsContent): ReactElement {
  const svgRef = useRef(null);
  const SvgDetail = useContext(SvgContext);
  useEditable(editable);
  // useShapes(Shapes);
  useClickEvent(svgRef);
  // useContextMenu(svgRef);
  // useMoveEvent(svgRef);

  return (
    <svg
      {...props}
      className="svg_container"
      ref={svgRef}
      height={sizeAttributes?.height ?? 'auto'}
      width={sizeAttributes?.width ?? 'auto'}
      style={{
        transform: `scale(${sizeAttributes?.scale ?? '1'})`,
      }}
    >
      <Triangles />
      {SvgDetail.Shapes.filter(shape => !shape.hidden).map(shape => (
        <ShapeContextProvider
          shape={shape}
          key={`${shape.Type}-${shape.Id}`}
          onShapeCreated={onShapeCreated}
          onContextMenu={onContextMenu}
        >
          <Shape {...shape} svgRef={svgRef} editable={editable} />
        </ShapeContextProvider>
      ))}
    </svg>
  );
}

export default Svg;
