import useDataDisplay from '@Hooks/useDataDisplay';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { getBeaconRules } from '@Store/BeaconRule/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { Card, Divider, Pagination, Typography } from 'antd';
import {
  BeaconRuleCard,
  SortByFilterBeaconRules,
} from '@Features/beaconRules/components';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import AddButton from '@Components/AddButton';
import { Link } from 'react-router-dom';
import CardGridWrapper from '@Components/CardGridWrapper';
import DataDisplayTable from '@Components/DataDisplayTable';
import { useBeaconRulesColumns } from './useBeaconRulesColumns';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';
import { FilterOption } from '@Types/Pagination';
import { BeaconRuleEnum } from '@Features/beaconRules/enums';
import { enumToIterable } from '@Utils/helpers';
import { getAllUsers } from '@Store/User/action';
import { noPaginationParams } from '@Hooks/usePagination.ts/constants';

export function BeaconRulesPage() {
  const [currentDisplay, toggleDisplay] = useDataDisplay('beaconRules');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const beaconRules = useAppSelector(state => state.BeaconRules.beaconRules);
  const allUsers = useAppSelector(state => state.User.allUsers);

  useEffect(() => {
    dispatch(getAllUsers(noPaginationParams));
  }, []);

  const pageConfigResult = useUserPageConfig('beaconRules', {
    orderBy: 'asc',
    sortKey: 'Id',
  });

  const filterOptions: FilterOption[] = [
    { field: 'Id', label: 'ID', type: FilterType.NUMBER },
    {
      field: 'Type',
      label: t('type'),
      type: FilterType.NUMBER_ARRAY_SELECT,
      options: enumToIterable(BeaconRuleEnum).map(([key, value]) => ({
        label: t(`beaconRules.${key}`, { defaultValue: key }),
        value: value,
      })),
    },
    { field: 'BeaconId', label: 'Beacon ID', type: FilterType.NUMBER },
    {
      field: 'Beacon.ExternalId',
      label: t('externalId'),
      type: FilterType.TEXT,
    },
    {
      field: 'Beacon.Store.BrandId',
      label: t('brand') + ' ID',
      type: FilterType.NUMBER,
    },
    {
      field: 'Beacon.Store.Brand.Name',
      label: t('brand'),
      type: FilterType.TEXT,
    },
    {
      field: 'Beacon.StoreId',
      label: t('store') + ' ID',
      type: FilterType.NUMBER,
    },
    { field: 'Beacon.Store.Name', label: t('store'), type: FilterType.TEXT },
    {
      field: 'Users.UserId',
      label: t('users'),
      type: FilterType.TEXT_ARRAY_SELECT,
      options: allUsers?.data?.Data?.map(u => ({
        label: u.Email,
        value: u.Id,
      })),
    },
  ];

  const { pagination, refetch } = usePaginatedQuery({
    status: beaconRules.status,
    filterOptions,
    pageConfigResult,
    queryFunction: p => dispatch(getBeaconRules(p)),
  });

  const beaconRulesTableColumns = useBeaconRulesColumns({
    beaconRules,
    getBeaconRules: refetch,
  });

  useSetHeaderTitle(t('beaconRules.beaconRules'));

  return (
    <Card className="page-logs-detail-container">
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilterBeaconRules
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <Link to="/beacon-rules/create">
            <AddButton />
          </Link>
        </div>

        <Divider />
        <Typography.Title level={5}>
          {t('beaconRules.description')}
        </Typography.Title>
        <Divider />

        <CardGridWrapper
          status={beaconRules.status}
          noData={beaconRules.data?.Data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <React.Fragment>
                {beaconRules.data?.Data?.map(beaconRule => (
                  <BeaconRuleCard
                    beaconRule={beaconRule}
                    getBeaconRules={refetch}
                  />
                ))}
              </React.Fragment>
            ) : (
              <DataDisplayTable
                columns={beaconRulesTableColumns}
                dataSource={beaconRules.data?.Data}
              />
            )
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={beaconRules.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={beaconRules.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
}
