import { createReducer } from '@reduxjs/toolkit';
import { Account, Icaptcha } from '../../Types/User';
import { capctha_clear, capctha_required, login, logout } from './action';
import { asyncFetchInterface } from '../../Types/ReduxTypes';
import { axiosBuilder } from '@Services/Api';

interface AccountInterface {
  Account: asyncFetchInterface<Account>;
  Captcha: Icaptcha;
}

const lSUser: Account = JSON.parse(localStorage.getItem('Account')!) || {
  userName: '',
  access_token: '',
};
axiosBuilder.setAutHeader(lSUser.access_token);

const initlState: AccountInterface = {
  Account: {
    data: {
      ...lSUser,
    },
    status: localStorage.getItem('Account') ? 'fulfilled' : 'Empty',
  },
  Captcha: {
    expire: '',
    image: '',
    required: false,
  },
};

const AccountReducer = createReducer(initlState, builder => {
  builder
    .addCase(login.pending, (state, action) => {
      state.Account = {
        ...state.Account,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(login.fulfilled, (state, action) => {
      state.Account = {
        ...state.Account,
        data: {
          ...state.Account.data,
          ...action.payload,
        },
        status: 'fulfilled',
      };
    })
    .addCase(login.rejected, (state, action) => {
      state.Account = {
        ...state.Account,
        data: {
          ...state.Account.data,
          userName: '',
          access_token: '',
        },
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(logout, state => {
      localStorage.removeItem('Account');
      axiosBuilder.setAutHeader(false);
      state.Account = {
        ...state.Account,
        data: {
          ...state.Account.data,
          userName: '',
          access_token: '',
        },
        status: 'Empty',
      };
    })
    .addCase(capctha_required, (state, action) => {
      state.Captcha = {
        ...state.Captcha,
        ...action.payload,
      };
    })
    .addCase(capctha_clear, state => {
      state.Captcha = {
        ...state.Captcha,
        expire: '',
        image: '',
        required: false,
      };
    });
});

export default AccountReducer;
