import {
  createContext,
  ReactElement,
  useState,
  useContext,
  useEffect,
} from 'react';
import { DrawElements } from '../Constants';
import { Coordinates } from '../Types/Bases';
import { ShapeObject } from '../Types/Shape';
import { onContextMenu, onShapeCreated } from '../Types/Svg';
import { ParamContext } from './Params';
import { SvgContext } from './Svg';

interface shapeContextType {
  shape: ShapeObject;
  move?: number;
  setMove: (status?: number) => void;
  movePoint: (queue: number, newCorner: Coordinates) => void;
  updateShape: () => void;
  addCorner: (newCorner: Coordinates, track?: boolean) => void;
  createOnEnd: () => void;
  onContextMenu: onContextMenu;
}

interface shapeContextProviderProps {
  children: ReactElement;
  shape: ShapeObject;
  onShapeCreated?: onShapeCreated;
  onContextMenu?: onContextMenu;
}

export const shapeContext = createContext<shapeContextType>({
  move: undefined,
  setMove: () => {},
  shape: {
    Color: '',
    Id: -1,
    Name: '',
    Points: [],
    Type: DrawElements.Polygon,
  },
  movePoint: (queue: number, newCorner: Coordinates) => {},
  updateShape: () => {},
  addCorner: () => {},
  createOnEnd: () => {},
  onContextMenu: () => {},
});

export const ShapeContextProvider = ({
  children,
  shape,
  onShapeCreated,
  onContextMenu = () => {},
}: shapeContextProviderProps) => {
  const { cancel, setDrawType } = useContext(ParamContext);
  const { updateShape, Shapes } = useContext(SvgContext);
  const [move, setMove] = useState<number | undefined>(undefined);
  const [_shape, setShape] = useState<ShapeObject>(shape);

  const movePoint = (queue: number, newCorner: Coordinates) => {
    setShape(prevShape => {
      prevShape.Points[queue] = newCorner;
      prevShape.moved = true;
      return { ...prevShape };
    });
  };
  const _updateShape = () => {
    updateShape(_shape);
  };
  useEffect(() => {
    setShape(shape);
  }, [shape]);
  useEffect(() => {
    if (_shape.isCreate) {
      addCorner(_shape.Points[_shape.Points.length - 1], true);
    }
  }, []);

  const addCorner = (newCorner: Coordinates, track?: boolean) => {
    if (_shape.Type === DrawElements.Line && _shape.Points.length === 2) {
      createOnEnd();
      return;
    }
    let newCorners: Array<Coordinates> = [newCorner];
    let newShape = { ..._shape };

    newShape.Points = newShape.Points.concat(newCorners);
    if (track) {
      setMove(newShape.Points.length - 1);
    }
    newShape.pointAdded = true;
    setShape({ ...newShape });
  };

  const createOnEnd = () => {
    const newShape = {
      ..._shape,
      isCreate: false,
    };
    setMove(undefined);
    updateShape(newShape);
    setDrawType(undefined);
    if (onShapeCreated && _shape.isCreate) {
      onShapeCreated(newShape, Shapes);
    }
    cancel();
  };

  return (
    <shapeContext.Provider
      value={{
        shape: _shape,
        move,
        setMove,
        movePoint,
        updateShape: _updateShape,
        addCorner,
        createOnEnd,
        onContextMenu,
      }}
    >
      {children}
    </shapeContext.Provider>
  );
};
