import { Form, Input } from 'antd';
import { FormInstance, useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';

export type SetNewPasswordFormValues = {
  NewPassword: string;
  ConfirmPassword: string;
};

export type SetNewPasswordFormProps = {
  form: FormInstance<SetNewPasswordFormValues>;
};

export default function SetNewPasswordForm({ form }: SetNewPasswordFormProps) {
  const { t } = useTranslation();

  const rules = [{ required: true, message: t('cannotBeEmpty') }];

  const handleSubmit = () => {
    form.resetFields();
  };

  return (
    <Form
      name="set-new-password-form"
      form={form}
      onFinish={handleSubmit}
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
    >
      <Form.Item name="NewPassword" label={t('newPassword')} rules={rules}>
        <Input.Password placeholder={t('newPassword')} />
      </Form.Item>
      <Form.Item
        name="ConfirmPassword"
        label={t('newPasswordConfirmation')}
        dependencies={['NewPassword']}
        hasFeedback
        rules={[
          ...rules,
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('NewPassword') === value)
                return Promise.resolve();
              return Promise.reject(new Error(t('passwordsDoNotMatch')));
            },
          }),
        ]}
      >
        <Input.Password placeholder={t('newPasswordConfirmation')} />
      </Form.Item>
    </Form>
  );
}
