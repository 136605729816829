import { Checkbox, Form, FormInstance } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useEffect, useState } from 'react';
import './style.scss';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useTranslation } from 'react-i18next';
import { UserEmails, UserStoresEmails } from '@Types/User';
import { ReportCreateForm } from '@Types/Report';

export type Props = {
  // onCheckboxChange: (AutoReportReceivers: {}) => void;
  searchUsers?: string[];
  brandId?: number;
  selectedUserTab?: string;
  storeEmails: UserStoresEmails;
  formItemName: string;
  form: FormInstance<ReportCreateForm>;
};
type TSelectedStoreUsers = {
  [key: string]: string[];
};
export default function UserCheckboxes({
  // onCheckboxChange,
  searchUsers = [],
  brandId,
  selectedUserTab,
  storeEmails,
  formItemName,
  form,
}: Props) {
  const [isInitiated, setIsInitiated] = useState<boolean>(false);
  const { t } = useTranslation();
  const getDefaultStoreMails = () => ({} as any);
  const selectedUsers = Form.useWatch(formItemName, form) ?? {};

  // Get users which are listed in the group
  const getExistFilteredUserIds = (Users: UserEmails[]) =>
    searchUsers.length > 0
      ? Users.filter(user => searchUsers.includes(user.UserEmail)).map(
          user => user.UserId
        )
      : Users.map(user => user.UserId);
  // Add searched users to the selected list
  const mergeWithExistList = (newUserIds: string[], checkedList: string[]) => {
    const userIds = new Set<string>();
    checkedList?.map(checkedUserId => userIds.add(checkedUserId));
    newUserIds.map(newUserId => userIds.add(newUserId));
    return Array.from(userIds);
  };
  // Remove searched users from the selected list
  const filterWithExistList = (
    newUserIds: string[],
    checkedList: string[] = []
  ) => {
    const userIds = new Set<string>();
    checkedList.map(checkedUserId => userIds.add(checkedUserId));
    newUserIds.map(newUserId => userIds.delete(newUserId));
    return Array.from(userIds);
  };
  // Is all searched users checked on the list
  const isSearchedUsersChecked = (
    checkedList: string[] = [],
    Users: UserEmails[]
  ) =>
    checkedList.filter(checkedUser =>
      getExistFilteredUserIds(Users).includes(checkedUser)
    ).length === searchUsers.length;

  const [checkAll, setCheckAll] = useState<boolean>(false);

  useEffect(() => {
    if (!isInitiated && storeEmails && Object.keys(selectedUsers).length > 0) {
      setCheckAll(
        Object.keys(selectedUsers).length === 0
          ? false
          : storeEmails.reduce<boolean>((checkedAllStatus, store) => {
              if (
                selectedUsers[store.StoreId]?.length !== store.Users.length &&
                checkedAllStatus
              )
                checkedAllStatus = false;
              return checkedAllStatus;
            }, true)
      );
      setIsInitiated(true);
    }
  }, [selectedUsers, storeEmails]);

  const checkIndeterminateStatus = () =>
    Object.keys(selectedUsers).length === 0
      ? false
      : storeEmails.reduce((indeterminateStatus, store) => {
          if (
            !!selectedUsers[store.StoreId]?.length &&
            selectedUsers[store.StoreId]!.length <= store.Users.length &&
            !indeterminateStatus
          )
            indeterminateStatus = true;
          return indeterminateStatus;
        }, false);
  const [indeterminate, setIndeterminate] = useState(
    checkIndeterminateStatus()
  );

  const onCheckAllStoresUserChange = (e: CheckboxChangeEvent) => {
    let newSelectedStoreList = {};
    newSelectedStoreList = checkSelectAllFlags(e.target.checked);
    form.setFieldValue(formItemName, newSelectedStoreList);
  };
  const checkSelectAllFlags = (checked?: boolean) => {
    // isAllChecked stores checked status for whole stores.
    let isAllChecked = true;
    let isIndeterminateActive = false;
    const existAllInSelected = storeEmails
      .map(store =>
        isSearchedUsersChecked(selectedUsers[store.StoreId], store.Users)
      )
      .some(b => b);
    const newSelectedStoreList = storeEmails.reduce<TSelectedStoreUsers>(
      (existSelectedUsers, store) => {
        const users = store.Users;
        let selectedUserIds = getExistFilteredUserIds(users);
        if (searchUsers.length > 0) {
          // If search user exist, we will consider about only searched users
          // if the operation add, the method will work as add for specific store.
          if (!existAllInSelected) {
            selectedUserIds = mergeWithExistList(
              selectedUserIds,
              selectedUsers[store.StoreId]
            );
          } else {
            // if the operation remove, the method will work as remove for specific store.
            selectedUserIds = filterWithExistList(
              selectedUserIds,
              selectedUsers[store.StoreId]
            );
          }
        } else if (!checked) {
          // if search user isn't exist and checkbox unchecked we will, we will clear all store selections.
          selectedUserIds = [];
        }
        if (selectedUserIds.length > 0) isIndeterminateActive = true;
        if (selectedUserIds.length < store.Users.length) isAllChecked = false;
        existSelectedUsers[store.StoreId] = selectedUserIds;
        return existSelectedUsers;
      },
      {}
    );
    setCheckAll(isAllChecked);
    setIndeterminate(isAllChecked ? false : isIndeterminateActive);
    return newSelectedStoreList;
  };

  return (
    <>
      <Checkbox
        onChange={e => onCheckAllStoresUserChange(e)}
        indeterminate={checkAll ? false : indeterminate}
        checked={checkAll}
      >
        {t('selectAll')}
      </Checkbox>

      {storeEmails.map((store, index) => (
        <StoreMails
          key={store.StoreId}
          name={store.StoreName}
          users={store.Users}
          UserIds={store.Users.map(mails => mails.UserId)}
          storeId={store.StoreId}
          searchUsers={searchUsers}
          defaultChecked={selectedUsers[store.StoreId] ?? []}
          formItemName={formItemName}
          form={form}
        />
      ))}
    </>
  );
}

const StoreMails = ({
  name,
  storeId,
  UserIds,
  users = [],
  searchUsers = [],
  defaultChecked,
  formItemName,
  form,
}: {
  name: string;
  storeId: number;
  UserIds: string[];
  users: UserEmails[];
  searchUsers: string[];
  defaultChecked: string[];
  form: FormInstance<ReportCreateForm>;
  formItemName: string;
}) => {
  const { t } = useTranslation();

  // Get users which are listed in the group
  const getExistFilteredUserIds = () =>
    searchUsers.length > 0
      ? users
          .filter(user => searchUsers.includes(user.UserEmail))
          .map(user => user.UserId)
      : users.map(user => user.UserId);
  // Add searched users to the selected list
  const mergeWithExistList = (newUserIds: string[]) => {
    const userIds = new Set<string>();
    checkedList.map(checkedUserId => userIds.add(checkedUserId));
    newUserIds.map(newUserId => userIds.add(newUserId));
    return Array.from(userIds);
  };

  // Remove searched users from the selected list
  const filterWithExistList = (newUserIds: string[]) => {
    const userIds = new Set<string>();
    checkedList.map(checkedUserId => userIds.add(checkedUserId));
    newUserIds.map(newUserId => userIds.delete(newUserId));
    return Array.from(userIds);
  };
  // Is all searched users checked on the list
  const isSearchedUsersChecked = () =>
    checkedList.filter(checkedUser =>
      getExistFilteredUserIds().includes(checkedUser)
    ).length === searchUsers.length;

  const [indeterminate, setIndeterminate] = useState(
    !!defaultChecked.length &&
      defaultChecked.length < getExistFilteredUserIds().length
  );
  const [checkAll, setCheckAll] = useState(
    defaultChecked.length === getExistFilteredUserIds().length
  );
  const [checkedList, setCheckedList] = useState<string[]>(defaultChecked);
  useEffect(() => {
    setCheckedList(defaultChecked);
    setCheckAll(defaultChecked.length === UserIds.length);
    setIndeterminate(
      !!defaultChecked.length && defaultChecked.length < UserIds.length
    );
  }, [defaultChecked]);
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    let selectedUserIds = getExistFilteredUserIds();
    if (searchUsers.length > 0) {
      if (!isSearchedUsersChecked()) {
        selectedUserIds = mergeWithExistList(selectedUserIds);
      } else {
        selectedUserIds = filterWithExistList(selectedUserIds);
      }
    } else if (!e.target.checked) {
      selectedUserIds = [];
    }
    // setCheckedList(selectedUserIds);
    const existSelectedStoreMailList = form.getFieldValue(formItemName);
    form.setFieldValue(formItemName, {
      ...existSelectedStoreMailList,
      [storeId]: selectedUserIds,
    });
    setIndeterminate(
      !!selectedUserIds.length && selectedUserIds.length < UserIds.length
    );
    setCheckAll(selectedUserIds.length === UserIds.length);
    // onChangeCheckboxes(storeId, selectedUserIds);
  };
  if (users.length <= 0) return <></>;
  return (
    <div className="UserCheckboxes">
      <Title level={5}>{name}</Title>
      <div className="checkboxGroup">
        <Checkbox
          indeterminate={indeterminate}
          onChange={e => onCheckAllChange(e)}
          checked={checkAll}
        >
          {t('selectAll')}
        </Checkbox>
        <Form.Item name={[formItemName, storeId.toString()]}>
          <Checkbox.Group>
            {users?.map(u => (
              <Checkbox
                style={{
                  display:
                    searchUsers.length <= 0 || searchUsers.includes(u.UserEmail)
                      ? undefined
                      : 'none',
                }}
                value={u.UserId}
              >
                {u.UserEmail}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </div>
    </div>
  );
};
