import SearchableSelect from '@Components/SearchableSelect';
import Status from '@Enums/Status';
import { useAppSelector } from '@Store/hooks';
import { IFieldSearch } from '@Types/FieldSearches';
import { Button, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

export type EditFieldSearchFormType = {
  TitleTr: string;
  TitleEn: string;
  TitleDe: string;
  KeywordsArr: string[];
  Route: string;
  Permissions: string[];
};

export type EditFieldSearchFormProps = {
  fieldSearch: IFieldSearch;
  onSubmit: (form: EditFieldSearchFormType) => void;
};

export default function EditFieldSearchForm({
  fieldSearch,
  onSubmit,
}: EditFieldSearchFormProps) {
  const { t } = useTranslation();

  const [form] = useForm<EditFieldSearchFormType>();

  const permissions = useAppSelector(s => s.Permission.allPermissions);

  const permissionOptions = useMemo(() => {
    if (permissions.status !== Status.success) return [];

    return permissions.data.map(p => ({
      value: p.Name,
      label: t(`permissions.${p.Name}`),
    }));
  }, [permissions.status]);

  return (
    <Form
      onFinish={onSubmit}
      form={form}
      className="edit-field-search-form"
      initialValues={{
        Id: fieldSearch.Id,
        TitleTr: fieldSearch.Title.find(t => t.Language === 'tr')?.Name || '',
        TitleEn: fieldSearch.Title.find(t => t.Language === 'en')?.Name || '',
        TitleDe: fieldSearch.Title.find(t => t.Language === 'de')?.Name || '',
        KeywordsArr: fieldSearch.KeywordsArr || [],
        Route: fieldSearch.Route,
        Permissions: fieldSearch.Permissions,
      }}
    >
      <Form.Item
        name="TitleTr"
        rules={[{ required: true, type: 'string' }]}
        label={t('fieldSearches.titleTr')}
        className="add-field-search-form-item"
      >
        <Input className="field-search-input" type="text" placeholder="Kasa" />
      </Form.Item>

      <Form.Item
        name="TitleEn"
        rules={[{ required: true, type: 'string' }]}
        label={t('fieldSearches.titleEn')}
        className="add-field-search-form-item"
      >
        <Input
          className="field-search-input"
          type="text"
          placeholder="Checkout"
        />
      </Form.Item>
      <Form.Item
        name="TitleDe"
        rules={[{ required: true, type: 'string' }]}
        label={t('fieldSearches.titleDe')}
        className="add-field-search-form-item"
      >
        <Input className="field-search-input" type="text" placeholder="Kasse" />
      </Form.Item>

      <Form.Item
        name="KeywordsArr"
        rules={[{ required: true }]}
        label={t('fieldSearches.keywords')}
        className="add-field-search-form-item"
      >
        <Select
          className="field-search-select"
          mode="tags"
          placeholder="Ekleme yapmak için yazıp 'Enter'a basmanız yeterli"
        />
      </Form.Item>
      <Form.Item
        name="Route"
        rules={[
          { required: true, type: 'string', pattern: /^\/([a-z0-9\/]?)+$/i },
        ]}
        label={t('Route')}
        className="add-field-search-form-item"
      >
        <Input
          className="field-search-input"
          type="text"
          placeholder="Ör: /Company"
        />
      </Form.Item>
      <Form.Item
        name="Permissions"
        rules={[{ required: true }]}
        label={t('allPermissions')}
        className="add-field-search-form-item"
      >
        <SearchableSelect
          className="field-search-select"
          mode="multiple"
          options={permissionOptions}
          placeholder="Yetki seçiniz"
        />
      </Form.Item>

      <div className="add-button-container">
        <Button htmlType="submit">{t('add')}</Button>
      </div>
    </Form>
  );
}
