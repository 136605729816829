import { createOrderBy } from '@Components/PaginationFilter/pagination-filter-utils';
import { OrderBy } from '@Enums/Filter';
import { NO_PAGINATION_PAGE_SIZE } from '@Constants/Api';
import { PaginationParams } from '@Types/Pagination';

export const defaultPaginationParams: Required<PaginationParams> = {
  PageIndex: 1,
  PageSize: 20,
  OrderBy: createOrderBy('Id', OrderBy.DESC),
  FilterBy: [],
};

export const noPaginationParams: Required<PaginationParams> = {
  PageIndex: 1,
  PageSize: NO_PAGINATION_PAGE_SIZE,
  OrderBy: createOrderBy('Id', OrderBy.DESC),
  FilterBy: [],
};
