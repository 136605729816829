import { ConfigSchemaEnums } from '@Enums/Config';
import { ConfigSchemaEntity } from '@Types/Config';

export function convertConfigTypeToEnum(
  entity: ConfigSchemaEntity
): ConfigSchemaEnums {
  switch (entity) {
    case 'store':
      return 0;
    case 'camera-record':
      return 1;
    case 'camera':
      return 2;
    case 'version':
      return 3;
  }

  return 1;
}
