import {
  ReactElement,
  isValidElement,
  cloneElement,
  Children,
  useRef,
  useState,
  useLayoutEffect,
  SyntheticEvent,
  useCallback,
  memo,
} from 'react';
import { WrapperProps } from './Types/Wrapper';
import Svg, { Image } from '.';
import WrapperTasks from './Tasks/Wrapper';
import { sizeAttributes } from './Types/Svg';
import classNames from 'classnames';
import './Wrapper.scss';
function Wrapper({ children, ...props }: WrapperProps): ReactElement {
  const WrapperRef = useRef<HTMLDivElement>(null);
  const [SvgSizes, setSvgSizes] = useState<sizeAttributes>({
    height: 0,
    width: 0,
    scale: 1,
  });

  const ImageOnLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    setSvgSizes(WrapperTasks.onResize(event.currentTarget));
    return null;
  };

  const childs = Children.map(children, (child, key) => {
    let El: ReactElement = <></>;
    if (isValidElement(child)) {
      switch (child.type) {
        case Image:
          El = cloneElement(child, {
            onLoad: ImageOnLoad,
          });
          break;
        case Svg:
          El = cloneElement(child, {
            sizeAttributes: SvgSizes,
          });
          break;
        default:
          El = cloneElement(child);
          break;
      }
    }
    return El;
  });
  const onResize = useCallback((img: HTMLImageElement) => {
    return WrapperTasks.onResize(img);
  }, []);
  useLayoutEffect(() => {
    if (WrapperRef?.current?.children?.length === undefined) return;
    const len = WrapperRef.current.children.length;
    var imgEl: HTMLImageElement;
    for (let i = 0; i < len; i++) {
      let el = WrapperRef.current.children[i];
      if (el?.nodeName === 'IMG') {
        imgEl = el as HTMLImageElement;
      }
    }
    window.addEventListener('resize', function (event) {
      if (!imgEl) {
        throw new Error('You have to add Image element inside to Wrapper!');
      }
      setSvgSizes(onResize(imgEl));
    });

    return () => {
      window.removeEventListener('resize', function (event) {
        setSvgSizes(onResize(imgEl));
      });
    };
  }, []);

  return (
    <div
      {...props}
      ref={WrapperRef}
      className={classNames('svg_wrapper', props.className)}
    >
      {childs}
    </div>
  );
}

export default memo(Wrapper);
