import SearchableSelect from '@Components/SearchableSelect';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getStoreUsers } from '@Store/User/action';
import { useEffect } from 'react';

export type ReportEmailsSelectorProps = {
  onChange: (Emails: string[]) => void;
  defaultEmails?: string[];
  brandId?: number;
  showAdmins: boolean;
};

export default function ReportEmailsSelector({
  onChange,
  brandId,
  defaultEmails,
  showAdmins,
}: ReportEmailsSelectorProps) {
  const storeUsers = useAppSelector(s => s.User.storeUsers);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getStoreUsers({ brandId, onlyuser: true }));
  }, [brandId]);

  function handleEmailChange(emails: string[]) {
    onChange(emails);
  }
  return (
    <SearchableSelect
      defaultValue={defaultEmails}
      options={storeUsers.data
        ?.filter(user => user.IsSys === showAdmins)
        .map(user => ({
          value: user.UserEmail,
          label: user.UserEmail,
        }))}
      loading={storeUsers.status === 'Pending'}
      onChange={handleEmailChange}
      mode="multiple"
    />
  );
}
