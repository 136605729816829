import { paramTypes } from '../../Context/Params';
import {
  svgClickTypes,
  svgContextClickTypes,
  svgEventTypes,
} from '../../Types/Svg';

interface SvgTasksType {
  Type: (
    params: paramTypes,
    eventType: svgEventTypes
  ) => svgClickTypes | svgContextClickTypes;
}
export const SvgTasks: SvgTasksType = {
  Type(params, eventType) {
    switch (eventType) {
      case 'click':
        return clickEvents(params);
        break;
      // case 'context':
      //   return contextEvent(params);
      //   break;
      default:
        break;
    }
    return { type: params.activeType } as svgClickTypes;
  },
};

const clickEvents = (params: paramTypes): svgClickTypes => {
  switch (params.activeType) {
    case 'stable':
      return { type: 'draw' };
      break;
    case 'draw':
      return { type: 'polygon_drawing' };
      break;
    default:
      return { type: params.activeType } as svgClickTypes;
      break;
  }
};
