import { PlusCircleOutlined } from '@ant-design/icons';
import FormCard from '@Components/FormCard';
import ReceiverTypes from '@Types/Receiver';
import ReceiversService from '@Services/Api/Receivers';
import ReceiverForm from '@Forms/ReceiverForm';
import { apiCall } from '@Utils';
import { useNavigate } from 'react-router-dom';

const NewReceiver = () => {
  let navigate = useNavigate();

  const handleSubmit = async (newReceiver: ReceiverTypes.NewReceiver) => {
    let newReceiverData: ReceiverTypes.NewReceiver = {
      Name: newReceiver.Name,
      BtMacAddress: newReceiver.BtMacAddress,
      ExternalId: newReceiver.ExternalId,
      StoreId: newReceiver.StoreId,
    };
    try {
      await apiCall(() => addNewReceiver(newReceiverData), 'addReceiver');
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const addNewReceiver = async (newReceiver: ReceiverTypes.NewReceiver) => {
    return await new ReceiversService().New(newReceiver);
  };

  return (
    <FormCard titleIcon={<PlusCircleOutlined />} titleKey="addReceiver">
      <ReceiverForm onFinish={handleSubmit} />
    </FormCard>
  );
};

export default NewReceiver;
