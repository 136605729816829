import { turkishCharacterRegex } from '@Utils/helpers';
import { Select, SelectProps } from 'antd';

/**
 * @description This component is used to prevent re-writing the same props for all <Select /> components
 */

export default function SearchableSelect(props: SelectProps) {
  return (
    <Select
      {...props}
      showSearch
      optionFilterProp="label"
      filterOption={(input, option) => {
        return (
          typeof option?.label === 'string' &&
          turkishCharacterRegex(option.label)
            .toLowerCase()
            .includes(turkishCharacterRegex(input).toLowerCase())
        );
      }}
    >
      {props.children}
    </Select>
  );
}

SearchableSelect.Option = Select.Option;
