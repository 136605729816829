import { createReducer } from '@reduxjs/toolkit';
import {
  AllGateways,
  AllGatewaysFetchResponse,
  Gateway,
  AllGateway,
} from '@Types/Gateway';

import { getAllGateway, getGatewayById } from './action';

interface stateInterface {
  allGateway: AllGateways;
  gatewayData: Gateway;
}

const initialState: stateInterface = {
  allGateway: {
    data: {} as AllGatewaysFetchResponse,
    status: 'Empty',
  },
  gatewayData: {
    data: {} as AllGateway,
    status: 'Empty',
  },
};

const GatewayReducer = createReducer(initialState, builder => {
  builder
    .addCase(getAllGateway.pending, state => {
      state.allGateway = {
        ...state.allGateway,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllGateway.fulfilled, (state, action) => {
      state.allGateway.data = action.payload as AllGatewaysFetchResponse;
      state.allGateway.status = 'fulfilled';
    })
    .addCase(getAllGateway.rejected, (state, action) => {
      state.allGateway = {
        ...state.allGateway,
        data: {} as AllGatewaysFetchResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getGatewayById.pending, state => {
      state.gatewayData = {
        ...state.gatewayData,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getGatewayById.fulfilled, (state, action) => {
      state.gatewayData.data = action.payload as AllGateway;
      state.gatewayData.status = 'fulfilled';
    })
    .addCase(getGatewayById.rejected, (state, action) => {
      state.gatewayData = {
        ...state.gatewayData,
        data: {} as AllGateway,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default GatewayReducer;
