import useLocalStorage from './useLocalStorage';

export type DataDisplay = 'card' | 'table';
export type ToggleDisplay = () => void;
export type DataDisplayHook = [DataDisplay, ToggleDisplay];

/**
 * @param {string} localStorageKey is used to indicate the key for local storage.
 * @description This hook is used for pages that containts card grids. Allows us to toggle between 'card' and 'table' data display systems.
 * @returns a tuple: [dataDisplay, toggleDisplay]
 * First item represents the current data display type.
 * Second item is a function that toggles the data display type.
 */
export default function useDataDisplay(
  localStorageKey: string
): DataDisplayHook {
  // Store the state in local storage so that the users won't have to change it every single mount
  const [dataDisplay, setDataDisplay] = useLocalStorage<DataDisplay>(
    `dataDisplay.${localStorageKey}`,
    'card'
  );

  const toggleDataDisplay = (): void => {
    setDataDisplay(currDataDisplay => {
      if (currDataDisplay === 'card') return 'table';
      if (currDataDisplay === 'table') return 'card';
      return 'card';
    });
  };

  return [dataDisplay, toggleDataDisplay];
}
