import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import BoardsService from '@Services/Api/Boards';
import { BoardId, GetBoardsParams } from '@Types/Board';
import {
  GET_ALL_ARCHIVED_BOARDS,
  GET_ALL_UNARCHIVED_BOARDS,
  GET_ARCHIVED_BOARDS,
  GET_BOARD_DETAIL,
  GET_UNARCHIVED_BOARDS,
  RESET_ALL_BOARDS,
  RESET_CURRENT_BOARD,
  GET_BOARD_WIFI_CONF,
} from './type';
import StoresService from '@Services/Api/Stores';

export const getUnarchivedBoards = createAsyncThunk(
  GET_UNARCHIVED_BOARDS,
  async (params: GetBoardsParams) => {
    try {
      const allBoards = await new StoresService().GetUnArchivedBoards(
        params.filters,
        params.storeId!
      );
      return allBoards;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getArchivedBoards = createAsyncThunk(
  GET_ARCHIVED_BOARDS,
  async (params: GetBoardsParams) => {
    try {
      const allBoards = await new StoresService().GetArchivedBoards(
        params.filters,
        params.storeId!
      );
      return allBoards;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getAllUnarchivedBoards = createAsyncThunk(
  GET_ALL_UNARCHIVED_BOARDS,
  async (params: GetBoardsParams) => {
    try {
      const allBoards = await new BoardsService().GetAllUnarchived(params);
      return allBoards;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getAllArchivedBoards = createAsyncThunk(
  GET_ALL_ARCHIVED_BOARDS,
  async (params: GetBoardsParams) => {
    try {
      const allBoards = await new BoardsService().GetAllArchived(params);
      return allBoards;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getBoardDetail = createAsyncThunk(
  GET_BOARD_DETAIL,
  async (boardId: BoardId) => {
    try {
      const board = await new BoardsService().GetBoardDetail(boardId);
      return board;
    } catch (err) {
      console.error(err);
      return {};
    }
  }
);

export const getBoardWifiConfigiration = createAsyncThunk(
  GET_BOARD_WIFI_CONF,
  async (boardId: number) => {
    try {
      const boardWifiConfig =
        await new BoardsService().GetBoardNetworkConfiguration(boardId);
      return boardWifiConfig;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const resetAllBoards = createAction(RESET_ALL_BOARDS);
export const resetCurrentBoard = createAction(RESET_CURRENT_BOARD);
