import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_BRAND_GROUPS, GET_BRAND_GROUPS_WITHOUT_PAGINATON } from './type';
import { PaginationParams } from '@Types/Filter';
import BrandGroupService from '@Services/Api/BrandGroupService';
import { defaultPaginationParams } from '@Hooks/usePagination.ts/constants';

export const getBrandGroups = createAsyncThunk(
  GET_BRAND_GROUPS,
  async (params: PaginationParams) => {
    try {
      const brandGroups = await new BrandGroupService().GetAllBrandGroups(
        params
      );
      return brandGroups;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getBrandGroupsWithoutPagination = createAsyncThunk(
  GET_BRAND_GROUPS_WITHOUT_PAGINATON,
  async () => {
    try {
      const brandGroups = await new BrandGroupService().GetAllBrandGroups(
        defaultPaginationParams
      );
      return brandGroups;
    } catch (err) {
      console.error(err);
    }
  }
);
