import DeleteButton from '@Components/Card/DeleteButton';
import { Permissions } from '@Enums/Permission';
import { UpdateBrandGroupModal } from '@Features/brand-groups/modals';
import useBoolean from '@Hooks/useBoolean';
import useCheckPermission from '@Hooks/useCheckPermission';
import BrandGroupService from '@Services/Api/BrandGroupService';
import { useAppSelector } from '@Store/hooks';
import { apiCall } from '@Utils/index';
import { EditOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';

interface Props {
  id: number;
  onRefetch: () => void;
}

export function BrandGroupActionButtons({ id, onRefetch }: Props) {
  const brandGroups = useAppSelector(s => s.BrandGroup.brandGroups.data?.Data);

  const actionPermission = useCheckPermission(Permissions.ADMIN_BRAND_ACTION);

  const updateModalState = useBoolean();

  const deleteBeaconRule = async () => {
    try {
      const req = () => new BrandGroupService().DeleteBrandGroup(id);
      await apiCall(req);
      onRefetch();
    } catch (e) {
      console.warn(e);
    }
  };

  if (!actionPermission) return <></>;

  const brandGroup = brandGroups?.find(b => b.Id === id);

  return (
    <Space direction="horizontal" size="small" wrap>
      {brandGroup && (
        <>
          <Button
            shape="circle"
            icon={<EditOutlined />}
            size="small"
            onClick={updateModalState.setTrue}
          />
          <UpdateBrandGroupModal
            onRefetch={onRefetch}
            brandGroup={brandGroup}
            modalState={updateModalState}
          />
        </>
      )}

      <DeleteButton onConfirm={deleteBeaconRule} />
    </Space>
  );
}
