import SearchableSelect from '@Components/SearchableSelect';
import Status from '@Enums/Status';
import { useAppSelector } from '@Store/hooks';
import { Form } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type BeaconRuleUserSelectorProps = {
  storeIds: number[];
};

export function BeaconRuleUserSelector({
  storeIds,
}: BeaconRuleUserSelectorProps) {
  const { t } = useTranslation();

  const stores = useAppSelector(s => s.User.userStoresAndEmails);

  const userOptions = useMemo(() => {
    if (stores.status !== Status.success) return [];

    let users = stores.data
      .filter(store => storeIds.includes(store.StoreId))
      .map(store =>
        store.Users.map(user => ({ value: user.UserId, label: user.UserEmail }))
      )
      .flat();

    // Remove duplicated users
    users = users.filter(
      (v, i, a) => a.findIndex(t => t.value === v.value) === i
    );

    return users;
  }, [stores.status, storeIds]);

  const inputRule = [{ required: true, message: t('cannotBeEmpty') }];

  return (
    <Form.Item label={t('users')} name="UserIds" rules={inputRule}>
      <SearchableSelect
        options={userOptions}
        loading={stores.status === Status.pending}
        mode="multiple"
        maxTagCount={2}
      />
    </Form.Item>
  );
}
