import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_ALL_LENS_TYPE, GET_LENS_TYPE } from './type';
import LensTypeServices from '@Services/Api/LensType';
import { PaginationParams } from '@Types/Filter';

export const getAllLensType = createAsyncThunk(
  GET_ALL_LENS_TYPE,
  async (params: PaginationParams) => {
    try {
      const lensType = await new LensTypeServices().GetAlllensType(params);
      return lensType;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getLensType = createAsyncThunk(
  GET_LENS_TYPE,
  async (lensTypeId: number) => {
    try {
      const lensType = await new LensTypeServices().GetLensType(lensTypeId);
      return lensType;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
