import { createReducer } from '@reduxjs/toolkit';
import { asyncFetchInterface } from '@Types/ReduxTypes';
import { getBrandRegions, getAllRegions, resetRegion } from './action';
import { AllRegions, Regions, AllRegionFetchResponse } from '@Types/Region';
import { PaginationData } from '@Types/index';

interface IInitialState {
  regions: asyncFetchInterface<Regions[]>;
  allRegion: asyncFetchInterface<PaginationData<AllRegions>>;
}

const initialState: IInitialState = {
  regions: {
    data: [],
    status: 'Empty',
  },
  allRegion: {
    data: {} as AllRegionFetchResponse,
    status: 'Empty',
  },
};

const RegionsReducer = createReducer(initialState, builder => {
  builder
    .addCase(getBrandRegions.pending, state => {
      state.regions = {
        ...state.regions,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getBrandRegions.fulfilled, (state, action) => {
      state.regions.data = action.payload;
      state.regions.status = 'fulfilled';
    })
    .addCase(getBrandRegions.rejected, (state, action) => {
      state.regions = {
        ...state.regions,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getAllRegions.pending, state => {
      state.allRegion = {
        ...state.allRegion,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllRegions.fulfilled, (state, action) => {
      state.allRegion = {
        status: 'fulfilled',
        data: action.payload as AllRegionFetchResponse,
      };
    })
    .addCase(getAllRegions.rejected, (state, action) => {
      state.allRegion = {
        ...state.allRegion,
        data: {} as AllRegionFetchResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(resetRegion, state => {
      state.regions = {
        data: [],
        status: 'Empty',
        ErrorMessage: undefined,
      };
    });
});

export default RegionsReducer;
