import { serialized } from '@Types/Coordinates';
import StackBarOptions from '../Options/StackBarOptions';
import { ReactElement } from 'react';
import { Chart } from 'react-chartjs-2';

type Props = {
  data: serialized;
  Label?: serialized;
  lineData?: serialized;
  lineLabel?: string;
  barLabel?: string;
};

function ComposedChart({
  data,
  Label,
  lineData,
  lineLabel,
  barLabel,
}: Props): ReactElement {
  var dataSets = [
    {
      type: 'line' as const,
      label: lineLabel,
      backgroundColor: '#26b99a',
      borderColor: '#26b99a',
      borderWidth: 0.5,
      fill: false,
      data: lineData?.Serial,
      yAxisID: 'y1',
    },
    {
      type: 'bar' as const,
      label: barLabel,
      data: data.Serial,
      backgroundColor: '#196ebd',
      yAxisID: 'y',
    },
  ];
  const convert = {
    labels: Label?.Serial,
    datasets: [...dataSets],
  };
  return <Chart type="bar" data={convert} options={StackBarOptions} />;
}
export default ComposedChart;
