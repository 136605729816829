import { useContext } from 'react';
import { DrawElements } from '../../Constants';
import { ParamContext } from '../../Context/Params';
import { PlusSquareOutlined } from '@ant-design/icons';
import './style.scss';
type Props = {
  show: boolean;
  onClick: () => void;
};

function AbsoluteFillPolygonAdd({ show, onClick }: Props) {
  const { setDrawType } = useContext(ParamContext);
  const addOnClick = () => {
    onClick();
    setDrawType(DrawElements.Polygon);
  };
  return show ? (
    <div className="svg-absolute-fill-polygon-add" onClick={addOnClick}>
      <PlusSquareOutlined style={{ fontSize: 25 }} />
    </div>
  ) : (
    <></>
  );
}

AbsoluteFillPolygonAdd.defaultProps = {
  show: false,
  onClick: () => {},
};
export default AbsoluteFillPolygonAdd;
