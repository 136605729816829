import DeleteButton from '@Components/Card/DeleteButton';
import EditButton from '@Components/Card/EditButton';
import useIsEnvAdmin from '@Hooks/useIsEnvAdmin';
import RoleService from '@Services/Api/Role';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getAllRoles } from '@Store/Role/action';
import { Role } from '@Types/Role';
import { apiCall } from '@Utils/index';
import { Space } from 'antd';

interface Props {
  role: Role;
  getRoles: () => void;
}

export default function RoleActionButtons({ role, getRoles }: Props) {
  const userId = useAppSelector(s => s.User.currentUserDetails.data.Id);

  const dispatch = useAppDispatch();

  const isEnvAdmin = useIsEnvAdmin();

  const deleteRole = async () => {
    try {
      const req = () => new RoleService().DeleteRole(role.RoleId);
      await apiCall(req, 'deleteRole');
      dispatch(getAllRoles());
    } catch (e) {
      console.warn(e);
    }
  };

  if (isEnvAdmin || userId === role.CreatedByUserId)
    return (
      <Space direction="horizontal" size="small" wrap>
        <EditButton to={`${role.RoleId}`} />
        <DeleteButton onConfirm={deleteRole} />
      </Space>
    );

  return <></>;
}
