const LOGIN = 'ACCOUNT/LOGIN';
const LOGOUT = 'ACCOUNT/LOGOUT';

const GET_ROLE = 'ACCOUNT/ROLE';
const GET_PERMISSION = 'ACCOUNT/PERMISSION';
const CAPTCHA_REQUIRED = 'ACCOUNT/CAPTCHA_REQUIRED';
const CAPTCHA_CLEAR = 'ACCOUNT/CAPTCHA_CLEAR';

export {
  LOGIN,
  LOGOUT,
  GET_ROLE,
  GET_PERMISSION,
  CAPTCHA_REQUIRED,
  CAPTCHA_CLEAR,
};
