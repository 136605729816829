import { DatePicker } from 'antd';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
const { RangePicker } = DatePicker;

export type Dates = [Moment | null, Moment | null] | null;
export type DateStrings = [string, string];

interface Props {
  onChange: (dates: Dates, dateStrings: DateStrings) => void;
}

export default function DateRangeSelector({ onChange }: Props) {
  const { t } = useTranslation();

  return (
    <RangePicker
      placeholder={[t('startDate'), t('endDate')]}
      showTime={{ format: 'HH:mm' }}
      format="DD/MM/YYYY HH:mm"
      onChange={onChange}
    />
  );
}
