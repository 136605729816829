import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { TicketStatus } from '@Enums/ticket.enums';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

interface TicketStatusTagProps {
  status: TicketStatus;
}

export function TicketStatusTag(props: TicketStatusTagProps) {
  const { status } = props;

  const { t } = useTranslation();

  const themes: Record<TicketStatus, string> = {
    [TicketStatus.PENDING]: 'blue',
    [TicketStatus.RESPONDED]: 'orange',
    [TicketStatus.CLOSED]: 'green',
  };

  const icons: Record<TicketStatus, JSX.Element> = {
    [TicketStatus.PENDING]: <ClockCircleOutlined />,
    [TicketStatus.RESPONDED]: <MailOutlined />,
    [TicketStatus.CLOSED]: <CheckCircleOutlined />,
  };

  const text = t(`ticketStatuses.${TicketStatus[status]}`);
  const theme = themes[status];
  const icon = icons[status];

  return (
    <Tag color={theme} icon={icon} style={{ margin: 0 }}>
      {text}
    </Tag>
  );
}
