import {
  AllGatewaysFetchResponse,
  AllGateway,
  EditedGateway,
  GetGatewayParams,
  NewGateway,
} from '@Types/Gateway';
import model from './index';
class GatewaysService extends model {
  constructor() {
    super();
    this.Prefix('Gateways');
  }

  /**
   * @param {GetGatewayParams} params
   * @description Get all Gateways
   * @returns Promise
   */
  async GetAllGateways(params: GetGatewayParams) {
    return this.Post(`GetAll`)
      .Data(params.filters)
      .Save<AllGatewaysFetchResponse>();
  }

  /**
   *
   * @param newGateway
   * @description Add a new Gateway
   * @returns Promise
   */
  async New(newGateway: NewGateway) {
    return this.Post().Data(newGateway).Save();
  }

  /**
   * @param {string}
   * @description Get code gateway by  Id
   * @returns Promise
   */

  async GetGatewayById(GatewayId: number) {
    return this.Get(`/${GatewayId}`).offset().Save<AllGateway>();
  }
  /**
   * @param {string} GatewayId
   * @param {EditedGateway} editedGateway
   * @description Edit Gateway
   * @returns Promise
   */
  async EditGateway(gatewayId: number, editedGateway: EditedGateway) {
    return this.Put(`/${gatewayId}`).Data(editedGateway).Save();
  }

  /**
   *
   * @description Delete a Gateway.
   * @returns Promise
   */
  async DeleteGateway(gatewayId: number) {
    return this.PrefixId(`/${gatewayId}`).Delete().Save<any>();
  }
}
export default GatewaysService;
