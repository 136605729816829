import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import StoresService from '@Services/Api/Stores';
import {
  CoordinatesCameraFilterParams,
  CoordinatesFilterParams,
  MultipleCoordinatesFilterParams,
} from '@Types/Coordinates';
import {
  GET_STORES_CAMERA_COORDINATES,
  GET_CAMERA_COORDINATES,
  GET_STORES_LINE_COORDINATES,
  GET_CAMERA_LINE_COORDINATES,
  RESET_STORE_CAMERA_COORDINATES,
  RESET_STORE_LINE_COORDINATES,
  GET_MULTIPLE_STORES_CAMERA_COORDINATES,
  GET_MULTIPLE_STORES_LINE_COORDINATES,
} from './type';
import CamerasService from '@Services/Api/Cameras';
import BrandService from '@Services/Api/Brand';

export const getStoresCameraCoordinate = createAsyncThunk(
  GET_STORES_CAMERA_COORDINATES,
  async (params: CoordinatesFilterParams) => {
    try {
      const storeCameraCoordinates =
        await new StoresService().GetStoresCameraCoordinates(params);
      return storeCameraCoordinates;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getMultipleStoresCameraCoordinate = createAsyncThunk(
  GET_MULTIPLE_STORES_CAMERA_COORDINATES,
  async (params: MultipleCoordinatesFilterParams) => {
    try {
      const storeCameraCoordinates =
        await new BrandService().GetMultipleStoresCameraCoordinates(params);
      return storeCameraCoordinates;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getStoresLineCoordinate = createAsyncThunk(
  GET_STORES_LINE_COORDINATES,
  async (params: CoordinatesFilterParams) => {
    try {
      const cameraCoordinates =
        await new StoresService().GetStoresCameraCoordinates(params);
      return cameraCoordinates;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getMultipleStoresLineCoordinate = createAsyncThunk(
  GET_MULTIPLE_STORES_LINE_COORDINATES,
  async (params: MultipleCoordinatesFilterParams) => {
    try {
      const cameraCoordinates =
        await new BrandService().GetMultipleStoresCameraCoordinates(params);
      return cameraCoordinates;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getCamerasCoordinate = createAsyncThunk(
  GET_CAMERA_COORDINATES,
  async (params: CoordinatesCameraFilterParams) => {
    try {
      const cameraCoordinates = await new CamerasService().GetCameraCoordinates(
        params
      );
      return cameraCoordinates;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

// export const getCamerasCoordinate = createAsyncThunk(
//   GET_CAMERA_COORDINATES,
//   async (params: CoordinatesCameraFilterParams) => {
//     try {
//       const cameraCoordinates = await new CamerasService().GetCameraCoordinates(
//         params
//       );
//       return cameraCoordinates;
//     } catch (err) {
//       console.error(err);
//       return [];
//     }
//   }
// );

export const getCamerasLineCoordinate = createAsyncThunk(
  GET_CAMERA_LINE_COORDINATES,
  async (params: CoordinatesCameraFilterParams) => {
    try {
      const cameraCoordinates = await new CamerasService().GetCameraCoordinates(
        params
      );
      return cameraCoordinates;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

// export const getCamerasLineCoordinate = createAsyncThunk(
//   GET_CAMERA_LINE_COORDINATES,
//   async (params: CoordinatesCameraFilterParams) => {
//     try {
//       const cameraCoordinates = await new CamerasService().GetCameraCoordinates(
//         params
//       );
//       return cameraCoordinates;
//     } catch (err) {
//       console.error(err);
//       return [];
//     }
//   }
// );

export const resetStoreCameraCoordinate = createAction(
  RESET_STORE_CAMERA_COORDINATES
);
export const resetStoreLineCoordinate = createAction(
  RESET_STORE_LINE_COORDINATES
);
