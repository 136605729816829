import { paramTypes } from '@Components/Svg/Context/Params';
import { Coordinates } from '../../Types/Bases';
import {
  Points,
  shapeEventInterface,
  shapeStatusTypes,
} from '../../Types/Shape';

export const ShapeTasks = {
  getShapePoints(Points: Points): string {
    return Points.map(point => `${point.x},${point.y}`).join(',');
  },
  getPointsCoordinates(Points: Points): Array<Coordinates> {
    return [];
  },
  area(Points: Points) {
    var area = 0,
      i,
      j,
      point1,
      point2;

    for (i = 0, j = Points.length - 1; i < Points.length; j = i, i++) {
      point1 = Points[i];
      point2 = Points[j];
      area += point1.x * point2.y;
      area -= point1.y * point2.x;
    }
    area /= 2;

    return area;
  },
  centroid(Points: Points): Coordinates {
    var x = 0,
      y = 0,
      i,
      j,
      f,
      point1,
      point2;
    for (i = 0, j = Points.length - 1; i < Points.length; j = i, i++) {
      point1 = Points[i];
      point2 = Points[j];
      f = point1.x * point2.y - point2.x * point1.y;
      x += (point1.x + point2.x) * f;
      y += (point1.y + point2.y) * f;
    }

    f = ShapeTasks.area(Points) * 6;
    return { x: x / f || 0, y: y / f || 0 };
  },
  lineCenter(Points: Points) {
    let x = 0,
      y = 0;
    const secondPoint = Points[1] || { x: 0, y: 0 };
    x = (secondPoint?.x - Points[0].x) / 2 + Points[0].x;
    y = (secondPoint?.y - Points[0].y) / 2 + Points[0].y;
    return { x, y };
  },
};

interface ShapeTasksType {
  Type: (
    params: paramTypes,
    eventType: shapeStatusTypes
  ) => shapeEventInterface;
}

export const shapeTasksTypes: ShapeTasksType = {
  Type(params, eventType): shapeEventInterface {
    switch (eventType) {
      case 'shape_context':
        return contextEvent(params);
        break;
      case 'shape_key_down':
        return keyDownEvent(params);
      default:
        break;
    }
    return { type: params.activeType };
  },
};

const contextEvent = (params: paramTypes): shapeEventInterface => {
  switch (params.activeType) {
    case 'polygon_drawing':
      return { type: 'stable' };
      break;
    default:
      return { type: params.activeType };
      break;
  }
};

const keyDownEvent = (params: paramTypes): shapeEventInterface => {
  switch (params.activeType) {
    case 'polygon_drawing':
      return { type: 'stable' };
      break;
    default:
      return { type: params.activeType };
      break;
  }
};
