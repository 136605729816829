import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export default function AddButton({ onClick }: Props) {
  const { t } = useTranslation();
  return (
    <Button type="primary" onClick={onClick}>
      {t('add')}
    </Button>
  );
}
