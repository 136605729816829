import useDataDisplay from '@Hooks/useDataDisplay';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import React, { useState } from 'react';
import { convertDateTimeParam } from '@Utils/Api';
import { Card, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import CardGridWrapper from '@Components/CardGridWrapper';
import SortByFilter from './Components/SortByFilter';
import DataDisplayTable from '@Components/DataDisplayTable';
import DateRangeSelector, { Dates } from '@Components/DateRangeSelector';
import { getAuditLogs } from '@Store/AuditLogs/action';
import AuditLogCard from '@Cards/AuditLogCard';
import moment from 'moment';
import useAuditLogsColumns from './usePageLogsColumns';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { FilterOption } from '@Types/Pagination';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';
import { AuditLogType } from '@Enums/AuditLog';

export default function AuditLogs() {
  const [sdate, setSDate] = useState<string | null>('03/05/2021');
  const [edate, setEDate] = useState<string | null>(
    moment().format('DD/MM/YYYY')
  );
  const [stime, setSTime] = useState<string | null>('10:00');
  const [etime, setETime] = useState<string | null>('23:00');
  const [currentDisplay, toggleDisplay] = useDataDisplay('auditLogs');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const auditLogs = useAppSelector(state => state.AuditLog.auditLogs);

  const pageConfigResult = useUserPageConfig('auditLogs', {
    orderBy: 'asc',
    sortKey: 'UserName',
  });

  const filterOptions: FilterOption[] = [
    {
      field: 'UserName',
      label: t('user'),
      type: FilterType.TEXT,
    },
    {
      field: 'Type',
      label: t('URL'),
      type: FilterType.TEXT_ARRAY_SELECT,
      options: [
        { label: t('create'), value: AuditLogType.CREATE },
        { label: t('delete'), value: AuditLogType.DELETE },
        { label: t('update'), value: AuditLogType.UPDATE },
      ],
    },
  ];

  const { pagination } = usePaginatedQuery({
    status: auditLogs.status,
    filterOptions,
    pageConfigResult,
    refetchDependencies: [sdate, edate, stime, etime],
    queryFunction: p => {
      if (!sdate || !edate || !stime || !etime) return;
      dispatch(
        getAuditLogs({
          filters: p,
          dateTimeParams: convertDateTimeParam({ sdate, edate, stime, etime }),
        })
      );
    },
  });

  const auditLogsTableColumns = useAuditLogsColumns({ auditLogs });

  const handleDateSelect = (dates: Dates) => {
    if (dates) {
      let [firstDate, secondDate] = dates;
      setSDate(firstDate ? firstDate.format('DD/MM/YYYY') : null);
      setEDate(secondDate ? secondDate.format('DD/MM/YYYY') : null);
      setSTime(firstDate ? firstDate.format('HH:mm') : null);
      setETime(secondDate ? secondDate.format('HH:mm') : null);
    }
  };

  useSetHeaderTitle(t('auditLogs'));

  return (
    <Card className="page-logs-detail-container">
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <DateRangeSelector onChange={handleDateSelect} />
        </div>

        <CardGridWrapper
          status={auditLogs.status}
          noData={auditLogs.data?.Data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <React.Fragment>
                {auditLogs.data?.Data?.map((auditLog, i) => (
                  <AuditLogCard auditLog={auditLog} key={i} />
                ))}
              </React.Fragment>
            ) : (
              <DataDisplayTable
                columns={auditLogsTableColumns}
                dataSource={auditLogs.data?.Data}
              />
            )
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={auditLogs.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={auditLogs.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
}
