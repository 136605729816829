import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_ALL } from './type';
import CameraRulesService from '@Services/Api/CameraRules';
import {
  AllCameraRulesFetchResponse,
  GetCameraRulesParams,
} from '@Types/CameraRules';

export const getCameraRules = createAsyncThunk(
  GET_ALL,
  async (params: GetCameraRulesParams) => {
    try {
      const cameraRules = await new CameraRulesService().GetAll(params);
      return cameraRules;
    } catch (err) {
      console.error(err);
      return {} as AllCameraRulesFetchResponse;
    }
  }
);
