import {
  UploadVersionFileForm,
  UploadVersionFileFormProps,
} from '@Forms/UploadVersionFileForm';
import { UseBooleanResult } from '@Hooks/useBoolean';
import { IUploadVersionFileForm } from '@Types/Version';
import { Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';

interface IProps extends UploadVersionFileFormProps {
  modalState: UseBooleanResult;
}

export function UploadVersionFileModal(props: IProps) {
  const { modalState, ...restProps } = props;

  const { t } = useTranslation();

  const [form] = useForm<IUploadVersionFileForm>();

  async function handleOkClick() {
    try {
      await form.validateFields();
      form.submit();
      modalState.setFalse();
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Modal
      title={t('uploadVersionFile')}
      open={modalState.value}
      onOk={handleOkClick}
      onCancel={modalState.setFalse}
      destroyOnClose
    >
      <UploadVersionFileForm form={form} {...restProps} />
    </Modal>
  );
}
