import ModalFooter from '@Components/ModalFooter';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import {
  AutoReportReceiver,
  IReport,
  ReportCreate,
  ReportCreateForm,
} from '@Types/Report';
import {
  Form,
  Input,
  Select,
  Card,
  Row,
  Col,
  TimePicker,
  Radio,
  Checkbox,
  Tabs,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { ReportEmailsSelector } from './components';
import { useEffect, useState } from 'react';
import { getAllAvailableBrands } from '@Store/Brand/action';
import Status from '@Enums/Status';
import PageSkeletons from '@Components/PageSkeletons';
import SearchableSelect from '@Components/SearchableSelect';
import ModalHeader from '@Pages/Users/Components/ModalHeader';
import { PaperClipOutlined } from '@ant-design/icons';
import UserCheckboxes from '@Features/reports/components/UserCheckboxes';
import ReportTypeSelect from '@Features/reports/components/ReportTypeSelect';
import {
  getStoreUsers,
  getUserStores,
  resetUserStores,
} from '@Store/User/action';
import { Brand } from '@Types/Brand';
import moment from 'moment';
import { UnsubscribeList } from './components/UnsubscribeList';

export type ReportFormProps = {
  onSubmit: (report: ReportCreateForm) => void;
  report?: ReportCreateForm;
  titleKey: string;
  unSubscribedUsers?: AutoReportReceiver[];
};

export default function ReportForm({
  onSubmit,
  report,
  titleKey,
  unSubscribedUsers,
}: ReportFormProps) {
  const { t } = useTranslation();

  const [form] = useForm<ReportCreateForm>();
  const dispatch = useAppDispatch();

  const brands = useAppSelector(s => s.Brand.allAvailableBrands);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>();
  const [searchUsers, setSearchUsers] = useState<string[]>([]);
  const [selectedUserTab, setselectedUserTab] = useState<string>();
  const userStoresAndEmails = useAppSelector(s => s.User.userStoresAndEmails);

  useEffect(() => {
    dispatch(getAllAvailableBrands());
    return () => {
      dispatch(resetUserStores());
    };
  }, []);
  const getStoreMails = (tab: string) =>
    userStoresAndEmails.data.map(store => ({
      ...store,
      Users: store.Users.filter(user =>
        tab === 'SysAdmin' ? user.IsSys : !user.IsSys
      ),
    }));

  const handleBrandChange = (brandId: number) => {
    if (brandId) {
      dispatch(getUserStores(brandId));
      setSelectedBrand(brands.data.find(b => b.Id === brandId));
    } else {
      dispatch(resetUserStores());
      setSelectedBrand(null);
    }
  };
  const getReceiverKey = (tab: string) =>
    tab !== 'SysAdmin' ? 'AutoReportReceivers' : 'AutoReportReceiversSys';

  const searchValuesOnChange = (Emails: string[]) => {
    setSearchUsers(Emails);
  };
  const tabOnChange = (value: string) => {
    setselectedUserTab(value);
  };
  const rules = [{ required: true, message: t('cannotBeEmpty') }];

  if (brands.status === Status.pending) return <PageSkeletons />;
  return (
    <Card
      title={
        <ModalHeader titleKey={titleKey}>
          <PaperClipOutlined />
        </ModalHeader>
      }
      className="role-edit-wrapper"
    >
      <Form
        name="report-form"
        form={form}
        onFinish={onSubmit}
        initialValues={{
          ...report,
          ActionTime: report?.ActionTime
            ? moment(report?.ActionTime, 'HH:mm')
            : '',
        }}
      >
        <Row>
          <Col span={6}>
            <Form.Item label={t('name')} name="Name" rules={rules}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} offset={2}>
            <Form.Item label={t('brand')} name="BrandId" rules={rules}>
              <SearchableSelect
                placeholder={t('selectBrand')}
                onChange={handleBrandChange}
                allowClear
                maxTagCount={2}
              >
                {brands.data.map(b => (
                  <Select.Option key={b.Id} value={b.Id} label={b.Name}>
                    {b.Name}
                  </Select.Option>
                ))}
              </SearchableSelect>
            </Form.Item>
          </Col>

          <Col span={6} offset={2}>
            <Form.Item
              name="AutoReportType"
              rules={rules}
              label={t('reportType')}
            >
              <ReportTypeSelect
                onChange={() => form.setFieldsValue}
                defaultValue={report?.AutoReportType}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item
              valuePropName="ActionTime"
              label={t('hour')}
              name="ActionTime"
              rules={rules}
            >
              <TimePicker
                format={'HH:mm'}
                defaultValue={
                  report?.ActionTime
                    ? moment(report?.ActionTime, 'HH:mm')
                    : undefined
                }
              />
            </Form.Item>
          </Col>
          <Col span={5} offset={2}>
            <Form.Item
              name="AutoReportScheduleDateType"
              initialValue={0}
              label={'Period'}
              rules={rules}
            >
              <Radio.Group>
                <Radio value={0}>{t('daily')}</Radio>
                <Radio value={1}>{t('weekly')}</Radio>
                <Radio value={2}>{t('monthly')}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item label={'Search Email'}>
              <ReportEmailsSelector
                brandId={form.getFieldValue('BrandId')}
                onChange={searchValuesOnChange}
                showAdmins={selectedUserTab === 'SysAdmin'}
              />
            </Form.Item>
          </Col>
        </Row>
        {userStoresAndEmails.status === Status.pending ? (
          <PageSkeletons />
        ) : (
          <Tabs
            onChange={val => tabOnChange(val)}
            destroyInactiveTabPane={false}
            items={[
              {
                label: t('users'),
                key: 'users',
                forceRender: true,
                animated: false,
                children: (
                  <UserCheckboxes
                    form={form}
                    searchUsers={searchUsers}
                    brandId={selectedBrand?.Id}
                    selectedUserTab={selectedUserTab}
                    storeEmails={getStoreMails('users')}
                    formItemName={getReceiverKey('users')}
                  />
                ),
              },
              {
                label: t('admins'),
                key: 'SysAdmin',
                forceRender: true,
                animated: false,
                children: (
                  <UserCheckboxes
                    form={form}
                    searchUsers={searchUsers}
                    brandId={selectedBrand?.Id}
                    selectedUserTab={selectedUserTab}
                    storeEmails={getStoreMails('SysAdmin')}
                    formItemName={getReceiverKey('SysAdmin')}
                  />
                ),
              },
              {
                label: t('unsubscribedUsers'),
                key: 'UnsubscribeList',
                forceRender: true,
                animated: false,
                disabled:
                  report?.Id === undefined ||
                  (unSubscribedUsers && unSubscribedUsers?.length < 1),
                children: (
                  <UnsubscribeList
                    UnsubscribeUsers={unSubscribedUsers}
                    reportId={report?.Id}
                  />
                ),
              },
            ]}
          />
        )}
        <ModalFooter formKey="report-form" />
      </Form>
    </Card>
  );
}
