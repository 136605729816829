import {
  Col,
  Divider,
  Form,
  InputNumber,
  Radio,
  Row,
  Select,
  TreeDataNode,
  TreeSelect,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import ModalFooter from '@Components/ModalFooter';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { useEffect, useState } from 'react';
import { Brand } from '@Types/Brand';
import { getAllAvailableBrands } from '@Store/Brand/action';
import { NewCameraRule } from '@Types/CameraRules';
import { getAllStoresOfBrands } from '@Store/Store/action';
import { getAllCamerasSortedByStoreIds } from '@Store/Camera/action';
import { CameraRuleIntType } from '@Enums/CameraRule';
import SearchableSelect from '@Components/SearchableSelect';
import './style.scss';
import { UserBase, UserId } from '@Types/User';
import UsersService from '@Services/Api/Users';
import { Camera } from '@Types/Camera';

export enum Level {
  STORE = 0,
  CAMERA = 1,
}

type Props = {
  onFinish: (newCameraRule: NewCameraRule) => void;
};

export default function NotificationRuleForm({ onFinish }: Props) {
  const [selectedBrand, setSelectedBrand] = useState<Brand[]>([]);
  const [checkedValues, setCheckedValues] = useState<string[]>([]);
  const [treeData, setTreeData] = useState<TreeDataNode[]>([]);
  const [allUsers, setAllUsers] = useState<UserBase[]>([]);
  const [allSelectOption, setAllSelectOption] = useState<Boolean>(true);

  const brands = useAppSelector(s => s.Brand.allAvailableBrands);
  const stores = useAppSelector(s => s.Store.allStores);
  const cameras = useAppSelector(s => s.Camera.camerasSortedByStoreId);

  useEffect(() => {
    dispatch(getAllAvailableBrands());
  }, []);

  // Get all users of the selected stores on checkedValues update
  useEffect(() => {
    (async () => {
      const queryParams = calculateQueryParams();
      if (queryParams.includes('storeIds')) {
        const users = await new UsersService().GetAllUsersOfStores(queryParams);
        setAllUsers(users);
      }
    })();

    const allStores = treeData.map(store => String(store.key));
    if (allStores.length > checkedValues.length) {
      setAllSelectOption(true);
    } else if (
      allStores.length === checkedValues.length &&
      checkedValues.length > 0
    ) {
      setAllSelectOption(false);
    }
    dispatch(getAllAvailableBrands());
  }, [checkedValues]);

  // Get stores of the selected brand on brand change
  useEffect(() => {
    if (selectedBrand)
      dispatch(getAllStoresOfBrands(selectedBrand.map(b => b.Id)));
    setAllUsers([]);
  }, [selectedBrand]);

  // Get cameras of the stores on stores change
  useEffect(() => {
    if (stores.status === 'fulfilled') {
      const storeIds = stores.data.filter(s => !s.Archived).map(s => s.Id);
      dispatch(getAllCamerasSortedByStoreIds(storeIds));
    }
  }, [stores.status]);

  // updates checkedValues when brand is removed
  useEffect(() => {
    const AvailableStoreIds = treeData.map(store => String(store.key));
    const checkedStores = checkedValues.filter(id => id.split('-')[0] === '0');
    const filteredCheckedStores = checkedStores.filter(id =>
      AvailableStoreIds.includes(id)
    );
    const result = checkedValues.filter(
      id =>
        id.split('-')[0] === String(Level.CAMERA) ||
        (id.split('-')[0] === String(Level.STORE) &&
          AvailableStoreIds.includes(id))
    );
    if (filteredCheckedStores.length < checkedStores.length) {
      setCheckedValues(result);
    }
  }, [treeData, checkedValues]);

  // Calculate tree data values when cameras are fetched
  useEffect(() => {
    if (cameras.status === 'fulfilled') {
      const newTreeData = calculateTreeData();
      setTreeData(() => [...newTreeData]);
    }
  }, [cameras.status]);

  /**
   * @description Finds out which stores are selected and converts them into a query string
   * @example '?storeIds=1,2,3'
   */
  const calculateQueryParams = () => {
    let queryParams = '';
    const selectedStoreIds: string[] = [];

    // Find out selected stores
    for (const checkedValue of checkedValues) {
      const level = parseInt(checkedValue.split('-')[0]) as Level;
      const id = checkedValue.split('-')[1];

      // Store
      if (level === Level.STORE && !selectedStoreIds.includes(id)) {
        selectedStoreIds.push(id);
        continue;
      }

      // Camera
      for (const [storeId, values] of Object.entries(cameras.data)) {
        const camerasOfStore = values as Camera[];
        const currCamera = camerasOfStore.find(c => c.Id.toString() === id);
        if (currCamera && !selectedStoreIds.includes(storeId.toString())) {
          selectedStoreIds.push(storeId);
          continue;
        }
      }
    }

    if (selectedStoreIds.length > 0) {
      queryParams += '?storeIds=';
    }

    // Add storeIds to queryParam
    for (const storeId of selectedStoreIds) {
      queryParams += `${storeId},`;
    }

    // Remove the last char (last "," is extra) and return it
    return queryParams.slice(0, -1);
  };

  // Values of stores starts with '0-' which indicates they are at the root level.
  // Values of cameras starts with '1-' which indicates they are at the second level.
  const calculateTreeData = (): TreeDataNode[] => {
    const newTreeData = [];

    for (const store of stores.data.filter(s => !s.Archived)) {
      if (cameras.data[store.Id].length === 0) continue;

      newTreeData.push({
        title: store.Name,
        value: '0-' + store.Id,
        key: '0-' + store.Id,
        children: cameras.data[store.Id]
          .filter(c => !c.Archived)
          .map(c => ({
            title: c.Name + ` (${c.Id})`,
            value: '1-' + c.Id,
            key: '1-' + c.Id,
          }))
          .sort((a, b) => (a.title >= b.title ? 1 : -1)),
      });
    }

    return newTreeData.sort((a, b) => (a.title >= b.title ? 1 : -1));
  };

  const formatValuesForAPI = ({
    Number,
    Type,
    UserIds,
  }: {
    Number: number;
    Type: CameraRuleIntType;
    UserIds: UserId[];
  }): NewCameraRule => {
    const StoreIds = [];
    const CameraIds: number[] = [];
    for (const value of checkedValues) {
      const Id = parseInt(value.split('-')[1]);
      const type = value.split('-')[0] === '0' ? 'store' : 'camera';
      type === 'store' ? StoreIds.push(Id) : CameraIds.push(Id);
      const cameras = treeData.filter(v => v.key === value)[0]?.children;
      cameras?.map(c => CameraIds.push(parseInt(String(c.key).split('-')[1])));
    }
    let apiData: NewCameraRule = {
      StoreIds,
      CameraIds,
      Number: type === CameraRuleIntType.NoDataReceived ? 60 : Number,
      Type,
      UserIds,
    };
    return apiData;
  };

  const handleCheckboxChange = (checkedValues: string[]) => {
    setCheckedValues(checkedValues);
  };

  const handleStoreSelect = (selected: string) => {
    if (parseInt(selected) === -1) {
      handleCheckboxChange(treeData.map(store => String(store.key)));
      setAllSelectOption(prevState => !prevState);
    } else if (parseInt(selected) === -2) {
      setAllSelectOption(prevState => !prevState);
      handleCheckboxChange([]);
    }
  };

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const type = Form.useWatch('Type', form);
  const number = Form.useWatch('Number', form);

  const handleBrandChange = (brandIds: number[]) => {
    setSelectedBrand(brands.data.filter(b => brandIds.includes(b.Id)));
    setAllSelectOption(true);
  };

  const inputRule = [{ required: true, message: t('cannotBeEmpty') }];
  return (
    <>
      <Form
        form={form}
        className="notification-rules-form"
        name="notification-rule-form"
        onFinish={v => onFinish(formatValuesForAPI(v))}
        initialValues={{
          Number: '1',
          Type: 0,
        }}
      >
        <Row className="camera-record-form-row">
          <Col span={8}>{t('brand')}:</Col>
          <Col span={16}>
            <SearchableSelect
              placeholder={t('selectBrand')}
              className="form-select-item"
              onChange={handleBrandChange}
              allowClear
              showSearch
              mode="multiple"
              maxTagCount={3}
              loading={
                stores.status === 'Pending' || cameras.status === 'Pending'
              }
            >
              {brands?.data?.map(brand => (
                <Select.Option
                  key={brand.Id}
                  value={brand.Id}
                  label={brand.Name}
                >
                  {brand.Name}
                </Select.Option>
              ))}
            </SearchableSelect>
          </Col>
        </Row>

        <Row className="camera-record-form-row">
          <Col span={8}>{t('camerasAndStores')}:</Col>
          <Col span={16}>
            {treeData.length > 0 ? (
              <TreeSelect
                placeholder={t('camerasAndStores')}
                className="form-select-item"
                treeData={[
                  {
                    title: allSelectOption ? t('selectAll') : t('unselectAll'),
                    value: allSelectOption ? '-1' : '-2',
                  },
                  ...treeData,
                ]}
                value={checkedValues}
                onSelect={handleStoreSelect}
                onChange={handleCheckboxChange}
                allowClear
                treeCheckable={true}
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                showSearch={false}
                maxTagCount={2}
              />
            ) : (
              <Typography.Text className="float-right">
                {t('noData')}
              </Typography.Text>
            )}
          </Col>
        </Row>
        <Divider />

        <Form.Item name="Type" label={t('Type')} rules={inputRule}>
          <Radio.Group className="float-right">
            <Radio value={CameraRuleIntType.Closed}>{t('closedMode')}</Radio>
            <Radio value={CameraRuleIntType.NoDataReceived}>
              {t('noDataMode')}
            </Radio>
          </Radio.Group>
        </Form.Item>
        {type === CameraRuleIntType.Closed && (
          <Form.Item
            name="Number"
            rules={inputRule}
            label={
              type === CameraRuleIntType.Closed ? t('number') : t('minute')
            }
          >
            <InputNumber className="float-right" min={1} />
          </Form.Item>
        )}
        <Form.Item name="UserIds" label={t('users')}>
          <SearchableSelect
            showSearch
            mode="multiple"
            className="form-select-item"
            allowClear
            loading={
              stores.status === 'Pending' || cameras.status === 'Pending'
            }
          >
            {allUsers.map(user => (
              <Select.Option
                key={user.Id}
                value={user.Id}
                label={user.Name}
                name={user.Email}
              >
                {user.Email}
              </Select.Option>
            ))}
          </SearchableSelect>
        </Form.Item>
        <Divider />

        <Typography.Title level={4}>
          {t(
            `notificationRuleResult.${
              type === CameraRuleIntType.Closed ? 'closedMode' : 'noDataMode'
            }`,
            { number: type === CameraRuleIntType.NoDataReceived ? 60 : number }
          )}
        </Typography.Title>
      </Form>
      <Divider />

      <ModalFooter
        formKey="notification-rule-form"
        sendButtonDisabled={checkedValues.length === 0 || !selectedBrand}
      />
    </>
  );
}
