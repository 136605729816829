import { BeaconRuleEnum } from '@Features/beaconRules/enums';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

type BeaconRuleFormDescriptionProps = {
  type: BeaconRuleEnum;
  amount: number;
};

export function BeaconRuleFormDescription({
  type,
  amount,
}: BeaconRuleFormDescriptionProps) {
  const { t } = useTranslation();

  const labels = {
    [BeaconRuleEnum.Closed]: t('beaconRules.minuteNotif', { amount }),
    [BeaconRuleEnum.LowBatteryLevel]: t('beaconRules.batteryLevelNotif', {
      amount,
    }),
    [BeaconRuleEnum.SignalFixedAreaLongTime]: t(
      'beaconRules.constantSignalNotif',
      { amount }
    ),
  };

  return <Typography.Title level={4}>{labels[type]}</Typography.Title>;
}
