import Status from '@Enums/Status';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import FilledText from '@Components/FilledText';
import HierarchyItems from '@Components/Card/HierarchyItems';
import useWindowSize from '@Hooks/useWindowSize';
import InfoItem from '@Components/InfoItem';
import { Space, Typography } from 'antd';
import { IBeaconRule } from '@Types/BeaconRule';
import { asyncFetchInterface } from '@Types/ReduxTypes';
import { PaginationData } from '@Types/index';
import { BeaconRuleActionButtons } from '@Features/beaconRules/components';

interface Params {
  beaconRules: asyncFetchInterface<PaginationData<IBeaconRule>>;
  getBeaconRules: Function;
}

export function useBeaconRulesColumns({
  beaconRules,
  getBeaconRules,
}: Params): ColumnsType<IBeaconRule> {
  const windowSize = useWindowSize();
  const { t } = useTranslation();

  const beaconRulesTableColumns: ColumnsType<IBeaconRule> = useMemo(() => {
    if (beaconRules.status !== Status.success) return [];

    const columns: ColumnsType<IBeaconRule> = [
      {
        title: 'Id',
        dataIndex: 'Id',
      },
      {
        title: t('entityIds'),
        dataIndex: 'EntityIds',
        render: (_: any, beaconRule) => (
          <HierarchyItems
            brandId={beaconRule.BrandId}
            storeId={beaconRule.StoreId}
            brand={beaconRule.BrandName}
            store={beaconRule.StoreName}
          />
        ),
        width: windowSize?.width && windowSize.width > 1360 ? 300 : 200,
      },
      {
        title: t('Type'),
        dataIndex: 'Type',
        render: (_, rule) => (
          <FilledText text={t(`beaconRules.${rule.Type}`) as string} />
        ),
      },
      {
        title: t('value'),
        dataIndex: 'Value',
        render: (_, rule) => <FilledText text={rule.Value} />,
      },
      {
        title: t('beacon'),
        dataIndex: 'Beacon',
        render: (_, rule) =>
          rule.BeaconId ? `${rule.BeaconId} / ${rule.BeaconName}` : '-',
      },
      {
        title: t('receiver'),
        dataIndex: 'Receiver',
        render: (_, rule) =>
          rule.ReceiverId ? `${rule.ReceiverId} / ${rule.ReceiverName}` : '-',
      },

      {
        title: t('users'),
        dataIndex: 'Users',
        render: (users: IBeaconRule['Users']) => (
          <InfoItem
            content={
              <Space direction="vertical">
                {users.map(user => (
                  <Typography.Text key={user.Id} style={{ color: 'white' }}>
                    {user.Username}
                  </Typography.Text>
                ))}
              </Space>
            }
          />
        ),
      },
      {
        title: t('actionButtons'),
        dataIndex: 'actionButtons',
        render: (_: any, beaconRule) => (
          <BeaconRuleActionButtons
            beaconRuleId={beaconRule.Id}
            getBeaconRules={getBeaconRules}
          />
        ),
      },
    ];
    return columns;
  }, [beaconRules.status]);

  return beaconRulesTableColumns;
}
