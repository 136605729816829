import {
  Checkbox,
  Col,
  Divider,
  Form,
  Row,
  Select,
  TreeDataNode,
  TreeSelect,
} from 'antd';
import { useTranslation } from 'react-i18next';
import ModalFooter from '@Components/ModalFooter';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { useEffect, useState } from 'react';
import { Brand } from '@Types/Brand';
import { getAllAvailableBrands } from '@Store/Brand/action';
import { getAllStoresOfBrands } from '@Store/Store/action';
import { getAllCamerasOfBrands } from '@Store/Camera/action';
import Text from 'antd/lib/typography/Text';
import './style.scss';
import { NewCameraRecordMultipleDates } from '@Types/CameraRecords';
import { useParams } from 'react-router-dom';
import JsonSchemaForm from '@Components/JsonSchema/Form';
import { IChangeEvent } from '@rjsf/core';
import { convertConfigTypeToEnum } from '@Utils/Config';
import MultipleDateRangeSelector from '@Components/MultipleDateRangeSelector/MultipleStartDateSelector';
import { IDateRange } from '@Components/MultipleDateRangeSelector/types';
import SearchableSelect from '@Components/SearchableSelect';
import { DefaultOptionType } from 'antd/lib/select';
import Status from '@Enums/Status';
import { Camera } from '@Types/Camera';
import { RJSFSchema } from '@rjsf/utils';
const { Option } = Select;

type Props = {
  onFinish: (values: any) => void;
};

export interface INewCameraRecordForm {
  DoNotProcess?: boolean;
  ParametersJson?: any;
  DateRanges: IDateRange[];
}

export default function NewCameraRecordForm({ onFinish }: Props) {
  const [treeData, setTreeData] = useState<TreeDataNode[]>([]);
  const [checkedValues, setCheckedValues] = useState<string[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<Brand>();
  const [selectedConfigId, setSelectedConfigId] = useState<number>();

  const brands = useAppSelector(s => s.Brand.allAvailableBrands);
  const stores = useAppSelector(s => s.Store.allStores);
  const allCameras = useAppSelector(s => s.Camera.allCameras);
  const configSchemas = useAppSelector(s => s.Config.configSchemas.data);

  const [form] = useForm<INewCameraRecordForm>();
  const parametersJson = Form.useWatch('ParametersJson', form);

  const { params } = useParams(); // params = "brandId=123&cameraId=123"
  useEffect(() => {
    dispatch(getAllAvailableBrands());
  }, []);

  // Get stores of the selected brand on brand change
  useEffect(() => {
    if (selectedBrand) {
      dispatch(getAllStoresOfBrands([selectedBrand.Id]));
      dispatch(getAllCamerasOfBrands([selectedBrand.Id]));

      const brandId = parseInt(params?.split('&')[0].split('=')[1] || '0');

      if (!params || brandId !== selectedBrand.Id) setCheckedValues([]);
    }
  }, [selectedBrand?.Id]);

  // This effect will run only when this page is initiated FROM cameras page.
  useEffect(() => {
    if (brands.status === 'fulfilled' && params) {
      const brandId = parseInt(params?.split('&')[0].split('=')[1]);
      setSelectedBrand(brands.data.find(b => b.Id === brandId));

      const cameraId = params?.split('&')[1].split('=')[1];
      setCheckedValues(() => ['1-' + cameraId]);
    }
  }, [brands.status]);

  useEffect(() => {
    if (
      allCameras.status === Status.success &&
      stores.status === Status.success
    ) {
      const newTreeData = calculateTreeData();
      setTreeData(() => [...newTreeData]);
    }
  }, [allCameras.status, stores.status]);

  useEffect(() => {
    form.setFieldsValue({ ParametersJson: '{}' });
  }, [selectedConfigId]);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // Values of stores starts with '0-' which indicates they are at the root level.
  // Values of cameras starts with '1-' which indicates they are at the second level.
  const calculateTreeData = (): TreeDataNode[] => {
    const newTreeData = [];

    if (allCameras.status !== Status.success) {
      return [];
    }

    const cameras = allCameras.data.reduce<Record<number, Camera[]>>(
      (acc, item) => {
        acc[item.StoreId] = [...(acc[item.StoreId] ?? []), item];
        return acc;
      },
      {}
    );

    for (const store of stores.data.filter(s => !s.Archived)) {
      if (!cameras[store.Id] || cameras[store.Id].length === 0) continue;

      newTreeData.push({
        title: store.Name,
        value: '0-' + store.Id,
        key: '0-' + store.Id,
        children: cameras[store.Id]
          .map(c => ({
            title: c.Name + ` (${c.Id})`,
            value: '1-' + c.Id,
            key: '1-' + c.Id,
          }))
          .sort((a, b) => (a.title >= b.title ? 1 : -1)),
      });
    }

    return newTreeData.sort((a, b) => (a.title >= b.title ? 1 : -1));
  };

  const handleCheckboxChange = (checkedValues: string[]) => {
    setCheckedValues(checkedValues);
  };

  const handleBrandChange = (brandId: number) => {
    setSelectedBrand(brands.data.find(b => b.Id === brandId));
  };

  const formatValuesForAPI = (
    formValues: INewCameraRecordForm
  ): NewCameraRecordMultipleDates => {
    const StoreIds = [];
    const CameraIds = [];
    for (const value of checkedValues) {
      const Id = parseInt(value.split('-')[1]);
      const type = value.split('-')[0] === '0' ? 'store' : 'camera';
      type === 'store' ? StoreIds.push(Id) : CameraIds.push(Id);
    }

    const cameraRecord: NewCameraRecordMultipleDates = {
      StoreIds,
      CameraIds,
      DoNotProcess: formValues.DoNotProcess || false,
      Dates: formValues.DateRanges.map(dateRange => ({
        StartDate: dateRange.startDate.format('YYYY-MM-DD HH:mm'),
        EndDate: dateRange.endDate.format('YYYY-MM-DD HH:mm'),
      })),
    };

    return cameraRecord;
  };

  const formOnChange = (e: IChangeEvent<RJSFSchema>) => {
    form.setFieldsValue({ ParametersJson: e.formData as RJSFSchema });
  };

  const selectedConfigHandler = (
    _: any,
    option: DefaultOptionType | DefaultOptionType[]
  ) => {
    const castedOption = option as DefaultOptionType;
    setSelectedConfigId(castedOption?.value as number);
  };

  const dateRanges = Form.useWatch('DateRanges', form) || [];

  const handleDateRangesChange = (newDateRanges: IDateRange[]) => {
    form.setFieldsValue({ DateRanges: newDateRanges });
  };

  return (
    <>
      <Form
        form={form}
        className="camera-record-form"
        name="camera-record-form"
        onFinish={v => onFinish(formatValuesForAPI(v))}
      >
        <Row className="camera-record-form-row">
          <Col span={8}>{t('brand')}:</Col>
          <Col span={16}>
            <SearchableSelect
              placeholder={t('selectBrand')}
              className="form-select-item"
              onChange={handleBrandChange}
              defaultValue={
                params
                  ? parseInt(params?.split('&')[0].split('=')[1])
                  : undefined
              }
              allowClear
              loading={
                stores.status === Status.pending ||
                allCameras.status === Status.pending
              }
            >
              {brands.data.map(brand => (
                <Select.Option
                  key={brand.Id}
                  value={brand.Id}
                  label={brand.Name}
                >
                  {brand.Name}
                </Select.Option>
              ))}
            </SearchableSelect>
          </Col>
        </Row>
        <Divider />

        <Row className="camera-record-form-row">
          <Col span={8}>{t('camerasAndStores')}:</Col>
          <Col span={16}>
            {treeData.length > 0 ? (
              <TreeSelect
                placeholder={t('camerasAndStores')}
                className="form-select-item"
                treeData={treeData}
                value={checkedValues}
                onChange={handleCheckboxChange}
                treeCheckable={true}
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                showSearch={false}
              />
            ) : (
              <Text className="float-right">{t('noData')}</Text>
            )}
          </Col>
        </Row>
        <Divider />

        <Form.Item name="DateRanges" label={t('startDate')} initialValue={[]}>
          <MultipleDateRangeSelector
            onChange={handleDateRangesChange}
            dateRanges={dateRanges}
          />
        </Form.Item>
        <Divider />

        <Form.Item name="DoNotProcess" label={t('willNotBeProcessed')}>
          <Checkbox className="float-right" />
        </Form.Item>
      </Form>

      <Divider />
      <Form.Item label={t('config')} name="ConfigSchemaId">
        <SearchableSelect allowClear onChange={selectedConfigHandler}>
          {configSchemas
            .filter(
              c =>
                c.ConfigSchemaType === convertConfigTypeToEnum('camera-record')
            )
            .map(c => (
              <Option value={c.Id} key={c.Id}>
                {c.Name}
              </Option>
            ))}
        </SearchableSelect>
      </Form.Item>

      <Form.Item
        labelCol={{ offset: 2 }}
        label={t('schema')}
        name="ParametersJson"
        rules={[
          {
            validator: async (_, value: IChangeEvent<RJSFSchema> | null) => {
              if (!selectedConfigId) return Promise.resolve();

              if (value?.errors.length === 0) return Promise.resolve();

              return Promise.reject(t('notValidConfig'));
            },
          },
        ]}
      >
        {selectedConfigId ? (
          <JsonSchemaForm
            schema={JSON.parse(
              configSchemas.find(c => c.Id === selectedConfigId)?.Schema!
            )}
            onChange={formOnChange}
            formData={parametersJson}
            omitExtraData
            liveOmit
            liveValidate
          />
        ) : (
          '-'
        )}
      </Form.Item>
      <Divider />

      <ModalFooter
        formKey="camera-record-form"
        sendButtonDisabled={
          checkedValues.length === 0 ||
          !selectedBrand?.Id ||
          !(dateRanges.length > 0)
        }
      />
    </>
  );
}
