import { ShopOutlined } from '@ant-design/icons';
import FormCard from '@Components/FormCard';
import StoreDetail from '@Forms/StoreDetail';
import { EditedStore, NewStore } from '@Types/Store';
import StoresService from '@Services/Api/Stores';
import { useNavigate, useParams } from 'react-router-dom';
import { apiCall } from '@Utils';
import { NewBoard } from '@Types/Board';

const AddStore = () => {
  const { brandId } = useParams();
  let navigate = useNavigate();

  const handleSubmit = async (formValues: NewStore | EditedStore) => {
    const {
      BoardCount,
      BoardCodeVersionId,

      CodeVersionId,
      BoardBrandModelId,
      Count,
      CardName,
      CreateCamera,
      IsHasWifiDongle,
      IsHasBluetoothDongle,
      IsHasPoeSplitter,
      IsHasRaySpot,
      IsHasHelperBoard,
      CameraType,
      RTSPUrl,
      AdapterBrand,
      Color,
      RaySpotType,
      ...storeValues
    } = formValues;

    const store = { ...storeValues, BrandId: parseInt(brandId!) };

    try {
      const storeID = await apiCall(() => addStore(store), 'newStore');

      if (
        CardName &&
        CodeVersionId &&
        BoardBrandModelId &&
        typeof Color !== 'undefined'
      ) {
        const newBoard: NewBoard = {
          CodeVersionId,
          BoardBrandModelId,
          Count,
          CardName,
          CreateCamera: !!CreateCamera,
          IsHasWifiDongle,
          IsHasBluetoothDongle,
          IsHasPoeSplitter,
          IsHasRaySpot,
          IsHasHelperBoard,
          CameraType,
          RTSPUrl,
          AdapterBrand,
          Color,
          RaySpotType,
        };
        await apiCall(() => addBoard(newBoard, storeID));
      }
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const addStore = async (newStore: NewStore) => {
    return await new StoresService().AddNewStore(newStore);
  };

  const addBoard = async (board: NewBoard, storeID: number) => {
    return await new StoresService().AddNewBoard(board, storeID);
  };

  return (
    <FormCard
      titleIcon={<ShopOutlined />}
      titleKey="addStore"
      className="store-detail-wrapper"
    >
      <StoreDetail onFinish={handleSubmit} />
    </FormCard>
  );
};

export default AddStore;
