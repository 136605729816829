export const GET_CAMERA_COORDINATES = 'GET_CAMERA_COORDINATES';
export const GET_MULTIPLE_CAMERA_COORDINATES = 'GET_CAMERA_COORDINATES';

export const GET_CAMERA_LINE_COORDINATES = 'GET_CAMERA_LINE_COORDINATES';
export const GET_MULTIPLE_CAMERA_LINE_COORDINATES =
  'GET_MULTIPLE_CAMERA_LINE_COORDINATES';

export const GET_STORES_LINE_COORDINATES = 'GET_STORES_LINE_COORDINATES';
export const GET_MULTIPLE_STORES_LINE_COORDINATES =
  'GET_MULTIPLE_STORES_LINE_COORDINATES';

export const GET_STORES_CAMERA_COORDINATES = 'GET_STORES_CAMERA_COORDINATES';
export const GET_MULTIPLE_STORES_CAMERA_COORDINATES =
  'GET_MULTIPLE_STORES_CAMERA_COORDINATES';

export const RESET_STORE_CAMERA_COORDINATES = 'RESET_STORE_CAMERA_COORDINATES';
export const RESET_STORE_LINE_COORDINATES = 'RESET_STORE_LINE_COORDINATES';
