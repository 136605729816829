import { BrandGroupForm } from '@Features/brand-groups/forms';
import { UseBooleanResult } from '@Hooks/useBoolean';
import BrandGroupService from '@Services/Api/BrandGroupService';
import { IBrandGroup, IBrandGroupForm } from '@Types/BrandGroup';
import { apiCall } from '@Utils/index';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
  modalState: UseBooleanResult;
  brandGroup: IBrandGroup;
  onRefetch: () => void;
};

export function UpdateBrandGroupModal(props: Props) {
  const { modalState, brandGroup, onRefetch } = props;

  const { t } = useTranslation();

  async function submitHandler(values: IBrandGroupForm) {
    try {
      const req = () =>
        new BrandGroupService().UpdateBrandGroup(brandGroup.Id, {
          Id: brandGroup.Id,
          ...values,
        });
      await apiCall(req, undefined, () => {
        modalState.setFalse();
      });
      onRefetch();
    } catch (e) {
      console.warn(e);
    }
  }

  return (
    <Modal
      title={t('editGroup')}
      open={modalState.value}
      onOk={modalState.setFalse}
      okButtonProps={{ hidden: true }}
      onCancel={modalState.setFalse}
      destroyOnClose
    >
      <BrandGroupForm onFinish={submitHandler} initialValues={brandGroup} />
    </Modal>
  );
}
