import './style.scss';
type Props = {
  text: string;
  textWidth?: string;
};
const TextFormatter = ({ text, textWidth }: Props) => {
  return (
    <h4 className="title" style={{ maxWidth: `${textWidth}` }}>
      {text}
    </h4>
  );
};
export default TextFormatter;
