import {
  BellOutlined,
  CameraOutlined,
  FolderOpenOutlined,
  InboxOutlined,
  NodeIndexOutlined,
  WifiOutlined,
} from '@ant-design/icons';
import SSHTunnelModal from '@Cards/BoardCard/SSHTunnelModal';
import DeleteButton from '@Components/Card/DeleteButton';
import EditButton from '@Components/Card/EditButton';
import CardButton from '@Components/CardButton';
import { Popconfirm } from '@Components/Popconfirm';
import { Permissions } from '@Enums/Permission';
import {} from '@Features/boards/modals';
import PermissionCheck from '@HOC/PermissionCheck';
import useBoolean from '@Hooks/useBoolean';
import BoardsService from '@Services/Api/Boards';
import { BoardId, FetchedBoard } from '@Types/Board';
import { apiCall } from '@Utils/index';
import { Space } from 'antd';
import './style.scss';

interface Props {
  board: FetchedBoard;
  isArchived: boolean;
  getBoards: () => void;
  hiddenButtons?: BoardActionButtonEnums[];
}

export enum BoardActionButtonEnums {
  ALL,
  CAMERAS,
  OPEN_SSH,
  SEND_PING,
  ARCHIVE,
  WEB_CONFIG,
  EDIT,
  DELETE,
}

export default function BoardActionButtons(props: Props) {
  const { board, isArchived, getBoards, hiddenButtons } = props;

  const sshTunnelModalState = useBoolean();

  const deleteBoard = async (boardId: BoardId) => {
    try {
      await new BoardsService().DeleteBoard(boardId);
      getBoards();
    } catch (e) {
      console.warn(e);
    }
  };

  const sendPing = async (boardId: BoardId) => {
    try {
      const req = () => new BoardsService().Ping(boardId);
      await apiCall(req, 'pingBoard');
    } catch (e) {
      console.warn(e);
    }
  };

  const toggleArchiveType = async (boardId: BoardId) => {
    try {
      if (isArchived) {
        await new BoardsService().UnarchiveBoard(boardId);
      } else {
        await new BoardsService().ArchiveBoard(boardId);
      }
      getBoards();
    } catch (e) {
      console.warn(e);
    }
  };

  if (hiddenButtons?.includes(BoardActionButtonEnums.ALL)) {
    return <></>;
  }

  return (
    <>
      <Space direction="horizontal" size="small" wrap>
        {!hiddenButtons?.includes(BoardActionButtonEnums.CAMERAS) && (
          <CardButton
            icon={<CameraOutlined />}
            tooltipKey="cameras"
            to={`/brands/${board.BrandId}/stores/${board.StoreId}/boards/${board.Id}/cameras`}
            locationState={{ showArchived: isArchived }}
          />
        )}

        {!hiddenButtons?.includes(BoardActionButtonEnums.OPEN_SSH) && (
          <PermissionCheck permission={Permissions.ADMIN_CAMERA_OPEN_SSH}>
            <CardButton
              icon={<NodeIndexOutlined />}
              tooltipKey="openSSHTunnel"
              onClick={sshTunnelModalState.setTrue}
            />
          </PermissionCheck>
        )}

        {!hiddenButtons?.includes(BoardActionButtonEnums.SEND_PING) && (
          <PermissionCheck permission={Permissions.ADMIN_CAMERA_SEND_PING}>
            <CardButton
              icon={<BellOutlined />}
              tooltipKey="pingBoard"
              onClick={() => sendPing(board.Id)}
            />
          </PermissionCheck>
        )}

        {!hiddenButtons?.includes(BoardActionButtonEnums.ARCHIVE) && (
          <Popconfirm onConfirm={() => toggleArchiveType(board.Id)}>
            <CardButton
              icon={isArchived ? <InboxOutlined /> : <FolderOpenOutlined />}
              tooltipKey={isArchived ? 'unarchive' : 'archive'}
            />
          </Popconfirm>
        )}

        {!hiddenButtons?.includes(BoardActionButtonEnums.WEB_CONFIG) && (
          <CardButton
            icon={<WifiOutlined />}
            tooltipKey="Ağ Konfigürasyonu"
            to={`/brands/${board.BrandId}/stores/${board.StoreId}/boards/${board.Id}/configiration`}
          />
        )}

        {!hiddenButtons?.includes(BoardActionButtonEnums.EDIT) && (
          <EditButton
            to={`/brands/${board.BrandId}/stores/${board.StoreId}/boards/${board.Id}`}
          />
        )}

        {!hiddenButtons?.includes(BoardActionButtonEnums.DELETE) && (
          <DeleteButton onConfirm={() => deleteBoard(board.Id)} />
        )}
      </Space>
      <SSHTunnelModal
        show={sshTunnelModalState.value}
        boardId={board.Id}
        onClose={sshTunnelModalState.setFalse}
      />
    </>
  );
}
