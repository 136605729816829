import { sizeAttributes } from '../../Types/Svg';

const WrapperTasks = {
  onResize(Target: HTMLImageElement): sizeAttributes {
    const naturalWidth = Target.naturalWidth ?? 0;
    const naturalHeight = Target.naturalHeight ?? 0;
    const clientWidth = Target.clientWidth ?? 0;
    const scale = clientWidth / naturalWidth;
    return { height: naturalHeight, width: naturalWidth, scale: scale };
  },
};

export default WrapperTasks;
