import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_CODE_VERSIONS } from './type';
import AdminService from '@Services/Api/Admin';

export const getCodeVersions = createAsyncThunk(GET_CODE_VERSIONS, async () => {
  try {
    const codeVers = await new AdminService().GetCodeVersions();
    return codeVers;
  } catch (err) {
    console.error(err);
    return [];
  }
});
