import Status from '@Enums/Status';
import { useAppSelector } from '@Store/hooks';
import { IBeaconRulesForm, IStoreReceiver } from '@Types/BeaconRule';
import { Form, FormInstance, TreeDataNode, TreeSelect } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type BeaconRuleReceiverSelectorProps = {
  disabled?: boolean;
  form: FormInstance<IBeaconRulesForm>;
};

enum SelectKind {
  SELECT_ALL = '-1',
  UNSELECT_ALL = '-2',
}

export function BeaconRuleReceiverSelector({
  disabled,
  form,
}: BeaconRuleReceiverSelectorProps) {
  const [checkedValues, setCheckedValues] = useState<string[]>([]);

  const { t } = useTranslation();

  const receivers = useAppSelector(s => s.Receiver.brandReceivers);

  const handleCheckboxChange = (values: string[]) => {
    setCheckedValues(values);
    form.resetFields(['UserIds'] as (keyof IBeaconRulesForm)[]);
    form.setFieldsValue({ ReceiverIds: findReceiverIds(values) });
  };

  const handleSelect = (value: string) => {
    if (value === SelectKind.SELECT_ALL) {
      handleCheckboxChange(treeData.map(item => item.key as string));
    }

    if (value === SelectKind.UNSELECT_ALL) {
      handleCheckboxChange([]);
    }
  };

  const calculateTreeData = (): TreeDataNode[] => {
    const newTreeData = [];

    const storeReceivers: IStoreReceiver[] = JSON.parse(
      JSON.stringify(receivers.data)
    );

    const sortedStoreReceivers = storeReceivers.sort((a, b) =>
      a.Name.localeCompare(b.Name)
    );

    for (const storeReceiver of sortedStoreReceivers) {
      newTreeData.push({
        title: storeReceiver.Name,
        value: '0-' + storeReceiver.Id,
        key: '0-' + storeReceiver.Id,
        children: storeReceiver.Receivers.map(r => ({
          title: r.Name + ` (${r.Id})`,
          value: '1-' + r.Id,
          key: '1-' + r.Id,
        })).sort((a, b) => (a.title >= b.title ? 1 : -1)),
      });
    }

    return newTreeData.sort((a, b) => (a.title >= b.title ? 1 : -1));
  };

  function findReceiverIds(values: string[]) {
    if (receivers.status !== Status.success) {
      return [];
    }

    return values.reduce((acc, value) => {
      try {
        const [entityKind, id] = value.split('-');

        const entity = entityKind === '0' ? 'store' : 'receiver';

        switch (entity) {
          case 'store':
            const receiverStore = receivers.data.find(b => b.Id === Number(id));
            if (receiverStore) {
              acc.push(...receiverStore.Receivers.map(b => b.Id));
            }
            return acc;

          case 'receiver':
            acc.push(Number(id));
            return acc;

          default:
            return acc;
        }
      } catch {
        return acc;
      }
    }, [] as number[]);
  }

  const treeData = useMemo(() => {
    if (receivers.status !== Status.success) {
      return [];
    }

    return calculateTreeData();
  }, [receivers.status]);

  const allSelected = receivers.data?.every(storeReceiver =>
    checkedValues.includes('0-' + storeReceiver.Id)
  );

  return (
    <>
      <Form.Item name="ReceiverIds" noStyle />
      <Form.Item label={t('receivers')}>
        <TreeSelect
          treeData={[
            {
              title: allSelected ? t('unselectAll') : t('selectAll'),
              value: allSelected
                ? SelectKind.UNSELECT_ALL
                : SelectKind.SELECT_ALL,
            },
            ...treeData,
          ]}
          value={checkedValues}
          onSelect={handleSelect}
          onChange={handleCheckboxChange}
          allowClear
          treeCheckable={true}
          showCheckedStrategy={TreeSelect.SHOW_PARENT}
          showSearch={false}
          maxTagCount={2}
          disabled={disabled}
          loading={receivers.status === Status.pending}
        />
      </Form.Item>
    </>
  );
}
