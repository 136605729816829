import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

function CameraRecords() {
  return (
    <Layout id="camera-records-page">
      <Outlet />
    </Layout>
  );
}

export default CameraRecords;
