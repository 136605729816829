import { Button, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import { CustomFilter } from '@Types/Components';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { removeDuplicates } from '@Utils';

const { Option } = Select;

interface Props<T> {
  filters: CustomFilter<T>[];
  onChange: (newFilters: CustomFilter<T>[]) => void;
  initialKeys: T[];
  specialKeys?: T[];
  onInputChange: (Key: T, e: ChangeEvent<HTMLInputElement>) => void;
}

const InnerCustomFiler = <T extends string>({
  filters,
  initialKeys,
  onChange,
  specialKeys,
  onInputChange,
}: Props<T>) => {
  const [availableKeys, setAvailableKeys] = useState<{
    isChanged: boolean;
    keys: T[];
  }>({
    isChanged: false,
    keys: initialKeys.filter(k => !specialKeys?.includes(k)),
  });
  const [newFilter, setNewFilter] = useState<CustomFilter<T>>({
    Key: '' as T,
    Value: '',
  });
  const { t } = useTranslation();

  useEffect(() => {
    setAvailableKeys(prevS => {
      // Remove duplicates
      const removedDuplicates = removeDuplicates(prevS.keys);

      // Remove keys those currently are in filter usage
      const filteredAvailableKeys = removedDuplicates.filter(
        k => !filters.map(f => f.Key).some(key => key === k)
      );

      return {
        ...prevS,
        keys: filteredAvailableKeys,
      };
    });
  }, [availableKeys.isChanged]);

  const addNewFilter = () => {
    setAvailableKeys(prevS => ({
      isChanged: !prevS.isChanged,
      keys: [...prevS.keys.filter(key => key !== newFilter.Key)],
    }));

    const newFilters = [...filters, newFilter];
    onChange(newFilters);

    setNewFilter({ Key: '' as T, Value: '' });
  };

  const deleteFilter = (filterKey: T) => {
    setAvailableKeys(prevS => ({
      isChanged: !prevS.isChanged,
      keys: [...prevS.keys, filterKey],
    }));

    const newFilters = filters.filter(f => f.Key !== filterKey);
    onChange(newFilters);

    setNewFilter({ Key: '' as T, Value: '' });
  };

  const handleNewFilterKeyChange = (key: T) => {
    setNewFilter(filter => ({ Key: key, Value: filter.Value }));
  };

  const handleNewFilterValueChange = (e: any) => {
    setNewFilter(filter => ({ Key: filter.Key, Value: e.target.value }));
  };

  const handleInputChange = (
    filterKey: T,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value) {
      onInputChange(filterKey, e);
      return;
    }

    deleteFilter(filterKey);
  };

  // This variable contains filters with special keys removed from it
  const filtersWithoutSpecials = filters.filter(
    f => !specialKeys?.includes(f.Key)
  );

  return (
    <div className="inner-filter-container">
      {filtersWithoutSpecials.map(filter => (
        <div className="new-condition-container" key={filter.Key}>
          <Select disabled defaultValue={filter.Key}>
            <Option value={filter.Key}>{t(filter.Key)}</Option>
          </Select>
          <Input
            defaultValue={filter.Value}
            onChange={e => handleInputChange(filter.Key, e)}
          />
          <Button onClick={() => deleteFilter(filter.Key)}>
            <DeleteOutlined />
          </Button>
        </div>
      ))}

      {filtersWithoutSpecials.length !== initialKeys.length && (
        <div className="new-condition-container">
          <Select onChange={handleNewFilterKeyChange} value={newFilter.Key}>
            {availableKeys.keys.map(key => (
              <Option key={key} value={key}>
                {t(key)}
              </Option>
            ))}
          </Select>
          <Input
            onChange={handleNewFilterValueChange}
            value={newFilter.Value}
          />
          <Button
            onClick={addNewFilter}
            disabled={!newFilter.Value || !newFilter.Key}
          >
            <PlusCircleOutlined />
          </Button>
        </div>
      )}
    </div>
  );
};

export default InnerCustomFiler;
