import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GET_ALL_RECEIVER,
  GET_BRAND_RECEIVERS,
  GET_SELECT_RECEIVER,
} from './type';
import ReceiverService from '@Services/Api/Receivers';
import { GetReceiverParams } from '@Types/Receiver';
import BeaconRulesService from '@Services/Api/BeaconRulesService';

export const getAllReceiver = createAsyncThunk(
  GET_ALL_RECEIVER,
  async (params: GetReceiverParams) => {
    try {
      const allReceiver = await new ReceiverService().GetAllReceiver(
        params || { archived: false }
      );
      return allReceiver;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
export const getReceiverById = createAsyncThunk(
  GET_SELECT_RECEIVER,
  async (receiverId: number) => {
    try {
      const receiver = await new ReceiverService().GetReceiverById(receiverId);
      return receiver;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);

export const getBrandReceivers = createAsyncThunk(
  GET_BRAND_RECEIVERS,
  async (brandId: number) => {
    try {
      const receivers = await new BeaconRulesService().GetStoreReceivers(
        brandId
      );
      return receivers;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
