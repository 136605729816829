import {
  AllReportFetchResponse,
  AutoReportReceiver,
  IReport,
  IUpdateReportForm,
  ReportCreate,
  ReportEnums,
} from '@Types/Report';
import model from './index';
import { PaginationParams } from '@Types/Filter';

class AutoReportsService extends model {
  constructor() {
    super();
    this.Prefix('AutoReports');
  }

  /**
   * @description Create a new auto report
   */
  async CreateAutoReport(autoReport: ReportCreate) {
    return this.Post('Create').Data(autoReport).offset().Save();
  }

  /**
   * @description Get all auto reports
   */
  async GetAllAutoReports(params: PaginationParams) {
    return this.Post('GetAll')
      .Data(params)
      .offset()
      .Save<AllReportFetchResponse>();
  }

  /**
   * @description Get an auto reports details by its id
   */
  async GetAutoReport(reportId: number) {
    return this.PrefixId(reportId).Get('Get').offset().Save<IReport>();
  }

  /**
   * @description Update an auto report
   */
  async UpdateAutoReport(reportId: number, updatedReport: IUpdateReportForm) {
    return this.PrefixId(reportId)
      .Post('Update')
      .Data(updatedReport)
      .offset()
      .Save();
  }

  /**
   * @description Delete an auto report
   */
  async DeleteAutoReport(reportId: number) {
    return this.PrefixId(reportId).Post('Delete').Save();
  }

  /**
   * @description Get report enums
   */
  async GetReportEnums() {
    return this.Get('GetEnums').offset().Save<ReportEnums[]>();
  }

  /**
   * @description Subscribe again unsubscribed user
   * @param ReportId
   * @param UserId
   */
  async Subscribe(
    ReportId: IReport['Id'],
    UserId: AutoReportReceiver['UserId']
  ) {
    return this.Post('Subscribe')
      .Data({
        ReportId,
        UserId,
      })
      .Save();
  }

  async ChangeReportStatus(reportId: number, isActive: boolean) {
    return this.PrefixId(reportId)
      .Post(`Status/${isActive ? 1 : 0}`)
      .Save();
  }
}

export default AutoReportsService;
