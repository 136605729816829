import ModalFooter from '@Components/ModalFooter';
import SearchableSelect from '@Components/SearchableSelect';
import { IUserForm } from '@Types/User';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Checkbox, Divider, Form, FormProps, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { useGetCamerasOfStores, useGetStoresOfBrands } from '@Hooks/api';
import {
  getAllArchivedAndUnarchivedBrands,
  getBrandsRegions,
} from '@Store/Brand/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import Status from '@Enums/Status';
import { useSelectAll } from '@Hooks/useSelectAll';
import { useEffect, useMemo, useState } from 'react';
import { getAllUserRoles } from '@Store/Role/action';

interface Props extends FormProps<IUserForm> {}

export function UserForm(props: Props) {
  const { onFinish, initialValues, ...restProps } = props;

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const [form] = Form.useForm<IUserForm>();

  const { t } = useTranslation();

  const sendMailValue = Form.useWatch('SendMail', form);
  const brandIds = Form.useWatch('BrandIds', form) ?? [];
  const regionIds = Form.useWatch('RegionIds', form) ?? [];
  const storeIds = Form.useWatch('StoreAccessIds', form) ?? [];
  const cameraIds = Form.useWatch('CameraAccessIds', form) ?? [];
  const roleId = Form.useWatch('RoleId', form) ?? [];

  const storesQuery = useGetStoresOfBrands(brandIds);
  const camerasQuery = useGetCamerasOfStores(brandIds, storesQuery);

  const user = useAppSelector(state => state.User.selectedUser.data);
  const regions = useAppSelector(state => state.Brand.allBrandsRegion);
  const rolesQuery = useAppSelector(state => state.Role.allUserRoles);
  const brandsQuery = useAppSelector(
    state => state.Brand.allArchivedAndUnarchivedBrands
  );

  const dispatch = useAppDispatch();

  const cameraOptions = useMemo(() => {
    return camerasQuery.data
      ?.filter(
        camera =>
          !camera.Archived &&
          storeIds.some(storeId => camera.StoreId === storeId)
      )
      .map(camera => ({
        label: getStoreNameFromStoreId(camera.StoreId) + ' -> ' + camera.Name,
        value: camera.Id,
      }));
  }, [camerasQuery.data, storeIds]);

  useEffect(() => {
    dispatch(getAllArchivedAndUnarchivedBrands());
    dispatch(getAllUserRoles());
  }, [dispatch]);

  useEffect(() => {
    if (
      !isInitialized &&
      user?.AllCameras &&
      cameraOptions.length > 0 &&
      camerasQuery.status === Status.success
    ) {
      form.setFieldsValue({ CameraAccessIds: cameraOptions.map(o => o.value) });
      setIsInitialized(true);
    }
  }, [cameraOptions, user?.AllCameras, camerasQuery.status]);

  const regionsSelectProps = useSelectAll({
    value: regionIds,
    onChange: RegionIds => form.setFieldsValue({ RegionIds }),
    options: regions.data.map(region => ({
      label: region.BrandName + ' -> ' + region.Name,
      value: region.Id,
    })),
  });

  const storeSelectProps = useSelectAll({
    value: storeIds,
    onChange: handleStoreChange,
    options: storesQuery.data
      ?.filter(
        store =>
          !store.Archived && brandIds.some(brandId => store.BrandId === brandId)
      )
      .filter(
        store =>
          (store.RegionId && regionIds.includes(store?.RegionId)) ||
          regionIds.length === 0
      )
      .map(store => ({
        label: store.Brand + ' -> ' + store.Name,
        value: store.Id,
      })),
  });

  const cameraSelectProps = useSelectAll({
    value: cameraIds,
    onChange: CameraAccessIds => form.setFieldsValue({ CameraAccessIds }),
    options: cameraOptions,
  });

  function getStoreNameFromStoreId(storeId: number) {
    return storesQuery.data.find(store => store.Id === storeId)?.Name ?? '';
  }

  function handleBrandsChange(newBrandIds: number[]) {
    form.setFieldsValue({ BrandIds: newBrandIds });
    form.setFieldsValue({
      RegionIds: regionIds.filter(id =>
        regions.data?.some(
          region => region.Id === id && newBrandIds.includes(region.BrandId)
        )
      ),
    });

    const newStoreIds = storeIds.filter(id =>
      storesQuery.data.some(
        store => store.Id === id && newBrandIds.includes(store.BrandId)
      )
    );

    form.setFieldsValue({ StoreAccessIds: newStoreIds });
    form.setFieldsValue({
      CameraAccessIds: cameraIds.filter(id =>
        camerasQuery.data?.some(
          camera => camera.Id === id && newStoreIds.includes(camera.StoreId)
        )
      ),
    });

    dispatch(getBrandsRegions(newBrandIds));
  }

  function handleStoreChange(newStoreIds: number[]) {
    form.setFieldsValue({ StoreAccessIds: newStoreIds });
    form.setFieldsValue({
      CameraAccessIds: cameraIds.filter(id =>
        camerasQuery.data?.some(
          camera => camera.Id === id && newStoreIds.includes(camera.StoreId)
        )
      ),
    });
  }

  function handleSubmit(values: IUserForm) {
    if (!onFinish) return;

    if (values.CameraAccessIds?.length === cameraOptions?.length) {
      onFinish({
        ...values,
        CameraAccessIds: [],
        AllCameras: true,
      });
      return;
    }

    onFinish(values);
  }

  const inputRule = [{ required: true, message: t('cannotBeEmpty') }];

  const languageOptions = [
    { label: t('turkish'), value: 'tr' },
    { label: t('english'), value: 'en' },
    { label: t('german'), value: 'de' },
  ];

  const roleOptions = rolesQuery.data?.map(role => ({
    label: role.Name,
    value: role.Id,
  }));

  const noStoreSelectedForAtLeastOneBrand = brandIds?.some(
    brandId =>
      !storeIds?.some(storeId => {
        const store = storesQuery.data?.find(store => store.Id === storeId);
        return store?.BrandId === brandId;
      })
  );

  const roleName = rolesQuery.data?.find(role => role.Id === roleId)?.Name;
  const isAdmin = roleName === 'System Admin' || roleName === 'App Admin';

  const defaultValues = initialValues
    ? {
        ...initialValues,
        BrandIds: user?.Brands?.map(b => b.Id),
        StoreAccessIds: user?.Stores?.map(s => s.Id),
        CameraAccessIds: user?.AllCameras
          ? cameraOptions?.map(c => c.value)
          : user?.Cameras?.map(c => c.Id),
        RoleId: user?.RoleId,
        RegionIds: user?.Regions?.map(c => c.Id),
      }
    : undefined;

  const showLoginFormItems = !defaultValues;

  return (
    <Form
      {...restProps}
      initialValues={defaultValues}
      onFinish={handleSubmit}
      id="user-form"
      form={form}
    >
      <Form.Item label={t('name')} name="Name" rules={inputRule}>
        <Input prefix={<UserOutlined />} />
      </Form.Item>

      <Form.Item label={t('surname')} name="Surname" rules={inputRule}>
        <Input prefix={<UserOutlined />} />
      </Form.Item>

      <Form.Item
        label={t('language')}
        name="SelectedLanguage"
        rules={inputRule}
      >
        <SearchableSelect options={languageOptions} />
      </Form.Item>

      {showLoginFormItems && (
        <Form.Item label={t('email')} name="UserName" rules={inputRule}>
          <Input prefix={<MailOutlined />} autoComplete="off" />
        </Form.Item>
      )}

      {showLoginFormItems && !sendMailValue && (
        <Form.Item label={t('password')} name="Password" rules={inputRule}>
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            autoComplete="off"
          />
        </Form.Item>
      )}

      {showLoginFormItems && (
        <Form.Item
          label={t('sendMailOnSignup')}
          name="SendMail"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      )}

      <Form.Item label={t('role')} name="RoleId" rules={inputRule}>
        <SearchableSelect
          placeholder={t('selectRole')}
          loading={rolesQuery.status === Status.pending}
          options={roleOptions}
        />
      </Form.Item>

      <Divider />

      <Form.Item label={t('brands')} name="BrandIds" rules={inputRule}>
        <SearchableSelect
          placeholder={t('selectBrand')}
          allowClear
          mode="multiple"
          maxTagCount={2}
          loading={brandsQuery.status === Status.pending}
          value={brandIds}
          onChange={handleBrandsChange}
        >
          {brandsQuery.data?.map(brand => (
            <SearchableSelect.Option
              className={brand.Archived ? 'user-form-archived-brand' : ''}
              key={brand.Id}
              value={brand.Id}
              label={brand.Name}
            >
              {brand.Name}
            </SearchableSelect.Option>
          ))}
        </SearchableSelect>
        {noStoreSelectedForAtLeastOneBrand && (
          <Alert
            showIcon
            type="warning"
            message={t('userForm.noStoreSelectedForAtLeastOneBrand')}
          />
        )}
      </Form.Item>

      {regions.data.length > 0 && (
        <Form.Item label={t('region')} name="RegionIds">
          <SearchableSelect
            placeholder={t('region')}
            allowClear
            mode="multiple"
            maxTagCount={1}
            loading={regions.status === Status.pending}
            {...regionsSelectProps}
          />
        </Form.Item>
      )}

      <Form.Item
        label={t('stores')}
        name="StoreAccessIds"
        rules={isAdmin ? [] : inputRule}
      >
        <SearchableSelect
          placeholder={t('selectStore')}
          allowClear
          mode="multiple"
          maxTagCount={1}
          loading={storesQuery.status === Status.pending}
          {...storeSelectProps}
        />
      </Form.Item>

      <Form.Item
        label={t('cameras')}
        name="CameraAccessIds"
        rules={isAdmin ? [] : inputRule}
      >
        <SearchableSelect
          placeholder={t('selectCamera')}
          allowClear
          mode="multiple"
          maxTagCount={1}
          loading={camerasQuery.status === Status.pending}
          {...cameraSelectProps}
        />
      </Form.Item>

      <ModalFooter formKey="user-form" />
    </Form>
  );
}
