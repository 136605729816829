import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_ALL_REGION, GET_BRAND_REGION, RESET_REGION } from './type';
import BrandServices from '@Services/Api/Brand';
import RegionsServices from '@Services/Api/Regions';
import { PaginationParams } from '@Types/Filter';

export const getBrandRegions = createAsyncThunk(
  GET_BRAND_REGION,
  async (brandId: number) => {
    try {
      const regions = await new BrandServices().GetRegions(brandId);
      return regions;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
export const getAllRegions = createAsyncThunk(
  GET_ALL_REGION,
  async (params: PaginationParams) => {
    try {
      const allRegions = await new RegionsServices().GetAllRegions(params);
      return allRegions;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
);
export const resetRegion = createAction(RESET_REGION);
