import { ReactElement, RefObject } from 'react';
import { ShapeTasks } from '../Tasks/Shape';
import { ShapeProps } from '../Types/Shape';
interface LineProps extends ShapeProps {}
function Line(shape: LineProps): ReactElement {
  const { Points, Color } = shape;
  const center = ShapeTasks.lineCenter(Points);
  return (
    <path
      stroke={'red'}
      strokeWidth="2"
      d={`M${Points[0].x},${Points[0].y}L${center.x},${center.y}L${
        Points[1]?.x || 0
      },${Points[1]?.y || 0}`}
      markerMid={`url(#Triangle)`}
    />
  );
}

export default Line;
