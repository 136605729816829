import { getBrandHomePagePhoto } from '@Store/Brand/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { asyncFlow } from '@Types/ReduxTypes';
import { useEffect } from 'react';

export interface BrandPhoto {
  status: asyncFlow;
  photo: string;
}

const useHomePagePhoto = (brandId: number): BrandPhoto => {
  const brandHomePagePhoto = useAppSelector(
    state => state.Brand.selectedBrandHomePagePhoto
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getBrandHomePagePhoto(brandId));
  }, []);

  return {
    status: brandHomePagePhoto.status,
    photo: brandHomePagePhoto.data.photo,
  };
};

export default useHomePagePhoto;
