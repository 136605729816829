import { createAsyncThunk } from '@reduxjs/toolkit';
import AdminService from '@Services/Api/Admin';
import { AllAuditLogsFetchResponse, AuditLogsParams } from '@Types/AuditLogs';
import { GET_AUDIT_LOGS } from './type';

export const getAuditLogs = createAsyncThunk(
  GET_AUDIT_LOGS,
  async (params: AuditLogsParams) => {
    try {
      const auditLogs = await new AdminService().AuditLogs(params);
      return auditLogs;
    } catch (err) {
      console.error(err);
      return {} as AllAuditLogsFetchResponse;
    }
  }
);
