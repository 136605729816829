import ModalFooter from '@Components/ModalFooter';
import { Form, FormProps } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { VersionFile, VersionFileGroup } from '@Types/Version';
import SearchableSelect from '@Components/SearchableSelect';
import { useMemo } from 'react';

export interface IChangeActiveFileForm {
  fileId: number;
}

export type ChangeActiveFileFormProps = FormProps<IChangeActiveFileForm> & {
  versionFileGroup: VersionFileGroup;
  versionFiles: VersionFile[];
};

export function ChangeActiveFileForm(props: ChangeActiveFileFormProps) {
  const { versionFileGroup, versionFiles, ...restProps } = props;

  const [form] = Form.useForm<IChangeActiveFileForm>();

  const { t } = useTranslation();

  const fileOptions = useMemo(
    () =>
      versionFiles.map(({ Id, Name }) => ({
        label: Name,
        value: Id,
      })),
    [versionFiles]
  );

  return (
    <Form
      id="active-file-form"
      className="active-file-form"
      form={form}
      labelCol={{ span: 8 }}
      labelWrap
      labelAlign="left"
      wrapperCol={{ span: 24 }}
      initialValues={versionFileGroup}
      {...restProps}
    >
      <Form.Item name="fileId" label={t('file')} rules={[{ required: true }]}>
        <SearchableSelect options={fileOptions} />
      </Form.Item>

      <ModalFooter
        sendButtonText={t('save')}
        formKey="active-file-form"
        hideGoBackButton
      />
    </Form>
  );
}
