import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import FieldSearchService from '@Services/Api/FieldSearches';
import { IFieldSearch, Language } from '@Types/FieldSearches';
import { apiCall } from '@Utils';
import { Button, Input, Popover, Select, Space, Typography } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FieldSearchesContext } from '../../Contexts';
import './style.scss';

export interface IFieldSearchItemProps {
  fieldSearch: IFieldSearch;
}

export default function FieldSearchItem({
  fieldSearch,
}: IFieldSearchItemProps) {
  const { retrieveFieldSearches } = useContext(FieldSearchesContext);
  const { t } = useTranslation();

  const deleteFieldSearchItemHandler = async () => {
    try {
      const func = () =>
        new FieldSearchService().DeleteFieldSearch(fieldSearch!.Id);
      await apiCall(func, 'deleteFieldSearch');
      await retrieveFieldSearches();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="field-search-item">
      <Popover
        content={
          <Space direction="vertical" align="center">
            <Space direction="horizontal" size="small">
              <Typography.Text>{t('fieldSearches.titleEn')}</Typography.Text>
              <Input
                type="text"
                disabled
                value={
                  fieldSearch.Title.find(t => t.Language === 'en')?.Name || ''
                }
              />
            </Space>
            <Space direction="horizontal" size="small">
              <Typography.Text>{t('fieldSearches.titleDe')}</Typography.Text>
              <Input
                type="text"
                disabled
                value={
                  fieldSearch.Title.find(t => t.Language === 'de')?.Name || ''
                }
              />
            </Space>
          </Space>
        }
      >
        <Input
          className="field-search-input"
          type="text"
          value={fieldSearch.Title.find(t => t.Language === 'tr')?.Name}
        />
      </Popover>
      <Select
        disabled
        className="field-search-select"
        mode="tags"
        value={fieldSearch.KeywordsArr || []}
      />
      <Popover content={<FieldSearchesItemDetails fieldSearch={fieldSearch} />}>
        <Button icon={<EllipsisOutlined />} />
      </Popover>
      <Link to={`/field-searches/${fieldSearch.Id}`}>
        <Button icon={<EditOutlined />} />
      </Link>
      <Button
        icon={<DeleteOutlined />}
        onClick={deleteFieldSearchItemHandler}
      />
    </div>
  );
}

type FieldSearchesItemDetailsProps = {
  fieldSearch: IFieldSearch;
};

const FieldSearchesItemDetails = ({
  fieldSearch,
}: FieldSearchesItemDetailsProps) => {
  const { t } = useTranslation();

  return (
    <Space direction="vertical" size="small" align="center">
      <Space direction="horizontal" size="small">
        <Typography.Text>{t('Route')}</Typography.Text>
        <Input value={fieldSearch.Route} disabled />
      </Space>
      <Space direction="horizontal" size="small">
        <Typography.Text>{t('allPermissions')}</Typography.Text>
        <Select
          disabled
          mode="multiple"
          value={fieldSearch.Permissions.map(p => ({
            value: t(`permissions.${p}`),
          }))}
        />
      </Space>
    </Space>
  );
};
