import {
  MultipleBeaconDetailFilterParams,
  StoreBeaconsDataSerial,
} from '@Types/Beacon';
import { Camera, CameraStatuses } from '@Types/Camera';
import {
  CameraCoordinate,
  CoordinatesFilterParams,
  MultipleCoordinatesFilterParams,
} from '@Types/Coordinates';
import { Store } from '@Types/Store';
import model from './index';
import { AllRegion } from '@Types/Region';
import {
  BrandId,
  BrandUnArchiveCamerasTypes,
  UnArchiveBrandCameras,
} from '@Types/Brand';

class BrandService extends model {
  constructor() {
    super();
    this.Prefix('Brand');
  }

  /**
   * @param {number} brandId
   * @description Get Brand Logo
   * @returns Promise
   */
  async GetBrandLogo(brandId: number) {
    return this.Get(`${brandId}/Logo`).getImage();
  }

  /**
   * @param {number} brandId
   * @description Get all stores of a brand
   * @returns Promise
   */
  async GetStores(brandId: number) {
    return this.Get(`${brandId}/Stores`).offset().Save<Store[]>();
  }

  async GetCameras(brandId: number) {
    return this.Get(`${brandId}/Cameras`).offset().Save<Camera[]>();
  }

  /**
   * @param {number} brandId
   * @description Upload brand home page image
   * @returns Promise
   */
  async UploadBrandHomePage(brandId: number, image: File) {
    return this.Post(`${brandId}/UploadHomePagePhoto`)
      .formData({ photo: image })
      .Save();
  }

  /**
   * @param {number} brandId
   * @description Get brand home page image
   * @returns Promise
   */
  async GetBrandHomePage(brandId: number) {
    return this.Get(`${brandId}/HomePagePhoto`).getImage();
  }

  /**
   * @param {CoordinatesFilterParams} params
   * @description Get Stores Camera Coordinates
   * @returns Promise
   */
  async GetMultipleStoresCameraCoordinates(
    params: MultipleCoordinatesFilterParams
  ) {
    //Type : 0: coordinate,
    //Type : 1: line,
    const { brandId, storeids, type, filterNumber, period } = params;
    return this.PrefixId(brandId)
      .Get('CameraCoordinates')
      .Params({
        type,
        filterNumber,
        period,
        storeids,
      })
      .offset()
      .Save<CameraCoordinate>();
  }

  /**
   * @param {MultipleCoordinatesFilterParams} params
   * @description Get Stores Camera Statuses
   * @returns Promise
   */
  async GetMultipleStoresCameraStatus(params: MultipleCoordinatesFilterParams) {
    const { brandId, storeids, type, filterNumber, period } = params;
    return this.PrefixId(brandId)
      .Get('CameraStatuses')
      .Params({
        type,
        filterNumber,
        period,
        storeids,
      })
      .offset()
      .Save<CameraStatuses>();
  }

  /**
   * @param {BeaconFilterParams} params
   * @description Get Stores Beacon Data
   * @returns Promise
   */
  async GetMultipleStoresBeaconsData(params: MultipleBeaconDetailFilterParams) {
    const { brandId, storeids, filterNumber, period } = params;
    return this.PrefixId(brandId)
      .Get('BeaconsData')
      .Params({
        filterNumber,
        period,
        storeids,
      })
      .offset()
      .Save<StoreBeaconsDataSerial>();
  }
  /**
   * @param {number} brandId
   * @description Get all regions of a brand
   * @returns Promise
   */
  async GetRegions(brandId: number) {
    return this.Get(`${brandId}/Regions`).offset().Save<AllRegion>();
  }

  /**
   * @param {BrandId} brandId
   * @description Archive brand
   * @returns Promise
   */
  async ArchiveBrand(brandId: BrandId) {
    return this.PrefixId(brandId).Post('Archive').offset().Save();
  }

  /**
   * @param {BrandId} brandId
   * @description Unarchive brand
   * @returns Promise
   */
  async UnarchiveBrand(brandId: BrandId) {
    return this.PrefixId(brandId).Post('UnArchive').offset().Save();
  }

  /**
   * @param {BrandUnArchiveCamerasTypes} params
   * @description Unarchive brand cameras
   * @returns Promise
   */
  async UnArchiveBrandCameras(params: BrandUnArchiveCamerasTypes) {
    const { brandId, archived } = params;

    return this.PrefixId(brandId)
      .Get('Cameras')
      .Params({
        archived,
      })
      .offset()
      .Save<UnArchiveBrandCameras>();
  }
}

export default BrandService;
