import { Divider } from 'antd';
import FieldSearchForm from './Components/FieldSearchForm';
import Header from './Components/Header';
import FieldSearchesProvider from './Contexts';
import './style.scss';

export default function FieldSearches() {
  return (
    <FieldSearchesProvider>
      <div className="field-searches-page">
        <div className="field-searches-container">
          <Header />
          <Divider />
          <FieldSearchForm />
        </div>
      </div>
    </FieldSearchesProvider>
  );
}
