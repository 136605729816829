// @ts-nocheck
export function setCookie(cname: string, cvalue: string, exdays: number) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function getCookie(cname: string) {
  let name = cname + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

// User agent represent unique id for current browser.
// this agent will inilialize after web site mounted.
// Used to deduplicate the user
function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
const generate_x_session_id = () => uuidv4();

export const create_x_session_id = () => {
  const userAgent = generate_x_session_id();
  setCookie('x_session_id', userAgent, 1);
};

export const get_x_session_id = () => getCookie('x_session_id');

export const is_exist_x_session_id = () => {
  const userAgent = getCookie('x_session_id');
  return userAgent != '';
};
