import { createReducer } from '@reduxjs/toolkit';
import {
  getAllArchivedCameras,
  getAllArchivedCamerasOfStore,
  getAllCamerasOfBrands,
  getAllCamerasOfStores,
  getAllCamerasSortedByStoreIds,
  getAllUnarchivedCameras,
  getAllUnarchivedCamerasOfStore,
  getArchivedCameras,
  getCameraDetail,
  getUnarchivedCameras,
  resetAllCameras,
  resetCurrentCamera,
  setSelectedCamera,
  setSelectedCameras,
} from './action';
import { asyncFetchInterface } from '../../Types/ReduxTypes';
import {
  AllFetchedCameras,
  Camera,
  CameraDetail,
  CamerasByStoreId,
  FetchedCamerasResponse,
} from '@Types/Camera';

interface stateInterface {
  // These are used for users page
  allCameras: asyncFetchInterface<Camera[]>;
  camerasSortedByStoreId: asyncFetchInterface<CamerasByStoreId>;
  selectedCamera: Camera;
  selectedCameras: Camera[];

  fetchedCameras: AllFetchedCameras;
  currentCamera: asyncFetchInterface<Camera | CameraDetail>;
}

const initialState: stateInterface = {
  allCameras: {
    data: [],
    status: 'Empty',
  },
  camerasSortedByStoreId: {
    data: [],
    status: 'Empty',
  },
  selectedCamera: {} as Camera,
  selectedCameras: [] as Camera[],
  fetchedCameras: {
    data: {} as FetchedCamerasResponse,
    status: 'Empty',
  },
  currentCamera: {
    data: {} as Camera,
    status: 'Empty',
  },
};

const CameraReducer = createReducer(initialState, builder => {
  builder
    .addCase(getAllCamerasOfBrands.pending, state => {
      state.allCameras = {
        ...state.allCameras,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllCamerasOfBrands.fulfilled, (state, action) => {
      state.allCameras.data = action.payload;
      state.allCameras.status = 'fulfilled';
    })
    .addCase(getAllCamerasOfBrands.rejected, (state, action) => {
      state.allCameras = {
        ...state.allCameras,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getAllCamerasOfStores.pending, state => {
      state.allCameras = {
        ...state.allCameras,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllCamerasOfStores.fulfilled, (state, action) => {
      state.allCameras.data = action.payload;
      state.allCameras.status = 'fulfilled';
    })
    .addCase(getAllCamerasOfStores.rejected, (state, action) => {
      state.allCameras = {
        ...state.allCameras,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getAllCamerasSortedByStoreIds.pending, state => {
      state.camerasSortedByStoreId = {
        ...state.camerasSortedByStoreId,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllCamerasSortedByStoreIds.fulfilled, (state, action) => {
      state.camerasSortedByStoreId.data = action.payload;
      state.camerasSortedByStoreId.status = 'fulfilled';
    })
    .addCase(getAllCamerasSortedByStoreIds.rejected, (state, action) => {
      state.camerasSortedByStoreId = {
        ...state.camerasSortedByStoreId,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })

    .addCase(setSelectedCamera, (state, action) => {
      state.selectedCamera = action.payload;
    })
    .addCase(setSelectedCameras, (state, action) => {
      state.selectedCameras = action.payload;
    })
    .addCase(resetAllCameras, state => {
      state.allCameras = {
        data: [],
        status: 'Empty',
        ErrorMessage: undefined,
      };
    })
    .addCase(getUnarchivedCameras.pending, state => {
      state.fetchedCameras = {
        ...state.fetchedCameras,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getUnarchivedCameras.fulfilled, (state, action) => {
      state.fetchedCameras.data = action.payload as FetchedCamerasResponse;
      state.fetchedCameras.status = 'fulfilled';
    })
    .addCase(getUnarchivedCameras.rejected, (state, action) => {
      state.fetchedCameras = {
        ...state.fetchedCameras,
        data: {} as FetchedCamerasResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getArchivedCameras.pending, state => {
      state.fetchedCameras = {
        ...state.fetchedCameras,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getArchivedCameras.fulfilled, (state, action) => {
      state.fetchedCameras.data = action.payload as FetchedCamerasResponse;
      state.fetchedCameras.status = 'fulfilled';
    })
    .addCase(getArchivedCameras.rejected, (state, action) => {
      state.fetchedCameras = {
        ...state.fetchedCameras,
        data: {} as FetchedCamerasResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(resetCurrentCamera, state => {
      state.currentCamera = {
        data: {} as Camera,
        status: 'Empty',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllUnarchivedCameras.pending, state => {
      state.fetchedCameras = {
        ...state.fetchedCameras,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllUnarchivedCameras.fulfilled, (state, action) => {
      state.fetchedCameras.data = action.payload as FetchedCamerasResponse;
      state.fetchedCameras.status = 'fulfilled';
    })
    .addCase(getAllUnarchivedCameras.rejected, (state, action) => {
      state.fetchedCameras = {
        ...state.fetchedCameras,
        data: {} as FetchedCamerasResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getAllArchivedCameras.pending, state => {
      state.fetchedCameras = {
        ...state.fetchedCameras,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllArchivedCameras.fulfilled, (state, action) => {
      state.fetchedCameras.data = action.payload as FetchedCamerasResponse;
      state.fetchedCameras.status = 'fulfilled';
    })
    .addCase(getAllArchivedCameras.rejected, (state, action) => {
      state.fetchedCameras = {
        ...state.fetchedCameras,
        data: {} as FetchedCamerasResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getAllUnarchivedCamerasOfStore.pending, state => {
      state.fetchedCameras = {
        ...state.fetchedCameras,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllUnarchivedCamerasOfStore.fulfilled, (state, action) => {
      state.fetchedCameras.data = action.payload as FetchedCamerasResponse;
      state.fetchedCameras.status = 'fulfilled';
    })
    .addCase(getAllUnarchivedCamerasOfStore.rejected, (state, action) => {
      state.fetchedCameras = {
        ...state.fetchedCameras,
        data: {} as FetchedCamerasResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getAllArchivedCamerasOfStore.pending, state => {
      state.fetchedCameras = {
        ...state.fetchedCameras,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllArchivedCamerasOfStore.fulfilled, (state, action) => {
      state.fetchedCameras.data = action.payload as FetchedCamerasResponse;
      state.fetchedCameras.status = 'fulfilled';
    })
    .addCase(getAllArchivedCamerasOfStore.rejected, (state, action) => {
      state.fetchedCameras = {
        ...state.fetchedCameras,
        data: {} as FetchedCamerasResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getCameraDetail.pending, state => {
      state.currentCamera = {
        ...state.currentCamera,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getCameraDetail.fulfilled, (state, action) => {
      state.currentCamera.data = action.payload as Camera;
      state.currentCamera.status = 'fulfilled';
    })
    .addCase(getCameraDetail.rejected, (state, action) => {
      state.currentCamera = {
        ...state.currentCamera,
        data: {} as Camera,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default CameraReducer;
