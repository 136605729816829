import { Button, Card, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import * as FilterTypes from '@Types/Filter';
import SortByFilter from '../SortByFilter';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import CardGridWrapper from '@Components/CardGridWrapper';
import { GetStoresParams } from '@Types/Store';
import ArchivedFilter from '@Components/ArchivedFilter';
import StoreCard from '@Cards/StoreCard';
import GoBackButton from '@Components/GoBackButton';
import { PlusCircleOutlined } from '@ant-design/icons';
import './style.scss';
import { Permissions } from '@Enums/Permission';
import useCheckPermission from '@Hooks/useCheckPermission';
import DataDisplayTable from '@Components/DataDisplayTable';
import useDataDisplay from '@Hooks/useDataDisplay';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import useStoresColumns from './useStoresColumns';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';
import { FilterOption } from '@Types/Pagination';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { getCountries } from '@Store/Country/action';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';
import { getCities } from '@Store/City/action';

type Props = {
  getUnarchivedStoresAction: any;
  getArchivedStoresAction: any;
  title: string;
};

const StoresDetail = ({
  getUnarchivedStoresAction,
  getArchivedStoresAction,
  title,
}: Props) => {
  const [showArchivedStores, setShowArchivedStores] = useState<boolean>(false);
  const [currentDisplay, toggleDisplay] = useDataDisplay('stores');
  const fetchedStores = useAppSelector(s => s.Store.fetchedStores);
  const countries = useAppSelector(s => s.Country.countries);
  const cities = useAppSelector(s => s.City.cities);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { brandId } = useParams();

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  useSetHeaderTitle(
    `${title} - ${
      showArchivedStores ? t('archivedStores') : t('unarchivedStores')
    }`
  );

  const pageConfigResult = useUserPageConfig('stores', {
    orderBy: 'asc',
    sortKey: 'Name',
  });

  const filterOptions: FilterOption[] = [
    { field: 'Id', label: 'ID', type: FilterType.NUMBER },
    { field: 'Name', label: t('name'), type: FilterType.TEXT },
    {
      field: 'PartnerId',
      label: 'Partner',
      type: FilterType.TEXT,
    },

    {
      field: 'BrandId',
      label: 'Brand ID',
      type: FilterType.NUMBER,
    },
    {
      field: 'Brand.Name',
      label: t('BrandName'),
      type: FilterType.TEXT,
    },
    {
      field: 'CountryId',
      label: t('country'),
      type: FilterType.NUMBER_SELECT,
      options: countries?.data?.map(u => ({
        label: u.Name,
        value: u.Id,
      })),
      formatValueBeforeApiRequest: value => {
        if (value) {
          return [value as number];
        }

        return value;
      },
    },
    {
      field: 'CityId',
      label: t('city'),
      type: FilterType.NUMBER_ARRAY_SELECT,
      options: cities?.data?.map(u => ({
        label: u.Name,
        value: u.Id,
      })),
    },
  ];

  const { pagination, refetch } = usePaginatedQuery({
    status: fetchedStores.status,
    filterOptions,
    pageConfigResult,
    refetchDependencies: [showArchivedStores],
    queryFunction: p => getStores(p),
  });

  useEffect(() => {
    const countryId = pagination.FilterBy.find(
      f => f.field === 'CountryId'
    )?.value;

    if (countryId) {
      dispatch(getCities(Number(countryId)));
    }
  }, [dispatch, pagination]);

  const storeActionPerm = useCheckPermission(Permissions.ADMIN_STORE_ACTION);

  const toggleShowArchievedStores = () => {
    setShowArchivedStores(p => !p);
  };

  const getStores = (filters: FilterTypes.PaginationParams) => {
    if (brandId) {
      const params: GetStoresParams = { filters, brandId };
      if (showArchivedStores) {
        dispatch(getArchivedStoresAction(params));
      } else {
        dispatch(getUnarchivedStoresAction(params));
      }
    } else {
      if (showArchivedStores) {
        dispatch(getArchivedStoresAction(filters));
      } else {
        dispatch(getUnarchivedStoresAction(filters));
      }
    }
  };

  const storesTableColumns = useStoresColumns({
    stores: fetchedStores,
    getStores: refetch,
    isArchived: showArchivedStores,
  });

  return (
    <Card className="store-detail-container">
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <GoBackButton />

          <SortByFilter
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />

          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <ArchivedFilter
            onClick={toggleShowArchievedStores}
            showArchived={showArchivedStores}
          />

          {brandId && (
            <Link to="new-store">
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                disabled={!storeActionPerm}
              >
                {t('addStore')}
              </Button>
            </Link>
          )}
        </div>

        <CardGridWrapper
          status={fetchedStores.status}
          noData={fetchedStores.data?.Data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <React.Fragment>
                {fetchedStores.data?.Data?.map(s => (
                  <StoreCard
                    store={s}
                    key={s.StoreId}
                    isArchived={showArchivedStores}
                    getStores={refetch}
                  />
                ))}
              </React.Fragment>
            ) : (
              <DataDisplayTable
                columns={storesTableColumns}
                dataSource={fetchedStores.data?.Data}
              />
            )
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={fetchedStores.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={fetchedStores.data?.TotalNumberOfRecords}
        />
      </div>

      <GoBackButton />
    </Card>
  );
};

export default StoresDetail;
