import { useTranslation } from 'react-i18next';
import { isValidMacAdress } from '@Utils/index';
import { Rule } from 'antd/lib/form';

export function useMacAdressRules(): Rule[] {
  const { t } = useTranslation();

  return [
    {
      validator(_, value) {
        if (isValidMacAdress(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(t('invalidMacAdress')));
      },
    },
  ];
}
