import GoBackButton from '@Components/GoBackButton';
import UserService from '@Services/Api/User';
import { Form, Input, Button, Typography, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import { ResetForm } from '@Types/User';
import { apiCall } from '@Utils/index';
const { Title } = Typography;
type Props = {};

const ForgetPassword = (props: Props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const handleSubmit = async (mailForm: ResetForm) => {
    try {
      await apiCall(() => sendResetPasswordMail(mailForm), 'resetPasswordMail');
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const sendResetPasswordMail = async (mailForm: ResetForm) => {
    return await new UserService().MailResetPassword(mailForm.Email);
  };

  return (
    <div id="login-page-wrapper" className="ForgetPassword">
      <div id="login-form-container">
        <Divider>
          <Title level={3}>{t('forgotPw')}</Title>
        </Divider>

        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
        >
          <Form.Item
            label="Email"
            name="Email"
            rules={[
              {
                required: true,
                message: t('loginForm.errors.noUsername'),
                type: 'email',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>

          <Form.Item className="login-form-button-container">
            <GoBackButton />
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              {t('submit')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ForgetPassword;
