import { createReducer } from '@reduxjs/toolkit';
import { getCities, setSelectedCity } from './action';
import { asyncFetchInterface } from '@Types/ReduxTypes';
import { City } from '@Types/City';

interface stateInterface {
  cities: asyncFetchInterface<City[]>;
  selectedCity: City | null;
}

const initialState: stateInterface = {
  cities: {
    data: [],
    status: 'Empty',
  },
  selectedCity: null,
};

const CityReducer = createReducer(initialState, builder => {
  builder
    .addCase(getCities.pending, state => {
      state.cities = {
        ...state.cities,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getCities.fulfilled, (state, action) => {
      state.cities.data = action.payload;
      state.cities.status = 'fulfilled';
    })
    .addCase(getCities.rejected, (state, action) => {
      state.cities = {
        ...state.cities,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(setSelectedCity, (state, action) => {
      state.selectedCity = action.payload;
    });
});

export default CityReducer;
