import { createReducer } from '@reduxjs/toolkit';
import { CameraCoordinate } from '@Types/Coordinates';
import { asyncFetchInterface } from '@Types/ReduxTypes';
import {
  getStoresCameraCoordinate,
  getStoresLineCoordinate,
  getCamerasCoordinate,
  getCamerasLineCoordinate,
  resetStoreCameraCoordinate,
  resetStoreLineCoordinate,
  getMultipleStoresCameraCoordinate,
  getMultipleStoresLineCoordinate,
} from './action';

interface stateInterface {
  storeCameraCoordinate: asyncFetchInterface<CameraCoordinate>;
  storeLineCoordinate: asyncFetchInterface<CameraCoordinate>;
  cameraCoordinate: asyncFetchInterface<CameraCoordinate>;
  cameraLineCoordinate: asyncFetchInterface<CameraCoordinate>;
}

const initialState: stateInterface = {
  storeCameraCoordinate: {
    data: [],
    status: 'Empty',
  },
  storeLineCoordinate: {
    data: [],
    status: 'Empty',
  },
  cameraCoordinate: {
    data: [],
    status: 'Empty',
  },
  cameraLineCoordinate: {
    data: [],
    status: 'Empty',
  },
};

const DataReducer = createReducer(initialState, builder => {
  builder
    .addCase(getStoresCameraCoordinate.pending, state => {
      state.storeCameraCoordinate = {
        ...state.storeCameraCoordinate,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getStoresCameraCoordinate.fulfilled, (state, action) => {
      state.storeCameraCoordinate.data = action.payload;
      state.storeCameraCoordinate.status = 'fulfilled';
    })
    .addCase(getStoresCameraCoordinate.rejected, (state, action) => {
      state.storeCameraCoordinate = {
        ...state.storeCameraCoordinate,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getStoresLineCoordinate.pending, state => {
      state.storeLineCoordinate = {
        ...state.storeLineCoordinate,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getStoresLineCoordinate.fulfilled, (state, action) => {
      state.storeLineCoordinate.data = action.payload;
      state.storeLineCoordinate.status = 'fulfilled';
    })
    .addCase(getStoresLineCoordinate.rejected, (state, action) => {
      state.storeLineCoordinate = {
        ...state.storeLineCoordinate,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getMultipleStoresCameraCoordinate.pending, state => {
      state.storeCameraCoordinate = {
        ...state.storeCameraCoordinate,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getMultipleStoresCameraCoordinate.fulfilled, (state, action) => {
      state.storeCameraCoordinate.data = action.payload;
      state.storeCameraCoordinate.status = 'fulfilled';
    })
    .addCase(getMultipleStoresCameraCoordinate.rejected, (state, action) => {
      state.storeCameraCoordinate = {
        ...state.storeCameraCoordinate,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getMultipleStoresLineCoordinate.pending, state => {
      state.storeLineCoordinate = {
        ...state.storeLineCoordinate,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getMultipleStoresLineCoordinate.fulfilled, (state, action) => {
      state.storeLineCoordinate.data = action.payload;
      state.storeLineCoordinate.status = 'fulfilled';
    })
    .addCase(getMultipleStoresLineCoordinate.rejected, (state, action) => {
      state.storeLineCoordinate = {
        ...state.storeLineCoordinate,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getCamerasCoordinate.pending, state => {
      state.cameraCoordinate = {
        ...state.cameraCoordinate,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getCamerasCoordinate.fulfilled, (state, action) => {
      state.cameraCoordinate.data = action.payload;
      state.cameraCoordinate.status = 'fulfilled';
    })
    .addCase(getCamerasCoordinate.rejected, (state, action) => {
      state.cameraCoordinate = {
        ...state.cameraCoordinate,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getCamerasLineCoordinate.pending, state => {
      state.cameraLineCoordinate = {
        ...state.cameraCoordinate,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getCamerasLineCoordinate.fulfilled, (state, action) => {
      state.cameraLineCoordinate.data = action.payload;
      state.cameraLineCoordinate.status = 'fulfilled';
    })
    .addCase(getCamerasLineCoordinate.rejected, (state, action) => {
      state.cameraLineCoordinate = {
        ...state.cameraLineCoordinate,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(resetStoreCameraCoordinate, state => {
      state.storeCameraCoordinate = {
        ...state.storeCameraCoordinate,
        data: [],
        status: 'Empty',
        ErrorMessage: undefined,
      };
    })
    .addCase(resetStoreLineCoordinate, state => {
      state.storeLineCoordinate = {
        ...state.storeLineCoordinate,
        data: [],
        status: 'Empty',
        ErrorMessage: undefined,
      };
    });
});

export default DataReducer;
