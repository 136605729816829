import Status from '@Enums/Status';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import { AllUsers, UserDataItemBase, UserFetchResponse } from '@Types/User';
import FilledText from '@Components/FilledText';
import DetailedRow from '@Components/Card/DetailedRow';
import UserActionButtons from '@Cards/UserCard/UserActionButtons';

interface Params {
  users: AllUsers;
  getUsers: () => void;
}

/**
 * @description This "hook" is not a generic hook. It's made to abstract the code and make it much more readable. It will only be used for <Users/>.
 */
export default function useUsersColumns(
  props: Params
): ColumnsType<UserFetchResponse> {
  const { users } = props;
  const usersActionPerm = useCheckPermission(Permissions.ADMIN_USERS_ACTION);
  const { t } = useTranslation();

  const usersTableColumns: ColumnsType<UserFetchResponse> = useMemo(() => {
    if (users.status !== Status.success) return [];

    const columns: ColumnsType<UserFetchResponse> = [
      {
        title: t('ID'),
        dataIndex: 'Id',
      },
      {
        title: t('name'),
        dataIndex: 'Name',
      },
      {
        title: t('RoleName'),
        dataIndex: 'RoleName',
        render: (value: string) => <FilledText text={value} />,
      },
      {
        title: t('email'),
        dataIndex: 'Email',
        render: (value: string) => <FilledText text={value} />,
      },
      {
        title: t('createdDate'),
        dataIndex: 'CreatedDate',
        render: (value: string) => <FilledText text={value} />,
      },
      {
        title: t('brands'),
        dataIndex: 'Brands',
        render: (value: UserDataItemBase[]) => <DetailedRow items={value} />,
      },
      {
        title: t('stores'),
        dataIndex: 'Stores',
        render: (value: UserDataItemBase[]) => <DetailedRow items={value} />,
      },
      {
        title: t('cameras'),
        dataIndex: 'Cameras',
        render: (value: UserDataItemBase[]) => <DetailedRow items={value} />,
      },
    ];

    if (usersActionPerm) {
      columns.push({
        title: t('actionButtons'),
        dataIndex: 'actionButtons',
        render: (_: any, user: UserFetchResponse) => (
          <UserActionButtons {...props} user={user} />
        ),
      });
    }

    return columns;
  }, [users.status]);

  return usersTableColumns;
}
