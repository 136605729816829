import { NotificationType } from '@Types/Dashboard';
import { useTranslation } from 'react-i18next';

interface Props {
  type: NotificationType;
}

/**
 * @description This component is used to display the type of the notification with the right language.
 * @description It is needed in order to use "useTranslation" hook since we can only access the hook in react components.
 */
export default function NotificationTitle({ type }: Props) {
  const { t } = useTranslation();
  return <>{t('notification.' + type)}</>;
}
