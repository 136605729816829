import React, { ReactElement, RefObject } from 'react';
import { ShapeTasks } from '../Tasks/Shape';
import { ShapeProps } from '../Types/Shape';
interface PolygonProps extends ShapeProps {}
function Polygon(shape: PolygonProps): ReactElement {
  const { Points, Color } = shape;
  return (
    <polygon
      stroke="black"
      strokeWidth="1"
      fill={Color}
      points={ShapeTasks.getShapePoints(Points)}
    />
  );
}

export default Polygon;
