import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import './styles.scss';

export function ErrorPage() {
  const { t } = useTranslation();

  function resetApp() {
    localStorage.clear();
    window.location.reload();
  }

  return (
    <Result
      status="500"
      title={t('errorPage.title')}
      subTitle={t('errorPage.subTitle')}
      className="error-page-container"
      extra={
        <Button type="primary" onClick={resetApp}>
          {t('errorPage.reload')}
        </Button>
      }
    />
  );
}
