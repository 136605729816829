import { PlusCircleOutlined } from '@ant-design/icons';
import FieldSearchService from '@Services/Api/FieldSearches';
import { FieldSearchId, IFieldSearch } from '@Types/FieldSearches';
import { apiCall } from '@Utils';
import { Button, Popover, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldSearchesContext } from '../../Contexts';
import AddFieldSearchItem from '../AddFieldSearchItem';
import FieldSearchItem from '../FieldSearchItem';
import './style.scss';

const { Title } = Typography;

export interface IFieldSearchFormProps {}

export default function FieldSearchForm({}: IFieldSearchFormProps) {
  const [addFieldVisible, setAddFieldVisible] = useState<boolean>();

  const { fieldSearches, retrieveFieldSearches } =
    useContext(FieldSearchesContext);

  useEffect(() => {
    (async () => {
      await retrieveFieldSearches();
    })();
  }, []);

  const { t } = useTranslation();

  const closeAddFieldPopover = () => {
    setAddFieldVisible(false);
  };

  const toggleAddFieldPopover = () => {
    setAddFieldVisible(p => !p);
  };

  return (
    <div className="field-search-items">
      <div className="field-searches-header">
        <Title level={5} className="title-header">
          {t('fieldSearches.title')}
        </Title>
        <Title level={5} className="keywords-header">
          {t('fieldSearches.keywords')}
        </Title>
      </div>

      {fieldSearches?.map(fieldSearch => (
        <FieldSearchItem fieldSearch={fieldSearch} key={fieldSearch.Id} />
      ))}

      <Popover
        content={<AddFieldSearchItem onSubmit={closeAddFieldPopover} />}
        destroyTooltipOnHide
        open={addFieldVisible}
        trigger="click"
      >
        <Button
          type="ghost"
          className="add-field-search-button"
          onClick={toggleAddFieldPopover}
          icon={<PlusCircleOutlined />}
        />
      </Popover>
    </div>
  );
}
