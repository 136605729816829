import { Permissions } from '@Enums/Permission';
import useCheckPermission from '@Hooks/useCheckPermission';
import useIsEnvAdmin from '@Hooks/useIsEnvAdmin';
import useIsSysAdmin from '@Hooks/useIsSysAdmin';
import AccessDenied from '@Pages/AccessDenied';

interface Props {
  permission?: Permissions;

  /**
   * Used for some pages that does not have permission dedicated for them.
   * We check if the user is a System Admin in that case
   */
  sysAdminCheck?: boolean;

  /**
   * Same thing as 'sysAdminCheck'.
   * The only difference is that it uses some different logic that depends on the current environment.
   */
  envAdminCheck?: boolean;

  children: React.ReactElement;
}

/**
 * This component is used to determine if a user should see a page or not.
 * If the user does not have permission to see the page, we redirect them to <AccessDenied /> page.
 */
function PermissionRouter({
  permission,
  sysAdminCheck,
  children,
  envAdminCheck,
}: Props) {
  const isSysAdmin = useIsSysAdmin();
  const isEnvAdmin = useIsEnvAdmin();
  const hasPermission = useCheckPermission(permission);

  if (envAdminCheck) {
    if (isEnvAdmin) return children;
    return <AccessDenied />;
  }

  if (sysAdminCheck) {
    if (isSysAdmin) return children;
    return <AccessDenied />;
  }

  if (!hasPermission) return <AccessDenied />;

  return children;
}

export default PermissionRouter;
