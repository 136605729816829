import { useTranslation } from 'react-i18next';
import { Form, Modal } from 'antd';
import { useContext, useEffect, useState } from 'react';
import CameraService from '@Services/Api/Camera';
import { CameraId } from '@Types/Camera';
import TextArea from 'antd/lib/input/TextArea';
import { apiCall } from '@Utils';
import { RecordProcessContext } from '@Pages/CameraRecords/Contexts';

type Props = {
  cameraId: CameraId;
  recordProcessId: number;
};

export default function SetNoteModal({ cameraId, recordProcessId }: Props) {
  const [note, setNote] = useState<string>('');

  const { showNoteModal, setShowNoteModal } = useContext(RecordProcessContext);

  useEffect(() => {
    if (showNoteModal) {
      (async () => {
        const res = await new CameraService().GetRecordProcessNote(
          cameraId,
          recordProcessId
        );
        setNote(res.Note || '');
      })();
    }
  }, [showNoteModal]);

  const { t } = useTranslation();

  const handleConfirmClick = async () => {
    const req = async () =>
      new CameraService().SetRecordProcessNote(cameraId, {
        Id: recordProcessId,
        Note: note,
      });

    try {
      await apiCall(req, 'setNote');
    } catch (err) {
      console.warn(err);
    } finally {
      handleCancelClick();
    }
  };

  const handleCancelClick = () => {
    setShowNoteModal(false);
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  return (
    <Modal
      title={t('setNote')}
      open={showNoteModal}
      onOk={handleConfirmClick}
      onCancel={handleCancelClick}
    >
      <Form.Item label={t('note')}>
        <TextArea value={note} onChange={handleNoteChange} />
      </Form.Item>
    </Modal>
  );
}
