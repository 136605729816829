import { StoreProduct } from '@Types/Brand';
import { Camera } from '@Types/Camera';
import model from './index';
import ConnectedCameraTypes from '@Types/connectCameras';
import {
  WifiConfigiration,
  WifiConfigirationData,
} from '@Types/WifiConfigiration';
import { StoreUnArchiveCamerasTypes } from '@Types/Store';
class StoreService extends model {
  constructor() {
    super();
    this.Prefix('Store');
  }

  /**
   * @param {number} storeId
   * @description Edit store products of a store
   * @returns Promise
   */
  async EditStoreProduct(storeId: number) {
    return this.Get(`${storeId}/Products`).offset().Save<StoreProduct[]>();
  }

  /**
   *  @param {number} storeId
   * @description Get all cameras from a store
   * @returns Promise
   */
  async GetAll(storeId: number | undefined) {
    if (storeId === undefined) {
      return Promise.reject('Store id is required!');
    }
    return this.Get(`${storeId}/Cameras`).offset().Save<Camera[]>();
  }

  /**
   *
   * @param {number} storeId
   * @param {ConnectedCameraTypes.LayoutConfig} LayoutConfig
   * @description Set connected cameras list layout.
   * @returns Promise
   */
  async setLayoutConfig(
    LayoutConfig: ConnectedCameraTypes.LayoutConfig,
    storeId?: number
  ) {
    if (!storeId) {
      return Promise.reject('Store id is Required!');
    }
    return this.PrefixId(storeId)
      .Post(`SetLayoutConfig`)
      .Data(LayoutConfig)
      .Save();
  }
  /**
   *
   * @param {number} storeId
   * @param {ConnectedCameraTypes.ConnectedCamerasConfig} Config
   * @description Set connected cameras grouped polygons.
   * @returns Promise
   */
  async setConnectedCamerasConfig(
    Config: ConnectedCameraTypes.ConnectedCamerasConfig,
    storeId?: number
  ) {
    if (!storeId) {
      return Promise.reject('Store id is Required!');
    }
    return this.PrefixId(storeId)
      .Post(`SetConnectedCamerasConfig`)
      .Data(Config)
      .Save();
  }
  /**
   *
   * @param {number} storeId
   * @returns {ConnectedCameraTypes.ConnectedCameraDetails} Store Connected Cameras Config
   */
  async getConnectedCameraConfigs(storeId?: number) {
    if (!storeId) {
      return Promise.reject('Store id is Required!');
    }
    return this.PrefixId(storeId)
      .Get(`GetConnectedCameraConfigs`)
      .Save<ConnectedCameraTypes.ConnectedCameraDetails>();
  }

  /**
   *
   * @param {number} storeId
   * @param {WifiConfigiration} StoreWifiConfig
   * @description Store Wifi Configiration Add or Edit
   * @returns Promise
   *    */
  async PostWifiConfiguration(
    StoreWifiConfig: WifiConfigiration,
    storeId?: number
  ) {
    if (!storeId) {
      return Promise.reject('Store id is Required!');
    }
    return this.PrefixId(storeId)
      .Post(`WifiConfiguration`)
      .Data(StoreWifiConfig)
      .Save();
  }
  /**
   *
   * @param {number} storeId
   * @returns {WifiConfigirationData} Store Wifi Configiration
   */
  async GetNetworkConfiguration(storeId?: number) {
    if (!storeId) {
      return Promise.reject('Store id is Required!');
    }
    return this.PrefixId(storeId)
      .Get(`NetworkConfiguration`)
      .Save<WifiConfigirationData>();
  }

  /**
   *
   * @description Delete a wifi configiration.
   * @returns Promise
   */
  async DeleteWifiConfiguration(storeid: number) {
    return this.PrefixId(storeid).Delete('WifiConfiguration').Save<any>();
  }

  /**
   * @param {StoreUnArchiveCamerasTypes} params
   * @description Unarchive store cameras
   * @returns Promise
   */
  async UnArchiveStoreCameras(params: StoreUnArchiveCamerasTypes) {
    const { StoreId, archived } = params;

    return this.PrefixId(StoreId)
      .Get('Cameras')
      .Params({
        archived,
      })
      .Save();
  }
}

export default StoreService;
