import { createReducer } from '@reduxjs/toolkit';
import { IStoreReceiver } from '@Types/BeaconRule';
import {
  AllReceiver,
  AllReceiversFetchResponse,
  AllReceivers,
  Receiver,
} from '@Types/Receiver';
import { asyncFetchInterface } from '@Types/ReduxTypes';
import { getAllReceiver, getBrandReceivers, getReceiverById } from './action';

interface stateInterface {
  allReceiver: AllReceivers;
  receiverData: Receiver;
  brandReceivers: asyncFetchInterface<IStoreReceiver[]>;
}

const initialState: stateInterface = {
  allReceiver: {
    data: {} as AllReceiversFetchResponse,
    status: 'Empty',
  },
  receiverData: {
    data: {} as AllReceiver,
    status: 'Empty',
  },
  brandReceivers: {
    data: [],
    status: 'Empty',
  },
};

const ReceiverReducer = createReducer(initialState, builder => {
  builder
    .addCase(getAllReceiver.pending, state => {
      state.allReceiver = {
        ...state.allReceiver,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllReceiver.fulfilled, (state, action) => {
      state.allReceiver.data = action.payload as AllReceiversFetchResponse;
      state.allReceiver.status = 'fulfilled';
    })
    .addCase(getAllReceiver.rejected, (state, action) => {
      state.allReceiver = {
        ...state.allReceiver,
        data: {} as AllReceiversFetchResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getReceiverById.pending, state => {
      state.receiverData = {
        ...state.receiverData,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getReceiverById.fulfilled, (state, action) => {
      state.receiverData.data = action.payload as AllReceiver;
      state.receiverData.status = 'fulfilled';
    })
    .addCase(getReceiverById.rejected, (state, action) => {
      state.receiverData = {
        ...state.receiverData,
        data: {} as AllReceiver,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getBrandReceivers.pending, state => {
      state.brandReceivers = {
        ...state.brandReceivers,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getBrandReceivers.fulfilled, (state, action) => {
      state.brandReceivers.data = action.payload;
      state.brandReceivers.status = 'fulfilled';
    })
    .addCase(getBrandReceivers.rejected, (state, action) => {
      state.brandReceivers = {
        ...state.brandReceivers,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default ReceiverReducer;
