import {
  AllCameraRulesFetchResponse,
  CameraRule,
  CameraRuleId,
  GetCameraRulesParams,
  NewCameraRule,
} from '@Types/CameraRules';
import model from './index';

class CameraRulesService extends model {
  constructor() {
    super();
    this.Prefix('CameraRules');
  }

  /**
   * @param {GetCameraRulesParams} params
   * @description Get all camera rules
   * @returns Promise
   */
  async GetAll(params: GetCameraRulesParams) {
    return this.Post(`GetAll`)
      .Data(params.filters)
      .offset()
      .Save<AllCameraRulesFetchResponse>();
  }

  /**
   * @param {NewCameraRule} newCameraRule
   * @description Add new camera rule
   * @returns Promise
   */
  async AddCameraRule(newCameraRule: NewCameraRule) {
    return this.Post('').Data(newCameraRule).Save();
  }

  /**
   * @description Get camera rule by id
   * @returns Promise
   */
  async GetCameraRuleById(cameraRuleId: CameraRuleId) {
    return this.PrefixId(cameraRuleId).Post().offset().Save<CameraRule>();
  }

  /**
   * @param {CameraRuleId[]} ruleIds
   * @description Delete camera rule
   * @returns Promise
   */
  async DeleteCameraRule(ruleIds: number[]) {
    return this.Delete().Data(ruleIds).Save();
  }
}

export default CameraRulesService;
