import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_COUNTRIES, SET_SELECTED_COUNTRY } from './type';
import { Country } from '@Types/Country';
import AdminService from '@Services/Api/Admin';

export const getCountries = createAsyncThunk(GET_COUNTRIES, async () => {
  try {
    const countries = await new AdminService().GetCountries();
    return countries;
  } catch (err) {
    console.error(err);
    return [];
  }
});
export const setSelectedCountry = createAction(
  SET_SELECTED_COUNTRY,
  (country: Country | null) => {
    return {
      payload: country,
    };
  }
);
