import { HTMLAttributes, ReactElement, useMemo } from 'react';
import { CameraStatusesDetail } from '@Types/Camera';
import { Empty, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ReactNode } from 'react';
import './style.scss';
import { Variant } from '@Enums/Status';
import Title from 'antd/lib/typography/Title';
import { convertApiDate } from '@Utils/index';
import USteps from '@Components/USteps';
import UStep from '@Components/USteps/UStep';
type Props = {
  data?: CameraStatusesDetail;
  Label: any;
};
type recordPayloadType = {
  current: CameraStatusesDetail['Status'][0];
  next: CameraStatusesDetail['Status'][0];
  startDate?: string;
  endDate?: string;
};
type recordType = Pick<
  RecordProps<recordPayloadType>,
  'value' | 'type' | 'payload'
>;
function TimeLineCard({ data, Label }: Props) {
  const { t } = useTranslation();

  const dataSet = useMemo(() => {
    // if (cameraTimeLine) {
    //   data &&
    //     data.BoardWorkerStatus.reduce<recordType[]>(
    //       (sets, currentDate, index) => {
    //         const startDate = moment(currentDate.StartAt);
    //         const endDate = moment(currentDate.EndAt);
    //         const diff = moment.duration(endDate.diff(startDate));
    //         const startDateFormat = convertApiDate(currentDate.StartAt, true);
    //         const endDateFormat = convertApiDate(currentDate.EndAt, true);
    //         const diffSecs = diff.asSeconds();
    //         sets.push({
    //           value: diffSecs,
    //           type: currentDate.Status ? Variant.error : Variant.success,
    //           payload: {
    //             current: currentDate,
    //             next: currentDate,
    //             startDate: startDateFormat,
    //             endDate: endDateFormat,
    //           },
    //         });
    //         return sets;
    //       },
    //       []
    //     );
    // } else {
    return (
      data &&
      data.Status.reduce<recordType[]>((sets, currentDate, index) => {
        const nextDate = data.Status[index + 1];
        if (nextDate) {
          const startDate = moment(currentDate.Date);
          const endDate = moment(nextDate.Date);
          const diff = moment.duration(endDate.diff(startDate));
          const startDateFormat = convertApiDate(currentDate.Date, true);
          const endDateFormat = convertApiDate(nextDate.Date, true);
          const diffSecs = diff.asSeconds();
          sets.push({
            value: diffSecs,
            type: currentDate.Closed ? Variant.error : Variant.success,
            payload: {
              current: currentDate,
              next: nextDate,
              startDate: startDateFormat,
              endDate: endDateFormat,
            },
          });
        } else {
          const startDate = moment(currentDate.Date);
          const endDate = moment().utcOffset(0, true);
          const diff = moment.duration(endDate.diff(startDate));
          const startDateFormat = convertApiDate(currentDate.Date, true);
          const endDateFormat = convertApiDate(endDate.format(), true);
          const diffSecs = diff.asSeconds();
          sets.push({
            value: diffSecs,
            type: currentDate.Closed ? Variant.error : Variant.success,
            payload: {
              current: currentDate,
              next: {
                Date: endDate.format(),
                Closed: currentDate.Closed,
              },
              startDate: startDateFormat,
              endDate: endDateFormat,
            },
          });
        }
        return sets;
      }, [])
    );
  }, [data]);
  return (
    <>
      <Title level={5} className="first-item-text">
        {t('board')} {t('status')}
      </Title>
      {dataSet && dataSet.length > 0 ? (
        <CameraStatusTimeLine records={dataSet} />
      ) : (
        <h5>-</h5>
      )}
    </>
  );
}
interface CameraStatusTimeLineProps {
  records: recordType[];
}

const CameraStatusTimeLine = ({ records }: CameraStatusTimeLineProps) => {
  const { t } = useTranslation();
  if (records.length === 0) return <></>;
  const firstdate = moment(records[0].payload?.current.Date);
  const lastDate = moment(records[records.length - 1].payload?.current.Date);
  const duration = moment.duration(lastDate.diff(firstdate)).asSeconds();
  const getDurationString = (record: recordType) => {
    const stringValue =
      t('Camera-Status.OpenClose', {
        startDate: record.payload?.startDate,
        endDate: record.payload?.endDate,
      }) +
      t('status') +
      ':' +
      t('Camera-Status.Duration', {
        duration: moment(record.payload?.next.Date).diff(
          moment(record.payload?.current.Date),
          'm'
        ),
      }) +
      (record.payload?.current.Closed === 1 ? t('off') : t('on'));
    return stringValue;
  };

  return (
    <USteps>
      {records.map((record, index) => (
        <Tooltip
          key={index}
          title={getDurationString(record)}
          color={'#7a7a7a'}
        >
          <UStep
            payload={record}
            maxRecord={duration}
            content={getDurationString(record)}
          />
        </Tooltip>
      ))}
    </USteps>
  );
};
// Ustep record base props
interface RecordProps<T = unknown> {
  value: number;
  type: Variant;
  payload?: T;
}

export default TimeLineCard;
