import { useAppSelector } from '@Store/hooks';
import { CreateBeaconRule, IBeaconRulesForm } from '@Types/BeaconRule';

export function useMergeBeaconsAndReceivers() {
  const brandReceivers = useAppSelector(s => s.Receiver.brandReceivers);
  const brandBeacons = useAppSelector(s => s.Beacons.brandBeacons);

  function mergeBeaconsAndReceivers(
    { ReceiverIds, BeaconIds }: IBeaconRulesForm,
    storeIds: number[]
  ) {
    const mergedBeaconsAndReceivers: CreateBeaconRule['BeaconReceivers'] = [];

    for (const storeId of storeIds) {
      // Beacon
      const allBeaconIdsOfStore = brandBeacons.data
        .filter(s => s.Id === storeId)
        .map(s => s.Beacons)
        .flat()
        .map(beacon => beacon.Id);

      const _beaconIds = [];

      for (const beaconId of BeaconIds)
        if (allBeaconIdsOfStore.includes(beaconId)) _beaconIds.push(beaconId);

      // Receiver
      const allReceiverIdsOfStore = brandReceivers.data
        .filter(s => s.Id === storeId)
        .map(s => s.Receivers)
        .flat()
        .map(receiver => receiver.Id);

      const _receiverIds = [];

      for (const receiverId of ReceiverIds)
        if (allReceiverIdsOfStore.includes(receiverId))
          _receiverIds.push(receiverId);

      mergedBeaconsAndReceivers.push({
        StoreId: storeId,
        BeaconIds: _beaconIds,
        ReceiverIds: _receiverIds,
      });
    }

    return mergedBeaconsAndReceivers;
  }

  return mergeBeaconsAndReceivers;
}
