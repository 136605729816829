import { Skeleton } from 'antd';
import { ReactElement } from 'react';
type Props = {
  loader?: ReactElement;
};

function PageSkeletons({
  loader = <Skeleton paragraph={{ rows: 10 }} active />,
}: Props) {
  return loader;
}

export default PageSkeletons;
