import { InfoOutlined } from '@ant-design/icons';
import DeleteButton from '@Components/Card/DeleteButton';
import EditButton from '@Components/Card/EditButton';
import AutoReportsService from '@Services/Api/AutoReportsService';
import { AllReportFetchResponse, IReport } from '@Types/Report';
import { apiCall } from '@Utils/index';
import { convertPeriodToText } from '@Utils/Period';
import { Button, Modal, Space, Switch, Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type ReportTableProps = TableProps<IReport> & {
  reports: AllReportFetchResponse;
  refetch: () => void;
};

export default function ReportTable({
  reports,
  refetch,
  ...restProps
}: ReportTableProps) {
  const { t } = useTranslation();

  const [showStoreModal, setShowStoreModal] = useState(false);
  const [selectedReportId, setselectedReportId] = useState<number>();
  const showStoreModalOnCancel = () => {
    setShowStoreModal(false);
  };
  const handleStoreModal = (reportId: number) => {
    setselectedReportId(reportId);
    setShowStoreModal(true);
  };

  async function handleDeleteReportClick(reportId: number) {
    try {
      const req = () => new AutoReportsService().DeleteAutoReport(reportId);
      await apiCall(req, 'deleteReport');
      refetch();
    } catch (e) {}
  }

  async function handleReportStatusChange(reportId: number, isActive: boolean) {
    try {
      await apiCall(() =>
        new AutoReportsService().ChangeReportStatus(reportId, isActive)
      );
      refetch();
    } catch (e) {}
  }

  const columns: ColumnsType<IReport> = [
    {
      align: 'center',
      dataIndex: 'Name',
      title: t('name'),
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },

    {
      align: 'center',
      dataIndex: 'BrandName',
      title: t('brands'),
      sorter: (a, b) => a.BrandName.localeCompare(b.BrandName),
    },
    {
      align: 'center',
      dataIndex: 'AutoReportTypeName',
      title: t('reportType'),
    },
    {
      align: 'center',
      dataIndex: 'ActionTime',
      title: t('hour'),
    },
    {
      align: 'center',
      dataIndex: 'AutoReportScheduleDateType',
      title: 'Period',
      render: (_, period) =>
        t(`${convertPeriodToText(period.AutoReportScheduleDateType)}`),
    },
    {
      align: 'center',
      dataIndex: 'Brands',
      title: t('StoreName') + ' - ' + t('email'),
      render: (_, report) => (
        <>
          <Button
            shape="circle"
            icon={<InfoOutlined size={5} />}
            onClick={() => handleStoreModal(report.Id)}
          />
          {report.Id === selectedReportId && (
            <Modal
              title={t('StoreName') + ' - ' + t('email')}
              open={showStoreModal}
              onOk={showStoreModalOnCancel}
              onCancel={showStoreModalOnCancel}
              destroyOnClose
              cancelButtonProps={{ hidden: true }}
            >
              {report.AutoReportReceivers.map(receiver => (
                <p>{receiver.StoreName + ' - ' + receiver.UserEmail}</p>
              ))}
            </Modal>
          )}
        </>
      ),
    },
    {
      align: 'center',
      title: t('active'),
      dataIndex: 'active',
      render: (_, report) => (
        <Switch
          checked={report.IsActive}
          onChange={checked => handleReportStatusChange(report.Id, checked)}
        />
      ),
    },
    {
      align: 'center',
      title: t('actionButtons'),
      dataIndex: 'actionButtons',
      render: (_, report) => (
        <Space>
          <EditButton to={`/reports/${report.Id}`} />
          <DeleteButton onConfirm={() => handleDeleteReportClick(report.Id)} />
        </Space>
      ),
    },
  ];
  return (
    <Table
      dataSource={reports.Data}
      columns={columns}
      pagination={false}
      {...restProps}
    />
  );
}
