import { DeleteOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import { useTranslation } from 'react-i18next';

type DeleteSelectedsButtonProps = ButtonProps & {};

export function DeleteSelectedsButton({
  onClick,
  ...restProps
}: DeleteSelectedsButtonProps) {
  const { t } = useTranslation();

  return (
    <Button
      onClick={onClick}
      danger
      type="primary"
      icon={<DeleteOutlined />}
      {...restProps}
    >
      {t('deleteSelecteds')}
    </Button>
  );
}
