export const GET_ALL_BOARDS = 'BOARD/GET_ALL_BOARDS';
export const GET_BOARD_DETAIL = 'BOARD/GET_BOARD_DETAIL';
export const GET_ARCHIVED_BOARDS = 'BOARD/GET_ARCHIVED_BOARDS';
export const GET_UNARCHIVED_BOARDS = 'BOARD/GET_UNARCHIVED_BOARDS';
export const GET_ALL_ARCHIVED_BOARDS = 'BOARD/GET_ALL_ARCHIVED_BOARDS';
export const GET_ALL_UNARCHIVED_BOARDS = 'BOARD/GET_ALL_UNARCHIVED_BOARDS';

export const GET_BOARD_WIFI_CONF = 'BOARD/GET_BOARD_WIFI_CONF';

export const SET_ALL_BOARDS = 'BOARD/SET_ALL_BOARDS';

export const RESET_ALL_BOARDS = 'BOARD/RESET_ALL_BOARDS';
export const RESET_CURRENT_BOARD = 'BOARD/RESET_CURRENT_BOARD';
